import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Autocomplete, Button, TextField } from '@mui/material';
import { BsCheck, BsChevronDoubleRight, BsChevronRight } from "react-icons/bs"
import { FiSave } from "react-icons/fi"
import moment from "moment";
import Parse from "html-react-parser";
import RichTextEditor from '../../../../Component/RichTextEditor';
import Swal from 'sweetalert2';
import "./AsanaProjects.css"
import { BsFillTrashFill } from 'react-icons/bs';
import Select from 'react-select';
const AsanaProjects = () => {
    const { projectId } = useParams()
    const taskName = useRef()
    const assigneeName = useRef()
    const projectName = useRef()
    const dueOn = useRef()
    const taskDescRef = useRef()
    const taskComment = useRef()
    const divRef = useRef(null);
    const buttonRef = useRef(null);
    const apiKey = '1/1181186354658771:8ce46a50ca6dd71089785591c1f34bc6';
    const [allProjects, setAllProjects] = useState([])
    const [singleProject, setSingleProject] = useState()
    const [singleTaskInfo, setSingleTaskInfo] = useState([])
    const [allTasks, setAllTasks] = useState([]);
    const [allPeoples, setAllPeoples] = useState([])
    const [show, setShow] = useState(false)

    const [singleTaskId, setSingleTaskId] = useState()
    const [taskComments, setTaskComments] = useState([])

    console.log()

    const [commentDesc, setCommentDesc] = useState("");
    const getCommentDesc = (value) => {
        setCommentDesc(value);
    };

    useEffect(() => {
        const allProjects = {
            method: 'GET',
            url: `https://app.asana.com/api/1.0/workspaces/1181186357184897/projects`,
            headers: { Authorization: `Bearer ${apiKey}`, }
        };
        axios
            .request(allProjects)
            .then(function (response) {
                setAllProjects(response.data?.data);
            })

        const projects = {
            method: 'GET',
            url: `https://app.asana.com/api/1.0/projects/${projectId}`,
            headers: { Authorization: `Bearer ${apiKey}`, }
        };
        axios
            .request(projects)
            .then(function (response) {
                setSingleProject(response.data?.data);
            })

        const allTasks = {
            method: 'GET',
            url: `https://app.asana.com/api/1.0/projects/${projectId}/tasks`,
            params: {
                opt_fields: 'assignee.name,assignee_status,assignee_section,completed,completed_by.name,due_on,name,projects.name,likes,html_notes,'
            },
            headers: { Authorization: `Bearer ${apiKey}`, }
        };
        axios
            .request(allTasks)
            .then(function (response) {

                setAllTasks(response.data?.data);
            })

        const peoples = {
            method: 'GET',
            url: `https://app.asana.com/api/1.0/workspaces/1181186357184897/users`,
            params: { opt_fields: 'name,email,photo,' },
            headers: { Authorization: `Bearer ${apiKey}`, }
        };
        axios
            .request(peoples)
            .then(function (response) {
                setAllPeoples(response.data?.data);
            })
    }, [projectId])

    const handleSingleTask = (taskId) => {
        setSingleTaskId(taskId)
        const singleTask = {
            method: 'GET',
            url: `https://app.asana.com/api/1.0/tasks/${taskId}`,
            headers: { Authorization: `Bearer ${apiKey}`, }
        };
        axios
            .request(singleTask)
            .then(function (response) {

                setSingleTaskInfo(response.data?.data);
            })

        const taskComments = {
            method: 'GET',
            url: `https://app.asana.com/api/1.0/tasks/${taskId}/stories`,
            headers: { Authorization: `Bearer ${apiKey}`, }
        };
        axios
            .request(taskComments)
            .then(function (response) {
                setTaskComments(response.data?.data);
            })
    }

    useEffect(() => {
        handleSingleTask(singleTaskId)
    }, [singleTaskId])

    function extractInitials(inputString) {
        const regexPattern = /(?:\b\w|(?<=\.))+/g;
        const matches = inputString?.match(regexPattern);

        if (matches) {
            const initials = matches?.map(match => match[0].toUpperCase()).join('');
            return initials;
        } else {
            return null; // Return null if no initials are found in the input string.
        }

    }

    // Task Submit
    const storeTask = (e) => {
        const assignee = assigneeName.current.value
        const separatorIndex = assignee.indexOf("-");
        const assigneeEmail = assignee.substring(0, separatorIndex);
        axios
            .post(
                `https://app.asana.com/api/1.0/tasks`,
                {
                    data: {
                        workspace: '1181186357184897',
                        name: taskName?.current?.value,
                        assignee: assigneeEmail,
                        due_on: dueOn?.current?.value,
                        notes: taskDescRef?.current?.value
                    }
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${apiKey}`,
                    },
                }
            )
            .then((response) => {
                if (response.status == 201) {
                    Swal.fire({
                        icon: "success",
                        text: "task created successfully",
                        confirmButtonColor: "#5eba86",
                    });
                    e.target.reset();

                } else {
                    Swal.fire({
                        icon: "error",
                        text: response.data?.errors[0]?.message,
                        confirmButtonColor: "#5eba86",
                    });
                }

            });
        e.preventDefault();
    };

    const storeComment = (e) => {
        axios
            .post(
                `https://app.asana.com/api/1.0/tasks/${singleTaskId}/stories`,
                {
                    data: {
                        workspace: '1181186357184897',
                        html_text: `<body>${taskComment.current.value}</body>`,
                    }
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${apiKey}`,
                    },
                }
            )
            .then((response) => {
                if (response.status == 201) {
                    Swal.fire({
                        icon: "success",
                        text: "task created successfully",
                        confirmButtonColor: "#5eba86",
                    });
                    e.target.reset();
                    setCommentDesc("", "html");
                    handleSingleTask()
                } else {
                    Swal.fire({
                        icon: "error",
                        text: response.data?.errors[0]?.message,
                        confirmButtonColor: "#5eba86",
                    });
                }

            });
        e.preventDefault();
    };

    const deleteComment = async (gid) => {
        const isConfirm = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "green",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            return result.isConfirmed;
        });

        if (!isConfirm) {
            return;
        }

        if (isConfirm) {
            axios
                .delete(`https://app.asana.com/api/1.0/stories/${gid}`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${apiKey}`,
                    },
                })
                .then((res) => {
                    Swal.fire({
                        icon: "success",
                        text: "comment deleted successfully",
                        confirmButtonColor: "#5eba86",
                    });
                    handleSingleTask()
                });
        }
    };

    const addNewTask = () => {
        setSingleTaskInfo([])
        setTaskComments([])
        setSingleTaskId()
    }

    const deleteTask = async (taskId) => {
        const isConfirm = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "green",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            return result.isConfirmed;
        });

        if (!isConfirm) {
            return;
        }

        if (isConfirm) {
            axios
                .delete(`
                https://app.asana.com/api/1.0/tasks/${taskId}`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${apiKey}`,
                    },
                })
                .then((res) => {
                    Swal.fire({
                        icon: "success",
                        text: "comment deleted successfully",
                        confirmButtonColor: "#5eba86",
                    });
                    handleSingleTask()
                });
        }
    };
    const deleteRow = (id) => {
        const updatedData = allTasks.filter((row) => row.id !== id);
        setAllTasks(updatedData);
    };

    return (
        <div className='asana_projects mt-5 p-4'>
            <div className='content_header_top'>
                <div className="flex_between">
                    <div className='p-3'>
                        <div className="flex_start">
                            <div className="icon_wrapper" style={{
                                backgroundColor: `${(singleProject?.color == `dark-red` && `#E53B52`)
                                    || (singleProject?.color == `dark-orange` && `#F96237`)
                                    || (singleProject?.color == `light-orange` && `#FA9A27`)
                                    || (singleProject?.color == ` dark-brown` && `#ECC42C`)
                                    || (singleProject?.color == `light-green` && `#A5CF3F`)
                                    || (singleProject?.color == `dark-green` && `#66D273`)
                                    || (singleProject?.color == `light-teal` && `#42C4AB`)
                                    || (singleProject?.color == `dark-teal` && `#32A9E8`)
                                    || (singleProject?.color == `light-blue` && `#4886DD`)
                                    || (singleProject?.color == `dark-purple` && ` #4886DD`)
                                    || (singleProject?.color == `light-purple` && `#A962E0`)
                                    || (singleProject?.color == `light-pink` && `#f9aaef`)
                                    || (singleProject?.color == `dark-pink` && `#E84F9C`)
                                    || (singleProject?.color == `light-red` && `#FA91AE`)
                                    || (singleProject?.color == `light-warm-gray` && `#8DA3A6`)
                                    || (singleProject?.color == `none` && `#C7C4C4`)}`
                            }} >
                            </div>
                            <div>
                                <h5>{singleProject?.name}</h5>
                                <div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div>

                    </div>
                </div>
            </div>
            <div className='content_header_bottom'>
                <div className="flex_between">
                    <div className='p-3'>
                        <div className="flex_start">
                            <div className="icon_wrapper">
                            </div>
                            <div>
                                <h5>{singleProject?.name}</h5>
                                <div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div>

                    </div>
                </div>
            </div>

            <table className='project_table table'>
                <thead>
                    <tr>
                        <th>Task name</th>
                        <th>Assignee</th>
                        <th>Due date</th>
                    </tr>
                </thead>
                <tbody>
                    {allTasks.map((task, index) => (
                        <tr key={index}>
                            <td ref={buttonRef} onClick={() => { setShow(true); handleSingleTask(task.gid) }} className={`task_name`} >
                                <div className='flex_between'>
                                    <span > {task.name}</span>
                                    <span className={`arrow_right ms-auto opacity-0`}><BsChevronRight /></span>
                                </div>
                            </td>
                            <td>
                                {task?.assignee?.name}
                            </td>
                            <td>
                                {task.due_on && moment(task.due_on).format("DD MMM")}
                            </td>

                            {/* <td>
                                <button onClick={() => deleteRow(index + 1)}>Delete {index + 1}</button>
                            </td> */}
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={4} onClick={() => { setShow(true); addNewTask() }}>
                            <button className='add_task_btn'>Add task..</button>
                        </td>
                    </tr>
                </tbody>
            </table>

            {/* Task Slide Div */}

            {show && <div ref={divRef} className={`task_slidebox ${show ? "show" : "hide"}`}>
                <div className='topbar mt-5 '>
                    <div className='flex_between'>
                        <Button variant='outlined' className="" startIcon={<BsCheck />}>
                            Completed
                        </Button>
                        <div>
                            <span onClick={() => deleteTask(singleTaskId)} style={{ cursor: "pointer" }} className="me-3"><BsFillTrashFill size={20} title='delete task' />

                            </span>
                            <span onClick={() =>   {setShow(false); setAllPeoples([]); window.location.reload() }} style={{ cursor: "pointer" }}><BsChevronDoubleRight /></span>
                        </div>
                    </div>
                </div>
                <div className='task_body_wrapper'>
                    <div className="task_body">
                        <h4 className='mb-3'>
                            <input type="text" className='form-control w-75 ' ref={taskName} defaultValue={singleTaskInfo?.name ? singleTaskInfo?.name : ""} />

                        </h4>
                        <div className=" d-flex">
                            <div className="w-25">
                                <p className='mb-3'>Assignee</p>
                                <p>Due date</p>
                                <p>Projects</p>
                                <p>Description</p>
                            </div>
                            <div className="w-75">
                                <p> <Autocomplete
                                    id="disable-clearable-1"
                                    options={allPeoples?.map((option) => option?.email + " - " + option.name)}
                                    getOptionSelected={singleTaskInfo?.assignee?.name}
                                    // defaultValue={singleTaskInfo?.assignee?.name}
                                    renderInput={(params) => (
                                        <>
                                            <TextField {...params} label="" variant="standard" inputRef={assigneeName} />
                                        </>
                                    )}
                                />
                                   
                                </p>
                                <p><input type="date" className='form-control form-control-sm' defaultValue={singleTaskInfo?.due_on ? singleTaskInfo?.due_on : ""} ref={dueOn} /></p>

                                <p>
                                    <Autocomplete
                                        id="disable-clearable-1"
                                        options={allProjects?.map(
                                            (project) => project?.name
                                        )}
                                        defaultValue={
                                            singleTaskInfo?.projects?.[0]?.name
                                        }
                                        renderInput={(params) => (
                                            <TextField {...params} label="" variant="standard" inputRef={projectName} />
                                        )}
                                    />

                                </p>
                            </div>
                        </div>
                        <div className="">

                            {singleTaskInfo?.notes ? <textarea name="" id="" rows="8" className="form-control" defaultValue={singleTaskInfo?.notes} /> : <textarea name="" id="" rows="8" className="form-control" ref={taskDescRef} />}
                        </div>

                        <div className='save_task'>
                            <Button onClick={storeTask} variant="outlined" startIcon={<FiSave />}>
                                save
                            </Button>
                        </div>

                    </div>

                    <div className='task_comment'>
                        <div className="comment_list">
                            <div className="comment_user">
                                {taskComments.map((comment) => {
                                    // Your original date string
                                    const originalDateString = comment?.created_at;

                                    // Parse the original date string into a Moment.js object
                                    const originalDate = moment(originalDateString);

                                    // Get the current time as a Moment.js object
                                    const currentTime = moment();

                                    // Calculate the difference between the current time and the original date
                                    const duration = moment.duration(currentTime.diff(originalDate));
                                    const days = duration.days();
                                    const hours = duration.hours();
                                    const minutes = duration.minutes();

                                    return (
                                        <div className="single_comment flex_between mb-3">
                                            <div className='flex_start'>
                                                <div className="icon_box">
                                                    {extractInitials(comment.created_by.name)?.slice(0, 2)}
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <div className="flex_start">
                                                        <h5 className='me-2'><b>{comment.created_by.name} </b></h5>
                                                        <small ><sup className='me-2'>.</sup>

                                                            {days >= 1 ? (days == 1 ? days + " day ago" : days + " days ago")
                                                                :
                                                                (hours && hours == 1 ? hours + " hour ago" : hours + " hours ago")
                                                                    ?
                                                                    minutes && minutes == 1 ? minutes + " minute ago" : minutes + " minutes ago" :
                                                                    ""}
                                                        </small>
                                                    </div>
                                                    <p className="mb-0" >{Parse(`${comment.text}`)}</p>
                                                </div>
                                            </div>
                                            <div className='three_dot align-self-start' onClick={() => deleteComment(comment.gid)}>
                                                <BsFillTrashFill />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {singleTaskId && <div className='new_comment_box'>
                    <div className="d-flex">
                        <div className="icon_box ">
                            {extractInitials(taskComments[0]?.created_by.name)?.slice(0, 2)}
                        </div>
                        <div className="flex-grow-1">
                            <RichTextEditor
                                joditRef={taskComment}
                                getValue={getCommentDesc}
                                value={commentDesc}
                            />
                        </div>
                    </div>
                    <div className='flex_end pt-2'>
                        <Button onClick={storeComment} variant="outlined" startIcon={<FiSave />}>
                            save
                        </Button>
                    </div>
                </div>}

            </div>
            }



        </div >
    )
}

export default AsanaProjects