import React, { useEffect, useRef, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { IoIosArrowUp } from "react-icons/io";
import { ImAttachment } from "react-icons/im";
import { MdGif } from "react-icons/md";
import { BsEmojiSmile } from "react-icons/bs";

import { motion, AnimatePresence } from "framer-motion";
import InboxDropdownButton from "../../../../Component/InboxDropdownButton";
import { cn } from "../../../../utils/cn";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";
import moment from "moment";

export default function ConversationSection({ type, parentReload, setParentReload }) {
  const { slug } = useParams()
  const [reload, setReload] = useState(false);
  const [chatInput, setChatInput] = useState("");
  const [textareaHeight, setTextareaHeight] = useState();
  const [conversation, setConversation] = useState({
    conversation_info: {},
    conversation_messages: []
  });
console.log("outlet parent" , parentReload)

  const handleMessageSend = (id) => {
   
    axios
      .post(
        `${BACKEND_BASE_URL}/api/v3/rianaire/chat/${type}/conversation/send-message`,
        {
          message: chatInput,
          conversation_id: id,
          type: "message"
        },
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
          },
        }
      ).then((response) => {
        if (response.data.status == 200) {
          setReload((prev) => !prev)
          setParentReload((prev) => !prev)
          setChatInput("")
        }
      })

  }
  useEffect(() => {
    let url = null;
    switch (type) {
      case "order":
        url = `${BACKEND_BASE_URL}/api/v3/rianaire/chat/order/conversation/${slug}`;
        break;
      case "support":
        url = `${BACKEND_BASE_URL}/api/v3/rianaire/chat/support/conversation/${slug}`;
        break;
      default:
        break;
    }

    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        if (type === "order") {
          setConversation({
            conversation_info: res.data?.conversation,
            conversation_messages: res.data?.order_conversation_messages?.data
          });

        }
        else {
          setConversation({
            conversation_info: res.data?.conversation,
            conversation_messages: res.data?.order_conversation_messages?.data
          });
        }
      });
  }, [reload, slug])

  return (
    <>
      <div className="position-relative" style={{ position: "relative", height: "100%", minHeight: "750px", maxHeight: "750px", width: "66.6667%" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid rgba(140, 140, 140, 0.2)", padding: "10px", paddingBottom: "1px", boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)" }}>
          <div>
            <h4 style={{ fontSize: "20px", lineHeight: "1" }}>{conversation['conversation_info']?.name}</h4>
          </div>
          <InboxDropdownButton dropdownIcon={<BsThreeDots size={22} />}>
            <li style={{ cursor: "pointer", padding: "8px 16px", ":hover": { backgroundColor: "#D1D5DB" } }}>option 1</li>
            <li style={{ cursor: "pointer", padding: "8px 16px", ":hover": { backgroundColor: "#D1D5DB" } }}>option 2</li>
            <li style={{ cursor: "pointer", padding: "8px 16px", ":hover": { backgroundColor: "#D1D5DB" } }}>option 3</li>
          </InboxDropdownButton>
        </div>

        <AnimatePresence>
          <motion.div initial={{ maxHeight: 550 }} animate={{ maxHeight: textareaHeight ? 0 : 550 }} transition={{ duration: 0.2 }} style={{ overflowY: "scroll", paddingBottom: !textareaHeight ? "7px" : undefined }} className="p-2">
            {/* UserInfo */}
            <div className="d-flex gap-3" style={{ marginBottom: "5px", marginTop: "3px", padding: "4px" }}>
              <div
                style={{
                  height: "fit-content",
                  minHeight: "64px",
                  width: "64px",
                  minWidth: "64px",
                  borderRadius: "50%",
                  backgroundColor: "#F87171"
                }}>
              </div>
              <div className="flex: 1" >
                <h4 className="">{conversation['conversation_info']?.name}</h4>
                <p style={{ fontSize: "12px" }}>Front-End Developer | React JS </p>
              </div>

            </div>
            {/* Hr line */}
            <div style={{ position: "relative", paddingBottom: "7px" }}>
              <div style={{ position: "absolute", left: "0", top: "10px", height: "0.5px", width: "100%", backgroundColor: "rgba(140, 140, 140, 0.2)" }}></div>
              <span style={{ position: "absolute", left: "45%", zIndex: 40, backgroundColor: "white", padding: "2px", fontSize: "small", color: "rgba(0, 0, 0, 0.6)" }}>TODAY</span>
              <div style={{ position: "absolute", right: "0", top: "10px", height: "0.5px", backgroundColor: "rgba(140, 140, 140, 0.2)" }}></div>
            </div>
            {/* Conversation */}
            <div style={{ spaceY: "4px", padding: "20px 12px" , display:"flex", flexDirection:"column-reverse"}}>
              {conversation?.conversation_messages?.map((conversation) => (
                <div style={{ display: "flex", gap: "12px" }}>
                  <div style={{ height: "fit-content", minHeight: "40px", width: "fit-content", minWidth: "40px", borderRadius: "50%", backgroundColor: "#F87171" }}></div>
                  <div>
                    <div style={{ marginBottom: "3px", display: "flex", alignItems: "center", gap: "8px" }}>
                      <h5 style={{ lineHeight: "1.5", color: "rgba(0, 0, 0, 0.9)" }}>{conversation?.conversation_user?.user_data?.name}</h5>
                      <span style={{ paddingBottom: "2px", alignSelf: "flex-start", fontSize: "large" }}>.</span>
                      <small style={{ lineHeight: "1.5", color: "rgba(0, 0, 0, 0.6)" }}>{moment(conversation?.created_at).format("h:mm A")}</small>
                    </div>
                    <p style={{ fontSize: "small" }}>{conversation?.message}</p>
                  </div>
                </div>
              ))}

            </div>
          </motion.div>

          {/* Input area */}
          <div className="position-absolute bottom-0 w-100 chat_input">
            <div style={{ borderBottom: textareaHeight ? "1px solid rgba(140, 140, 140, 0.2)" : "2px solid rgba(140, 140, 140, 0.2)", padding: "3px" }}>
              <div style={{ marginBottom: "2px", display: "flex", gap: "8px" }}>
                <motion.textarea
                  initial={{ height: "100%" }}
                  animate={{ height: textareaHeight ? 570 : "100%" }}
                  transition={{ duration: 0.2 }}
                  rows={3}
                  placeholder="Replying as Sarfaa.."
                  style={{ width: "95%", borderRadius: "8px", border: "none", backgroundColor: "#f4f2ee", fontSize: "small", boxShadow: "none" }}
                  value={chatInput}
                  onChange={(e) => setChatInput(e.target.value)} className="p-3">

                </motion.textarea>
                <div style={{ display: "flex", alignItems: "end", justifyContent: "center", borderRadius: "50%", padding: "8px", ":hover": { backgroundColor: "rgba(140, 140, 140, 0.1)" } }} onClick={() => setTextareaHeight((prev) => !prev)}>
                  <IoIosArrowUp size={22} style={{ cursor: "pointer" }} />
                </div>
              </div>
              <span style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.6)" }}>Members won&rsquo;t see which admin responded.</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 16px 7px 16px",
                paddingTop: "3px",


              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", padding: "8px", cursor: "pointer", ":hover": { backgroundColor: "rgba(140, 140, 140, 0.1)" } }}>
                  <ImAttachment size={22} />
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", padding: "8px", cursor: "pointer", ":hover": { backgroundColor: "rgba(140, 140, 140, 0.1)" } }}>
                  <MdGif size={35} />
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", padding: "8px", cursor: "pointer", ":hover": { backgroundColor: "rgba(140, 140, 140, 0.1)" } }}>
                  <BsEmojiSmile size={22} />
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "1px" }}>
                <button
                  disabled={!chatInput}
                  onClick={() => handleMessageSend(conversation['conversation_info']?.id)}
                >
                  Send
                </button>
                <div style={{ cursor: "pointer", borderRadius: "50%", padding: "8px", transition: "all 0.3s", ":hover": { backgroundColor: "#F3F3F3" } }}>
                  <BsThreeDots size={22} />
                </div>
              </div>
            </div>
          </div>

        </AnimatePresence>


      </div>

    </>
  );
}
