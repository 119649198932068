import React from "react";
import { FaMoneyBill } from "react-icons/fa";
import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import Parse from "html-react-parser";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Autocomplete, TextField } from "@mui/material";
import { HiLightBulb } from "react-icons/hi";
import { UserContext } from "../../../App";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import { ToastAlert } from "../../../Component/ToastAlert";
import {
    Box,
    LinearProgress,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from "@mui/material";

// import "./createProjectDashboard.css";
import { MdAccessTimeFilled } from "react-icons/md";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";
import { BsCheckCircleFill } from "react-icons/bs";

const CreateNewOrderTwo = () => {
    const { allMainServices, currUserInfo } = useContext(UserContext);
    const [userinfo, setUserInfo] = useState(false);
    const [selectedUserInfo, setSelectedUserInfo] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [search, setSearch] = useState("");
    const [excludedPluginSearch, setExcludedPluginSearch] = useState("");
    const [shopifyPluginSearch, setShopifyPluginSearch] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(
        getLocalStorageWithExpiry("userInfo")?.phone
    );

    const subServiceName = useRef();
    const ServiceType = useRef();
    const SectionTypeDropdown = useRef();
    const sectionType = useRef();
    const numOfPage = useRef();
    const name = useRef();
    const email = useRef();
    const phone = useRef();
    const company = useRef();
    const address = useRef();
    const excludedPluginDiv = useRef();

    // Grand total
    const [grandTotalCostUSD, setGrandTotalCostUSD] = useState(0);
    const [grandTotalCostBDT, setGrandTotalCostBDT] = useState(0);
    const [grandTotalDuration, setGrandTotalCostDuration] = useState(0);

    // Checkbox Total
    const [checkboxCostUsd, setCheckboxCostUsd] = useState(0);
    const [checkboxCostBdt, setCheckboxCostBdt] = useState(0);
    const [checkboxTotalDuration, setCheckboxcheckboxTotalDuration] = useState(0);

    // Show/Hide
    const [show, setShow] = useState(false);
    const [togglePluginList, setTogglePluginList] = useState(false);
    const [pageShow, setPageShow] = useState(false);
    const [allPluginShow, setAllPluginShow] = useState(false);
    const [includeExcludePluginShow, setIncludeExcludePluginShow] =
        useState(false);

    // DROPDOWN
    const [allSubServices, setAllSubServices] = useState([]);
    const [allDesignTypes, setAllDesignTypes] = useState([]);
    const [allNumberOfPage, setAllNumberOfPage] = useState([]);
    const [sectionTypeDropdown, setSectionTypeDropdown] = useState([]);

    // RADIO
    const [numOfpageRadio, setNumOfpageRadio] = useState();
    const [twoStepValRadio, setTwoStepValRadio] = useState([]);

    // INPUT
    const [inputVal, setInputVal] = useState([]);
    const [radioUsdPrice, setRadioUsdPrice] = useState();
    const [radioBdtPrice, setRadioBdtPrice] = useState();
    const [numOfPageDuration, setNumOfPageDuration] = useState();

    const [webMaintenanceValue, setWebMaintenanceValue] = useState([]);

    // CHECKBOX
    const [sectionTypeCheckbox, setSectionTypeCheckbox] = useState([]);
    const [checkboxUSDPrice, setCheckboxUSDPrice] = useState([]);
    const [checkboxBDTPrice, setCheckboxBDTPrice] = useState([]);
    const [checkboxDuration, setCheckboxDuration] = useState([]);

    const [checkboxUSDPriceTwo, setCheckboxUSDPriceTwo] = useState([]);
    const [checkboxBDTPriceTwo, setCheckboxBDTPriceTwo] = useState([]);
    const [checkboxDurationTwo, setCheckboxDurationTwo] = useState([]);

    const [checkboxUSDPriceThree, setCheckboxUSDPriceThree] = useState([]);
    const [checkboxBDTPriceThree, setCheckboxBDTPriceThree] = useState([]);
    const [checkboxDurationThree, setCheckboxDurationThree] = useState([]);

    const [
        checkboxUSDPriceAdditionalService,
        setCheckboxUSDPriceAdditionalService,
    ] = useState([]);
    const [
        checkboxBDTPriceAdditionalService,
        setCheckboxBDTPriceAdditionalService,
    ] = useState([]);
    const [
        checkboxDurationAdditionalService,
        setCheckboxDurationAdditionalService,
    ] = useState([]);

    const [
        checkboxUSDPriceAdditionalAppPlugin,
        setCheckboxUSDPriceAdditionalAppPlugin,
    ] = useState([]);
    const [
        checkboxBDTPriceAdditionalAppPlugin,
        setCheckboxBDTPriceAdditionalAppPlugin,
    ] = useState([]);
    const [
        checkboxDurationAdditionalAppPlugin,
        setCheckboxDurationAdditionalAppPlugin,
    ] = useState([]);

    const [additionalServiceInfoCheckbox, setAdditionalServiceInfoCheckbox] =
        useState([]);
    const [additionalAppPluginInfoCheckbox, setAdditionalAppPluginInfoCheckbox] =
        useState([]);
    const [dynamicModuleInfoCheckbox, setdynamicModuleInfoCheckbox] = useState(
        []
    );
    const [additionalServices, setAdditionalServices] = useState([]);
    const [additionalAppPlugin, setAdditionalAppPlugin] = useState([]);

    const [filteredAdditionalAppPlugin, setFilteredAdditionalAppPlugin] =
        useState([]);
    const [
        filteredExcludedAdditionalAppPlugin,
        setFilteredExcludedAdditionalAppPlugin,
    ] = useState([]);
    const [filteredShopifyAppPlugins, setFilteredShopifyAppPlugin] = useState([]);
    const [includeExcludeplugin, setIncludeExcludeplugin] = useState([]);
    const [includeExcludepluginChecked, setIncludeExcludepluginChecked] =
        useState([]);

    // Section Type Value set (web programming , wordpress)
    const [sectionTypeCheckboxValue, setSectionTypeCheckboxValue] = useState([]);
    const [sectionTypeDropdownValue, setSectionTypeDropdwonValue] = useState();
    const [sectionTypeDropdownValueName, setSectionTypeDropdwonValueName] =
        useState([]);

    const [additionalServiceValue, setAdditionalServiceValue] = useState([]);
    const [additionalAppPluginValue, setAdditionalAppPluginValue] = useState([]);
    const [dynamicModuleValue, setDynamicModuleValue] = useState([]);




    // Get All Info
    const renderAllInfo = async () => {
        setSectionTypeCheckbox([]);
        setSectionTypeDropdown([]);
        setAllNumberOfPage([]);
        setSectionTypeDropdwonValue([]);
        setInputVal([]);
        setAdditionalServiceInfoCheckbox([]);
        setAdditionalAppPluginInfoCheckbox([]);
        setdynamicModuleInfoCheckbox([]);
        setIncludeExcludeplugin([]);
        setAdditionalServices([]);
        await axios.get(`${BACKEND_BASE_URL}/api/v2/sub-services/${sessionStorage.getItem("Service_categody_id")}`).then((res) => {
            setAllSubServices(res.data?.sub_services);
        });
    };

    const fetchDesignType = () => {
        setShow(false);
        setPageShow(false);
        setAllNumberOfPage([]);
        setSectionTypeCheckbox([]);
        setSectionTypeDropdown([]);
        setSectionTypeDropdwonValue([]);
        setWebMaintenanceValue([]);
        setInputVal([]);
        setCheckboxCostUsd(0);
        setCheckboxCostBdt(0);
        setCheckboxcheckboxTotalDuration(0);
        setGrandTotalCostUSD(0);
        setGrandTotalCostBDT(0);
        setGrandTotalCostDuration(0);

        axios
            .get(
                `${BACKEND_BASE_URL}/api/v2/child-services/${sessionStorage.getItem("Service_name_id")}/${sessionStorage.getItem("Service_categody_id")}`
            )
            .then((res) => {
                setAllDesignTypes(res.data?.child_services);
                setdynamicModuleInfoCheckbox(res.data?.dynamic_module_info);
                if (subServiceName?.current?.value == 3) {
                    setAllNumberOfPage(res.data?.two_step_value);
                }
                if (subServiceName?.current?.value == 5) {
                    setSectionTypeCheckbox(res.data?.child_services);
                    setAllDesignTypes([]);
                }
            });
    };
    const fetchSectionType = (e) => {
        setShow(false);
        setPageShow(false);
        setAllPluginShow(false);
        setAllNumberOfPage([]);
        setSectionTypeCheckbox([]);
        setSectionTypeDropdown([]);
        setSectionTypeDropdwonValue([]);
        setAdditionalServices([]);
        setIncludeExcludeplugin([]);
        setIncludeExcludepluginChecked([]);
        setWebMaintenanceValue([]);
        setTwoStepValRadio([]);
        setInputVal([]);
        setCheckboxCostUsd(0);
        setCheckboxCostBdt(0);
        setCheckboxcheckboxTotalDuration(0);
        setGrandTotalCostUSD(0);
        setGrandTotalCostBDT(0);
        setGrandTotalCostDuration(0);
        // setCurrValue(e.target.value);
        setFormValue({
            ...formValue,
            serviceType: e.target.value,
        });

        axios
            .get(
                `${BACKEND_BASE_URL}/api/v2/dynamic-dropdown/${sessionStorage.getItem(
                    "Service_name_id"
                )}/${sessionStorage.getItem("Service_categody_id")}/${ServiceType.current.value
                }`
            )
            .then((res) => {
                if (ServiceType.current.value == 1) {
                    setSectionTypeCheckbox([]);
                    setSectionTypeDropdown(res.data?.dynamic_dropdown_info);
                    setAllNumberOfPage(res.data?.number_of_pages);
                    setdynamicModuleInfoCheckbox([]);
                } else if (ServiceType.current.value == 2) {
                    setSectionTypeCheckbox([]);
                    setAdditionalServiceInfoCheckbox(res.data?.additional_service_info);
                    setAdditionalAppPluginInfoCheckbox(
                        res.data?.additional_app_plugin_info
                    );
                    setAllNumberOfPage(res.data?.number_of_pages);
                    setSectionTypeDropdown(res.data?.dynamic_dropdown_info);
                    setdynamicModuleInfoCheckbox([]);
                } else if (ServiceType.current.value == 3) {
                    //! All plugin show
                    setAllPluginShow(true);
                    setSectionTypeDropdown([]);
                    setAllNumberOfPage([]);
                    setAdditionalServiceInfoCheckbox([]);
                    setAdditionalAppPlugin(res.data?.additional_app_plugin_info);
                    setFilteredAdditionalAppPlugin(res.data?.additional_app_plugin_info);
                    setdynamicModuleInfoCheckbox([]);
                } else if (ServiceType.current.value == 4) {
                    setAllNumberOfPage([]);
                    setSectionTypeDropdown(res.data?.dynamic_dropdown_info);
                    setAdditionalServiceInfoCheckbox();
                    setAdditionalAppPluginInfoCheckbox(
                        res.data?.additional_app_plugin_info
                    );
                    setSectionTypeCheckbox(res.data?.number_of_pages);
                    setdynamicModuleInfoCheckbox([]);
                } else if (ServiceType.current.value == 5) {
                    setSectionTypeDropdown(res.data?.dynamic_dropdown_info);
                    setAdditionalServiceInfoCheckbox(res.data?.additional_service_info);
                    setAdditionalAppPluginInfoCheckbox(
                        res.data?.additional_app_plugin_info
                    );
                    setAllNumberOfPage(res.data?.number_of_pages);
                    setTwoStepValRadio(res.data?.two_step_value);
                    setSectionTypeCheckbox([]);
                    setdynamicModuleInfoCheckbox([]);
                } else if (ServiceType.current.value == 6) {
                    setShow(true);
                    setAdditionalServiceInfoCheckbox([]);
                    setSectionTypeCheckbox([]);
                    setTwoStepValRadio(res.data?.two_step_value);
                    setFilteredShopifyAppPlugin(res.data?.two_step_value);
                    setSectionTypeDropdown([]);
                    setdynamicModuleInfoCheckbox([]);
                    setAllNumberOfPage([]);
                } else if (
                    ServiceType.current.value == 7 ||
                    ServiceType.current.value == 8 ||
                    ServiceType.current.value == 9
                ) {
                    setSectionTypeDropdown(res.data?.dynamic_dropdown_info);
                    setdynamicModuleInfoCheckbox(res.data?.dynamic_module_info);
                    setAllNumberOfPage(res.data?.number_of_pages);
                    setSectionTypeCheckbox([]);
                    setAdditionalServiceInfoCheckbox([]);
                    setAdditionalAppPluginInfoCheckbox([]);
                } else if (
                    ServiceType.current.value == 26 ||
                    ServiceType.current.value == 27 ||
                    ServiceType.current.value == 28 ||
                    ServiceType.current.value == 29
                ) {
                    setSectionTypeDropdown([]);
                    setdynamicModuleInfoCheckbox([]);
                    setAllNumberOfPage([]);
                    setInputVal(res.data?.number_of_pages);
                    setSectionTypeCheckbox([]);
                    setAdditionalServiceInfoCheckbox([]);
                    setAdditionalAppPluginInfoCheckbox([]);
                    setGrandTotalCostUSD(res.data?.number_of_pages[0]?.usd_price);
                    setGrandTotalCostBDT(res.data?.number_of_pages[0]?.bdt_price);
                    setGrandTotalCostDuration(res.data?.number_of_pages[0]?.duration);
                } else {
                    setSectionTypeCheckbox([]);
                    setSectionTypeDropdown([]);
                    setAllNumberOfPage([]);
                    setAdditionalServiceInfoCheckbox([]);
                    setAdditionalAppPluginInfoCheckbox([]);
                    setdynamicModuleInfoCheckbox([]);
                }
            });

        e.preventDefault();
    };

    const DropdownInfo = (e) => {
        setIncludeExcludePluginShow(false);
        setGrandTotalCostUSD(0);
        setGrandTotalCostBDT(0);
        setGrandTotalCostDuration(0);
        setPageShow(true);
        setSectionTypeDropdwonValue(e.target.value.split(",")[0]);
        setSectionTypeDropdwonValueName(e.target.value.split(",")[1]);
        if (ServiceType?.current?.value == 12) {
            setGrandTotalCostUSD(parseInt(e.target.value.split(",")[2]));
            setGrandTotalCostBDT(parseInt(e.target.value.split(",")[3]));
            setGrandTotalCostDuration(parseInt(e.target.value.split(",")[4]));
        }
    };

    const sumOfCostWebsiteRedesign = (e) => {
        setSectionTypeDropdwonValue(e.target.value.split(",")[0]);
        setSectionTypeDropdwonValueName(e.target.value.split(",")[1]);
        setGrandTotalCostUSD(parseInt(e.target.value.split(",")[2]));
        setGrandTotalCostBDT(parseInt(e.target.value.split(",")[3]));
        setGrandTotalCostDuration(parseInt(e.target.value.split(",")[4]));
    };

    const websiteRedesignCosting = (e, data) => {
        console.log(data?.bdt_price);
        if (e.target.value != "") {
            setGrandTotalCostUSD(data.usd_price * parseInt(e.target.value));
            setGrandTotalCostBDT(data.bdt_price * parseInt(e.target.value));
            setGrandTotalCostDuration(data.duration * parseInt(e.target.value));
            setNumOfpageRadio(e.target.value);
            setRadioUsdPrice(parseInt(data?.usd_price));
            setRadioBdtPrice(parseInt(data?.bdt_price));
            setNumOfPageDuration(parseInt(data?.duration));
        }
    };

    const sumOfCostRadio = (e, item) => {
        setGrandTotalCostUSD(0);
        setGrandTotalCostBDT(0);
        setGrandTotalCostDuration(0);
        setIncludeExcludeplugin(item.exclude_app_plugins);
        setFilteredExcludedAdditionalAppPlugin(item.exclude_app_plugins);
        setIncludeExcludepluginChecked(item.include_app_plugins);
        setAdditionalServices(item.additional_services);
        setShow(true);
        setIncludeExcludePluginShow(true);
        if (e.target.checked) {
            setNumOfpageRadio(item?.total_page);
            setRadioUsdPrice(parseInt(item?.usd_price));
            setRadioBdtPrice(parseInt(item?.bdt_price));
            setNumOfPageDuration(parseInt(item?.duration));

            // setGrandTotalCostUSD(parseInt(item?.usd_price) + checkboxCostUsd);
            // setGrandTotalCostBDT(parseInt(item?.bdt_price) + checkboxCostBdt);
            // setGrandTotalCostDuration(
            //   parseInt(item?.duration) + checkboxTotalDuration
            // );
            setGrandTotalCostUSD(parseInt(item?.usd_price));
            setGrandTotalCostBDT(parseInt(item?.bdt_price));
            setGrandTotalCostDuration(parseInt(item?.duration));
        }
    };

    const sumOfCostServerCheckbox1 = (e, item) => {
        if (e.target.checked) {
            setWebMaintenanceValue(item.dynamic_drop_down_info);
        } else {
            setWebMaintenanceValue([]);
            setGrandTotalCostUSD(grandTotalCostUSD - radioUsdPrice);
            setGrandTotalCostBDT(grandTotalCostBDT - radioBdtPrice);
            setGrandTotalCostDuration(grandTotalDuration - numOfPageDuration);
        }
    };

    const sumOfCostServerRadio = (e) => {
        setSectionTypeCheckboxValue([
            ...sectionTypeCheckboxValue,
            e.target.value.split(",")[1],
        ]);
        setCheckboxUSDPrice([
            ...checkboxUSDPrice,
            parseInt(e.target.value.split(",")[2]),
        ]);
        setCheckboxBDTPrice([
            ...checkboxBDTPrice,
            parseInt(e.target.value.split(",")[3]),
        ]);
        setCheckboxDuration([
            ...checkboxDuration,
            parseInt(e.target.value.split(",")[4]),
        ]);

        setRadioUsdPrice(parseInt(e.target.value.split(",")[2]));
        setRadioBdtPrice(parseInt(e.target.value.split(",")[3]));
        setNumOfPageDuration(parseInt(e.target.value.split(",")[4]));

        setGrandTotalCostUSD(
            parseInt(e.target.value.split(",")[2]) + checkboxCostUsd
        );
        setGrandTotalCostBDT(
            parseInt(e.target.value.split(",")[3]) + checkboxCostBdt
        );
        setGrandTotalCostDuration(
            parseInt(e.target.value.split(",")[4]) + checkboxTotalDuration
        );
    };

    const sumOfCostCheckbox = (e, item) => {
        console.log(item);
        if (e.target.checked) {
            setSectionTypeCheckboxValue([
                ...sectionTypeCheckboxValue,
                e.target.value.split("-")[0],
            ]);

            setCheckboxUSDPrice([...checkboxUSDPrice, e.target.value.split("-")[1]]);
            setCheckboxBDTPrice([...checkboxBDTPrice, e.target.value.split("-")[2]]);
            setCheckboxDuration([...checkboxDuration, e.target.value.split("-")[3]]);

            setGrandTotalCostUSD((total) => total + parseInt(item?.usd_price));
            setGrandTotalCostBDT((total) => total + parseInt(item?.bdt_price));
            setGrandTotalCostDuration(
                (duration) => duration + parseInt(item?.duration)
            );
        } else {
            setSectionTypeCheckboxValue(
                sectionTypeCheckboxValue.filter(
                    (id) => id !== e.target.value.split("-")
                )
            );
            setGrandTotalCostUSD((total) => total - parseInt(item?.usd_price));
            setGrandTotalCostBDT((total) => total - parseInt(item?.bdt_price));
            setGrandTotalCostDuration(
                (duration) => duration - parseInt(item?.duration)
            );
        }
    };

    // ! Plugin integration New Module ========================================================
    const [pluginIntegrationCheckbox, setPluginIntegrationCheckbox] = useState(
        []
    );
    const sumOfCostPluginIntegration = (e, item) => {
        console.log(item);
        if (e.target.checked) {
            setAdditionalAppPluginValue([
                ...additionalAppPluginValue,
                e.target.value.split("-")[0],
            ]);

            setCheckboxUSDPriceAdditionalAppPlugin([
                ...checkboxUSDPriceAdditionalAppPlugin,
                e.target.value.split("-")[1],
            ]);
            setCheckboxBDTPriceAdditionalAppPlugin([
                ...checkboxBDTPriceAdditionalAppPlugin,
                e.target.value.split("-")[2],
            ]);
            setCheckboxDurationAdditionalAppPlugin([
                ...checkboxDurationAdditionalAppPlugin,
                e.target.value.split("-")[3],
            ]);

            setGrandTotalCostUSD((total) => total + parseInt(item?.usd_price));
            setGrandTotalCostBDT((total) => total + parseInt(item?.bdt_price));
            setGrandTotalCostDuration(
                (duration) => duration + parseInt(item?.duration)
            );
        } else {
            setAdditionalAppPluginValue(
                additionalAppPluginValue.filter(
                    (id) => id !== e.target.value.split("-")
                )
            );
            setGrandTotalCostUSD((total) => total - parseInt(item?.usd_price));
            setGrandTotalCostBDT((total) => total - parseInt(item?.bdt_price));
            setGrandTotalCostDuration(
                (duration) => duration - parseInt(item?.duration)
            );
        }
    };

    const additionalServiceValueCheckbox = (e, item) => {
        console.log("plugin name", e.target.value);
        if (e.target.checked) {
            setAdditionalServiceValue([...additionalServiceValue, e.target.value]);
            setCheckboxUSDPriceTwo([...checkboxUSDPriceTwo, item.usd_price]);
            setCheckboxBDTPriceTwo([...checkboxBDTPriceTwo, item.bdt_price]);
            setCheckboxDurationTwo([...checkboxDurationTwo, item.duration]);

            setCheckboxCostUsd((total) => total + parseInt(item?.usd_price));
            setCheckboxCostBdt((total) => total + parseInt(item?.bdt_price));
            setCheckboxcheckboxTotalDuration(
                (total) => total + parseInt(item?.duration)
            );

            setGrandTotalCostUSD((total) => total + parseInt(item?.usd_price));
            setGrandTotalCostBDT((total) => total + parseInt(item?.bdt_price));
            setGrandTotalCostDuration(
                (duration) => duration + parseInt(item?.duration)
            );
        } else {
            setAdditionalServiceValue(
                additionalServiceValue.filter((id) => id !== e.target.value)
            );

            setCheckboxCostUsd((total) => total - parseInt(item?.usd_price));
            setCheckboxCostBdt((total) => total - parseInt(item?.bdt_price));
            setCheckboxcheckboxTotalDuration(
                (total) => total - parseInt(item?.duration)
            );

            setGrandTotalCostUSD((total) => total - parseInt(item?.usd_price));
            setGrandTotalCostBDT((total) => total - parseInt(item?.bdt_price));
            setGrandTotalCostDuration(
                (duration) => duration - parseInt(item?.duration)
            );
        }
    };

    // ! Additional App Plugin New Module (include + exclude)
    const additionalAppPluginCheckbox = (e, item) => {
        console.log("item");
        if (e.target.checked) {
            setAdditionalAppPluginValue([
                ...additionalAppPluginValue,
                e.target.value,
            ]);
            setCheckboxUSDPriceAdditionalAppPlugin([
                ...checkboxUSDPriceAdditionalAppPlugin,
                item.additional_app_plugin.usd_price,
            ]);
            setCheckboxBDTPriceAdditionalAppPlugin([
                ...checkboxBDTPriceAdditionalAppPlugin,
                item.additional_app_plugin.bdt_price,
            ]);
            setCheckboxDurationAdditionalAppPlugin([
                ...checkboxDurationAdditionalAppPlugin,
                item.additional_app_plugin.duration,
            ]);

            setCheckboxCostUsd((total) => total + parseInt(item?.usd_price));
            setCheckboxCostBdt((total) => total + parseInt(item?.bdt_price));
            setCheckboxcheckboxTotalDuration(
                (total) => total + parseInt(item?.duration)
            );

            setGrandTotalCostUSD(
                (total) => total + parseInt(item?.additional_app_plugin.usd_price)
            );
            setGrandTotalCostBDT(
                (total) => total + parseInt(item?.additional_app_plugin.bdt_price)
            );
            setGrandTotalCostDuration(
                (duration) => duration + parseInt(item?.additional_app_plugin.duration)
            );
        } else {
            setAdditionalAppPluginValue(
                additionalAppPluginValue.filter((id) => id !== e.target.value)
            );

            setCheckboxCostUsd((total) => total - parseInt(item?.usd_price));
            setCheckboxCostBdt((total) => total - parseInt(item?.bdt_price));
            setCheckboxcheckboxTotalDuration(
                (total) => total - parseInt(item?.duration)
            );

            setGrandTotalCostUSD(
                (total) => total - parseInt(item?.additional_app_plugin.usd_price)
            );
            setGrandTotalCostBDT(
                (total) => total - parseInt(item?.additional_app_plugin.bdt_price)
            );
            setGrandTotalCostDuration(
                (duration) => duration - parseInt(item?.additional_app_plugin.duration)
            );
        }
    };

    // ! Additional Service Checkbox New Module
    const additionalServicesCheckbox = (e, item) => {
        console.log(item);
        if (e.target.checked) {
            setAdditionalServiceValue([...additionalServiceValue, e.target.value]);
            setCheckboxUSDPriceAdditionalService([
                ...checkboxUSDPriceAdditionalService,
                item.additional_service.usd_price,
            ]);
            setCheckboxBDTPriceAdditionalService([
                ...checkboxBDTPriceAdditionalService,
                item.additional_service.bdt_price,
            ]);
            setCheckboxDurationAdditionalService([
                ...checkboxDurationAdditionalService,
                item.additional_service.duration,
            ]);

            setCheckboxCostUsd((total) => total + parseInt(item?.usd_price));
            setCheckboxCostBdt((total) => total + parseInt(item?.bdt_price));
            setCheckboxcheckboxTotalDuration(
                (total) => total + parseInt(item?.duration)
            );

            setGrandTotalCostUSD(
                (total) => total + parseInt(item?.additional_service.usd_price)
            );
            setGrandTotalCostBDT(
                (total) => total + parseInt(item?.additional_service.bdt_price)
            );
            setGrandTotalCostDuration(
                (duration) => duration + parseInt(item?.additional_service.duration)
            );
        } else {
            setAdditionalServiceValue(
                additionalServiceValue.filter((id) => id !== e.target.value)
            );

            setCheckboxCostUsd((total) => total - parseInt(item?.usd_price));
            setCheckboxCostBdt((total) => total - parseInt(item?.bdt_price));
            setCheckboxcheckboxTotalDuration(
                (total) => total - parseInt(item?.duration)
            );

            setGrandTotalCostUSD(
                (total) => total - parseInt(item?.additional_service.usd_price)
            );
            setGrandTotalCostBDT(
                (total) => total - parseInt(item?.additional_service.bdt_price)
            );
            setGrandTotalCostDuration(
                (duration) => duration - parseInt(item?.additional_service.duration)
            );
        }
    };


    useEffect(() => {
        renderAllInfo();
        setFormValue({
            ...formValue,
            category: sessionStorage.getItem("Service_name"),
            subCategory: sessionStorage.getItem("Service_categody_name"),
            category_id: sessionStorage.getItem("Service_name_id"),
            subCategory_id: sessionStorage.getItem("Service_categody_id"),
        });
    }, []);

    const getUserInfo = (e, value) => {
        e.preventDefault();

        axios
            .get(`${BACKEND_BASE_URL}/api/v2/get-user-info/email/${value}`)
            .then((res) => {
                setSelectedUserInfo(res.data?.user_info);
            });
    };

    const togglePluginDiv = () => {
        setTogglePluginList(!togglePluginList);
    };

    const toggleExcludePluginDiv = () => {
        setTogglePluginList(!togglePluginList);
    };


    // Form submit to backend
    const store = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formdata = new FormData();

        formdata.append("project_name", formValue.name);

        formdata.append("main_service_id", formValue.category_id);
        formdata.append("sub_service_id", formValue.subCategory_id);
        formdata.append("child_service_id", formValue.serviceType);
        formdata.append("country_code", currUserInfo?.countryCode);

        if (sectionTypeCheckboxValue.length > 0) {
            sectionTypeCheckboxValue.forEach((item) => {
                formdata.append("section_type[]", item);
            });
            formdata.append("section_type_additional_str", "section_type");
        }
        if (checkboxUSDPrice.length > 0) {
            checkboxUSDPrice.forEach((item) => {
                formdata.append("section_type_usd_price[]", item);
            });
        }
        if (checkboxBDTPrice.length > 0) {
            checkboxBDTPrice.forEach((item) => {
                formdata.append("section_type_bdt_price[]", item);
            });
        }
        if (checkboxDuration.length > 0) {
            checkboxDuration.forEach((item) => {
                formdata.append("section_type_duration[]", item);
            });
        }

        if (sectionTypeDropdownValueName) {
            formdata.append("section_type_dropdown", sectionTypeDropdownValueName);
        }

        // if (webMaintenanceValue.lenght > 0) {
        //   formdata.append("web_maintanance", webMaintenanceValue[0]);
        //   formdata.append("web_maintanance_usd_price", webMaintenanceValue[1]);
        //   formdata.append("web_maintanance_bdt_price", webMaintenanceValue[2]);
        //   formdata.append("web_maintanance_duration", webMaintenanceValue[3]);
        // }

        // ------------------------------ additional service ---------------------------
        if (additionalServiceValue.length > 0) {
            additionalServiceValue.forEach((item) => {
                formdata.append("additional_service[]", item);
            });
            formdata.append(
                "additional_service_additional_str",
                "additional_service"
            );
        }
        if (checkboxBDTPriceAdditionalService.length > 0) {
            checkboxBDTPriceAdditionalService.forEach((item) => {
                formdata.append("additional_service_bdt_price[]", item);
            });
        }
        if (checkboxUSDPriceAdditionalService.length > 0) {
            checkboxUSDPriceAdditionalService.forEach((item) => {
                formdata.append("additional_service_usd_price[]", item);
            });
        }
        if (checkboxDurationAdditionalService.length > 0) {
            checkboxDurationAdditionalService.forEach((item) => {
                formdata.append("additional_service_duration[]", item);
            });
        }

        // ------------------------------ additional app/plugin ---------------------------
        if (additionalAppPluginValue.length > 0) {
            additionalAppPluginValue.forEach((item) => {
                formdata.append("additional_app_plugin[]", item);
            });

            formdata.append(
                "additional_app_plugin_additional_str",
                "additional_app_plugin"
            );
        }

        if (checkboxBDTPriceAdditionalAppPlugin.length > 0) {
            checkboxBDTPriceAdditionalAppPlugin.forEach((item) => {
                formdata.append("additional_app_plugin_bdt_price[]", item);
            });
        }
        if (checkboxUSDPriceAdditionalAppPlugin.length > 0) {
            checkboxUSDPriceAdditionalAppPlugin.forEach((item) => {
                formdata.append("additional_app_plugin_usd_price[]", item);
            });
        }
        if (checkboxDurationAdditionalAppPlugin.length > 0) {
            checkboxDurationAdditionalAppPlugin.forEach((item) => {
                formdata.append("additional_app_plugin_duration[]", item);
            });
        }

        if (numOfpageRadio?.length > 0) {
            formdata.append("number_of_page", numOfpageRadio);
            formdata.append("number_of_page_usd_price", radioUsdPrice);
            formdata.append("number_of_page_bdt_price", radioBdtPrice);
            formdata.append("number_of_page_duration", numOfPageDuration);
        }
        formdata.append("total_usd_price", grandTotalCostUSD);
        formdata.append("total_bdt_price", grandTotalCostBDT);
        formdata.append("total_duration", grandTotalDuration);
        formdata.append("name", name.current.value);
        formdata.append("email", email.current.value);
        formdata.append("phone", phone.current.value);
        formdata.append("company", company.current.value);
        formdata.append("address", address.current.value);

        axios
            .post(`${BACKEND_BASE_URL}/api/v2/rianaire/orders/store`, formdata, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
                },
            })

            .then((response) => {
                if (response.data?.status === 200) {
                    ToastAlert.fire({
                        title: "Submitted Successfully",
                    });
                    setIsLoading(false);
                    setIncludeExcludepluginChecked([]);
                    setSectionTypeCheckbox([]);
                    setSectionTypeCheckboxValue([]);
                    setAdditionalServiceInfoCheckbox([]);
                    setAdditionalAppPluginInfoCheckbox([]);
                    setdynamicModuleInfoCheckbox([]);
                    setSectionTypeDropdwonValueName([]);
                    setSectionTypeDropdwonValue([]);
                    setDynamicModuleValue([]);
                    setAdditionalAppPluginValue([]);
                    setAdditionalServiceValue([]);
                    setWebMaintenanceValue([]);
                    setCheckboxUSDPrice([]);
                    setCheckboxBDTPrice([]);
                    setCheckboxDuration([]);
                    setCheckboxUSDPriceTwo([]);
                    setCheckboxBDTPriceTwo([]);
                    setCheckboxDurationTwo([]);
                    setCheckboxUSDPriceThree([]);
                    setCheckboxBDTPriceThree([]);
                    setCheckboxDurationThree([]);
                    setCheckboxUSDPriceAdditionalService([]);
                    setCheckboxBDTPriceAdditionalService([]);
                    setCheckboxDurationAdditionalService([]);
                    setCheckboxUSDPriceAdditionalAppPlugin([]);
                    setCheckboxBDTPriceAdditionalAppPlugin([]);
                    setCheckboxDurationAdditionalAppPlugin([]);
                    setCheckboxDuration(0);
                    setCheckboxCostUsd(0);
                    setCheckboxCostBdt(0);
                    setGrandTotalCostUSD(0);
                    setGrandTotalCostBDT(0);
                    setGrandTotalCostDuration(0);
                    e.target.reset();
                } else {
                    ToastAlert.fire({
                        icon: "error",
                        text: "Invalid email",
                        confirmButtonColor: "#5eba86",
                    });
                }

                renderAllInfo();
            });
    };

    const [formValue, setFormValue] = useState({
        name: "",
        category: "",
        subCategory: "",
        serviceType: "",
        description: "",
        siteUpdate: "",
        email: "",
        companyName: "",
        phone: "",
        address: "",
    });
    console.log(formValue)
    // MUI stepper
    const steps = ["Step 1", "Step 2", "Step 3"];
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };



    const allUserInfo = () => {
        axios
            .get(`${BACKEND_BASE_URL}/api/v2/rianaire/registered-user-management`, {
                headers: {
                    Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
                },
            })
            .then((res) => {
                setUserInfo(res.data?.all_users);
            });
    };

    useEffect(() => {
        fetchDesignType()
        allUserInfo();
    }, []);

    // useEffect(() => {
    //   axios
    //     .get(`${BACKEND_BASE_URL}/api/v2/get-dynamic-dropdown/1/6/3`)
    //     .then((res) => {
    //       setCompleteWebsiteShopify(res.data?.dynamic_dropdown_info);
    //     });
    // }, []);

    return (
        <div style={{ backgroundColor: "#FAF9F4", minHeight: "100vh", overflowY: "auto" }}>
            <div className="custom_container">
                <div className="p-0 p-lg-5">
                    <Row className="">
                        <Col md={3} lg={4} xl={3} className="user_project_left_section">
                            <Link
                                to="/admin/orders"
                                className="flex_start t-mb-55"
                                style={{ textDecoration: "none" }}
                            >
                                <FiArrowLeft size="1.8em" className="me-3 text-black" />
                                &nbsp;
                                <p className="mb-0 t-fs-17 t-fw-600 text-black">
                                    Back to Dashboard
                                </p>
                            </Link>
                        </Col>
                        <Col
                            md={9}
                            lg={8}
                            xl={9}
                            className="user_project_right_section position-relative"
                        >
                            <Row>
                                <Col md={9}>
                                    <Stepper activeStep={activeStep}>
                                        {steps.map((label) => {
                                            const stepProps = {};
                                            const labelProps = {};
                                            return (
                                                <Step key={label} {...stepProps}>
                                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                    {activeStep === steps.length ? (
                                        <React.Fragment>
                                            <Typography sx={{ mt: 2, mb: 1 }}>
                                                All steps completed - you&apos;re finished
                                            </Typography>
                                            <Box
                                                sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                                            >
                                                <Box sx={{ flex: "1 1 auto" }} />
                                                <Button onClick={handleReset}>Reset</Button>
                                            </Box>
                                        </React.Fragment>
                                    ) : (
                                        <>
                                            <div className="create-a-project-wrapper mb-3 my-3">
                                                {isLoading && !error && <LinearProgress />}
                                                <Form onSubmit={store}>
                                                    {/* ==================== STEP 1 ==========================*/}

                                                    {activeStep === 0 && (
                                                        <Row>
                                                            <h3> Step {activeStep + 1}</h3>
                                                            <Form.Group className="mb-4" as={Col} md={12}>
                                                                <Form.Label className="t-fw-600 t-fs-15">
                                                                    Give your project a name
                                                                    <span className="text-danger">*</span>{" "}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="project name"
                                                                    value={formValue.name}
                                                                    onChange={(e) => {
                                                                        setFormValue({
                                                                            ...formValue,
                                                                            name: e.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className="mb-4">
                                                                <Form.Label className="t-fw-600 t-fs-15">
                                                                    Project Category
                                                                    <span className="text-danger">*</span>
                                                                </Form.Label>
                                                                <Form.Select
                                                                    aria-label="Default select example"
                                                                    disabled
                                                                >
                                                                    {allMainServices.map((tabData) => (
                                                                        <option
                                                                            key={tabData.id}
                                                                            value={tabData.name}
                                                                            selected={
                                                                                tabData.id ==
                                                                                    sessionStorage.getItem(
                                                                                        "Service_name_id"
                                                                                    )
                                                                                    ? "selected"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            {tabData.name}
                                                                        </option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Form.Group>

                                                            <Form.Group className="mb-4">
                                                                <Form.Label className="t-fw-600 t-fs-15">
                                                                    Project Sub Category
                                                                    <span className="text-danger">*</span>
                                                                </Form.Label>
                                                                <Form.Select
                                                                    aria-label="Default select example"
                                                                    disabled
                                                                >
                                                                    {allMainServices.map((tabData) =>
                                                                        tabData?.sub_service?.map((tabItem) => (
                                                                            <option
                                                                                key={tabItem.id}
                                                                                value={tabItem.name}
                                                                                selected={
                                                                                    tabData.id ==
                                                                                        sessionStorage.getItem(
                                                                                            "Service_name_id"
                                                                                        ) &&
                                                                                        tabItem.id ==
                                                                                        sessionStorage.getItem(
                                                                                            "Service_categody_id"
                                                                                        )
                                                                                        ? "selected"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                {tabItem.name}
                                                                            </option>
                                                                        ))
                                                                    )}
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Row>
                                                    )}

                                                    {/* ==================== STEP 2 ==========================*/}

                                                    {activeStep === 1 &&
                                                        formValue.category ==
                                                        "Development & Technology" && (
                                                            <>
                                                                <div className="row">
                                                                    <Form.Group as={Col} md={6} className="mb-3">
                                                                        <Form.Label>Service Type</Form.Label>

                                                                        <Form.Select
                                                                            aria-label="language example "
                                                                            className=""
                                                                            ref={ServiceType}
                                                                            onChange={fetchSectionType}
                                                                        >
                                                                            <option value="" selected>
                                                                                Select Service Type
                                                                            </option>

                                                                            {allDesignTypes?.map((data) => (
                                                                                <option key={data.id} value={data.id}>
                                                                                    {data?.name}
                                                                                </option>
                                                                            ))}
                                                                        </Form.Select>
                                                                    </Form.Group>

                                                                    {sectionTypeDropdown.length > 0 &&
                                                                        (ServiceType?.current?.value == 1 ||
                                                                            ServiceType?.current?.value == 2 ||
                                                                            ServiceType?.current?.value == 4 ||
                                                                            ServiceType?.current?.value == 5 ||
                                                                            ServiceType?.current?.value == 7 ||
                                                                            ServiceType?.current?.value == 8 ||
                                                                            ServiceType?.current?.value == 9) && (
                                                                            <>
                                                                                <Form.Group
                                                                                    as={Col}
                                                                                    md={12}
                                                                                    className="mb-3"
                                                                                    style={{
                                                                                        animation:
                                                                                            "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                    }}
                                                                                >
                                                                                    <Form.Label>Website Type</Form.Label>

                                                                                    <Form.Select
                                                                                        required
                                                                                        aria-label="language example "
                                                                                        className=""
                                                                                        onChange={(e) => DropdownInfo(e)}
                                                                                        ref={SectionTypeDropdown}
                                                                                    >
                                                                                        <option value="" selected>
                                                                                            Select Website Type
                                                                                        </option>

                                                                                        {sectionTypeDropdown?.map((data) => {
                                                                                            return (
                                                                                                <option
                                                                                                    key={data.id}
                                                                                                    value={
                                                                                                        data.id +
                                                                                                        "," +
                                                                                                        data?.name +
                                                                                                        "," +
                                                                                                        data?.service_cost?.usd_price +
                                                                                                        "," +
                                                                                                        data?.service_cost?.bdt_price +
                                                                                                        "," +
                                                                                                        data?.service_cost?.duration
                                                                                                    }
                                                                                                    name={data.name}
                                                                                                >
                                                                                                    {data?.name}
                                                                                                </option>
                                                                                            );
                                                                                        })}
                                                                                    </Form.Select>
                                                                                </Form.Group>
                                                                            </>
                                                                        )}

                                                                    {sectionTypeDropdown.length > 0 &&
                                                                        subServiceName?.current?.value == 6 && (
                                                                            <>
                                                                                <Form.Group
                                                                                    as={Col}
                                                                                    md={12}
                                                                                    className="mb-3"
                                                                                    style={{
                                                                                        animation:
                                                                                            "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                    }}
                                                                                >
                                                                                    <Form.Label>Website Type</Form.Label>

                                                                                    <Form.Select
                                                                                        required
                                                                                        aria-label="language example "
                                                                                        className=""
                                                                                        onChange={(e) => sumOfCostWebsiteRedesign(e)}
                                                                                        ref={SectionTypeDropdown}
                                                                                    >
                                                                                        <option value="" selected>
                                                                                            Select Website Type
                                                                                        </option>

                                                                                        {sectionTypeDropdown?.map((data) => {
                                                                                            return (
                                                                                                <option
                                                                                                    key={data.id}
                                                                                                    value={
                                                                                                        data.id +
                                                                                                        "," +
                                                                                                        data?.name +
                                                                                                        "," +
                                                                                                        data?.service_cost[0]?.usd_price +
                                                                                                        "," +
                                                                                                        data?.service_cost[0]?.bdt_price +
                                                                                                        "," +
                                                                                                        data?.service_cost[0]?.duration
                                                                                                    }
                                                                                                    name={data.name}
                                                                                                >
                                                                                                    {data?.name}
                                                                                                </option>
                                                                                            );
                                                                                        })}
                                                                                    </Form.Select>
                                                                                </Form.Group>
                                                                            </>
                                                                        )}

                                                                    {/*/SECTION TYPE CHECKBOX /*/}
                                                                    {ServiceType?.current?.value == 4 &&
                                                                        sectionTypeCheckbox.length > 0 && (
                                                                            <Form.Group
                                                                                as={Col}
                                                                                md={12}
                                                                                className="mb-3"
                                                                                style={{
                                                                                    animation:
                                                                                        "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                }}
                                                                            >
                                                                                {ServiceType?.current?.value == 2 && (
                                                                                    <Form.Label>App Type</Form.Label>
                                                                                )}
                                                                                {ServiceType?.current?.value == 4 && pageShow && (
                                                                                    <Form.Label
                                                                                        style={{
                                                                                            animation:
                                                                                                "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                        }}
                                                                                    >
                                                                                        Section Type
                                                                                    </Form.Label>
                                                                                )}

                                                                                <div className="row">
                                                                                    {sectionTypeCheckbox.map((type) => {
                                                                                        return ServiceType?.current?.value == 4 ? (
                                                                                            pageShow && (
                                                                                                <div className="col-md-6" key={type.id}>
                                                                                                    <div className="row">
                                                                                                        <div className="col-md-9">
                                                                                                            <Form.Check
                                                                                                                type="checkbox"
                                                                                                                id={type.id}
                                                                                                                label={type.total_page}
                                                                                                                ref={sectionType}
                                                                                                                onChange={(e) =>
                                                                                                                    sumOfCostCheckbox(e, type)
                                                                                                                }
                                                                                                                value={
                                                                                                                    type.total_page +
                                                                                                                    "-" +
                                                                                                                    type?.usd_price +
                                                                                                                    "-" +
                                                                                                                    type?.bdt_price +
                                                                                                                    "-" +
                                                                                                                    type?.duration
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                        {currUserInfo?.countryCode == "BD"
                                                                                                            ? type?.service_cost?.bdt_price && (
                                                                                                                <div className="col-md-3 ps-2">
                                                                                                                    ৳
                                                                                                                    {
                                                                                                                        type?.service_cost
                                                                                                                            ?.bdt_price
                                                                                                                    }
                                                                                                                </div>
                                                                                                            )
                                                                                                            : type?.service_cost?.usd_price && (
                                                                                                                <div className="col-md-3 ps-2">
                                                                                                                    $
                                                                                                                    {
                                                                                                                        type?.service_cost
                                                                                                                            ?.usd_price
                                                                                                                    }
                                                                                                                </div>
                                                                                                            )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        ) : (
                                                                                            <div className="col-md-6" key={type.id}>
                                                                                                <div className="row">
                                                                                                    <div className="col-md-9">
                                                                                                        <Form.Check
                                                                                                            type="checkbox"
                                                                                                            id={type.id}
                                                                                                            label={type.name}
                                                                                                            ref={sectionType}
                                                                                                            onChange={(e) =>
                                                                                                                sumOfCostCheckbox(e, type)
                                                                                                            }
                                                                                                            value={
                                                                                                                type.name +
                                                                                                                "-" +
                                                                                                                type?.service_cost?.usd_price +
                                                                                                                "-" +
                                                                                                                type?.service_cost?.bdt_price +
                                                                                                                "-" +
                                                                                                                type?.service_cost?.duration
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                    {currUserInfo?.countryCode == "BD"
                                                                                                        ? type?.service_cost?.bdt_price && (
                                                                                                            <div className="col-md-3 ps-2">
                                                                                                                ৳{type?.service_cost?.bdt_price}
                                                                                                            </div>
                                                                                                        )
                                                                                                        : type?.service_cost?.usd_price && (
                                                                                                            <div className="col-md-3 ps-2">
                                                                                                                ${type?.service_cost?.usd_price}
                                                                                                            </div>
                                                                                                        )}
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            </Form.Group>
                                                                        )}

                                                                    {subServiceName?.current?.value == 5 && (
                                                                        <>
                                                                            <Form.Group
                                                                                as={Col}
                                                                                md={12}
                                                                                className="mb-3"
                                                                                style={{
                                                                                    animation:
                                                                                        "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                }}
                                                                            >
                                                                                <Form.Label>Maintenance Type</Form.Label>

                                                                                <div className="row maintenance_box">
                                                                                    {sectionTypeCheckbox.map((type) => {
                                                                                        return (
                                                                                            <div className="col-md-6" key={type.id}>
                                                                                                <div className="row">
                                                                                                    <div className="col-md-9">
                                                                                                        {type.name !=
                                                                                                            "Server Installation" && (
                                                                                                                <Form.Check
                                                                                                                    type="checkbox"
                                                                                                                    className="abc"
                                                                                                                    name="abc"
                                                                                                                    id={type.id}
                                                                                                                    label={type.name}
                                                                                                                    ref={sectionType}
                                                                                                                    // onChange={(e) =>
                                                                                                                    //   sumOfCostServerCheckbox(e, type)
                                                                                                                    // }
                                                                                                                    value={
                                                                                                                        type.name +
                                                                                                                        "-" +
                                                                                                                        type?.service_cost[0]
                                                                                                                            ?.usd_price +
                                                                                                                        "-" +
                                                                                                                        type?.service_cost[0]
                                                                                                                            ?.bdt_price +
                                                                                                                        "-" +
                                                                                                                        type?.service_cost[0]
                                                                                                                            ?.duration +
                                                                                                                        "-mt"
                                                                                                                    }
                                                                                                                />
                                                                                                            )}
                                                                                                        {type.name ==
                                                                                                            "Server Installation" && (
                                                                                                                <Form.Check
                                                                                                                    type="checkbox"
                                                                                                                    className="abc"
                                                                                                                    id={type.id}
                                                                                                                    label={type.name}
                                                                                                                    ref={sectionType}
                                                                                                                    onChange={(e) =>
                                                                                                                        sumOfCostServerCheckbox1(
                                                                                                                            e,
                                                                                                                            type
                                                                                                                        )
                                                                                                                    }
                                                                                                                />
                                                                                                            )}
                                                                                                    </div>
                                                                                                    {type.name != "Server Installation" &&
                                                                                                        currUserInfo?.countryCode == "BD"
                                                                                                        ? type?.service_cost[0]
                                                                                                            ?.bdt_price && (
                                                                                                            <div className="col-md-3 ps-2">
                                                                                                                ৳
                                                                                                                {
                                                                                                                    type?.service_cost[0]
                                                                                                                        ?.bdt_price
                                                                                                                }
                                                                                                            </div>
                                                                                                        )
                                                                                                        : type.name !=
                                                                                                        "Server Installation" &&
                                                                                                        type?.service_cost[0]
                                                                                                            ?.usd_price && (
                                                                                                            <div className="col-md-3 ps-2">
                                                                                                                $
                                                                                                                {
                                                                                                                    type?.service_cost[0]
                                                                                                                        ?.usd_price
                                                                                                                }
                                                                                                            </div>
                                                                                                        )}
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            </Form.Group>
                                                                        </>
                                                                    )}
                                                                    {allPluginShow && additionalAppPlugin.length > 0 && (
                                                                        <Form.Group
                                                                            ref={excludedPluginDiv}
                                                                            as={Col}
                                                                            md={12}
                                                                            className="mb-3 position-relative"
                                                                            style={{
                                                                                animation:
                                                                                    "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                            }}
                                                                        >
                                                                            <Form.Label>All Plugins</Form.Label>

                                                                            <div className="plugin_search_box mb-4">
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="search here..."
                                                                                    value={search}
                                                                                    onChange={(e) => {
                                                                                        setSearch(e.target.value);
                                                                                        setTogglePluginList(true);
                                                                                    }}
                                                                                />
                                                                                <button type="button" onClick={togglePluginDiv}>
                                                                                    Browse
                                                                                </button>
                                                                            </div>

                                                                            <div
                                                                                className={`row gx-0 ${togglePluginList
                                                                                    ? "d-block custom_menu"
                                                                                    : "d-none"
                                                                                    } mb-3`}
                                                                            >
                                                                                {filteredAdditionalAppPlugin?.map(
                                                                                    (app_plugin) => {
                                                                                        return (
                                                                                            <div className="" key={app_plugin.id}>
                                                                                                <div className="row">
                                                                                                    <div className="col-md-9">
                                                                                                        <Form.Check
                                                                                                            type="checkbox"
                                                                                                            id={app_plugin.id}
                                                                                                            label={app_plugin.name}
                                                                                                            onChange={(e) =>
                                                                                                                sumOfCostPluginIntegration(
                                                                                                                    e,
                                                                                                                    app_plugin
                                                                                                                )
                                                                                                            }
                                                                                                            value={
                                                                                                                app_plugin.name +
                                                                                                                "-" +
                                                                                                                app_plugin?.usd_price +
                                                                                                                "-" +
                                                                                                                app_plugin?.bdt_price +
                                                                                                                "-" +
                                                                                                                app_plugin?.duration
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                    {currUserInfo?.countryCode == "BD"
                                                                                                        ? app_plugin?.bdt_price && (
                                                                                                            <div className="col-md-3 ps-2">
                                                                                                                ৳{app_plugin?.bdt_price}
                                                                                                            </div>
                                                                                                        )
                                                                                                        : app_plugin?.usd_price && (
                                                                                                            <div className="col-md-3 ps-2">
                                                                                                                ${app_plugin?.usd_price}
                                                                                                            </div>
                                                                                                        )}
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </div>
                                                                        </Form.Group>
                                                                    )}

                                                                    {ServiceType?.current?.value == 6 && (
                                                                        <Form.Group
                                                                            ref={excludedPluginDiv}
                                                                            as={Col}
                                                                            md={12}
                                                                            className="mb-3"
                                                                            style={{
                                                                                animation:
                                                                                    "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                            }}
                                                                        >
                                                                            <Form.Label>Excluded Plugins</Form.Label>
                                                                            <div className="plugin_search_box">
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="search here..."
                                                                                    value={shopifyPluginSearch}
                                                                                    onChange={(e) => {
                                                                                        setShopifyPluginSearch(e.target.value);
                                                                                        setTogglePluginList(true);
                                                                                    }}
                                                                                />
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={toggleExcludePluginDiv}
                                                                                >
                                                                                    Browse
                                                                                </button>
                                                                            </div>
                                                                            <div
                                                                                className={`row gx-0 ${togglePluginList
                                                                                    ? "d-block custom_menu"
                                                                                    : "d-none"
                                                                                    } mb-3`}
                                                                            >
                                                                                {filteredShopifyAppPlugins?.map(
                                                                                    (exclude_plugins) =>
                                                                                        exclude_plugins.exclude_app_plugins?.map(
                                                                                            (exclude_plugin) => (
                                                                                                <div className="" key={exclude_plugin.id}>
                                                                                                    <div
                                                                                                        className="row gx-0 px-2 py-1"
                                                                                                        style={{
                                                                                                            borderBottom: "1px solid #fff",
                                                                                                        }}
                                                                                                    >
                                                                                                        <div className="col-md-9">
                                                                                                            <Form.Check
                                                                                                                type="checkbox"
                                                                                                                id={
                                                                                                                    exclude_plugin
                                                                                                                        ?.additional_app_plugin?.id
                                                                                                                }
                                                                                                                label={
                                                                                                                    exclude_plugin
                                                                                                                        ?.additional_app_plugin.name
                                                                                                                }
                                                                                                                ref={sectionType}
                                                                                                                onChange={(e) =>
                                                                                                                    additionalAppPluginCheckbox(
                                                                                                                        e,
                                                                                                                        exclude_plugin
                                                                                                                    )
                                                                                                                }
                                                                                                                value={
                                                                                                                    exclude_plugin
                                                                                                                        ?.additional_app_plugin.name
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                        {currUserInfo?.countryCode == "BD"
                                                                                                            ? exclude_plugin
                                                                                                                ?.additional_app_plugin
                                                                                                                ?.bdt_price && (
                                                                                                                <div className="col-md-3 ps-2">
                                                                                                                    ৳
                                                                                                                    {
                                                                                                                        exclude_plugin
                                                                                                                            ?.additional_app_plugin
                                                                                                                            ?.bdt_price
                                                                                                                    }
                                                                                                                </div>
                                                                                                            )
                                                                                                            : exclude_plugin
                                                                                                                ?.additional_app_plugin
                                                                                                                ?.usd_price && (
                                                                                                                <div className="col-md-3 ps-2">
                                                                                                                    $
                                                                                                                    {
                                                                                                                        exclude_plugin
                                                                                                                            ?.additional_app_plugin
                                                                                                                            ?.usd_price
                                                                                                                    }
                                                                                                                </div>
                                                                                                            )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        )
                                                                                )}
                                                                            </div>
                                                                        </Form.Group>
                                                                    )}

                                                                    {/* Website Maintenance [Server Type] */}
                                                                    {webMaintenanceValue?.length > 0 && (
                                                                        <Form.Group
                                                                            as={Col}
                                                                            md={12}
                                                                            className="mb-3"
                                                                            style={{
                                                                                animation:
                                                                                    "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                            }}
                                                                        >
                                                                            <Form.Label>Server Type</Form.Label>
                                                                            <div className="row">
                                                                                <Form.Select
                                                                                    required
                                                                                    aria-label="language example "
                                                                                    className=""
                                                                                    onChange={(e) => sumOfCostServerRadio(e)}
                                                                                    ref={SectionTypeDropdown}
                                                                                >
                                                                                    <option value="0" selected>
                                                                                        Select Website Type
                                                                                    </option>

                                                                                    {webMaintenanceValue?.map((data) => {
                                                                                        return (
                                                                                            <option
                                                                                                key={data.id}
                                                                                                value={
                                                                                                    data.id +
                                                                                                    "," +
                                                                                                    data?.name +
                                                                                                    "," +
                                                                                                    data?.service_cost?.usd_price +
                                                                                                    "," +
                                                                                                    data?.service_cost?.bdt_price +
                                                                                                    "," +
                                                                                                    data?.service_cost?.duration
                                                                                                }
                                                                                                name={data.name}
                                                                                            >
                                                                                                {data?.name}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </Form.Select>
                                                                            </div>
                                                                        </Form.Group>
                                                                    )}

                                                                    {/* INPUT VALUE */}
                                                                    {inputVal?.length > 0 && (
                                                                        <>
                                                                            <Form.Group
                                                                                as={Col}
                                                                                md={6}
                                                                                className="mb-3"
                                                                                style={{
                                                                                    animation:
                                                                                        "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                }}
                                                                            >
                                                                                <Form.Label>Number of Pages</Form.Label>

                                                                                {inputVal.map((data, index) => (
                                                                                    <Form.Control
                                                                                        key={index}
                                                                                        type="number"
                                                                                        min={1}
                                                                                        onChange={(e) =>
                                                                                            websiteRedesignCosting(e, data)
                                                                                        }
                                                                                        defaultValue={data?.total_page}
                                                                                    />
                                                                                ))}
                                                                            </Form.Group>
                                                                            <Form.Group
                                                                                as={Col}
                                                                                md={6}
                                                                                className="mb-3"
                                                                                style={{
                                                                                    animation:
                                                                                        "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                }}
                                                                            >
                                                                                <Form.Label>Cost</Form.Label>

                                                                                {inputVal.map((data, index) => (
                                                                                    <Form.Control
                                                                                        disabled
                                                                                        key={index}
                                                                                        type="text"
                                                                                        defaultValue={
                                                                                            currUserInfo?.countryCode == "BD"
                                                                                                ? "৳ " + data?.bdt_price
                                                                                                : "$ " + data?.usd_price
                                                                                        }
                                                                                    />
                                                                                ))}
                                                                            </Form.Group>
                                                                        </>
                                                                    )}

                                                                    {/* NUM OF PAGE */}
                                                                    {allNumberOfPage?.length > 0 && (
                                                                        <Form.Group as={Col} md={12} className="mb-3">
                                                                            <div className="row">
                                                                                {pageShow &&
                                                                                    (ServiceType.current.value == 1 ||
                                                                                        ServiceType.current.value == 2) && (
                                                                                        <Form.Label
                                                                                            style={{
                                                                                                animation:
                                                                                                    "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                            }}
                                                                                        >
                                                                                            Number of Pages
                                                                                        </Form.Label>
                                                                                    )}

                                                                                {ServiceType.current.value == 1 &&
                                                                                    pageShow &&
                                                                                    allNumberOfPage.map((data, index) => (
                                                                                        <div className="col-md-6" key={index}>
                                                                                            <Form.Check
                                                                                                type="radio"
                                                                                                name="WP"
                                                                                                id={data.id}
                                                                                                label={data.total_page}
                                                                                                ref={numOfPage}
                                                                                                onChange={(e) => sumOfCostRadio(e, data)}
                                                                                                style={{
                                                                                                    animation:
                                                                                                        "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    ))}

                                                                                {ServiceType.current.value == 5 && pageShow && (
                                                                                    <>
                                                                                        <Form.Label
                                                                                            style={{
                                                                                                animation:
                                                                                                    "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                            }}
                                                                                        >
                                                                                            {sectionTypeDropdownValue == "48" &&
                                                                                                "Store Type"}
                                                                                        </Form.Label>
                                                                                        {allNumberOfPage.map((data, index) => (
                                                                                            <div className="col-md-6" key={index}>
                                                                                                {sectionTypeDropdownValue ==
                                                                                                    data?.section_type && (
                                                                                                        <Form.Check
                                                                                                            name="store type"
                                                                                                            type="radio"
                                                                                                            id={data.id}
                                                                                                            label={`${data.total_page}`}
                                                                                                            ref={numOfPage}
                                                                                                            onChange={(e) =>
                                                                                                                sumOfCostRadio(e, data)
                                                                                                            }
                                                                                                            style={{
                                                                                                                animation:
                                                                                                                    "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                                            }}
                                                                                                        />
                                                                                                    )}
                                                                                            </div>
                                                                                        ))}
                                                                                        <Form.Group as={Col} md={12} className="mb-3">
                                                                                            <Row>
                                                                                                {twoStepValRadio.length > 0 && (
                                                                                                    <Form.Label
                                                                                                        style={{
                                                                                                            animation:
                                                                                                                "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                                        }}
                                                                                                    >
                                                                                                        {/* Shopify -> complete website -> website type */}
                                                                                                        {sectionTypeDropdownValue != "48" &&
                                                                                                            "Include Plugin"}
                                                                                                    </Form.Label>
                                                                                                )}

                                                                                                {twoStepValRadio?.map((appPlugin) => {
                                                                                                    return appPlugin?.include_app_plugins.map(
                                                                                                        (app, index) =>
                                                                                                            sectionTypeDropdownValue ==
                                                                                                            appPlugin.section_type && (
                                                                                                                <Col md={6} key={index}>
                                                                                                                    <Form.Check
                                                                                                                        name="include_plugin"
                                                                                                                        type="radio"
                                                                                                                        id={app.additional_app_plugin.id}
                                                                                                                        label={`${app.additional_app_plugin.name}`}
                                                                                                                        ref={numOfPage}
                                                                                                                        onChange={(e) =>
                                                                                                                            sumOfCostRadio(e, appPlugin)
                                                                                                                        }
                                                                                                                        style={{
                                                                                                                            animation:
                                                                                                                                "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </Col>
                                                                                                            )
                                                                                                    );
                                                                                                })}
                                                                                            </Row>
                                                                                        </Form.Group>
                                                                                    </>
                                                                                )}
                                                                            </div>

                                                                            <div className="row">
                                                                                {subServiceName?.current?.value == 3 &&
                                                                                    allNumberOfPage.map((data, index) => (
                                                                                        <div className="col-md-6" key={index}>
                                                                                            <Form.Check
                                                                                                type="radio"
                                                                                                id={data.id}
                                                                                                label={data.total_page}
                                                                                                ref={numOfPage}
                                                                                                onChange={(e) => sumOfCostRadio(e, data)}
                                                                                                style={{
                                                                                                    animation:
                                                                                                        "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    ))}

                                                                                {/* WEB PROGRAMMING: React JS = 7 , PHP Laravel = 8, React Laravel = 9 */}
                                                                                {(ServiceType?.current?.value == 2 ||
                                                                                    ServiceType?.current?.value == 7 ||
                                                                                    ServiceType?.current?.value == 8 ||
                                                                                    ServiceType?.current?.value == 9 ||
                                                                                    subServiceName?.current?.value == 6) &&
                                                                                    allNumberOfPage.map(
                                                                                        (data, index) =>
                                                                                            sectionTypeDropdownValue ==
                                                                                            data?.section_type && (
                                                                                                <div className="col-md-6" key={data.id}>
                                                                                                    <Form.Check
                                                                                                        type="radio"
                                                                                                        name="WP"
                                                                                                        id={data.id}
                                                                                                        label={data.total_page}
                                                                                                        ref={numOfPage}
                                                                                                        onChange={(e) =>
                                                                                                            sumOfCostRadio(e, data)
                                                                                                        }
                                                                                                        style={{
                                                                                                            animation:
                                                                                                                "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                    )}
                                                                            </div>
                                                                        </Form.Group>
                                                                    )}

                                                                    {includeExcludePluginShow &&
                                                                        includeExcludepluginChecked.length > 0 && (
                                                                            <Form.Group
                                                                                as={Col}
                                                                                md={12}
                                                                                className="mb-3"
                                                                                style={{
                                                                                    backgroundColor: "#F2F2F2",
                                                                                    animation:
                                                                                        "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                }}
                                                                            >
                                                                                <Form.Label>Included Plugins</Form.Label>

                                                                                <div
                                                                                    className="d-flex flex-wrap"
                                                                                // style={{  margin:"0 15px"}}
                                                                                >
                                                                                    {includeExcludepluginChecked.map(
                                                                                        (include_plugin) => {
                                                                                            return (
                                                                                                <div
                                                                                                    className="mx-2 flex_center"
                                                                                                    key={include_plugin.id}
                                                                                                    style={{
                                                                                                        backgroundColor: "#fff",
                                                                                                        padding: "4px 6px",
                                                                                                        borderRadius: "8px",
                                                                                                        marginBottom: "8px",
                                                                                                        boxShadow:
                                                                                                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                                                                                    }}
                                                                                                >
                                                                                                    <BsCheckCircleFill
                                                                                                        color="green"
                                                                                                        className="me-2"
                                                                                                    />
                                                                                                    <span style={{ fontSize: "14px" }}>
                                                                                                        {
                                                                                                            include_plugin.additional_app_plugin
                                                                                                                .name
                                                                                                        }
                                                                                                    </span>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </div>
                                                                            </Form.Group>
                                                                        )}

                                                                    {includeExcludePluginShow &&
                                                                        includeExcludeplugin.length > 0 && (
                                                                            <Form.Group
                                                                                ref={excludedPluginDiv}
                                                                                as={Col}
                                                                                md={12}
                                                                                className="mb-3 position-relative"
                                                                                style={{
                                                                                    animation:
                                                                                        "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                }}
                                                                            >
                                                                                <Form.Label>Excluded Plugins</Form.Label>
                                                                                {includeExcludePluginShow && (
                                                                                    <div className="plugin_search_box">
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            placeholder="search here..."
                                                                                            value={excludedPluginSearch}
                                                                                            onChange={(e) => {
                                                                                                setExcludedPluginSearch(e.target.value);
                                                                                                setTogglePluginList(true);
                                                                                            }}
                                                                                        />
                                                                                        <button
                                                                                            type="button"
                                                                                            onClick={toggleExcludePluginDiv}
                                                                                        >
                                                                                            Browse
                                                                                        </button>
                                                                                    </div>
                                                                                )}
                                                                                <div
                                                                                    className={`row gx-0 ${togglePluginList
                                                                                        ? "d-block custom_menu"
                                                                                        : "d-none"
                                                                                        } mb-3`}
                                                                                >
                                                                                    {filteredExcludedAdditionalAppPlugin.map(
                                                                                        (exclude_plugin) => {
                                                                                            return (
                                                                                                <div className="" key={exclude_plugin.id}>
                                                                                                    <div
                                                                                                        className="row gx-0 px-2 py-1"
                                                                                                        style={{
                                                                                                            borderBottom: "1px solid #fff",
                                                                                                        }}
                                                                                                    >
                                                                                                        <div className="col-md-9">
                                                                                                            <Form.Check
                                                                                                                type="checkbox"
                                                                                                                id={exclude_plugin.id}
                                                                                                                label={
                                                                                                                    exclude_plugin
                                                                                                                        .additional_app_plugin.name
                                                                                                                }
                                                                                                                ref={sectionType}
                                                                                                                onChange={(e) =>
                                                                                                                    additionalAppPluginCheckbox(
                                                                                                                        e,
                                                                                                                        exclude_plugin
                                                                                                                    )
                                                                                                                }
                                                                                                                value={
                                                                                                                    exclude_plugin
                                                                                                                        .additional_app_plugin.name
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                        {currUserInfo?.countryCode == "BD"
                                                                                                            ? exclude_plugin
                                                                                                                .additional_app_plugin
                                                                                                                ?.bdt_price && (
                                                                                                                <div className="col-md-3 ps-2">
                                                                                                                    ৳
                                                                                                                    {
                                                                                                                        exclude_plugin
                                                                                                                            .additional_app_plugin
                                                                                                                            ?.bdt_price
                                                                                                                    }
                                                                                                                </div>
                                                                                                            )
                                                                                                            : exclude_plugin
                                                                                                                .additional_app_plugin
                                                                                                                ?.usd_price && (
                                                                                                                <div className="col-md-3 ps-2">
                                                                                                                    $
                                                                                                                    {
                                                                                                                        exclude_plugin
                                                                                                                            .additional_app_plugin
                                                                                                                            ?.usd_price
                                                                                                                    }
                                                                                                                </div>
                                                                                                            )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                    {/* </div> */}
                                                                                </div>
                                                                            </Form.Group>
                                                                        )}

                                                                    {includeExcludePluginShow &&
                                                                        additionalServices.length > 0 && (
                                                                            <Form.Group
                                                                                as={Col}
                                                                                md={12}
                                                                                className="mb-3 position-relative"
                                                                                style={{
                                                                                    animation:
                                                                                        "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                    // zIndex: "-1",
                                                                                }}
                                                                            >
                                                                                <Form.Label>Additional Services</Form.Label>

                                                                                <div
                                                                                    className="row"
                                                                                    style={{
                                                                                        maxHeight: "120px",
                                                                                        overflowX: "hidden",
                                                                                        overflowY: "auto",
                                                                                    }}
                                                                                >
                                                                                    {additionalServices.map(
                                                                                        (additional_services) => {
                                                                                            return (
                                                                                                <div
                                                                                                    className="col-md-12"
                                                                                                    key={additional_services.id}
                                                                                                >
                                                                                                    <div className="row">
                                                                                                        <div className="col-md-9">
                                                                                                            <Form.Check
                                                                                                                type="checkbox"
                                                                                                                id={
                                                                                                                    additional_services
                                                                                                                        .additional_service.name
                                                                                                                }
                                                                                                                label={
                                                                                                                    additional_services
                                                                                                                        .additional_service.name
                                                                                                                }
                                                                                                                ref={sectionType}
                                                                                                                onChange={(e) =>
                                                                                                                    additionalServicesCheckbox(
                                                                                                                        e,
                                                                                                                        additional_services
                                                                                                                    )
                                                                                                                }
                                                                                                                value={
                                                                                                                    additional_services
                                                                                                                        .additional_service.name
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                        {currUserInfo?.countryCode == "BD"
                                                                                                            ? additional_services
                                                                                                                .additional_service
                                                                                                                ?.bdt_price && (
                                                                                                                <div className="col-md-3 ps-2">
                                                                                                                    ৳
                                                                                                                    {
                                                                                                                        additional_services
                                                                                                                            .additional_service
                                                                                                                            ?.bdt_price
                                                                                                                    }
                                                                                                                </div>
                                                                                                            )
                                                                                                            : additional_services
                                                                                                                .additional_service
                                                                                                                ?.usd_price && (
                                                                                                                <div className="col-md-3 ps-2">
                                                                                                                    $
                                                                                                                    {
                                                                                                                        additional_services
                                                                                                                            .additional_service
                                                                                                                            ?.usd_price
                                                                                                                    }
                                                                                                                </div>
                                                                                                            )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                    {/* {includeExcludeplugin.map((include_plugin) =>
                                  include_plugin.exclude_app_plugins.map(
                                    (plugins) =>
                                    <div
                                    className="col-md-12"
                                    key={include_plugin.id}
                                  >
                                    <div className="row">
                                      <div className="col-md-9">
                                        <Form.Check
                                          type="checkbox"
                                          id={include_plugin.name}
                                          label={ plugins.additional_app_plugin.name}
                                          ref={sectionType}
                                          onChange={(e) =>
                                            additionalServiceValueCheckbox(
                                              e,
                                              include_plugin
                                            )
                                          }
                                          value={include_plugin?.name}
                                        />
                                      </div>
                                      {currUserInfo?.countryCode == "BD"
                                        ? include_plugin?.bdt_price && (
                                            <div className="col-md-3 ps-2">
                                              ৳{include_plugin?.bdt_price}
                                            </div>
                                          )
                                        : include_plugin?.usd_price && (
                                            <div className="col-md-3 ps-2">
                                              ${include_plugin?.usd_price}
                                            </div>
                                          )}
                                    </div>
                                  </div>
                                  )
                                )} */}
                                                                                </div>
                                                                            </Form.Group>
                                                                        )}


                                                                    <p>
                                                                        Do you want us to update your site with
                                                                        content?
                                                                    </p>
                                                                    <Form.Group className="mb-4">
                                                                        <Form.Check
                                                                            inline
                                                                            label="Yes"
                                                                            name="radio"
                                                                            type="radio"
                                                                            value="Yes"
                                                                            onChange={(e) => {
                                                                                setFormValue({
                                                                                    ...formValue,
                                                                                    siteUpdate: e.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                        <Form.Check
                                                                            inline
                                                                            label="No"
                                                                            name="radio"
                                                                            type="radio"
                                                                            defaultValue="No"
                                                                            onChange={(e) => {
                                                                                setFormValue({
                                                                                    ...formValue,
                                                                                    siteUpdate: e.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </Form.Group>

                                                                    {/* Project cost */}
                                                                    <Form.Group
                                                                        as={Col}
                                                                        md={6}
                                                                    // style={{ zIndex: "-1", position: "relative" }}
                                                                    >
                                                                        <p>
                                                                            <FaMoneyBill size="1.5em" />
                                                                            &nbsp;Project cost <br />
                                                                            {currUserInfo?.countryCode == "BD"
                                                                                ? grandTotalCostBDT > 0 && (
                                                                                    <span
                                                                                        className="fw-bold"
                                                                                        style={{
                                                                                            animation:
                                                                                                "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        ৳{grandTotalCostBDT}
                                                                                    </span>
                                                                                )
                                                                                : grandTotalCostUSD > 0 && (
                                                                                    <span className="fw-bold">
                                                                                        {" "}
                                                                                        ${grandTotalCostUSD}
                                                                                    </span>
                                                                                )}
                                                                        </p>
                                                                    </Form.Group>
                                                                    {/* Time to complete */}
                                                                    <Form.Group
                                                                        as={Col}
                                                                        md={6}
                                                                    // style={{ zIndex: "-1", position: "relative" }}
                                                                    >
                                                                        <p>
                                                                            <MdAccessTimeFilled size="1.5em" />
                                                                            &nbsp;Time to complete&nbsp; <br />
                                                                            {grandTotalDuration > 0 && (
                                                                                <span
                                                                                    className="fw-bold"
                                                                                    style={{
                                                                                        animation:
                                                                                            "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                    }}
                                                                                >
                                                                                    {grandTotalDuration} days
                                                                                </span>
                                                                            )}
                                                                        </p>
                                                                    </Form.Group>

                                                                    <Form.Group className="mb-4">
                                                                        <Form.Label className="t-fw-600 t-fs-15">
                                                                            Tell us about your project
                                                                            <span className="text-danger">*</span>
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            as="textarea"
                                                                            rows={3}
                                                                            value={formValue.description}
                                                                            onChange={(e) => {
                                                                                setFormValue({
                                                                                    ...formValue,
                                                                                    description: e.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </Form.Group>
                                                                </div>
                                                            </>
                                                        )}

                                                    {activeStep === 1 &&
                                                        formValue.category == "Graphics & Design" && (
                                                            <Row>
                                                                <Form.Group as={Col} md={12} className="mb-3">
                                                                    <Form.Label>Service Type</Form.Label>

                                                                    <Form.Select
                                                                        required
                                                                        aria-label="language example "
                                                                        className=""
                                                                        ref={ServiceType}
                                                                        onChange={fetchSectionType}
                                                                    >
                                                                        <option value="" selected>
                                                                            Select Service Type
                                                                        </option>

                                                                        {allDesignTypes?.map((data) => (
                                                                            <option key={data.id} value={data.id}>
                                                                                {data?.name}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Form.Group>

                                                                <Form.Group className="mb-4" as={Col} md={12}>
                                                                    <Form.Label className="t-fw-600 t-fs-15">
                                                                        Tell us about your project
                                                                        <span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        as="textarea"
                                                                        rows={3}
                                                                        value={formValue.description}
                                                                        onChange={(e) => {
                                                                            setFormValue({
                                                                                ...formValue,
                                                                                description: e.target.value,
                                                                            });
                                                                        }}
                                                                    />
                                                                </Form.Group>

                                                                <Form.Group as={Col} md={6}>
                                                                    <p>
                                                                        <FaMoneyBill size="1.5em" />
                                                                        &nbsp;Project cost <br />
                                                                        {currUserInfo?.countryCode == "BD"
                                                                            ? grandTotalCostBDT > 0 && (
                                                                                <span
                                                                                    className="fw-bold"
                                                                                    style={{
                                                                                        animation:
                                                                                            "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                    }}
                                                                                >
                                                                                    {" "}
                                                                                    ৳{grandTotalCostBDT}
                                                                                </span>
                                                                            )
                                                                            : grandTotalCostUSD > 0 && (
                                                                                <span className="fw-bold">
                                                                                    {" "}
                                                                                    ${grandTotalCostUSD}
                                                                                </span>
                                                                            )}
                                                                    </p>
                                                                </Form.Group>

                                                                <Form.Group as={Col} md={6}>
                                                                    <p>
                                                                        <MdAccessTimeFilled size="1.5em" />
                                                                        &nbsp;Time to complete&nbsp; <br />
                                                                        {grandTotalDuration > 0 && (
                                                                            <span
                                                                                className="fw-bold"
                                                                                style={{
                                                                                    animation:
                                                                                        "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                }}
                                                                            >
                                                                                {grandTotalDuration} days
                                                                            </span>
                                                                        )}
                                                                    </p>
                                                                </Form.Group>
                                                            </Row>
                                                        )}

                                                    {activeStep === 1 &&
                                                        formValue.category == "Marketing & Management" && (
                                                            <Row>
                                                                <Form.Group as={Col} md={12} className="mb-3">
                                                                    <Form.Label>Service Type</Form.Label>

                                                                    <Form.Select
                                                                        required
                                                                        aria-label="language example "
                                                                        className=""
                                                                        ref={ServiceType}
                                                                        onChange={fetchSectionType}
                                                                    >
                                                                        <option value="" selected>
                                                                            Select Service Type
                                                                        </option>

                                                                        {allDesignTypes?.map((data) => (
                                                                            <option key={data.id} value={data.id}>
                                                                                {data?.name}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Form.Group>

                                                                {/* {sectionTypeDropdwonValueDetails && (
                                                                    <Form.Group
                                                                        as={Col}
                                                                        md={12}
                                                                        className="mb-3"
                                                                        style={{
                                                                            animation:
                                                                                "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                        }}
                                                                    >
                                                                        <Form.Label>Package Details</Form.Label>
                                                                        <Form.Control
                                                                            ref={details}
                                                                            value={sectionTypeDropdwonValueDetails}
                                                                            type="hidden"
                                                                        />
                                                                        {Parse(
                                                                            `${sectionTypeDropdwonValueDetails}`
                                                                        )}
                                                                    </Form.Group>
                                                                )}
                                                                {sectionTypeDropdwonValueNote &&
                                                                    sectionTypeDropdwonValueNote != "null" && (
                                                                        <Form.Group
                                                                            as={Col}
                                                                            md={12}
                                                                            className="mb-3"
                                                                            style={{
                                                                                animation:
                                                                                    "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                            }}
                                                                        >
                                                                            <Form.Label>Note</Form.Label>
                                                                            <Form.Control
                                                                                ref={note}
                                                                                value={sectionTypeDropdwonValueNote}
                                                                                type="hidden"
                                                                            />
                                                                            {Parse(`${sectionTypeDropdwonValueNote}`)}
                                                                        </Form.Group>
                                                                    )} */}

                                                                <Form.Group className="mb-4" as={Col} md={12}>
                                                                    <Form.Label className="t-fw-600 t-fs-15">
                                                                        Tell us about your project
                                                                        <span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        as="textarea"
                                                                        rows={3}
                                                                        value={formValue.description}
                                                                        onChange={(e) => {
                                                                            setFormValue({
                                                                                ...formValue,
                                                                                description: e.target.value,
                                                                            });
                                                                        }}
                                                                    />
                                                                </Form.Group>

                                                                <Form.Group as={Col} md={6}>
                                                                    <p>
                                                                        <FaMoneyBill size="1.5em" />
                                                                        &nbsp;Project cost <br />
                                                                        {currUserInfo?.countryCode == "BD"
                                                                            ? grandTotalCostBDT > 0 && (
                                                                                <span
                                                                                    className="fw-bold"
                                                                                    style={{
                                                                                        animation:
                                                                                            "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                    }}
                                                                                >
                                                                                    {" "}
                                                                                    ৳{grandTotalCostBDT}
                                                                                </span>
                                                                            )
                                                                            : grandTotalCostUSD > 0 && (
                                                                                <span className="fw-bold">
                                                                                    {" "}
                                                                                    ${grandTotalCostUSD}
                                                                                </span>
                                                                            )}
                                                                    </p>
                                                                </Form.Group>

                                                                <Form.Group as={Col} md={6}>
                                                                    <p>
                                                                        <MdAccessTimeFilled size="1.5em" />
                                                                        &nbsp;Time to complete&nbsp; <br />
                                                                        {grandTotalDuration > 0 && (
                                                                            <span
                                                                                className="fw-bold"
                                                                                style={{
                                                                                    animation:
                                                                                        "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                }}
                                                                            >
                                                                                {grandTotalDuration} days
                                                                            </span>
                                                                        )}
                                                                    </p>
                                                                </Form.Group>
                                                            </Row>
                                                        )}

                                                    {activeStep === 1 &&
                                                        formValue.category == "Writing & Translation" && (
                                                            <Row>
                                                                {sectionTypeDropdown.length > 0 && (
                                                                    <>
                                                                        <Form.Group
                                                                            as={Col}
                                                                            md={12}
                                                                            className="mb-3"
                                                                        >
                                                                            <Form.Label>Package Type</Form.Label>

                                                                            {/* <Form.Label>Website Type</Form.Label> */}
                                                                            <Form.Select
                                                                                required
                                                                                aria-label="language example "
                                                                                className=""
                                                                                // onChange={(e) =>
                                                                                //     sumOfCostTranslation(e)
                                                                                // }
                                                                                ref={SectionTypeDropdown}
                                                                            >
                                                                                <option value="" selected>
                                                                                    Select Package Type
                                                                                </option>

                                                                                {sectionTypeDropdown?.map((data) => {
                                                                                    return (
                                                                                        <option
                                                                                            key={data.id}
                                                                                            value={
                                                                                                data.id +
                                                                                                "_" +
                                                                                                data?.name +
                                                                                                "_" +
                                                                                                data?.details +
                                                                                                "_" +
                                                                                                data?.note +
                                                                                                "_" +
                                                                                                data?.service_cost?.usd_price +
                                                                                                "_" +
                                                                                                data?.service_cost?.bdt_price +
                                                                                                "_" +
                                                                                                data?.service_cost?.duration
                                                                                            }
                                                                                            name={data.name}
                                                                                        >
                                                                                            {data?.name}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                    </>
                                                                )}
                                                                <Form.Group className="mb-4" as={Col} md={12}>
                                                                    <Form.Label className="t-fw-600 t-fs-15">
                                                                        Tell us about your project
                                                                        <span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        as="textarea"
                                                                        rows={3}
                                                                        value={formValue.description}
                                                                        onChange={(e) => {
                                                                            setFormValue({
                                                                                ...formValue,
                                                                                description: e.target.value,
                                                                            });
                                                                        }}
                                                                    />
                                                                </Form.Group>

                                                                <Form.Group as={Col} md={6}>
                                                                    <p>
                                                                        <FaMoneyBill size="1.5em" />
                                                                        &nbsp;Project cost <br />
                                                                        {currUserInfo?.countryCode == "BD"
                                                                            ? grandTotalCostBDT > 0 && (
                                                                                <span
                                                                                    className="fw-bold"
                                                                                    style={{
                                                                                        animation:
                                                                                            "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                    }}
                                                                                >
                                                                                    {" "}
                                                                                    ৳{grandTotalCostBDT}
                                                                                </span>
                                                                            )
                                                                            : grandTotalCostUSD > 0 && (
                                                                                <span className="fw-bold">
                                                                                    {" "}
                                                                                    ${grandTotalCostUSD}
                                                                                </span>
                                                                            )}
                                                                    </p>
                                                                </Form.Group>

                                                                <Form.Group as={Col} md={6}>
                                                                    <p>
                                                                        <MdAccessTimeFilled size="1.5em" />
                                                                        &nbsp;Time to complete&nbsp; <br />
                                                                        {grandTotalDuration > 0 && (
                                                                            <span
                                                                                className="fw-bold"
                                                                                style={{
                                                                                    animation:
                                                                                        "inAnimation 550ms cubic-bezier(0.47, 0.48, 0.49, 0.5) forwards",
                                                                                }}
                                                                            >
                                                                                {grandTotalDuration} days
                                                                            </span>
                                                                        )}
                                                                    </p>
                                                                </Form.Group>
                                                            </Row>
                                                        )}

                                                    {/* ==================== STEP 3 ==========================*/}

                                                    {activeStep === 2 && (
                                                        <>
                                                            <Form.Group className="mb-4">
                                                                <Form.Label className="t-fw-600 t-fs-15">
                                                                    Email
                                                                </Form.Label>
                                                                <Autocomplete
                                                                    onChange={(e, value) => getUserInfo(e, value)}
                                                                    options={userinfo.map(
                                                                        (option) => option.email
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params} inputRef={email} />
                                                                    )}
                                                                    sx={{
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "&.Mui-focused fieldset": {
                                                                                border: "1px solid #86b7fe",
                                                                            },
                                                                        },
                                                                        "& .MuiInputBase-root": {
                                                                            ".MuiInputBase-root:hover": {
                                                                                border: "none",
                                                                            },
                                                                            padding: "0",
                                                                        },
                                                                    }}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group className="mb-4">
                                                                <Form.Label className="t-fw-600 t-fs-15">
                                                                    Client Name
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder=" Your Name"
                                                                    ref={name}
                                                                    defaultValue={selectedUserInfo?.name}
                                                                // onChange={(e) => {
                                                                //   setFormValue({
                                                                //     ...formValue,
                                                                //     clientName: e.target.value,
                                                                //   });
                                                                // }}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group className="mb-4">
                                                                <Form.Label className="t-fw-600 t-fs-15">
                                                                    Company Name
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Company Name"
                                                                    ref={company}
                                                                    defaultValue={selectedUserInfo?.company_name}
                                                                // onChange={(e) => {
                                                                //   setFormValue({
                                                                //     ...formValue,
                                                                //     companyName: e.target.value,
                                                                //   });
                                                                // }}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group className="mb-4">
                                                                <Form.Label className="t-fw-600 t-fs-15">
                                                                    Phone Number
                                                                    <span className="text-danger">*</span>
                                                                </Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    placeholder="Phone no"
                                                                    ref={phone}
                                                                    defaultValue={selectedUserInfo?.phone}
                                                                // onChange={(e) => {
                                                                //   setFormValue({
                                                                //     ...formValue,
                                                                //     phone: e.target.value,
                                                                //   });
                                                                // }}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className="mb-4">
                                                                <Form.Label className="t-fw-600 t-fs-15">
                                                                    Your Address
                                                                </Form.Label>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    placeholder="Your Address"
                                                                    ref={address}
                                                                    defaultValue={
                                                                        selectedUserInfo?.address
                                                                            ? selectedUserInfo?.address
                                                                            : ""
                                                                    }
                                                                // onChange={(e) => {
                                                                //   setFormValue({
                                                                //     ...formValue,
                                                                //     address: e.target.value,
                                                                //   });
                                                                // }}
                                                                />
                                                            </Form.Group>
                                                        </>
                                                    )}

                                                    {activeStep === steps.length - 1 && (
                                                        <div className="flex_center">
                                                            <button type="submit" className="btn_submit">
                                                                Submit
                                                            </button>
                                                        </div>
                                                    )}
                                                </Form>
                                            </div>
                                            <div className="flex_end">
                                                <button
                                                    onClick={handleBack}
                                                    className={`me-3 btn btn-prev ${activeStep == 0 ? "d-none" : "d-block"
                                                        }`}
                                                >
                                                    Prev
                                                </button>

                                                {activeStep !== steps.length - 1 && (
                                                    <span
                                                        className="tooltip-arrow"
                                                        tabIndex="0"
                                                        data-bs-toggle="tooltip"
                                                        title="Give your project a name to continue"
                                                    >
                                                        <button
                                                            className="btn-next"
                                                            onClick={() => {
                                                                handleNext();
                                                            }}
                                                            disabled={formValue.name == ""}
                                                        >
                                                            Next
                                                        </button>
                                                    </span>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </Col>
                                <Col md={3}>
                                    <div className="right_content">
                                        <HiLightBulb size="2rem" className="bulb" />
                                        <p className="mb-0 t-fw-700 ">Fresh Content Brewin'</p>
                                        <p>
                                            Welcome! Let's help you create your project so that you
                                            get the output
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default CreateNewOrderTwo;
