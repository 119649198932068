import { InputLabel, LinearProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import Swal from "sweetalert2";

import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";

import { FaEye } from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import { MdDeleteForever } from "react-icons/md";
import getCookie from "../../../Component/GetCookie";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";

const JobApplication = () => {
  const [tableData, setTableData] = useState([]);

  // Modal Section Data
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("lg");

  // Vew single value
  const [singleApplicationInfo, setSingleApplicationInfo] = useState([]);

  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/explore-openings/job-posts/applications`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        setTableData(res.data?.all_job_applications);
      });
  };

  // View single Data
  const showSingleData = (modalValue, data) => {
    setModalSize("lg");
    setModalData(modalValue);
    setSingleApplicationInfo(data);
    setModalShow(true);
  };

  // Delete Data
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}/api/v2/rianaire/explore-openings/job-posts/delete/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllInfo();
        });
    }
  };

  useEffect(() => {
    renderAllInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Job Applications</h3>
                </div>
                {tableData.length > 0 ? (
                  <div className="table-responsive custom_table">
                    <div className="table_fixed">
                      <table className="table table-hover my-5">
                        <thead>
                          <tr>
                            <th scope="col">Handle</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Resume/CV</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData?.map((data, index) => (
                            <tr key={index}>
                              <td>
                                <Dropdown style={{ position: "unset" }}>
                                  <Dropdown.Toggle>
                                    <BsThreeDotsVertical color="#000" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {/* edit */}

                                    <Dropdown.Item
                                      onClick={() =>
                                        showSingleData("View", data)
                                      }
                                    >
                                      View
                                    </Dropdown.Item>

                                    {/* delete */}

                                    <Dropdown.Item
                                      onClick={() => deleteData(data.id)}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>
                                {data?.first_name + " " + data?.last_name}
                              </td>
                              <td>{data?.email}</td>
                              <td>{data?.phone}</td>
                              <td>
                                {" "}
                                <a
                                  href={`${BACKEND_BASE_URL}${data?.resume}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="btn btn-warning btn-sm"
                                  download
                                >
                                  Download
                                </a>
                              </td>

                              {/* <td>
                            {data.status == 1 || data.status == 0 ?  (
                              <Link to="" className="btn btn-warning">
                                Pending
                                
                              </Link>
                            ) : (
                              <Link to="" onClick={() => StatusUpdate(data.id)}>
                                <AiIcons.AiOutlineClose
                                  style={{
                                    color: "white",
                                    backgroundColor: "red",

                                    padding: "2px 4px",
                                    borderRadius: "4px",
                                  }}
                                  size="1.6em"
                                />
                              </Link>
                            )}
                          </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <LinearProgress />
                )}
              </div>
            </div>
            <Modal
              show={modalShow}
              size={modalSize}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => setModalShow(false)}
            >
              <Modal.Header>
                <Modal.Title className="" id="contained-modal-title-vcenter">
                  {modalData}
                </Modal.Title>
                <Button
                  className="p-0 border-0 bg-danger"
                  onClick={() => setModalShow(false)}
                >
                  <CgClose
                    style={{
                      fontSize: "26px",
                    }}
                  />
                </Button>
              </Modal.Header>
              <Modal.Body>
                {/* View Modal section */}
                {modalData === "View" && (
                  <>
                    <h2>
                      Name:{" "}
                      {singleApplicationInfo?.first_name +
                        " " +
                        singleApplicationInfo?.last_name}
                    </h2>
                    <h6>Email: {singleApplicationInfo?.email}</h6>
                    <h6>Contact No: {singleApplicationInfo?.phone}</h6>
                    {singleApplicationInfo?.linkedin_profile && (
                      <h6>
                        Linkedin: {singleApplicationInfo?.linkedin_profile}
                      </h6>
                    )}
                    {singleApplicationInfo?.website && (
                      <h6>Website: {singleApplicationInfo?.website}</h6>
                    )}

                    <h6>
                      <a
                        href={`${BACKEND_BASE_URL}${singleApplicationInfo?.resume}`}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-warning btn-sm"
                        download
                      >
                        Download Resume/CV
                      </a>
                    </h6>

                    <div>
                      <object
                        width="100%"
                        height="500"
                        type="application/pdf"
                        data={`${BACKEND_BASE_URL}${singleApplicationInfo?.resume}?#zoom=90&scrollbar=0&toolbar=0&navpanes=0`}
                      >
                        PDF
                      </object>
                    </div>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setModalShow(false)}
                  className="btn-danger"
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobApplication;
