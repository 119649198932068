import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import getCookie from "../../../../Component/GetCookie";
import { ToastAlert } from "../../../../Component/ToastAlert";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import { Link } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FiSave } from "react-icons/fi";
import LinearProgressMui from "../../../../Component/Custom Loader/LinearProgress";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const AdminChildServiceEdit = () => {
  const { childServiceId } = useParams();
  const serviceName = useRef();
  const invoicePrefix = useRef();
  const subServiceName = useRef();
  const childService = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const [mainServices, setMainServices] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/child-services`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })

      .then((res) => {
        setIsLoading(false);
        setMainServices(res.data?.main_services);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [subServices, setSubServices] = useState([]);

  const fetchSubService = async () => {
    await axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/sub-services/${serviceName.current.value}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        setSubServices(res.data?.sub_services);
      });
  };

  // single table data
  const [singleData, setSingleData] = useState([]);

  // Get Single Info
  const renderSingleData = async () => {
    await axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/child-services/show/${childServiceId}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        setSingleData(res.data?.single_child_service);
      });
  };

  // Updated data to backend
  const updateData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("main_service_id", serviceName.current.value);
    formdata.append("sub_service_id", subServiceName.current.value);
    formdata.append("name", childService.current.value);
    formdata.append("invoice_prefix", invoicePrefix.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/child-services/update/${childServiceId}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        ToastAlert.fire({
          icon: "success",
          title: response.data?.message,
        });
      });

    e.preventDefault();
  };

  useEffect(() => {
    renderSingleData();
  }, []);

  useEffect(() => {
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/sub-services/${singleData.main_service_id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        setSubServices(res.data?.sub_services);
      });
  }, [singleData]);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar pageTitle="Child Service Edit" />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header d-flex">
                  <Link
                    to="/admin/final-service-module/child-services"
                    className="ms-auto"
                  >
                    <Button variant="success" size="sm">
                      All Child Services &nbsp;
                    </Button>
                  </Link>
                </div>
                <Row>
                  {!isLoading && (
                    <Form onSubmit={updateData}>
                      <div className="content-wrapper">
                        <div className="card">
                          <div className="card-body">
                            <div className="row py-3">
                              {/* Main service */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Main Service
                                </Form.Label>
                                <Form.Select
                                  aria-label="language example "
                                  className=""
                                  ref={serviceName}
                                  onChange={fetchSubService}
                                >
                                  <option value="" disabled selected>
                                    Select main service
                                  </option>
                                  {mainServices?.map((data, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={data.id}
                                        selected={
                                          data.id ==
                                          singleData?.main_service?.id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {data.name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>

                              {/*Sub Service Name */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Service Name
                                </Form.Label>
                                <Form.Select
                                  aria-label="language example "
                                  className=""
                                  ref={subServiceName}
                                >
                                  <option value="" disabled selected>
                                    Select Service
                                  </option>
                                  {subServices?.length > 0 &&
                                    subServices?.map((subService, index) => (
                                      <option
                                        key={index}
                                        value={subService.id}
                                        selected={
                                          subService.id ==
                                          singleData?.sub_service?.id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {subService.name}
                                      </option>
                                    ))}
                                </Form.Select>
                              </Form.Group>

                              {/* Child Service */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Child Service{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  placeholder="Name"
                                  ref={childService}
                                  defaultValue={singleData?.name}
                                />
                              </Form.Group>

                              {/* Invoice Prefix */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Invoice Prefix
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  placeholder="Invoice Prefix"
                                  ref={invoicePrefix}
                                  defaultValue={singleData?.invoice_prefix}
                                />
                              </Form.Group>

                              {/* Submit button */}
                              <button
                                type="submit"
                                className="btn-submit mt-5 rounded-3 border-0"
                              >
                                <FiSave /> &nbsp; Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Row>
              </div>
              {isLoading  && <LinearProgressMui />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminChildServiceEdit;
