import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Parse from "html-react-parser";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import getCookie from "../../../../Component/GetCookie";
import { Button } from "react-bootstrap";
import { LinearProgress } from "@mui/material";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const ExploreOpeningsView = () => {
  const { jobopeningId } = useParams();
  const [singleJobInfo, setSingleJobInfo] = useState();
  // fetch single Info
  const renderSingleData = async () => {
    await axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/explore-openings/job-posts/show/${jobopeningId}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        setSingleJobInfo(res.data?.job_post);
      });
  };
  useEffect(() => {
    renderSingleData();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between mb-5">
                  <h3>Job Openings</h3>
                  <Link to={`/admin/career/explore-openings`}>
                    <Button variant="success" size="sm">
                      All Job Openings &nbsp;
                    </Button>
                  </Link>
                </div>

                {singleJobInfo?.title ? (
                  <>
                    <h3>
                      <strong>Job Title:</strong> {singleJobInfo?.title}
                    </h3>
                    <hr />
                    <div
                      className="mt-4"
                      style={{ maxHeight: "70vh", overflowY: "auto" }}
                    >
                      {Parse(`${singleJobInfo?.description}`)}
                    </div>
                  </>
                ) : (
                  <LinearProgress />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreOpeningsView;
