import { LinearProgress } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import { FaEye } from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import getCookie from "../../../Component/GetCookie";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Swal from "sweetalert2";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";

const RegisteredUserAll = () => {
  const [tableData, setTableData] = useState([]);

  // Get All Info
  const fetchTableData = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/registered-user-management`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setTableData(res.data?.all_users);
      });
  };

  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}/api/v2/rianaire/registered-user-management/delete/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data?.message,
            confirmButtonColor: "#5eba86",
          });
          fetchTableData();
        });
    }
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  // View single value
  const [singleData, setSingleData] = useState([]);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Registered User</h3>
                  <Link to="/admin/registered-user/add-new">
                    <Button variant="success" size="sm">
                      Add &nbsp;
                      <span>
                        <AiOutlinePlusCircle className="mb-1" />
                      </span>
                    </Button>
                  </Link>
                </div>

                {tableData.length > 0 ? (
                  <div className="table-responsive custom_table">
                    <div className="table_fixed">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Handle</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Address</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData?.map((data) => (
                            <tr key={data.id}>
                              <td>
                                <Dropdown style={{ position: "unset" }}>
                                  <Dropdown.Toggle>
                                    <BsThreeDotsVertical color="#000" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {/* view */}
                                    <Dropdown.Item
                                      as={Link}
                                      to={`/admin/registered-user/view/${data?.id}`}
                                    >
                                      View
                                    </Dropdown.Item>
                                    {/* edit */}

                                    <Dropdown.Item
                                      as={Link}
                                      to={`/admin/registered-user/edit/${data?.id}`}
                                    >
                                      Edit
                                    </Dropdown.Item>
                                    {/* delete */}
                                    <Dropdown.Item
                                      onClick={() => deleteData(data.id)}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td> {data?.name}</td>
                              <td> {data?.email}</td>
                              <td> {data?.phone}</td>
                              <td>{data?.address}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <LinearProgress />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisteredUserAll;
