import React, { useRef, useState } from "react";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { AiFillHome, AiOutlineMenu, AiOutlineProject } from "react-icons/ai";
import { BsPencilFill } from "react-icons/bs";
import { MdNotifications, MdOutlineEdit } from "react-icons/md";
import { BiLogOut } from "react-icons/bi";
import { Button, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import axios from "axios";
import { ToastAlert } from "../../../Component/ToastAlert";
import Swal from "sweetalert2";
import Cookie from "cookie-universal";
import "./adminProfile.css";
import { RabbitSVG } from "../../../Component/SVG/RabbitSVG";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";

const AdminProfile = () => {
  const hiddenFileInput = useRef(null);
  const navigate = useNavigate();
  const [toggleNav, setToggleNav] = useState(false);
  const cookies = Cookie();

  const handleChange = (e) => {
    const formdata = new FormData();
    if (hiddenFileInput.current.files[0]) {
      formdata.append("profile_photo", hiddenFileInput.current.files[0]);
    }

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/admin-user-profile/image/update`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            title: response.data?.message,
          });

          e.target.reset();
        }
      });
    e.preventDefault();
  };

  const LogoutAdmin = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/logout`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        if (res.data?.status == 200) {
          Swal.fire({
            icon: "success",
            text: res.data.message,
            confirmButtonColor: "#5eba86",
          });
          cookies.removeAll();
          localStorage.removeItem("LOGGED_IN_USER_PERMISSION_INFO");
          localStorage.removeItem("adminUserInfo");
          localStorage.removeItem("LOGGED_IN_ADMIN_USER_TYPE");
          localStorage.removeItem("ACCESS_TOKEN");
          localStorage.removeItem("LOGGED_IN_ADMIN_ID");
          navigate("/admin/login");
        }
      });
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  return (
    <div style={{ backgroundColor: "rgb(241, 242, 246)" }}>
      <div className="user_profile_navbar admin_navbar">
        <Navbar expand="lg" variant="dark" className="custom_container">
          <Navbar.Brand as={Link} to="/" target="_blank">
            <img
              src={require("../../../Assets/logo-white.png")}
              alt=""
              className="img-fluid"
              width={120}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto text-white d-flex align-items-lg-center">
              <Nav.Link
                href="#home"
                className="me-3"
                style={{ color: "#ababab" }}
              >
                Help & Support
              </Nav.Link>

              <Nav.Link
                href="/admin"
                className="me-3"
                style={{ color: "#ababab" }}
              >
                <Button className="my_workspace_btn"> My Workspace</Button>
              </Nav.Link>
              <div className="d-lg-none">
                <Nav.Link
                  as={Link}
                  to="/user/profile"
                  className=" t-fs-17 cl-333 py-2"
                >
                  Profile
                </Nav.Link>
                <Nav.Link as={Link} to="#" className=" t-fs-17 cl-333  py-2">
                  Dashboard
                </Nav.Link>

                <Nav.Link as={Link} to="#" className=" t-fs-17 cl-333  py-2">
                  Settings
                </Nav.Link>
                <Nav.Link as={Link} to="#" className=" t-fs-17 cl-333  py-2">
                  Help & Support
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/user"
                  onClick={LogoutAdmin}
                  className=" t-fs-17 cl-333 py-2"
                >
                  Logout
                </Nav.Link>
              </div>

              <NavDropdown
                className="admin_profile_dropdown position-relative d-none d-lg-block"
                id="collasible-nav-dropdown"
                title={
                  <div className={`user_profile_logo`}>
                    {getLocalStorageWithExpiry("adminUserInfo")
                      ?.profile_photo != null ? (
                      <img
                        src={`${BACKEND_BASE_URL}${getLocalStorageWithExpiry("adminUserInfo")
                          ?.profile_photo
                          }`}
                        alt="Profile"
                        className="w-100 h-100"
                        style={{ objectFit: "cover" }}
                      />
                    ) : (
                      <span>
                        <RabbitSVG />
                      </span>
                    )}
                  </div>
                }
              >
                <NavDropdown.Item as={Link} to="#" className=" border-bottom">
                  <div className="flex_start">
                    <div className={`user_profile_logo me-3 mb-3`}>
                      {getLocalStorageWithExpiry("adminUserInfo")
                        ?.profile_photo != null ? (
                        <img
                          src={`${BACKEND_BASE_URL}${getLocalStorageWithExpiry("adminUserInfo")
                            ?.profile_photo
                            }`}
                          alt="Profile"
                          className="img-fluid"
                        />
                      ) : (
                        <span>
                          <RabbitSVG />
                        </span>
                      )}
                    </div>
                    <div className="mb-3">
                      <h5 className="mb-0 cl-333 text-capitalize">
                        {getLocalStorageWithExpiry("adminUserInfo")?.name}
                      </h5>
                      <p className="mb-0">
                        {
                          getLocalStorageWithExpiry("adminUserInfo")
                            ?.email
                        }
                      </p>
                    </div>
                  </div>
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={Link}
                  to="/admin/profile"
                  className=" t-fs-17 cl-333 py-2"
                >
                  Account Overview
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="#"
                  className=" t-fs-17 cl-333  py-2"
                >
                  Notification Settings
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="#"
                  className=" t-fs-17 cl-333  py-2"
                >
                  Workspace Settings
                </NavDropdown.Item>
                
                <NavDropdown.Item
                  as={Link}
                  to="#"
                  className=" t-fs-17 cl-333  py-2"
                >
                  Help & Support
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={Link}
                  to="/user"
                  onClick={LogoutAdmin}
                  className=" t-fs-17 cl-333 py-2"
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div className="custom_container">
        <div className="d-flex user_profile_dashboard">
          <nav
            className={`user-profile-sidebar admin-profile-sidebar ${toggleNav ? "close" : ""
              }`}
          >
            <header className="my-5">
              {/* <Link to="/" target="_blank" className="text-decoration-none"> */}
              <div className="mx-4 flex_center position-relative edit_box">
                <span onClick={handleClick} className="cursor">
                  <MdOutlineEdit className="" />
                </span>

                <input
                  type="file"
                  onChange={handleChange}
                  ref={hiddenFileInput}
                  style={{ display: "none" }}
                />

                <div className="text logo_img_wrapper">
                  {getLocalStorageWithExpiry("adminUserInfo")?.profile_photo !=
                    null ? (
                    <img
                      src={`${BACKEND_BASE_URL}${getLocalStorageWithExpiry("adminUserInfo")
                        ?.profile_photo
                        }`}
                      alt="Profile"
                      className="w-100 h-100"
                      style={{ objectFit: "cover" }}
                    />
                  ) : (
                    <span>
                      <img
                        src={require("../../../Assets/rabbit_png.png")}
                        alt=""
                        className="img-fluid"
                      />
                    </span>
                  )}
                </div>
              </div>

              {/* </Link> */}

              <AiOutlineMenu
                className="bx bx-chevron-right toggle d-lg-none"
                onClick={() => setToggleNav(!toggleNav)}
              />
            </header>

            <div className="menu-bar">
              <div className="menu">
                <ul className="menu-links ps-0">
                  <NavLink to="/admin/profile/">
                    <li className={`nav-link`} title="Account Overview">
                      <AiFillHome className="icon" />
                      <span className={`text nav-text `}>Account Overview</span>
                    </li>
                  </NavLink>

                  <NavLink to="edit-profile">
                    <li className={`nav-link`} title="Edit Profile">
                      <BsPencilFill className="icon" />
                      <span className={`text nav-text`}>Edit Profile</span>
                    </li>
                  </NavLink>

                  <NavLink
                    to="notification-settings"
                    title="Notification Settings"
                  >
                    <li className={`nav-link`}>
                      <MdNotifications className="icon" />
                      <span className="text  nav-text me-3">
                        Notification Settings
                      </span>
                    </li>
                  </NavLink>
                  <NavLink to="/admin/profile/workspace">
                    <li className={`nav-link`}>
                      <AiOutlineProject className="icon" />
                      <span className="text nav-text me-3">
                        Workspace Settings
                      </span>
                    </li>
                  </NavLink>

                  <Link to="" onClick={LogoutAdmin} className="">
                    <li className="nav-link">
                      <BiLogOut className="icon" />
                      <span className="text nav-text">Logout</span>
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </nav>
          <section className="user-profile-rightbar">
            <Outlet />
          </section>
        </div>
      </div>
    </div>
  );
};

export default AdminProfile;
