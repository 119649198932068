import { LinearProgress } from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import getCookie from "../../../Component/GetCookie";

import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";

const AdminOrderUserView = () => {
  const { userId, orderId } = useParams();
  const [singleUserInfo, setSingleUserInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  // Get All Info
  const renderAllInfo = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/orders/user-info/${userId}`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setSingleUserInfo(res.data?.order_user_info);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    renderAllInfo();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_end mb-5">
                  <Link to={`/admin/orders/view/${orderId}`}>
                    <Button variant="success" size="sm">
                      Back to order details
                    </Button>
                  </Link>
                </div>
                {!isLoading && !error && (
                  <>
                    {singleUserInfo?.name && (
                      <p>
                        {" "}
                        <b>Username: </b> {singleUserInfo?.name}
                      </p>
                    )}
                    {singleUserInfo?.email && (
                      <p>
                        {" "}
                        <b>Email: </b> {singleUserInfo?.email}
                      </p>
                    )}
                    {singleUserInfo?.phone && (
                      <p>
                        {" "}
                        <b>Phone: </b>
                        {singleUserInfo?.phone}
                      </p>
                    )}
                  </>
                )}
                {isLoading && !error && <LinearProgress />}

                {!isLoading && error && (
                  <div className="text-center text-danger">
                    <h1>No data Found</h1>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminOrderUserView;
