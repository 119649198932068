import axios from "axios";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { FiSave } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import getCookie from "../../../../Component/GetCookie";

import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const ServiceDesignTypeEdit = () => {
  const { designTypeId } = useParams();
  const serviceName = useRef();
  const subServiceName = useRef();
  const name = useRef();
  const [serviceCategory, setServiceCategory] = useState([]);
  const [subServiceCategory, setSubServiceCategory] = useState([]);

  const fetchServiceCategory = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/get-order-category`)
      .then((res) => {
        setServiceCategory(res.data?.get_category);
      });
  };

  const fetchSubServiceCategory = (e) => {
    setSubServiceCategory([]);
    e.preventDefault();
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/get-sub-services/${serviceName.current.value}`
      )
      .then((res) => {
        setSubServiceCategory(res.data?.get_sub_services);
      });
  };

  // single table data
  const [singleData, setSingleData] = useState([]);

  // Get Single Info
  const renderSingleData = async () => {
    await axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/service-cost/design-type/edit/${designTypeId}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        axios
          .get(
            `${BACKEND_BASE_URL}/api/v2/get-sub-services/${res.data?.edit_design_type_info?.service_id}`
          )
          .then((res) => {
            setSubServiceCategory(res.data?.get_sub_services);
          });
        setSingleData(res.data?.edit_design_type_info);
      });
  };

  // form submit to backend
  const updateData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("name", name.current.value);
    formdata.append("service_id", serviceName.current.value);
    formdata.append("sub_service_id", subServiceName.current.value);
    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/service-cost/design-type/update/${singleData?.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((response) => {
        if (response.data?.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          e.target.reset();
          renderSingleData();
        } else {
          Swal.fire({
            icon: "warning",
            text: response.data.errors.name[0],
            confirmButtonColor: "#5eba86",
          });
        }
      });
    e.preventDefault();
  };

  useEffect(() => {
    fetchServiceCategory();
    renderSingleData();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_end mb-5">
                  <Link to="/admin/service-module/design-type">
                    <Button variant="success" size="sm">
                      View All Design Type &nbsp;
                    </Button>
                  </Link>
                </div>
                <Form onSubmit={updateData}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3">
                          {/* Service Name */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Service Name
                            </Form.Label>
                            <Form.Select
                              aria-label="language example "
                              className=""
                              ref={serviceName}
                              onChange={fetchSubServiceCategory}
                            >
                              {serviceCategory.map((parentCategory) => {
                               
                                return (
                                  <option
                                    key={parentCategory.id}
                                    value={parentCategory.id}
                                    selected={
                                      parentCategory?.id == singleData?.id
                                        ? "selected"
                                        : ""
                                    }
                                  >
                                    {parentCategory.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>

                          {/*Sub Service Name */}

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Sub Service Name
                            </Form.Label>
                            <Form.Select
                              aria-label="language example "
                              className=""
                              ref={subServiceName}
                            >
                              {subServiceCategory.length > 0 &&
                                subServiceCategory?.map((subCategory) => (
                                  <option
                                    key={subCategory.id}
                                    value={subCategory.id}
                                    selected={
                                      subCategory?.id ==
                                      singleData?.sub_service_id
                                        ? "selected"
                                        : ""
                                    }
                                  >
                                    {subCategory.name}
                                  </option>
                                ))}
                            </Form.Select>
                          </Form.Group>

                          {/* add News title */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Design Type
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Design Type"
                              ref={name}
                              defaultValue={singleData?.name}
                            />
                          </Form.Group>

                          <Button
                            type="submit"
                            className="btn-submit mt-5 rounded-3 border-0"
                          >
                            <FiSave /> &nbsp; Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDesignTypeEdit;
