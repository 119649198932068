import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Parse from "html-react-parser";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import getCookie from "../../../../Component/GetCookie";
import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const AdminMainServiceView = () => {
  const { serviceId } = useParams();

  // single table data
  const [singleData, setSingleData] = useState([]);

  // Get Single Info
  const renderSingleData = async () => {
    await axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/main-services/show/${serviceId}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        setSingleData(res.data?.single_main_service);

        axios
          .get(
            `${BACKEND_BASE_URL}/api/v2/get-sub-services/${res.data?.edit_design_type_info?.service_id}`
          )
          .then((res) => {});
      });
  };

  useEffect(() => {
    renderSingleData();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Add Service</h3>
                  <Link to="/admin/final-service-module/main-services">
                    <Button variant="success" size="sm">
                      All Service &nbsp;
                    </Button>
                  </Link>
                </div>
                <div className="p-4">
                  <h4> {singleData?.name}</h4>
                  <img
                    src={`${BACKEND_BASE_URL}/${singleData?.image}`}
                    alt=""
                  />
                </div>
                <div className="mt-2">
                  {Parse(`${singleData?.description}`)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminMainServiceView;
