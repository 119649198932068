import { LinearProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import Parse from "html-react-parser";
import RichTextEditor from "../../../Component/RichTextEditor";
import { ToastAlert } from "../../../Component/ToastAlert";
import getCookie from "../../../Component/GetCookie";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";

const MasonaryProjects = () => {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const renderAllInfo = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/take-a-peek`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setTableData(res.data?.all_info);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(true);
      });
  };

  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}/api/v2/rianaire/take-a-peek/delete/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          ToastAlert.fire({
            title: "deleted successfully",
          });
          renderAllInfo();
        });
    }
  };

  useEffect(() => {
    renderAllInfo();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Masonary Projects</h3>
                  <Link to="/admin/masonary-latest-projects/add-new">
                    <Button variant="success" size="sm">
                      Add Masonary Projects&nbsp;
                      <span>
                        <AiOutlinePlusCircle className="mb-1" />
                      </span>
                    </Button>
                  </Link>
                </div>
                {!isLoading && !error && tableData.length > 0 && (
                  <div className="table-responsive custom_table">
                    <div className="table_fixed">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Handle</th>
                            <th scope="col">Name </th>
                            <th scope="col">Image </th>
                            <th scope="col">Link </th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData?.map((data, i) => (
                            <tr key={data.id}>
                              <td>
                                <Dropdown style={{ position: "unset" }}>
                                  <Dropdown.Toggle>
                                    <BsThreeDotsVertical color="#000" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {/* edit */}
                                    <Dropdown.Item
                                      as={Link}
                                      to={`/admin/masonary-latest-projects/edit/${data?.id}`}
                                      className="text-black"
                                    >
                                      Edit
                                    </Dropdown.Item>
                                    {/* delete */}

                                    <Dropdown.Item
                                      onClick={() => deleteData(data.id)}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>{data?.name}</td>
                              <td>
                                <img
                                  className="img-thumbnail"
                                  width={80}
                                  height={50}
                                  src={`${BACKEND_BASE_URL}${data.image}`}
                                  alt={data.name}
                                />
                              </td>
                              <td>{data?.page_link}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {isLoading && !error && <LinearProgress />}

                {!isLoading && !error && tableData?.length < 1 && (
                  <>
                    <div className="text-center text-danger">
                      <h1>No data Found</h1>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasonaryProjects;
