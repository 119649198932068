import React, { useContext } from "react";
import { Form, NavDropdown } from "react-bootstrap";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BACKEND_BASE_URL } from "./GlobalVariables";
import axios from "axios";
import Swal from "sweetalert2";
import { BsSearch } from "react-icons/bs";
import { AiOutlineInfoCircle, AiOutlineMenu } from "react-icons/ai";
import { MdNotificationsActive } from "react-icons/md";
import { UserContext } from "../App";
import { RabbitSVG } from "./SVG/RabbitSVG";
import { getLocalStorageWithExpiry } from "./LocalStorageWithExpiry";

const AdminDashboardNavbar = ({ pageTitle }) => {
  const [show, setShow] = useState(false);
  const { toggleNav, setToggleNav } = useContext(UserContext);
  const navigate = useNavigate();
  const LogoutAdmin = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/logout`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        if (res.data?.status == 200) {
          // cookies.removeAll();
          localStorage.removeItem("LOGGED_IN_USER_PERMISSION_INFO");
          localStorage.removeItem("adminUserInfo");
          localStorage.removeItem("LOGGED_IN_ADMIN_USER_TYPE");
          localStorage.removeItem("ACCESS_TOKEN");
          localStorage.removeItem("LOGGED_IN_ADMIN_ID");
          Swal.fire({
            icon: "success",
            text: res.data.message,
            confirmButtonColor: "#5eba86",
          });

          navigate("/");
        }
      });
  };

  return (
    <div className="topbar">
      <AiOutlineMenu
        className="toggle"
        onClick={() => setToggleNav(!toggleNav)}
      />
      <div className="row gx-0 justify-content-between searchbox ">
        <div className="col-md-2 col-xl-6">
          <h3 className="ms-4">{pageTitle}</h3>
        </div>
        <div className="col-md-10 col-xl-6">
          <div className="row gx-0 flex_center">
            <div className="col-9 col-md-7 col-xl-8">
              <Form className="search_form">
                <Form.Group className="position-relative">
                  <Form.Control
                    type="text"
                    placeholder="Search for projects and items"
                    className="form_control"
                  />
                  <BsSearch size="1.2em" className="search_icon" />
                </Form.Group>
              </Form>
            </div>
            <div className="col-md-5 col-xl-4 flex_around">
              <AiOutlineInfoCircle size="1.5em" />
              <MdNotificationsActive size="1.5em" />

              <NavDropdown
                onClick={() => setShow(!show)}
                className="user_profile_dropdown position-relative"
                id="collasible-nav-dropdown"
                title={
                  <div className={`admin_profile_logo`}>
                    {getLocalStorageWithExpiry("adminUserInfo")?.profile_photo != null ? (
                      <img
                        src={`${BACKEND_BASE_URL}${getLocalStorageWithExpiry("adminUserInfo")
                            ?.profile_photo
                          }`}
                        alt="profile_pic"
                        className="w-100 h-100"
                      />
                    ) : (
                      <RabbitSVG />
                    )}
                  </div>
                }
              >
                <NavDropdown.Item as={Link} to="#" className=" border-bottom">
                  <div className="flex_start">
                    <div className="mb-3">
                      <h4 className="mb-0 cl-333">
                        {getLocalStorageWithExpiry("adminUserInfo")?.name}
                      </h4>
                      <p className="mb-0">
                        {getLocalStorageWithExpiry("adminUserInfo")?.email}
                      </p>
                    </div>
                  </div>
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={Link}
                  to="/admin/profile"
                  className=" t-fs-18 cl-333"
                >
                  Profile
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="#" className=" t-fs-18 cl-333">
                  Personal Settings
                </NavDropdown.Item>
                {/* <NavDropdown.Item as={Link} to="#" className=" t-fs-18 cl-333">
                  Admin Control
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="#" className=" t-fs-18 cl-333">
                  Invoices
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="#" className=" t-fs-18 cl-333">
                  Payment History
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="#"
                  className=" t-fs-18 cl-333 mb-1 border-bottom"
                >
                  Payment History
                </NavDropdown.Item> */}
                <NavDropdown.Item
                  as={Link}
                  onClick={LogoutAdmin}
                  to="/admin"
                  className="t-fs-18 cl-333"
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardNavbar;
