import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import {
  AiOutlineCaretDown,
  AiOutlineClose,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiEdit2, FiSave } from "react-icons/fi";
import { Link } from "react-router-dom";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import getCookie from "../../../../Component/GetCookie";

import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import { ToastAlert } from "../../../../Component/ToastAlert";
import LinearProgressMui from "../../../../Component/Custom Loader/LinearProgress";
import { MdAdd } from "react-icons/md";
import Swal from "sweetalert2";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const DynamicModuleAll = () => {
  const [additionalServices, setAdditionalServices] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchDynamicModuleServices = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/dynamic-module-cost`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setAdditionalServices(res.data?.dynamic_modules);
      });
  };

  const handleDeleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}/api/v2/rianaire/dynamic-module-cost/delete/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data.message,
            confirmButtonColor: "#5eba86",
          });
          fetchDynamicModuleServices();
        });
    }
  };

  useEffect(() => {
    fetchDynamicModuleServices();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar pageTitle="Dynamic Module" />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header"></div>
                <div className="custom_table">
                  <div className="table_fixed">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            <Dropdown style={{ position: "unset" }}>
                              <Dropdown.Toggle>
                                <span
                                  style={{
                                    backgroundColor: "#eeeeee",
                                    padding: "8px 12px",
                                    borderRadius: "8px",
                                  }}
                                >
                                  <MdAdd color="#000" className="me-2" />
                                  <AiOutlineCaretDown color="#000" />
                                </span>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {/* {JSON.parse(
                                        localStorage.getItem("LOGGED_IN_USER_PERMISSION_INFO")
                                      )?.map(
                                        (data, index) =>
                                          data?.permission_name === "create-service-cost" && ( */}
                                <Dropdown.Item
                                  as={Link}
                                  to={`/admin/service-module/dynamic-module/add-new`}
                                >
                                  Add Dynamic Module
                                </Dropdown.Item>
                                {/* )
                                        )} */}
                              </Dropdown.Menu>
                            </Dropdown>
                          </th>
                          {/* <th>Main Service</th>
                          <th>Sub Service</th> */}
                          <th>Additional</th>
                          <th>USD Price</th>
                          <th>BDT Price</th>
                          <th>Duration</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <tr>
                            <td colspan="7">
                              <LinearProgressMui />
                            </td>
                          </tr>
                        )}
                        {!isLoading &&
                          !error &&
                          additionalServices?.length > 0 && (
                            <>
                              {additionalServices.map((data, index) => (
                                <>
                                  <tr key={index}>
                                    <td>
                                      <Dropdown style={{ position: "unset" }}>
                                        <Dropdown.Toggle>
                                          <span
                                            style={{
                                              backgroundColor: "#eeeeee",
                                              padding: "8px 12px",
                                              borderRadius: "8px",
                                            }}
                                          >
                                            <FiEdit2
                                              color="#000"
                                              className="me-2"
                                            />
                                            <AiOutlineCaretDown color="#000" />
                                          </span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          {/* edit */}
                                          <Dropdown.Item
                                            as={Link}
                                            to={`/admin/service-module/dynamic-module/edit/${data?.id}`}
                                          >
                                            Edit
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() => handleDeleteData(data.id)}
                                          >
                                            Delete
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>

                                    {/* <td>{data?.main_service?.name}</td>
                                    <td>{data?.sub_service?.name}</td> */}
                                    <td>{data?.name}</td>
                                    <td>$ {data?.usd_price}</td>
                                    <td>Tk {data?.bdt_price}</td>
                                    <td>{data?.duration}</td>
                                  </tr>
                                </>
                              ))}
                            </>
                          )}
                        {!isLoading &&
                          !error &&
                          additionalServices?.length < 1 && (
                            <>
                              <tr className="text-center text-danger">
                                <td colspan="7">No data Found</td>
                              </tr>
                            </>
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DynamicModuleAll