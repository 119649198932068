import { LinearProgress } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import { FaEye } from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import getCookie from "../../../Component/GetCookie";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";

const RegisteredUserView = () => {
  const { userId } = useParams();

  // single table data
  const [singleData, setSingleData] = useState([]);

  // Get Single Info
  const renderSingleData = async () => {
    await axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/registered-user-management/show/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        setSingleData(res.data?.single_user);
      });
  };

  useEffect(() => {
    renderSingleData();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Registered User</h3>
                  <Link to="/admin/registered-user">
                    <Button variant="success" size="sm">
                      All User &nbsp;
                      <span>
                        <AiOutlinePlusCircle className="mb-1" />
                      </span>
                    </Button>
                  </Link>
                </div>
                {singleData?.name ? (
                  <div className="mt-5 ms-3">
                    <h4>
                      <strong>Name :</strong> {singleData?.name}
                    </h4>
                    <h6>
                      <strong>Email :</strong> {singleData?.email}
                    </h6>
                    <h6>
                      <strong>Phone :</strong> {singleData?.phone}
                    </h6>
                  </div>
                ) : (
                  <LinearProgress />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisteredUserView;
