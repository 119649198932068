import React from "react";
import { useLayoutEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import {IoChatboxOutline} from "react-icons/io5"
import "./Chatbox.css"

const Chatbox = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);

    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, [pathname]);

  const chatboxPopup = () => {

  };

  return (
    <div className="top-to-btm">
     
      {/* {showTopBtn && (
        <div className="chatbox_wrapper">
            <IoChatboxOutline
              className="chatbox-icon-position chatbox-icon-style"
              onClick={chatboxPopup}
            />
        </div>
      )} */}
    </div>
  );
};

export default Chatbox;
