import axios from "axios";
import React, { useEffect,  useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import AdminDashboardNavbar from "../../Component/AdminDashboardNavbar";
import getCookie from "../../Component/GetCookie";
import { BACKEND_BASE_URL } from "../../Component/GlobalVariables";
import { ToastAlert } from "../../Component/ToastAlert";
import { getLocalStorageWithExpiry } from "../../Component/LocalStorageWithExpiry";

const AdminRolePermission = () => {
  const { roleId } = useParams();
  var tt;

  const { state } = useLocation();
  const { permission } = state;

  // View single value
  const [singleRoleId, setSingleRoleId] = useState([]);
  const [getPermissionData, setGetPermissionData] = useState([]);
  const [singlePermissionChecked, setSinglePermissionChecked] = useState([]);

  // View single Data
  const showPermissionModal = () => {
    axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/roles/show/${roleId}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setSingleRoleId(res.data?.single_role?.id);
        setGetPermissionData(res.data?.available_permissions);
      });
  };

  const handlePermission = (e) => {
    if (e.target.checked) {
      setSinglePermissionChecked([...singlePermissionChecked, e.target.value]);
    } else {
      setSinglePermissionChecked(
        singlePermissionChecked.filter((id) => id !== e.target.value)
      );
    }
  };

  // Update permission
  const updatePermission = (e) => {
    const formdata = new FormData();
    formdata.append("role_id", singleRoleId);
    singlePermissionChecked.forEach((item) => {
      formdata.append("permission[]", item);
    });

    axios
      .post(`${BACKEND_BASE_URL}/api/v2/rianaire/set-permission`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })

      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            title: response.data?.message,
          });
        } else {
          ToastAlert.fire({
            icon: "error",
            text: response.data?.errors?.permission,
            confirmButtonColor: "#5eba86",
          });
        }
      });
    e.preventDefault();
  };

  console.log("singlePermissionChecked", singlePermissionChecked);
  console.log("type", typeof singlePermissionChecked);

  useEffect(() => {
    let a = [];
    getPermissionData.forEach((item) => {
      a.push(item.permission_id);
    });

    setSinglePermissionChecked(a);
  }, [getPermissionData]);

  useEffect(() => {
    showPermissionModal();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />
        <div className="col-md-12 p-4">
          <form onSubmit={updatePermission}>
            <div className="card">
              <div className="card-body">
                <div className="col-lg-12">
                  <div className="card-header flex_between">
                    <h3>Roles</h3>

                    <Link to="/admin/role-management">
                      <Button variant="success" size="sm">
                        All Roles &nbsp;
                      </Button>
                    </Link>
                  </div>
                  <div className="position-relative">
                    <div className="content-wrapper table_fixed">
                      <div className="row py-3 ">
                        {/* content Name */}

                        {permission.map((data, index) => (
                          <div className="border my-4" key={index}>
                            <Form.Group
                              className="mb-3 border-bottom"
                              as={Col}
                              sm="12"
                            >
                              <label>{data?.name}</label>
                            </Form.Group>

                            <div className="row">
                              {data.permissions?.map((pData, i) => (
                                <Form.Group
                                  key={i}
                                  className="mb-3"
                                  as={Col}
                                  sm="4"
                                >
                                  {getPermissionData.map((d, index) => {
                                    if (pData?.id == d?.permission_id) {
                                      tt = d?.permission_id;
                                    }
                                    return (
                                      pData?.id == d?.permission_id && (
                                        <Form.Check
                                          onChange={handlePermission}
                                          type="checkbox"
                                          label={pData?.name}
                                          value={pData?.id}
                                          defaultChecked="checked"
                                        />
                                      )
                                    );
                                  })}

                                  {tt != pData.id && (
                                    <Form.Check
                                      onChange={handlePermission}
                                      type="checkbox"
                                      label={pData?.name}
                                      value={pData?.id}
                                    />
                                  )}
                                </Form.Group>
                              ))}
                            </div>
                          </div>
                        ))}

                        {/* Submit button */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="rounded-3 border-0 my-4"
                style={{
                  padding: "12px 18px",
                  backgroundColor: "#f4f2ff",
                  color: "#4936b6",
                  fontWeight: 700,
                  margin: "0 auto",
                  maxWidth: "fit-content",
                }}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminRolePermission;
