import axios from "axios";
import React, { useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FiSave } from "react-icons/fi";
import { Link } from "react-router-dom";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import getCookie from "../../../../Component/GetCookie";

import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import RichTextEditor from "../../../../Component/RichTextEditor";
import { ToastAlert } from "../../../../Component/ToastAlert";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const AdminMainServiceAdd = () => {
  const serviceName = useRef();
  const serviceImage = useRef();
  const serviceDescription = useRef();


  // Image Preview
  const [files, setFile] = useState([]);

  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  const [value, setValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };

  // Form submit to backend
  const store = (e) => {
    const formdata = new FormData();
    formdata.append("name", serviceName.current.value);
    formdata.append("image", serviceImage.current.files[0]);
    formdata.append("description", serviceDescription.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/main-services/store`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((response) => {
        setFile([]);
        if (response.data.status === 200) {
          ToastAlert.fire({
            title: "added successfully",
          });
          e.target.reset();
          setValue("", "html");
        }
      });
    e.preventDefault();
  };

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Add Service</h3>
                  <Link to="/admin/final-service-module/main-services">
                    <Button variant="success" size="sm">
                      All Service &nbsp;
                    </Button>
                  </Link>
                </div>
                <Row>
                  <form onSubmit={store}>
                    <div className="content-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <div className="row py-3">
                            {/* Name */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Name <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Name"
                                ref={serviceName}
                              />
                              <Form.Control.Feedback type="invalid">
                                name is required
                              </Form.Control.Feedback>
                            </Form.Group>

                            {/* content Image */}
                            <div className="col-md-6">
                              <div className="form-group">
                                <div>
                                  <label className="label fw-bold">
                                    Image
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="image-file">
                                  <input
                                    required
                                    type="file"
                                    className="form-control"
                                    onChange={handleImgPreview}
                                    ref={serviceImage}
                                  />
                                  {files.map((file, key) => {
                                    return (
                                      <div key={key} className="Row">
                                        <span className="Filename">
                                          <img
                                            className="img-thumbnail"
                                            width={80}
                                            height={70}
                                            src={URL.createObjectURL(file)}
                                            alt={file.name}
                                          />
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>

                            {/* Service Description */}
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom02"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Description
                              </Form.Label>
                              <RichTextEditor
                                joditRef={serviceDescription}
                                getValue={getValue}
                                value={value}
                              />
                            </Form.Group>

                            {/* Submit button */}
                            <button
                              type="submit"
                              className="btn-submit mt-5 rounded-3 border-0"
                            >
                              <FiSave /> &nbsp; Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminMainServiceAdd;
