import axios from "axios";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import getCookie from "../../../../Component/GetCookie";
import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import RichTextEditor from "../../../../Component/RichTextEditor";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const AdminFaqEdit = () => {
  const { faqId } = useParams();
  const FaqQuestion = useRef();
  const FaqAnswer = useRef();
  const FaqCategory = useRef();

  const [allFaqCategory, setAllFaqCategory] = useState([]);
  const [value, setValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };

  const renderAllFaqCategory = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/faq-category`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setAllFaqCategory(res.data?.all_info);
      });
  };
  // single table data
  const [singleData, setSingleData] = useState([]);

  // Get Single Info
  const renderSingleData = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/faq/edit/${faqId}`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setSingleData(res.data?.edit_info);
      });
  };

  // form submit to backend
  const updateData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("title", FaqQuestion.current.value);
    formdata.append("description", FaqAnswer.current.value);
    formdata.append("category_id", FaqCategory.current.value);
    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/faq/update/${singleData?.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((response) => {
        if (response.data?.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          e.target.reset();
          renderSingleData();
        } else {
          Swal.fire({
            icon: "warning",
            text: "404",
            confirmButtonColor: "#5eba86",
          });
        }
      });
    e.preventDefault();
  };

  useEffect(() => {
    renderSingleData();
    renderAllFaqCategory();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar pageTitle={"FAQ Edit"} />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_end mb-4">
                  <Link to="/admin/faq">
                    <Button variant="success" size="sm">
                      View All Faqs &nbsp;
                    </Button>
                  </Link>
                </div>
                <Form onSubmit={updateData}>
                  <div className="content-wrapper">

                    <div className="row py-3">
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationCustom01"
                        className="mb-3"
                      >
                        <Form.Label className="label fw-bold">
                          Faq question
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Category Name"
                          ref={FaqQuestion}
                          defaultValue={singleData?.title}
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationCustom02"
                        className="mb-3"
                      >
                        <Form.Label className="label fw-bold">
                          Faq Category
                        </Form.Label>
                        <select
                          className="form-select  w_45 mb-2"
                          aria-label=".form-select-lg example"
                          ref={FaqCategory}
                        >
                          {allFaqCategory?.map((data, index) => (
                            <option
                              key={index}
                              value={data?.id}
                              selected={
                                data?.id == singleData?.id ? "selected" : ""
                              }
                            >
                              {data?.name}
                            </option>
                          ))}
                        </select>
                      </Form.Group>

                      <RichTextEditor
                        joditRef={FaqAnswer}
                        getValue={getValue}
                        value={singleData?.description}
                      />


                    </div>

                  </div>
                  <div className="text-center">
                    <Button
                      type="submit"
                      className="btn-submit mt-4 rounded-3 border-0 "
                    >
                      update
                    </Button>
                  </div>

                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminFaqEdit;
