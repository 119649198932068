import { InputLabel, LinearProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../Component/GlobalVariables";
import Swal from "sweetalert2";
import Parse from "html-react-parser";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import AdminDashboardNavbar from "../../Component/AdminDashboardNavbar";
import RichTextEditor from "../../Component/RichTextEditor";
import { AiOutlineClose, AiOutlinePlusCircle } from "react-icons/ai";
import { FaCheck, FaEye } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever, MdSystemUpdateAlt } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import { FiSave } from "react-icons/fi";
import getCookie from "../../Component/GetCookie";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getLocalStorageWithExpiry } from "../../Component/LocalStorageWithExpiry";

const ClientReviews = () => {
  const clientName = useRef();
  const clientRating = useRef();
  const clientReview = useRef();
  const clientDesignation = useRef();

  const [tableData, setTableData] = useState([]);
  const [value, setValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };

  // Modal Section Data
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("lg");

  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/clients-review`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        // if(res.data?.status == 401){
        //   Swal.fire({
        //     icon: "success",
        //     text: res.data.message,
        //     confirmButtonColor: "#5eba86",
        //   });
        // }
        setTableData(res.data?.client_reviews);
      });
  };

  // Add new
  const addNewData = (modalValue) => {
    setModalSize("lg");
    setModalData(modalValue);
    setModalShow(true);
  };

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();
    formdata.append("client_name", clientName.current.value);
    formdata.append("rating", clientRating.current.value);
    formdata.append("review", clientReview.current.value);
    formdata.append("client_designation", clientDesignation.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/clients-review/store`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllInfo();
          e.target.reset();
          setValue("", "html");
          setModalShow(false);
        }
      });
    e.preventDefault();
  };

  // Updated data to backend
  const updatedClientInfo = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("client_name", clientName.current.value);
    formdata.append("rating", clientRating.current.value);
    formdata.append("review", clientReview.current.value);
    formdata.append("client_designation", clientDesignation.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/clients-review/update/${singleClientReview.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        Swal.fire({
          icon: "success",
          text: response.data?.message,
          confirmButtonColor: "#5eba86",
        });
        setModalShow(false);

        renderAllInfo();
      });

    e.preventDefault();
  };

  // View single value
  const [singleClientReview, setSingleClientReview] = useState([]);

  // View single Data
  const showSingleData = (modalValue, data) => {
    setModalSize("lg");
    setModalData(modalValue);
    setSingleClientReview(data);
    setModalShow(true);
  };

  // Edit data
  const fetchDataForEdit = (modalValue, data) => {
    setModalSize("lg");
    setModalData(modalValue);
    setSingleClientReview(data);
    setModalShow(true);
  };

  // Delete Data
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}/api/v2/rianaire/clients-review/delete/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllInfo();
        });
    }
  };

  useEffect(() => {
    renderAllInfo();
  }, []);

  const StatusUpdate = (id) => {
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/clients-review/status-update/${id}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        renderAllInfo();
      });
  };

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Clients Review</h3>
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => addNewData("Add")}
                  >
                    Add &nbsp;
                    <span>
                      <AiOutlinePlusCircle className="mb-1" />
                    </span>
                  </Button>
                </div>

                {tableData.length > 0 ? (
                  <div className="table-responsive custom_table">
                    <div className="table_fixed">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Handle</th>
                            <th scope="col">Client Name</th>
                            <th scope="col">Client Rating</th>
                            <th scope="col">Is Home</th>
                          </tr>
                        </thead>

                        <tbody>
                          {tableData?.map((data, index) => (
                            <tr key={index}>
                              <td>
                                <Dropdown style={{ position: "unset" }}>
                                  <Dropdown.Toggle>
                                    <BsThreeDotsVertical color="#000" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {/* view */}
                                    <Dropdown.Item
                                      onClick={() => {
                                        showSingleData("View", data);
                                      }}
                                    >
                                      View
                                    </Dropdown.Item>
                                    {/* edit */}
                                    <Dropdown.Item
                                      onClick={() =>
                                        fetchDataForEdit("Edit", data)
                                      }
                                    >
                                      Edit
                                    </Dropdown.Item>
                                    {/* delete */}

                                    <Dropdown.Item
                                      onClick={() => deleteData(data.id)}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>{data?.client_name}</td>
                              <td>{data?.rating}</td>

                              <td>
                                {data.is_home == 1 ? (
                                  <Link
                                    to=""
                                    onClick={() => StatusUpdate(data.id)}
                                  >
                                    <FaCheck
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",

                                        padding: "2px 4px",
                                        borderRadius: "4px",
                                      }}
                                      size="1.6em"
                                    />
                                  </Link>
                                ) : (
                                  <Link
                                    to=""
                                    onClick={() => StatusUpdate(data.id)}
                                  >
                                    <AiOutlineClose
                                      style={{
                                        color: "white",
                                        backgroundColor: "red",

                                        padding: "2px 4px",
                                        borderRadius: "4px",
                                      }}
                                      size="1.6em"
                                    />
                                  </Link>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <LinearProgress />
                )}
              </div>
            </div>
            <Modal
              show={modalShow}
              size={modalSize}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => setModalShow(false)}
            >
              <Modal.Header>
                <Modal.Title className="" id="contained-modal-title-vcenter">
                  {modalData}
                </Modal.Title>
                <Button
                  className="p-0 border-0 bg-danger"
                  onClick={() => setModalShow(false)}
                >
                  <CgClose
                    style={{
                      fontSize: "26px",
                    }}
                  />
                </Button>
              </Modal.Header>
              <Modal.Body>
                {/* Add Modal section */}
                {modalData === "Add" && (
                  <div className="">
                    <form onSubmit={storeData}>
                      <div className="content-wrapper">
                        <div className="card">
                          <div className="card-body">
                            <div className="row py-3">
                              {/* client Name */}
                              <div className="col-md-12">
                                <Form.Group className="mb-3">
                                  <Form.Label className="label fw-bold">
                                    Client Name
                                    <span className="text-danger">*</span>
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    placeholder="Client Name"
                                    required
                                    ref={clientName}
                                  />
                                </Form.Group>
                              </div>

                              {/* client Rating */}
                              <div className="col-md-6">
                                <Form.Group className="mb-3">
                                  <Form.Label className="label fw-bold">
                                    Rating
                                    <span className="text-danger">*</span>
                                  </Form.Label>

                                  <Form.Control
                                    type="number"
                                    min={1}
                                    max={5}
                                    className="form-control"
                                    placeholder="Rating"
                                    required
                                    ref={clientRating}
                                  />
                                </Form.Group>
                              </div>

                              {/* client designation */}
                              <div className="col-md-6">
                                <div className="form-group mb-3">
                                  <div>
                                    <Form.Label className="label fw-bold">
                                      Client Designation
                                    </Form.Label>
                                  </div>

                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder=" Client Designation"
                                    required
                                    ref={clientDesignation}
                                  />
                                </div>
                              </div>

                              {/* client review */}
                              <div className="col-md-12">
                                <div className="form-group mb-3">
                                  <div>
                                    <Form.Label
                                      required
                                      className="label fw-bold"
                                    >
                                      Review
                                      <span className="text-danger">*</span>
                                    </Form.Label>
                                  </div>

                                  <RichTextEditor
                                    joditRef={clientReview}
                                    getValue={getValue}
                                    value={value}
                                  />
                                </div>
                              </div>

                              {/* Submit button */}
                              <button
                                type="submit"
                                className="btn-submit mt-5 rounded-3 border-0"
                              >
                                <FiSave /> &nbsp; Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                )}

                {/* Add Modal section */}
                {modalData === "Edit" && (
                  <div className="">
                    <form onSubmit={updatedClientInfo}>
                      <div className="content-wrapper">
                        <div className="card">
                          <div className="card-body">
                            <div className="row py-3">
                              {/* client Name */}
                              <div className="col-md-12">
                                <Form.Group className="mb-3">
                                  <Form.Label className="label fw-bold">
                                    Client Name
                                    <span className="text-danger">*</span>
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    placeholder="Client Name"
                                    required
                                    ref={clientName}
                                    defaultValue={
                                      singleClientReview?.client_name
                                    }
                                  />
                                </Form.Group>
                              </div>

                              {/* client Rating */}
                              <div className="col-md-6">
                                <Form.Group className="mb-3">
                                  <Form.Label className="label fw-bold">
                                    Rating
                                    <span className="text-danger">*</span>
                                  </Form.Label>

                                  <Form.Control
                                    type="number"
                                    min={1}
                                    max={5}
                                    placeholder="Rating"
                                    required
                                    ref={clientRating}
                                    defaultValue={singleClientReview?.rating}
                                  />
                                </Form.Group>
                              </div>

                              {/* client designation */}
                              <div className="col-md-6">
                                <Form.Group className="mb-3">
                                  <Form.Label className="label fw-bold">
                                    Client Designation
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    placeholder="Client Designation"
                                    required
                                    ref={clientDesignation}
                                    defaultValue={
                                      singleClientReview?.client_designation
                                    }
                                  />
                                </Form.Group>
                              </div>

                              {/* client review */}
                              <div className="col-md-12">
                                <Form.Group className="mb-3">
                                  <Form.Label className="label fw-bold">
                                    Review<span className="text-danger">*</span>
                                  </Form.Label>

                                  <RichTextEditor
                                    joditRef={clientReview}
                                    getValue={getValue}
                                    value={singleClientReview?.review}
                                  />
                                </Form.Group>
                              </div>

                              {/* Submit button */}
                              <button
                                type="submit"
                                className="btn-submit mt-5 rounded-3 border-0 flex_center"
                              >
                                <MdSystemUpdateAlt className="me-2" />
                                &nbsp; Update
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                )}

                {/* View Modal section */}
                {modalData === "View" && (
                  <>
                    <h4>Name: {singleClientReview?.client_name}</h4>
                    <div className="mt-2">
                      {Parse(`${singleClientReview?.review}`)}
                    </div>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setModalShow(false)}
                  className="btn-danger"
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientReviews;
