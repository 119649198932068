import { LinearProgress } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FiSave } from "react-icons/fi";
import { Link } from "react-router-dom";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import getCookie from "../../../Component/GetCookie";

import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import { PassInputFieldFormControl } from "../../../Component/PassInputField";
import { ToastAlert } from "../../../Component/ToastAlert";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";

const EmployeeAdd = () => {
  const employeeName = useRef();
  const employeeEmail = useRef();
  const employeePassword = useRef();
  const employeeConfirmPassword = useRef();
  const employeeRoleId = useRef();

  const [allRoles, setRoles] = useState([]);
  const renderAllRoles = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/roles`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setRoles(res.data?.all_roles);
      });
  };

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();
    formdata.append("name", employeeName.current.value);
    formdata.append("email", employeeEmail.current.value);
    formdata.append("password", employeePassword.current.value);
    formdata.append("confirm_password", employeeConfirmPassword.current.value);
    formdata.append("role_id", employeeRoleId.current.value);

    axios
      .post(`${BACKEND_BASE_URL}/api/v2/rianaire/employee/store`, formdata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })

      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            title: response.data?.message,
          });
          e.target.reset();
        } else {
          ToastAlert.fire({
            icon: "error",
            title: response.data?.errors?.email,
          });
        }
      });
    e.preventDefault();
  };

  useEffect(() => {
    renderAllRoles();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Add Employee</h3>
                  <Link to="/admin/employee">
                    <Button variant="success" size="sm">
                      All Employee  &nbsp;
                      <span>
                        <AiOutlinePlusCircle className="mb-1" />
                      </span>
                    </Button>
                  </Link>
                </div>
                <Row>
                  <form onSubmit={storeData}>
                    <div className="content-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <div className="row py-3">
                            {/* Name */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Name <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Name"
                                ref={employeeName}
                              />
                              <Form.Control.Feedback type="invalid">
                                name is required
                              </Form.Control.Feedback>
                            </Form.Group>

                            {/* Email */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Email <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                required
                                type="email"
                                placeholder="Email"
                                ref={employeeEmail}
                              />
                              <Form.Control.Feedback type="invalid">
                                Email is required
                              </Form.Control.Feedback>
                            </Form.Group>

                            {/* Password */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Password <span className="text-danger">*</span>
                              </Form.Label>

                              <PassInputFieldFormControl
                                placeholderValue="Enter password"
                                inputRef={employeePassword}
                              />

                              <Form.Control.Feedback type="invalid">
                                Password is required
                              </Form.Control.Feedback>
                            </Form.Group>

                            {/* Confirm Password */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Confirm Password{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>

                              <PassInputFieldFormControl
                                placeholderValue="Retype password"
                                inputRef={employeeConfirmPassword}
                              />
                              <Form.Control.Feedback type="invalid">
                                Password is required
                              </Form.Control.Feedback>
                            </Form.Group>

                            {/* Role */}
                            <Form.Group as={Col} md="12" className="mb-3">
                              <Form.Label>
                                Select Role
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                required
                                ref={employeeRoleId}
                              >
                                <option>Select Role</option>
                                {allRoles?.map((data, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={data.id}
                                      selected={
                                        data.id == "10" ? "selected" : ""
                                      }
                                    >
                                      {data.name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Form.Group>

                            {/* Submit button */}
                            <div className="flex_center">
                              <button
                                type="submit"
                                className="btn-submit mt-5 rounded-3 border-0 "
                              >
                                <FiSave /> &nbsp; Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeAdd;
