import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { AiOutlineMail } from 'react-icons/ai'
import { BACKEND_BASE_URL } from '../../../Component/GlobalVariables'
import { getLocalStorageWithExpiry } from '../../../Component/LocalStorageWithExpiry'
import { PassInputFieldFormControl } from '../../../Component/PassInputField'

const AdminWorkspaceSettings = () => {
    const [userInfo, setUserInfo] = useState([]);

    const userCurrentPassword = useRef()
    const userNewPassword = useRef()
    const userConfirmPassword = useRef()

    const fetchData = () => {
        axios
            .get(`${BACKEND_BASE_URL}/api/v2/rianaire/profile`, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
                },
            })

            .then((res) => {
                setUserInfo(res.data?.profile_info);
            });
    };
    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div className="user_dashboard_content p-4 p-lg-5">
            <h1 className="mb-5 ms-3 ms-lg-0">Workspace settings</h1>

            <div className="notification_settings">
                <Form>
                    <Row>
                        <Form.Group className="mb-4">
                            <Form.Label>
                                Current Password: <span className="text-danger">*</span>
                            </Form.Label>
                            <PassInputFieldFormControl
                                inputRef={userCurrentPassword}
                                placeholderValue={"Current Password"}
                            />
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label>
                                New Password: <span className="text-danger">*</span>
                            </Form.Label>
                            <PassInputFieldFormControl
                                inputRef={userNewPassword}
                                placeholderValue={"New Password"}
                            />
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label>
                                Confirm Password: <span className="text-danger">*</span>
                            </Form.Label>
                            <PassInputFieldFormControl
                                inputRef={userConfirmPassword}
                                placeholderValue={"Confirm Password"}
                            />
                        </Form.Group>

                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default AdminWorkspaceSettings