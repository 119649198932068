import axios from "axios";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FiSave } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import { PassInputFieldFormControl } from "../../../Component/PassInputField";
import { ToastAlert } from "../../../Component/ToastAlert";
import { useParams } from "react-router-dom";
import getCookie from "../../../Component/GetCookie";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";
import { useForm } from "react-hook-form";

const EmployeeEdit = () => {
  const { employeeId } = useParams();
  const employeeName = useRef();
  const employeeEmail = useRef();
  const employeePassword = useRef();
  const employeeConfirmPassword = useRef();
  const phone = useRef();
  const employeeRoleId = useRef();

  const { state } = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const allRoles = state?.data;

  // single table data
  const [singleData, setSingleData] = useState();

  const renderSingleData = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/employee/edit/${employeeId}`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setSingleData(res.data?.edit_employee_info);
      });
  };

  const handleRoleChange = async (e, id) => {
    await axios.get(
      `${BACKEND_BASE_URL}/api/v2/rianaire/employee/role-update/${id}/${e.target.value}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      }
    );
  };

  const updateData = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("name", employeeName.current.value);
    formdata.append("email", employeeEmail.current.value);
    if (employeePassword.current.value != "") {
      formdata.append("password", employeePassword.current.value);
    }
    if (employeeConfirmPassword.current.value != "") {
      formdata.append(
        "confirm_password",
        employeeConfirmPassword.current.value
      );
    }
    formdata.append("phone", phone.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/employee/update/${employeeId}`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            title: response.data?.message,
          });
          renderSingleData();
          e.target.reset();
        } else {
        }
      });
  };

  // form submit to backend
  const onSubmit = (data) => {
    console.log(data.emergency_address1);
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("role_id", employeeRoleId.current.value);
    // * General Info
    if (data.firstName) {
      formdata.append("first_name", data.firstName);
    }
    if (data.lastName) {
      formdata.append("last_name", data.lastName);
    }
    if (data.homePhone) {
      formdata.append("home_phone", data.homePhone);
    }
    if (data.cellPhone) {
      formdata.append("cell_phone", data.cellPhone);
    }
    if (data.userAddress) {
      formdata.append("address", data.userAddress);
    }
    if (data.gender) {
      formdata.append("gender", data.gender);
    }
    if (data.DOB) {
      formdata.append("dob", data.DOB);
    }
    if (data.govnId) {
      formdata.append("govt_id", data.govnId);
    }
    if (data.maritalStatus) {
      formdata.append("marital_status", data.maritalStatus);
    }
    if (data.spouseName) {
      formdata.append("spouse_name", data.spouseName);
    }
    if (data.spouseEmployer) {
      formdata.append("spouse_employer", data.spouseEmployer);
    }
    if (data.spouseWorkPhone) {
      formdata.append("spouse_work_phone", data.spouseWorkPhone);
    }
    if (data.country) {
      formdata.append("country", data.country);
    }

    // * Prev Job Info
    if (data.prevCompanyName) {
      formdata.append("prev_job_company_name", data.prevCompanyName);
    }
    if (data.prevJobPhone) {
      formdata.append("prev_job_phone", data.prevJobPhone);
    }
    if (data.prevJobTitle) {
      formdata.append("prev_job_title", data.prevJobTitle);
    }
    if (data.prevJobSupervisor) {
      formdata.append("prev_job_supervisor", data.prevJobSupervisor);
    }
    if (data.prevJobWorkLocation) {
      formdata.append("prev_job_work_location", data.prevJobWorkLocation);
    }
    if (data.prevJobEmail) {
      formdata.append("prev_job_email", data.prevJobEmail);
    }
    if (data.prevJobWorkPhone) {
      formdata.append("prev_job_work_phone", data.prevJobWorkPhone);
    }
    if (data.prevJobCellPhone) {
      formdata.append("prev_job_cell_phone", data.prevJobCellPhone);
    }
    if (data.prevJobStartDate) {
      formdata.append("prev_job_start_date", data.prevJobStartDate);
    }
    if (data.prevJobSalary) {
      formdata.append("prev_job_salary", data.prevJobSalary);
    }
    if (data.prevJobOtherSkills) {
      formdata.append("prev_job_other_skills", data.prevJobOtherSkills);
    }
    if (data.prevJobHobby) {
      formdata.append("prev_job_hobby", data.prevJobHobby);
    }
    if (data.prevJobAdditionalComments) {
      formdata.append(
        "prev_job_additional_comments",
        data.prevJobAdditionalComments
      );
    }

    // * Emergency Contact
    if (data.emergency_name1) {
      formdata.append("emergency_name1", data.emergency_name1);
    }
    if (data.emergency_relationship1) {
      formdata.append("emergency_relationship1", data.emergency_relationship1);
    }
    if (data.emergency_address1) {
      formdata.append("emergency_address1", data.emergency_address1);
    }
    if (data.emergency_phone1) {
      formdata.append("emergency_phone1", data.emergency_phone1);
    }
    if (data.emergency_email1) {
      formdata.append("emergency_email1", data.emergency_email1);
    }
    if (data.emergency_additional_information1) {
      formdata.append(
        "emergency_additional_information1",
        data.emergency_additional_information1
      );
    }

    if (data.emergency_name2) {
      formdata.append("emergency_name2", data.emergency_name2);
    }
    if (data.emergency_relationship2) {
      formdata.append("emergency_relationship2", data.emergency_relationship2);
    }
    if (data.emergency_address2) {
      formdata.append("emergency_address2", data.emergency_address2);
    }
    if (data.emergency_phone2) {
      formdata.append("emergency_phone2", data.emergency_phone2);
    }
    if (data.emergency_email2) {
      formdata.append("emergency_email2", data.emergency_email2);
    }
    if (data.emergency_additional_information2) {
      formdata.append(
        "emergency_additional_information2",
        data.emergency_additional_information2
      );
    }

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/employee/update/${employeeId}`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            title: response.data?.message,
          });

          // data.target.reset();
          renderSingleData();
        }
      });
  };

  useEffect(() => {
    renderSingleData();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Edit Employee</h3>
                  <Link to="/admin/employee">
                    <Button variant="success" size="sm">
                      All Employee &nbsp;
                      <span>
                        <AiOutlinePlusCircle className="mb-1" />
                      </span>
                    </Button>
                  </Link>
                </div>
                <Row>
                  <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="content-wrapper">
                      <div className="row py-3">
                        {/* Password */}
                        {/* <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                          className="mb-3"
                        >
                          <Form.Label className="label fw-bold">
                            Password <span className="text-danger">*</span>
                          </Form.Label>

                          <PassInputFieldFormControl
                            placeholderValue="Enter password"
                            inputRef={employeePassword}
                          />

                          <Form.Control.Feedback type="invalid">
                            Password is required
                          </Form.Control.Feedback>
                        </Form.Group> */}

                        {/* Confirm Password */}
                        {/* <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                          className="mb-3"
                        >
                          <Form.Label className="label fw-bold">
                            Confirm Password{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>

                          <PassInputFieldFormControl
                            placeholderValue="Retype password"
                            inputRef={employeeConfirmPassword}
                          />
                          <Form.Control.Feedback type="invalid">
                            Password is required
                          </Form.Control.Feedback>
                        </Form.Group> */}

                        {/* Role */}
                        <Form.Group as={Col} md="6" className="mb-3">
                          <Form.Label>
                            Select Role
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            required
                            ref={employeeRoleId}
                            onChange={(e) => {
                              e.preventDefault();
                              handleRoleChange(e, singleData.id);
                            }}
                          >
                            <option>Select Role</option>
                            {allRoles?.map((data, index) => {
                              return (
                                <option
                                  key={index}
                                  value={data.id}
                                  selected={
                                    data.id == singleData?.role_id
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  {data.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>

                        <Row>
                          <Col
                            md="12"
                            style={{ backgroundColor: "rgb(246 246 246)" }}
                            className=" mb-4"
                          >
                            <Row>
                              <h2 className="my-4">General Information</h2>
                              <Form.Group as={Col} className="mb-2">
                                <Form.Label className="">
                                  First Name:{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Name"
                                  defaultValue={singleData?.first_name}
                                  // ref={first_name}
                                  {...register("firstName", {})}
                                />
                              </Form.Group>
                              <Form.Group as={Col} className="mb-2">
                                <Form.Label className="">
                                  Last Name:{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Name"
                                  defaultValue={singleData?.last_name}
                                  // ref={last_name}
                                  {...register("lastName", {})}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label>
                                  Address:{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  as="textarea"
                                  type="textarea"
                                  rows={3}
                                  placeholder="Enter your address"
                                  className="mb-2"
                                  defaultValue={singleData?.address}
                                  // ref={userAddress}
                                  {...register("userAddress", {})}
                                />
                              </Form.Group>
                              <Form.Group as={Col} className="mb-2">
                                <Form.Label className="">
                                  Home Phone:{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Home Phone"
                                  defaultValue={
                                    singleData?.employee_information?.home_phone
                                  }
                                  // ref={first_name}
                                  {...register("homePhone", {})}
                                />
                              </Form.Group>
                              <Form.Group as={Col} md="12" className="mb-2">
                                <Form.Label className="">
                                  Cell Phone:{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Name"
                                  defaultValue={singleData?.phone}
                                  // ref={first_name}
                                  {...register("cellPhone", {})}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label className="">Email:</Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="Email"
                                  disabled
                                  defaultValue={singleData?.email}
                                  // ref={userEmail}
                                  {...register("userEmail", {})}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label className="">
                                  Government ID:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Govn. ID"
                                  defaultValue={
                                    singleData?.employee_information?.govt_id
                                  }
                                  // ref={userEmail}
                                  {...register("govnId", {})}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label className="">
                                  Birth Date:
                                </Form.Label>
                                <Form.Control
                                  type="date"
                                  placeholder="Birth Date"
                                  defaultValue={singleData?.dob}
                                  // ref={userEmail}
                                  {...register("DOB", {})}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label className="">
                                  Marital Status:
                                </Form.Label>
                                <Form.Select
                                  type="text"
                                  placeholder="Marital Status"
                                  defaultValue={singleData?.email}
                                  {...register("maritalStatus", {})}
                                >
                                  <option value="single">Single</option>
                                  <option value="married">Married</option>
                                </Form.Select>
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label className="">
                                  Spouse's Name:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Spouse's Name"
                                  defaultValue={
                                    singleData?.employee_information
                                      ?.spouse_name
                                  }
                                  // ref={userEmail}
                                  {...register("spouseName", {})}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label className="">
                                  Spouse's Employer:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Spouse's Employer"
                                  defaultValue={
                                    singleData?.employee_information
                                      ?.spouse_employer
                                  }
                                  {...register("spouseEmployer", {})}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label className="">
                                  Spouse's Work Phone:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Spouse's Work Phone"
                                  defaultValue={
                                    singleData?.employee_information
                                      ?.spouse_work_phone
                                  }
                                  // ref={userEmail}
                                  {...register("spouseWorkPhone", {})}
                                />
                              </Form.Group>
                              {/* <Form.Group as={Col} md="12" className="mb-4">
                      <Form.Label className="">
                        First Name: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        defaultValue={singleData?.name}
                        ref={userName}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="mb-4">
                      <Form.Label className="">
                        Last Name: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        defaultValue={singleData?.name}
                        ref={userName}
                      />
                    </Form.Group> 

                    <Form.Group className="mb-4">
                      <Form.Label>
                        Current Password: <span className="text-danger">*</span>
                      </Form.Label>
                      <PassInputFieldFormControl
                        inputRef={userCurrentPassword}
                        placeholderValue={"Current Password"}
                      />
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Label>
                        New Password: <span className="text-danger">*</span>
                      </Form.Label>
                      <PassInputFieldFormControl
                        inputRef={userNewPassword}
                        placeholderValue={"New Password"}
                      />
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Label>
                        Confirm Password: <span className="text-danger">*</span>
                      </Form.Label>
                      <PassInputFieldFormControl
                        inputRef={userConfirmPassword}
                        placeholderValue={"Confirm Password"}
                      />
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Label>
                        Phone Number: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        placeholder="Phone"
                        aria-label="Phone"
                        defaultValue={singleData?.phone}
                        ref={userPhone}
                      />
                    </Form.Group>*/}

                              <Form.Group className="mb-4">
                                <Form.Label className=" mb-2">
                                  Country or region
                                </Form.Label>
                                <Form.Select
                                  className=""
                                  type="text"
                                  {...register("country", {})}
                                >
                                  <option selected value="Bangladesh">
                                    Bangladesh
                                  </option>
                                  <option value="United States">
                                    United States
                                  </option>
                                </Form.Select>
                              </Form.Group>
                            </Row>
                          </Col>
                          <Col
                            md="12"
                            style={{ backgroundColor: "#f8f9fa" }}
                            className=" mb-4"
                          >
                            <Row>
                              <h2 className="my-4">Previous Job Information</h2>
                              <Form.Group as={Col} className="mb-2">
                                <Form.Label className="">
                                  Company Name:{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Name"
                                  defaultValue={
                                    singleData?.emp_previous_job_info
                                      ?.company_name
                                  }
                                  // ref={first_name}
                                  {...register("prevCompanyName", {})}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label>
                                  Phone Number:{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Phone Number"
                                  className="mb-2"
                                  defaultValue={
                                    singleData?.emp_previous_job_info?.phone
                                  }
                                  // ref={userAddress}
                                  {...register("prevJobPhone", {})}
                                />
                              </Form.Group>
                              <Form.Group as={Col} className="mb-2">
                                <Form.Label className="">
                                  Title: <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Title"
                                  defaultValue={singleData?.name}
                                  // ref={first_name}
                                  {...register("prevJobTitle", {})}
                                />
                              </Form.Group>
                              <Form.Group as={Col} md="12" className="mb-2">
                                <Form.Label className="">
                                  Supervisor:{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Name"
                                  defaultValue={
                                    singleData?.emp_previous_job_info
                                      ?.supervisor
                                  }
                                  // ref={first_name}
                                  {...register("prevJobSupervisor", {})}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label className="">
                                  Work Location:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Work Location"
                                  defaultValue={
                                    singleData?.emp_previous_job_info
                                      ?.work_location
                                  }
                                  // ref={userEmail}
                                  {...register("prevJobWorkLocation", {})}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label className="">
                                  Email Address:
                                </Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="Email Address"
                                  defaultValue={
                                    singleData?.emp_previous_job_info?.email
                                  }
                                  // ref={userEmail}
                                  {...register("prevJobEmail", {})}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label className="">
                                  Work Phone:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Work Phone"
                                  defaultValue={
                                    singleData?.emp_previous_job_info
                                      ?.work_phone
                                  }
                                  // ref={userEmail}
                                  {...register("prevJobWorkPhone", {})}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label className="">
                                  Cell Phone:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Cell Phone"
                                  defaultValue={
                                    singleData?.emp_previous_job_info
                                      ?.cell_phone
                                  }
                                  // ref={userEmail}
                                  {...register("prevJobCellPhone", {})}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label className="">
                                  Start Date:
                                </Form.Label>
                                <Form.Control
                                  type="date"
                                  placeholder="Start Date"
                                  defaultValue={
                                    singleData?.emp_previous_job_info
                                      ?.start_date
                                  }
                                  // ref={userEmail}
                                  {...register("prevJobStartDate", {})}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label className="">
                                  Salary Taka:
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  min={0}
                                  placeholder="Salary Taka"
                                  defaultValue={
                                    singleData?.emp_previous_job_info?.salary
                                  }
                                  {...register("prevJobSalary", {})}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label className="">
                                  Other Skills:
                                </Form.Label>
                                <Form.Control
                                  as="textarea"
                                  type="textarea"
                                  rows={5}
                                  placeholder="Other Skills"
                                  defaultValue={
                                    singleData?.emp_previous_job_info
                                      ?.other_skills
                                  }
                                  // ref={userEmail}
                                  {...register("prevJobOtherSkills", {})}
                                />
                              </Form.Group>

                              <Form.Group className="mb-4">
                                <Form.Label>
                                  Hobby: <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  placeholder="Phone"
                                  aria-label="Phone"
                                  defaultValue={
                                    singleData?.emp_previous_job_info?.hobby
                                  }
                                  // ref={userPhone}
                                  {...register("prevJobHobby", {})}
                                />
                              </Form.Group>

                              <Form.Group className="mb-4">
                                <Form.Label className=" mb-2">
                                  Additional Comments
                                </Form.Label>
                                <Form.Control
                                  className=""
                                  type="text"
                                  placeholder="Additional Comments"
                                  defaultValue={
                                    singleData?.emp_previous_job_info
                                      ?.additional_comments
                                  }
                                  {...register("prevJobAdditionalComments", {})}
                                />
                              </Form.Group>
                            </Row>
                          </Col>
                          <Col
                            md="12"
                            style={{ backgroundColor: "#f8f9fa" }}
                            className=" "
                          >
                            <Row>
                              <h2 className="my-4">Emergency Contact - 1</h2>
                              <Form.Group as={Col} className="mb-2">
                                <Form.Label className="">
                                  Name: <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Name"
                                  defaultValue={
                                    singleData?.emp_emergency_contacts?.name1
                                  }
                                  {...register("emergency_name1", {})}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label>
                                  Relationship:{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter your address"
                                  className="mb-2"
                                  defaultValue={
                                    singleData?.emp_emergency_contacts
                                      ?.relationship1
                                  }
                                  {...register("emergency_relationship1", {})}
                                />
                              </Form.Group>
                              <Form.Group as={Col} className="mb-2">
                                <Form.Label className="">
                                  Address:{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  as="textarea"
                                  type="textarea"
                                  rows={3}
                                  placeholder="Name"
                                  defaultValue={
                                    singleData?.emp_emergency_contacts?.address1
                                  }
                                  {...register("emergency_address1", {})}
                                />
                              </Form.Group>
                              <Form.Group as={Col} md="12" className="mb-2">
                                <Form.Label className="">
                                  Phone: <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Name"
                                  defaultValue={
                                    singleData?.emp_emergency_contacts?.phone1
                                  }
                                  {...register("emergency_phone1", {})}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label className="">Email:</Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="Email"
                                  defaultValue={
                                    singleData?.emp_emergency_contacts?.email1
                                  }
                                  {...register("emergency_email1", {})}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label className="">
                                  Additional Information:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Additional Information"
                                  defaultValue={
                                    singleData?.emp_emergency_contacts
                                      ?.additional_information1
                                  }
                                  {...register(
                                    "emergency_additional_information1",
                                    {}
                                  )}
                                />
                              </Form.Group>
                            </Row>
                            <Row>
                              <h2 className="my-4">Emergency Contact - 2</h2>
                              <Form.Group as={Col} className="mb-2">
                                <Form.Label className="">
                                  Name: <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Name"
                                  defaultValue={
                                    singleData?.emp_emergency_contacts?.name2
                                  }
                                  {...register("emergency_name2", {})}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label>
                                  Relationship:{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter your address"
                                  className="mb-2"
                                  defaultValue={
                                    singleData?.emp_emergency_contacts
                                      ?.relationship2
                                  }
                                  {...register("emergency_relationship2", {})}
                                />
                              </Form.Group>
                              <Form.Group as={Col} className="mb-2">
                                <Form.Label className="">
                                  Address:{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  as="textarea"
                                  type="textarea"
                                  rows={3}
                                  placeholder="Name"
                                  defaultValue={
                                    singleData?.emp_emergency_contacts?.address2
                                  }
                                  {...register("emergency_address2", {})}
                                />
                              </Form.Group>
                              <Form.Group as={Col} md="12" className="mb-2">
                                <Form.Label className="">
                                  Phone: <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Name"
                                  defaultValue={
                                    singleData?.emp_emergency_contacts?.phone2
                                  }
                                  {...register("emergency_phone2", {})}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label className="">Email:</Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="Email"
                                  defaultValue={
                                    singleData?.emp_emergency_contacts?.email2
                                  }
                                  {...register("emergency_email2", {})}
                                />
                              </Form.Group>
                              <Form.Group className="mb-2">
                                <Form.Label className="">
                                  Additional Information:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Additional Information"
                                  defaultValue={
                                    singleData?.emp_emergency_contacts
                                      ?.additional_information2
                                  }
                                  {...register(
                                    "emergency_additional_information2",
                                    {}
                                  )}
                                />
                              </Form.Group>

                              {/* Submit button */}
                              <div className="flex_center">
                                <button
                                  type="submit"
                                  className="btn-submit my-4 rounded-3 border-0 "
                                >
                                  <FiSave /> &nbsp; Save
                                </button>
                              </div>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </form>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeEdit;
