import { LinearProgress, TablePagination } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import Parse from "html-react-parser";
import Swal from "sweetalert2";
import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import { ToastAlert } from "../../../../Component/ToastAlert";
import RichTextEditor from "../../../../Component/RichTextEditor";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { CgClose } from "react-icons/cg";
import getCookie from "../../../../Component/GetCookie";
import { MdSystemUpdateAlt } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";
import { Reorder, useDragControls } from "framer-motion";
import { GrDrag } from "react-icons/gr";
import LinearProgressMui from "../../../../Component/Custom Loader/LinearProgress";

function DragItem({ data, parentService }) {
  const controls = useDragControls();
  const elementRef = useRef();

  const handlePointerDown = (e) => {
    const tr = e.target.closest("tr");
    elementRef.current = tr;
    tr.style.transition = "none";

    tr.style.position = "relative";
    tr.style.zIndex = 999;
    tr.style.background = "#F1F1F1";
    controls.start(e);
  };

  useEffect(() => {
    const handlePointerUp = () => {
      const tr = elementRef.current;
      if (tr) {
        tr.style.transition = "all linear 0.3s";
        tr.style.background = "inherit";
        tr.style.position = "static";
        tr.style.zIndex = 0;
      }
    };
    window.addEventListener("pointerup", handlePointerUp);
    return () =>
      setTimeout(() => {
        window.removeEventListener("pointerup", handlePointerUp);
      }, 2000);
  }, []);

  return (
    <Reorder.Item
      as="tr"
      value={data}
      dragListener={false}
      dragControls={controls}
      style={{ userSelect: "none" }}
    >
      <td className="d-flex align-items-center">
        <GrDrag
          key={data.id}
          style={{
            marginTop: "4px",
            cursor: "grab",
          }}
          onPointerDown={handlePointerDown}
        />
        <Dropdown style={{ position: "unset" }}>
          <Dropdown.Toggle>
            <BsThreeDotsVertical color="#000" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {/* view */}
            <Dropdown.Item
              as={Link}
              to={`/admin/final-service-module/main-services/view/${data?.id}`}
            >
              View
            </Dropdown.Item>
            {/* edit */}
            <Dropdown.Item
              as={Link}
              to={`/admin/final-service-module/main-services/edit/${data?.id}`}
              state={{ parentService }}
            >
              Edit
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
      <td>{data?.name}</td>
      <td>
        <img src={`${BACKEND_BASE_URL}/${data?.image}`} alt="" width={80} />
      </td>
    </Reorder.Item>
  );
}

const AdminMainServices = () => {
  const [search, setSearch] = useState("");
  const [value, setValue] = useState("");
  const [isSorting, setIsSorting] = useState(false);

  const getValue = (value) => {
    setValue(value);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  // Fetch Table Data
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [parentService, setParentService] = useState();

  const fetchData = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/main-services`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })

      .then((res) => {
        setIsLoading(false);
        setError(false);
        setTableData(res.data?.main_services);
        setFilteredData(res.data?.main_services);
        setParentService(res.data?.parent_service);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(true);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleReorder = (e) => {
    setFilteredData(e);
    updateOrder(e);
  };
  const debounceRef = useRef();
  function updateOrder(state) {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      if (state.length === 0) return;
      const formdata = new FormData();
      state.forEach((item, index) => {
        formdata.append("ids[]", item.id);
        formdata.append("display_orders[]", index + 1);
      });

      setIsSorting(true);
      axios
        .post(
          `${BACKEND_BASE_URL}/api/v3/rianaire/reorder/main-service`,
          formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {})
        .catch((error) => {
          // setIsLoading(false);
          setError(true);
        })
        .finally(() => {
          setIsSorting(false);
        });
    }, 300);
  }

  useEffect(() => {
    const result = tableData?.filter((data) => {
      return data.name.toLowerCase().match(search.toLowerCase());
    });
    setFilteredData(result);
  }, [search]);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar pageTitle="All Service" />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="custom_table mb-5">
                  <Row className="flex_between">
                    <Col md={8} className="mb-2">
                      <Link to="/admin/final-service-module/main-services/add-new">
                        <Button variant="success" size="sm">
                          Add &nbsp;
                          <span>
                            <AiOutlinePlusCircle className="mb-1" />
                          </span>
                        </Button>
                      </Link>
                    </Col>
                    <Col md={4} className="mb-2">
                      <Form.Control
                        type="text"
                        placeholder="search here..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </Col>
                  </Row>
                  { isSorting &&  <LinearProgressMui />}
                  {!isLoading && !error && tableData?.length > 0 && (
                    <div className="table_fixed">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Handle</th>
                            <th>Name</th>
                            <th>Image</th>
                          </tr>
                        </thead>
                        <Reorder.Group
                          layoutScroll
                          as="tbody"
                          axis="y"
                          values={filteredData}
                          onReorder={handleReorder}
                        >
                          {filteredData.map((data, index) => (
                            <DragItem
                              key={data.id}
                              data={data}
                              parentService={parentService}
                            />
                          ))}
                        </Reorder.Group>
                       
                      </table>
                    </div>
                  )}
                  {/* <div className="mt-3 mui_pagination">
                      <TablePagination
                        component="div"
                        count={filteredData.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[10, 20, 50]}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        showFirstButton
                        showLastButton
                      />
                    </div> */}
                </div>

                {isLoading && !error && <LinearProgress />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminMainServices;
