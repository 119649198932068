import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import { MdDeleteForever } from "react-icons/md";
import Swal from "sweetalert2";
import { BACKEND_BASE_URL } from "../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../Component/AdminDashboardNavbar";
import getCookie from "../../Component/GetCookie";
import { LinearProgress } from "@mui/material";
import { getLocalStorageWithExpiry } from "../../Component/LocalStorageWithExpiry";

const AdminFeedback = () => {
  // Fetch Table Data
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const fetchData = () => {
    setIsLoading(true);
    axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/feedback`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setTableData(res.data?.all_feedback);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // View Single Data
  const [singleData, setSingleData] = useState([]);
  const showSingleData = (modalValue, id) => {
    setModalSize("");
    axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/feedback/show/${id}`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setModalSize("lg");
        setModalData(modalValue);
        setSingleData(res.data?.feedback);
        setModalShow(true);
      });
  };

  // Delete Data

  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(`${BACKEND_BASE_URL}/api/v2/rianaire/feedback/delete/${id}`, {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        })
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data.message,
            confirmButtonColor: "#5eba86",
          });
          fetchData();
        });
    }
  };

  // Modal Section Data
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("lg");

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Feedback</h3>
                </div>
              </div>
              <div className="table-responsive">
                {!isLoading && !error && tableData.length > 0 && (
                  <table className="table table-hover ">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Language</th>
                        <th scope="col">Type Of Feedback</th>
                        <th scope="col">Overall Experience</th>
                        <th scope="col">Handle</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.map((data, index) => (
                        <tr key={index}>
                          <td> {index + 1}</td>
                          <td>{data.language}</td>
                          <td>{data.type_of_feedback}</td>
                          <td>{data.overall_experience}</td>

                          <td>
                            <button
                              onClick={() => showSingleData("View", data.id)}
                              className="py-1 px-2 bg-info border-0 rounded-3 me-1"
                            >
                              <FaEye
                                style={{
                                  color: "white",
                                }}
                                title="View"
                                size="1.5em"
                              />{" "}
                            </button>

                            <button
                              onClick={() => deleteData(data.id)}
                              className="py-1 px-2 bg-danger border-0 rounded-3 me-1"
                            >
                              <MdDeleteForever
                                style={{
                                  color: "white",
                                }}
                                title="Delete"
                                size="1.5em"
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {isLoading && !error && <LinearProgress />}
                {!isLoading && !error && tableData?.length < 1 && (
                  <div>
                    <h1 className="mt-3 text-danger">No Records Found</h1>
                  </div>
                )}
              </div>
            </div>

            <Modal
              show={modalShow}
              size={modalSize}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => setModalShow(false)}
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  {modalData}
                </Modal.Title>
                <Button
                  className="p-0 border-0 bg-danger"
                  onClick={() => setModalShow(false)}
                >
                  <CgClose
                    style={{
                      fontSize: "26px",
                    }}
                  />
                </Button>
              </Modal.Header>
              <Modal.Body>
                {/* View Modal section */}
                {modalData === "View" && (
                  <>
                    <h4>Language: {singleData?.language}</h4>
                    <h5>
                      Overall Experience: {singleData?.overall_experience}
                    </h5>
                    <h5>Type Of Feedback: {singleData?.type_of_feedback}</h5>
                    <h5>
                      Feedback Most Related To:{" "}
                      {singleData?.feedback_most_relate_to}
                    </h5>
                    <p>Description: {singleData?.feedback_description}</p>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setModalShow(false)}
                  className="btn-danger"
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminFeedback;
