import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import { AiOutlineCaretDown } from "react-icons/ai";
import { BsCheck2, BsThreeDotsVertical } from "react-icons/bs";
import { FiEdit2, FiSave } from "react-icons/fi";
import { Link } from "react-router-dom";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";

import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import { ToastAlert } from "../../../../Component/ToastAlert";
import LinearProgressMui from "../../../../Component/Custom Loader/LinearProgress";
import { MdAdd, MdArrowDropDown, MdClose } from "react-icons/md";
import Swal from "sweetalert2";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";
import { Reorder, useDragControls } from "framer-motion";
import { GrDrag } from "react-icons/gr";

function DragItem({
  data,
  handleCancelClick,
  handleEditClick,
  handleDeleteData,
  editedTableId,
  handleEditFormChange,
}) {
  const controls = useDragControls();
  const elementRef = useRef();
  console.log("data",data)

  const handlePointerDown = (e) => {
    const tr = e.target.closest("tr");
    elementRef.current = tr;
    tr.style.transition = "none";

    tr.style.position = "relative";
    tr.style.zIndex = 999;
    tr.style.background = "#F1F1F1";
    controls.start(e);
  };

  useEffect(() => {
    const handlePointerUp = () => {
      const tr = elementRef.current;
      if (tr) {
        tr.style.transition = "all linear 0.3s";
        tr.style.background = "inherit";
        tr.style.position = "static";
        tr.style.zIndex = 0;
      }
    };
    window.addEventListener("pointerup", handlePointerUp);
    return () =>
      setTimeout(() => {
        window.removeEventListener("pointerup", handlePointerUp);
      }, 2000);
  }, []);

  return (
    <>
      {editedTableId === data.id ? (
        <Reorder.Item
          as="tr"
          value={data}
          dragListener={false}
          dragControls={controls}
          style={{ userSelect: "none" }}
        >
          <td className="">
            <div className="flex">
              <button type="submit" className="save_btn">
                <BsCheck2
                  size="1.7rem"
                  className="me-1 mb-1 mb-lg-0 cursor"
                  color="green"
                />
              </button>

              <MdClose
                size="1.7rem"
                color="red"
                onClick={handleCancelClick}
                className="cursor"
              />
            </div>
          </td>

          <td>
            <Form.Group>
              <Form.Control
                required
                type="text"
                name="additional_services"
                placeholder="Additional Service"
                defaultValue={data?.name}
                onChange={(e) => {
                  handleEditFormChange(e);
                }}
              />
            </Form.Group>
          </td>
          <td>
            <Form.Group>
              <Form.Control
                required
                type="text"
                name="usd_price"
                placeholder="Usd Price"
                defaultValue={data?.usd_price}
                onChange={(e) => {
                  handleEditFormChange(e);
                }}
              />
            </Form.Group>
          </td>
          <td>
            <Form.Group>
              <Form.Control
                required
                type="text"
                name="bdt_price"
                placeholder="Bdt Price"
                defaultValue={data?.bdt_price}
                onChange={(e) => {
                  handleEditFormChange(e);
                }}
              />
            </Form.Group>
          </td>
          <td>
            <Form.Group>
              <Form.Control
                required
                type="text"
                name="duration"
                placeholder="Duration"
                defaultValue={data?.duration}
                onChange={(e) => {
                  handleEditFormChange(e);
                }}
              />
            </Form.Group>
          </td>
        </Reorder.Item>
      ) : (
        <Reorder.Item
          as="tr"
          value={data}
          dragListener={false}
          dragControls={controls}
          style={{ userSelect: "none" }}
        >
          <td className="d-flex align-items-center">
          <GrDrag
              style={{
                marginTop: "4px",
                cursor: "grab",
              }}
              onPointerDown={handlePointerDown}
            />
            <Dropdown style={{ position: "unset" }}>
              <Dropdown.Toggle>
                <span
                  style={{
                    backgroundColor: "#eeeeee",
                    padding: "8px 12px",
                    borderRadius: "8px",
                  }}
                >
                  <FiEdit2 color="#000" className="me-2" />
                  <AiOutlineCaretDown color="#000" />
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  as={Link}
                  to={`/admin/service-module/child-services/view/${data?.id}`}
                >
                  View
                </Dropdown.Item>
                {/* edit */}
                <Dropdown.Item
                  // as={Link}
                  // to={`/admin/service-module/additional-services/edit/${data?.id}`}
                  onClick={(event) => handleEditClick(event, data)}
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={(event) => handleDeleteData(data.id)}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
          <td>{data?.name}</td>
          <td>$ {data?.usd_price}</td>
          <td>Tk {data?.bdt_price}</td>
          <td>{data?.duration}</td>
        </Reorder.Item>
      )}
    </>
  );
}

const AdditionalServiceAll = () => {
  const [additionalServices, setAdditionalServices] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isSorting, setIsSorting] = useState(false);

  const [editFormData, setEditFormData] = useState([]);
  const [editedTableId, setEditedTableId] = useState(null);

  const fetchAdditionalServices = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/additional-service-cost`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setAdditionalServices(res.data?.additional_services);
      });
  };

  const handleEditClick = (event, data) => {
    event.preventDefault();
    setEditedTableId(data?.id);
    setEditFormData({
      additional_services: data?.name,
      bdt_price: data?.bdt_price,
      usd_price: data?.usd_price,
      duration: data?.duration,
    });
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();

    // const editedTableRow = {
    //   id: editedTableId,
    //   additional_services: editFormData?.name,
    //   bdt_price: editFormData?.bdt_price,
    //   usd_price: editFormData?.usd_price,
    //   duration: editFormData?.duration,
    // };
    // const newAdditionalServices = [...additionalServices];
    // const index = additionalServices.findIndex(
    //   (data) => data.id === editedTableId
    // );

    // newAdditionalServices[index] = editedTableRow;
    // setAdditionalServices(newAdditionalServices);

    setEditedTableId(null);
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("additional_name", editFormData.additional_services);
    formdata.append("additional_usd_price", editFormData.usd_price);
    formdata.append("additional_bdt_price", editFormData.bdt_price);
    formdata.append("additional_duration", editFormData.duration);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/additional-service-cost/update/${editedTableId}`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            text: "Updated successfully",
            confirmButtonColor: "#5eba86",
          });
          fetchAdditionalServices();
        } else {
        }
      });
  };

  const handleCancelClick = () => {
    setEditedTableId(null);
  };

  const handleDeleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}/api/v2/rianaire/additional-service-cost/delete/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data.message,
            confirmButtonColor: "#5eba86",
          });
          fetchAdditionalServices();
        });
    }
  };

  const handleReorder = (e) => {
    setAdditionalServices(e);
    updateOrder(e);
  };
  const debounceRef = useRef();

  function updateOrder(state) {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      if (state.length === 0) return;
      const formdata = new FormData();
      state.forEach((item, index) => {
        formdata.append("ids[]", item.id);
        formdata.append("display_orders[]", index + 1);
      });

      setIsSorting(true);
      axios
        .post(
          `${BACKEND_BASE_URL}/api/v3/rianaire/reorder/additional-service-cost`,
          formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {})
        .catch((error) => {
          // setIsLoading(false);
          setError(true);
        })
        .finally(() => {
          setIsSorting(false);
        });
    }, 300);
  }

  useEffect(() => {
    fetchAdditionalServices();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar pageTitle="All Additional Service" />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header"></div>
                <div className="custom_table">
                  <Form onSubmit={handleEditFormSubmit}>
                    <div className="table_fixed">
                    { isSorting &&  <LinearProgressMui />}
                      <table className="table">
                        <thead>
                          <tr>
                            <th>
                              <Dropdown style={{ position: "unset" }}>
                                <Dropdown.Toggle>
                                  <span
                                    style={{
                                      backgroundColor: "#eeeeee",
                                      padding: "8px 12px",
                                      borderRadius: "8px",
                                    }}
                                  >
                                    <MdAdd color="#000" className="me-2" />
                                    <AiOutlineCaretDown color="#000" />
                                  </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {/* {JSON.parse(
                                        localStorage.getItem("LOGGED_IN_USER_PERMISSION_INFO")
                                      )?.map(
                                        (data, index) =>
                                          data?.permission_name === "create-service-cost" && ( */}
                                  <Dropdown.Item
                                    as={Link}
                                    to={`/admin/service-module/additional-services/add-new`}
                                  >
                                    Add additional service
                                  </Dropdown.Item>
                                  {/* )
                                        )} */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </th>
                            {/* <th>Main Service</th>
                            <th>Sub Service</th>
                            <th>Child Service</th>
                            <th>Section Type</th> */}
                            <th>Additional</th>
                            <th>USD Price</th>
                            <th>BDT Price</th>
                            <th>Duration</th>
                          </tr>
                        </thead>
                        <Reorder.Group
                        layoutScroll
                        as="tbody"
                        axis="y"
                        values={additionalServices}
                        onReorder={handleReorder}
                      >
                          {isLoading && (
                            <tr>
                              <td colspan="9">
                                <LinearProgressMui />
                              </td>
                            </tr>
                          )}
                          {!isLoading &&
                            !error &&
                            additionalServices?.length > 0 &&
                            additionalServices.map((data, index) => (
                              <DragItem
                                key={data.id}
                                data={data}
                                editedTableId={editedTableId}
                                handleCancelClick={handleCancelClick}
                                handleEditFormChange={handleEditFormChange}
                                handleEditClick={handleEditClick}
                                handleDeleteData={handleDeleteData}
                              />
                            ))}
                          {!isLoading &&
                            !error &&
                            additionalServices?.length < 1 && (
                              <>
                                <tr className="text-center text-danger">
                                  <td colspan="7">No data Found</td>
                                </tr>
                              </>
                            )}
                        </Reorder.Group>
                      </table>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalServiceAll;
