import { LinearProgress } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BACKEND_BASE_URL } from "../../Component/GlobalVariables";
import Swal from "sweetalert2";
import { Button, Col, Dropdown, Form, Modal } from "react-bootstrap";
import AdminDashboardNavbar from "../../Component/AdminDashboardNavbar";
import { ToastAlert } from "../../Component/ToastAlert";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import { FiSave } from "react-icons/fi";
import getCookie from "../../Component/GetCookie";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { UserContext } from "../../App";
import { getLocalStorageWithExpiry } from "../../Component/LocalStorageWithExpiry";

const AdminRoleManagement = () => {
  var tt;

  const roleName = useRef();
  const { allRoles, setAllRoles } = useContext(UserContext);
  const [permission, setPermission] = useState([]);

  // const [permissionChecked, setPermissionChecked] = useState([]);

  // Modal Section Data
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("lg");

  // View single value
  const [singleRoleId, setSingleRoleId] = useState([]);
  const [getPermissionData, setGetPermissionData] = useState([]);
  const [singlePermissionChecked, setSinglePermissionChecked] = useState([]);

  // useEffect(() => {
  //   let a = [];
  //   getPermissionData.forEach((item) => {
  //     a.push(item.permission_id);
  //   });

  //   setSinglePermissionChecked(a);
  // }, [getPermissionData]);

  console.log("singlePermissionChecked", singlePermissionChecked);

  // Edit value
  const [editedRoleName, setEditedRoleName] = useState();

  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/roles`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setAllRoles(res.data?.all_roles);
        setPermission(res.data?.all_permissions);
      });
  };

  // Add new
  const addNewData = (modalValue) => {
    setModalSize("lg");
    setModalData(modalValue);
    setModalShow(true);
  };

  const handlePermission = (e) => {
    if (e.target.checked) {
      setSinglePermissionChecked([...singlePermissionChecked, e.target.value]);
    } else {
      setSinglePermissionChecked(
        singlePermissionChecked.filter((id) => id !== e.target.value)
      );
    }
  };

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();
    formdata.append("name", roleName.current.value);

    axios
      .post(`${BACKEND_BASE_URL}/api/v2/rianaire/roles/store`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })

      .then((response) => {
        if (response.data?.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllInfo();
          e.target.reset();
          setModalShow(false);
        }
      });
    e.preventDefault();
  };

  // View single Data
  // const showPermissionModal = (modalValue, id) => {
  //   axios
  //     .get(`${BACKEND_BASE_URL}/api/v2/rianaire/roles/show/${id}`, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
  //       },
  //     })
  //     .then((res) => {
  //       setModalSize("xxl");
  //       setModalData(modalValue);
  //       setSingleRoleId(res.data?.single_role?.id);
  //       setGetPermissionData(res.data?.available_permissions);
  //       setModalShow(true);
  //     });
  // };

  // Edit data

  const fetchDataForEdit = (modalValue, data) => {
    setEditedRoleName(data);

    setModalData(modalValue);
    setModalSize("lg");
    setModalShow(true);
  };

  // Update permission
  const updatePermission = (e) => {
    const formdata = new FormData();
    formdata.append("role_id", singleRoleId);
    singlePermissionChecked.forEach((item) => {
      formdata.append("permission[]", item);
    });

    axios
      .post(`${BACKEND_BASE_URL}/api/v2/rianaire/set-permission`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })

      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            title: response.data?.message,
          });
        } else {
          ToastAlert.fire({
            icon: "error",
            text: response.data?.errors?.permission,
            confirmButtonColor: "#5eba86",
          });
        }

        setModalShow(false);
        renderAllInfo();
      });
    e.preventDefault();
  };

  // Updated data to backend
  const updateClientInfo = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("name", roleName.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/roles/update/${editedRoleName.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        ToastAlert.fire({
          icon: "success",
          title: response.data?.message,
        });
        setModalShow(false);
        renderAllInfo();
      });

    e.preventDefault();
  };

  // Delete Data
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(`${BACKEND_BASE_URL}/api/v2/rianaire/roles/delete/${id}`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        })
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllInfo();
        });
    }
  };

  useEffect(() => {
    renderAllInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Roles</h3>
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => addNewData("Add")}
                  >
                    Add &nbsp;
                    <span>
                      <AiOutlinePlusCircle className="mb-1" />
                    </span>
                  </Button>
                </div>

                {allRoles.length > 0 ? (
                  <div className="table-responsive custom_table">
                    <div className="table_fixed">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Handle</th>
                            <th scope="col">Name</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allRoles?.map((data, index) => (
                            <tr key={index}>
                              <td>
                                <Dropdown style={{ position: "unset" }}>
                                  <Dropdown.Toggle>
                                    <BsThreeDotsVertical color="#000" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {/* edit */}
                                    {data.slug != "super-admin" && (
                                      <Dropdown.Item
                                        onClick={() =>
                                          fetchDataForEdit("Edit", data)
                                        }
                                      >
                                        Edit
                                      </Dropdown.Item>
                                    )}
                                    {/* delete */}
                                    {data.slug != "super-admin" && (
                                      <Dropdown.Item
                                        onClick={() => deleteData(data.id)}
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>{data?.name}</td>
                              <td>
                                {data.slug != "super-admin" && (
                                  <Link
                                    to={`role-permission/${data.id}`}
                                    state={{ permission }}
                                  >
                                    <button
                                      // onClick={() =>
                                      //   showPermissionModal(
                                      //     "Permission",
                                      //     data.id
                                      //   )
                                      // }
                                      className="py-1 px-2 bg-info border-0 rounded-3 me-1 mb-1 text-white"
                                    >
                                      Set Permission
                                    </button>
                                  </Link>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <LinearProgress />
                )}
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminRoleManagement;
