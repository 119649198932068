import { Navigate, useLocation } from "react-router-dom";
import getCookie from "../GetCookie";
import { getLocalStorageWithExpiry } from "../LocalStorageWithExpiry";

export default function AdminPrivateRoute({ children }) {
  const location = useLocation();
  // const LOGGED_IN_ADMIN_USER_TYPE = sessionStorage.getItem(
  //   "LOGGED_IN_ADMIN_USER_TYPE"
  // );
  // const ACCESS_TOKEN = sessionStorage.getItem("ACCESS_TOKEN");

  let LOGGED_IN_ADMIN_USER_TYPE = null;
  let ACCESS_TOKEN = null;

  LOGGED_IN_ADMIN_USER_TYPE = getLocalStorageWithExpiry(
    "LOGGED_IN_ADMIN_USER_TYPE"
  );
  ACCESS_TOKEN = getLocalStorageWithExpiry("ACCESS_TOKEN");

  if (ACCESS_TOKEN) {
    if (LOGGED_IN_ADMIN_USER_TYPE == 1 || LOGGED_IN_ADMIN_USER_TYPE == 2) {
      return children;
    } else {
      return <Navigate to="/admin/login" replace state={{ from: location }} />;
    }
  } else {
    return <Navigate to="/admin/login" replace state={{ from: location }} />;
  }
}
