import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DotLoader } from "../../../Component/Custom Loader/CustomLoader";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";

const AdminProfileDashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { name, email, profile_photo, phone } =
    getLocalStorageWithExpiry("adminUserInfo");

  return (
    <div className="user_dashboard_content admin-profile-dashboard p-4 p-lg-5">
      <h1 className="mb-5 ms-3 ms-lg-0">Account Overview</h1>
      <h3 className="mb-4">Profile</h3>

      <div>
        {!isLoading && (
          <>
            <Form.Group className="d-flex align-items-center form_group">
              <Form.Label className="w-50 mb-0">Full Name</Form.Label>
              <Form.Control
                className="w-50 bg-transparent"
                type="text"
                disabled
                placeholder="Username"
                defaultValue={name}
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center form_group">
              <Form.Label className="w-50 mb-0">Email</Form.Label>
              <Form.Control
                className="w-50 bg-transparent"
                type="text"
                disabled
                placeholder="Email"
                defaultValue={email}
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center form_group">
              <Form.Label className="w-50 mb-0">Profile Photo</Form.Label>
              <div className="logo_img_wrapper">
                {profile_photo != null ? (
                  <img
                    src={`${BACKEND_BASE_URL}${profile_photo}`}
                    alt="Profile"
                    className="img-fluid"
                    width={80}
                  />
                ) : (
                  <span>
                    <img
                      src={require("../../../Assets/rabbit_png.png")}
                      alt=""
                      className="img-fluid"
                      width={60}
                    />
                  </span>
                )}
              </div>
            </Form.Group>
            <Form.Group className="d-flex align-items-center form_group">
              <Form.Label className="w-50 mb-0">Phone Number</Form.Label>
              <Form.Control
                className="w-50 bg-transparent"
                type="text"
                disabled
                placeholder="phone"
                defaultValue={phone}
              />
            </Form.Group>
          </>
        )}
      </div>

      {isLoading && (
        <div className="flex_center" style={{ minHeight: "365px" }}>
          <DotLoader />
        </div>
      )}

      <div className="edit_profile_btn mt-4 mb-5">
        <Link to="/admin/profile/edit-profile">
          <button>Edit Profile</button>
        </Link>
      </div>
    </div>
  );
};

export default AdminProfileDashboard;
