import axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Badge, Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import moment from "moment";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import { LinearProgress } from "@mui/material";
import { BsCheck2, BsThreeDotsVertical } from "react-icons/bs";
import { ToastAlert } from "../../../Component/ToastAlert";
import { MdClose } from "react-icons/md";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import "./adminOrder.css";
import { UserContext } from "../../../App";
import Swal from "sweetalert2";
import Select from "react-select";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";

const AdminOrder = () => {
  const { setModalShow } = useContext(UserContext);
  const [singlePermissionChecked, setSinglePermissionChecked] = useState([]);
  const handlePermission = (e) => {
    if (e.target.checked) {
      setSinglePermissionChecked([...singlePermissionChecked, e.target.value]);
    } else {
      setSinglePermissionChecked(
        singlePermissionChecked.filter((id) => id !== e.target.value)
      );
    }
  };

  // Initial table data
  const [tableData, setTableData] = useState([]);
  const [allEmployeeInfo, setAllEmployeeInfo] = useState([]);
  const [userType, setUserType] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [editedTableId, setEditedTableId] = useState(null);
  const [editFormData, setEditFormData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  // const [page, setPage] = useState(0);

  const headers = [
    { label: "Title", key: "project_name" },
    { label: "Area", key: "project_sub_category" },
    { label: "Status", key: "order_status" },
    { label: "Assignee", key: "employee_id" },
  ];

  // Get All Info
  const renderAllInfo = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v3/rianaire/order`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setTableData(res.data?.orders?.data);
        setFilteredData(res.data?.orders?.data);
        setSelectedEmployee(
          res.data?.orders?.data.map((data) => data.employees)
        );
        setUserType(res.data?.user_type);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(true);
      });
  };

  // console.log(selectedEmployee);
  // form submit to backend
  const handleStatus = (status, id) => {
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v3/rianaire/orders/change-status/${id}/${status.value}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllInfo();
        }
      });
  };

  // const [selectedEmployee, setSelectedEmployee] = useState([]);

  const handleEmployeeAssign = (info, orderId) => {
    const formdata = new FormData();
    const employeeId = info.map((info) => info.value);
    console.log(employeeId);

    employeeId.forEach((item) => {
      formdata.append("user_ids[]", item);
    });
    formdata.append("order_id", orderId);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v3/rianaire/order/assign/employee-to-order`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            title: response.data?.message,
          });
        } else {
          ToastAlert.fire({
            icon: "error",
            title: response.data?.message,
          });
        }
      });
  };
  // console.log(selected)
  const fetchAllEmployee = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v3/rianaire/user/all`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setAllEmployeeInfo(res.data?.all_users);
      });
  };

  const handleEditClick = (event, data) => {
    console.log(data);
    event.preventDefault();
    setEditedTableId(data.id);

    setEditFormData({
      project_name: data?.project_name,
      project_sub_category: data.project_sub_category,
      order_status: data.order_status,
      assignee: editFormData?.employee_id,
    });
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const editedContact = {
      id: editedTableId,
      project_name: editFormData?.project_name,
      project_sub_category: editFormData?.project_sub_category,
      order_status: editFormData?.order_status,
      assignee: editFormData?.employee_id,
    };

    const newContacts = [...tableData];
    const index = tableData.findIndex((data) => data.id === editedTableId);

    newContacts[index] = editedContact;

    setTableData(newContacts);
    setEditedTableId(null);
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("project_name", editFormData?.project_name);
    formdata.append("project_sub_category", editFormData?.project_sub_category);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/project-orders/update/${editedTableId}`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            text: "Updated successfully",
            confirmButtonColor: "#5eba86",
          });
          event.target.reset();
          renderAllInfo();
        } else {
        }
      });
  };

  const handleCancelClick = () => {
    setEditedTableId(null);
  };

  // const handleDeleteClick = (tableId) => {

  //   const newContacts = [...tableData];
  //   const index = tableData.findIndex((data) => data.id === tableId);
  //   newContacts.splice(index, 1);
  //   setTableData(newContacts);
  // };

  const handleDeleteClick = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(`${BACKEND_BASE_URL}/api/v2/rianaire/orders/delete/${id}`, {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        })
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllInfo();
        });
    }
  };

  useEffect(() => {
    renderAllInfo();
    fetchAllEmployee();
  }, []);

  useEffect(() => {
    const result = tableData.filter((data) => {
      return (
        data?.project_name?.toLowerCase().match(search.toLowerCase()) ||
        data?.sub_service?.name.toLowerCase().match(search.toLowerCase())
      );
    });
    if (result.length != 0) {
      setFilteredData(result);
    }
  }, [search]);

  useEffect(() => {
    let a = [];
    selectedEmployee.forEach((item) => {
      // a.push(item.permission_id);
    });

    setSinglePermissionChecked(a);
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar pageTitle="Orders" />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header"></div>

                <div className="py-5">
                  <Row className="flex_end">
                    <Col className="mb-2">
                      <button className="csv_button me-4">
                        <CSVLink
                          data={filteredData}
                          headers={headers}
                          filename={"order.csv"}
                          target="_blank"
                        >
                          CSV Export
                        </CSVLink>
                      </button>
                      <Link to={`/admin/orders/add-new`}>
                        <Button
                          variant="success"
                          size="sm"
                          onClick={() => {
                            setModalShow(true);
                          }}
                        >
                          Add &nbsp;
                        </Button>
                      </Link>
                    </Col>
                    <Col md={4} className="mb-2">
                      <Form.Control
                        type="text"
                        placeholder="search here..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <div className="custom_table table-responsive">
                    <div className="table_fixed">
                      <Form onSubmit={handleEditFormSubmit}>
                        <table className="table mb-5">
                          <thead>
                            <tr>
                              <th>Handle</th>
                              <th>Title</th>
                              <th>Area</th>
                              <th>Status</th>
                              <th>Assignee</th>
                              <th>Project Created Date</th>
                              <th>Project Deadline</th>
                              <th>Added From</th>
                            </tr>
                          </thead>
                          {!isLoading && !error && tableData.length > 0 && (
                            <tbody>
                              {filteredData
                                // .slice(
                                //   page * rowsPerPage,
                                //   page * rowsPerPage + rowsPerPage
                                // )
                                ?.map((data, index) => (
                                  <>
                                    {editedTableId === data.id ? (
                                      <tr key={index}>
                                        <td>
                                          <div className="flex">
                                            <button
                                              type="submit"
                                              className="save_btn"
                                            >
                                              <BsCheck2
                                                size="1.7rem"
                                                className="me-1 mb-1 mb-lg-0 cursor"
                                                color="green"
                                              />
                                            </button>

                                            <MdClose
                                              size="1.7rem"
                                              color="red"
                                              onClick={handleCancelClick}
                                              className="cursor"
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <Form.Group className="position-relative">
                                            <Form.Control
                                              type="text"
                                              required="required"
                                              placeholder="title"
                                              name="project_name"
                                              defaultValue={data?.project_name}
                                              onChange={handleEditFormChange}
                                            />
                                          </Form.Group>
                                        </td>
                                        <td>
                                          <Form.Group className="position-relative">
                                            <Form.Control
                                              type="text"
                                              required="required"
                                              placeholder="total page"
                                              name="total_pages"
                                              defaultValue={
                                                data?.sub_service_info?.name
                                              }
                                              onChange={handleEditFormChange}
                                            />
                                          </Form.Group>
                                        </td>
                                        <td>
                                          <Form.Group className="position-relative">
                                            {/* <Form.Select
                                              name="order_status"
                                              onChange={(e) => {
                                                handleEditFormChange(e);
                                              }}
                                              ref={orderStatus}
                                              className=""
                                            >
                                              <option
                                                value="pending"
                                                selected={
                                                  data?.order_status ==
                                                    "pending" && "selected"
                                                }
                                              >
                                                Pending
                                              </option>
                                              <option
                                                value="review"
                                                selected={
                                                  data?.order_status ==
                                                    "review" && "selected"
                                                }
                                              >
                                                Review
                                              </option>
                                              <option
                                                value="accepted"
                                                selected={
                                                  data?.order_status ==
                                                    "accepted" && "selected"
                                                }
                                              >
                                                Accepted
                                              </option>
                                              <option
                                                value="hold"
                                                selected={
                                                  data?.order_status ==
                                                    "hold" && "selected"
                                                }
                                              >
                                                Hold
                                              </option>
                                              <option
                                                value="rejected"
                                                selected={
                                                  data?.order_status ==
                                                    "rejected" && "selected"
                                                }
                                              >
                                                Rejected
                                              </option>
                                              <option
                                                value="delivered"
                                                selected={
                                                  data?.order_status ==
                                                    "delivered" && "selected"
                                                }
                                              >
                                                Delivered
                                              </option>
                                              <option
                                                value="complete"
                                                selected={
                                                  data?.order_status ==
                                                    "complete" && "selected"
                                                }
                                              >
                                                Completed
                                              </option>
                                            </Form.Select>
                                            <MdArrowDropDown className="dropdown" /> */}
                                            <Select
                                              // isDisabled
                                              options={[
                                                {
                                                  value: "pending",
                                                  label: "Pending",
                                                },
                                                {
                                                  value: "review",
                                                  label: "Review",
                                                },
                                                {
                                                  value: "accepted",
                                                  label: "Accepted",
                                                },
                                                {
                                                  value: "hold",
                                                  label: "Hold",
                                                },
                                                {
                                                  value: "rejected",
                                                  label: "Rejected",
                                                },
                                                {
                                                  value: "delivered",
                                                  label: "Delivered",
                                                },
                                                {
                                                  value: "completed",
                                                  label: "Completed",
                                                },
                                              ]}
                                              defaultValue={
                                                (data?.order_status ==
                                                  "pending" && {
                                                  label: "Pending",
                                                }) ||
                                                (data?.order_status ==
                                                  "review" && {
                                                  label: "Review",
                                                }) ||
                                                (data?.order_status ==
                                                  "accepted" && {
                                                  label: "Accepted",
                                                }) ||
                                                (data?.order_status ==
                                                  "hold" && {
                                                  label: "Hold",
                                                }) ||
                                                (data?.order_status ==
                                                  "rejected" && {
                                                  label: "Rejected",
                                                }) ||
                                                (data?.order_status ==
                                                  "delivered" && {
                                                  label: "Delivered",
                                                }) ||
                                                (data?.order_status ==
                                                  "completed" && {
                                                  label: "Completed",
                                                })
                                              }
                                              placeholder="Select Status"
                                              classNamePrefix="react-select"
                                              onChange={(value = "") => {
                                                handleStatus(value, data.id);
                                              }}
                                            />
                                          </Form.Group>
                                        </td>
                                        <td>
                                          <Form.Group className="position-relative">
                                            {/* <Form.Select
                                              name="assignee"
                                              onChange={(e) => {
                                                handleStatus(e, data.id);
                                              }}
                                              className=""
                                              disabled={
                                                sessionStorage.getItem(
                                                  "LOGGED_IN_ADMIN_ID"
                                                ) == 35
                                                  ? "disabled"
                                                  : ""
                                              }
                                            >
                                              <option value="">
                                                Select Employee
                                              </option>
                                              {allEmployeeInfo?.map((employee) => (
                                                <option
                                                  value={employee.id}
                                                  key={employee.id}
                                                  selected={
                                                    employee.id ==
                                                    data?.employee_id
                                                      ? "selected"
                                                      : ""
                                                  }
                                                >
                                                  {employee?.name}
                                                </option>
                                              ))}
                                            </Form.Select>
                                            <MdArrowDropDown className="dropdown" /> */}
                                            <Select
                                              isMulti
                                              isDisabled={
                                                sessionStorage.getItem(
                                                  "LOGGED_IN_ADMIN_ID"
                                                ) == 35
                                                  ? "disabled"
                                                  : ""
                                              }
                                              name="colors"
                                              options={allEmployeeInfo?.map(
                                                (employee) => {
                                                  return {
                                                    label: employee.name,
                                                    value: employee.id,
                                                  };
                                                }
                                              )}
                                              defaultValue={allEmployeeInfo?.map(
                                                (employee) => {
                                                  if (
                                                    employee?.id ==
                                                    data?.employee_id
                                                  ) {
                                                    return {
                                                      label: employee?.name,
                                                      value: employee?.id,
                                                    };
                                                  }
                                                }
                                              )}
                                              placeholder="Select Assignee"
                                              classNamePrefix="react-select"
                                              onChange={(e) =>
                                                handleEmployeeAssign(e, data.id)
                                              }
                                              // hideSelectedOptions={false}
                                            />
                                          </Form.Group>
                                        </td>
                                        <td>
                                          {moment(data?.created_at).format(
                                            "DD MMM, Y"
                                          )}
                                        </td>
                                        <td></td>
                                        <td>
                                          {data?.is_convert == "1" && (
                                            <Badge
                                              title="From Quote"
                                              style={{
                                                backgroundColor: "#0089c8",
                                              }}
                                            >
                                              From Quote
                                            </Badge>
                                          )}
                                          {data?.added_by && (
                                            <Badge
                                              title={data?.added_by}
                                              style={{
                                                backgroundColor: "#0089c8",
                                              }}
                                            >
                                              {data?.added_by}
                                            </Badge>
                                          )}
                                        </td>
                                      </tr>
                                    ) : (
                                      <tr>
                                        <td>
                                          <Dropdown
                                            style={{ position: "unset" }}
                                          >
                                            <Dropdown.Toggle>
                                              <BsThreeDotsVertical color="#000" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                as={Link}
                                                to={`/admin/orders/view/${data?.id}`}
                                              >
                                                View
                                              </Dropdown.Item>
                                              {userType == 1 && (
                                                <>
                                                  <Dropdown.Item
                                                    onClick={(event) =>
                                                      handleEditClick(
                                                        event,
                                                        data
                                                      )
                                                    }
                                                  >
                                                    Edit
                                                  </Dropdown.Item>

                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      handleDeleteClick(data.id)
                                                    }
                                                  >
                                                    Delete
                                                  </Dropdown.Item>
                                                </>
                                              )}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </td>
                                        <td>
                                          <Link
                                            to={`/admin/orders/view/${data?.id}`}
                                            className="text-black"
                                          >
                                            {data?.project_name
                                              ? data?.project_name
                                              : " - "}
                                          </Link>
                                        </td>
                                        <td>{data?.sub_service_info?.name}</td>
                                        <td>
                                          <Form.Group className="position-relative">
                                            {/* <Form.Select
                                              ref={orderStatus}
                                              onChange={(e) => {
                                                handleStatus(e, data.id);
                                              }}
                                            >
                                              <option
                                                value="pending"
                                                selected={
                                                  data?.order_status ==
                                                    "pending" && "selected"
                                                }
                                              >
                                                Pending
                                              </option>
                                              <option
                                                value="review"
                                                selected={
                                                  data?.order_status ==
                                                    "review" && "selected"
                                                }
                                              >
                                                Review
                                              </option>
                                              <option
                                                value="accepted"
                                                selected={
                                                  data?.order_status ==
                                                    "accepted" && "selected"
                                                }
                                              >
                                                Accepted
                                              </option>
                                              <option
                                                value="hold"
                                                selected={
                                                  data?.order_status ==
                                                    "hold" && "selected"
                                                }
                                              >
                                                Hold
                                              </option>
                                              <option
                                                value="rejected"
                                                selected={
                                                  data?.order_status ==
                                                    "rejected" && "selected"
                                                }
                                              >
                                                Rejected
                                              </option>
                                              <option
                                                value="delivered"
                                                selected={
                                                  data?.order_status ==
                                                    "delivered" && "selected"
                                                }
                                              >
                                                Delivered
                                              </option>
                                              <option
                                                value="complete"
                                                selected={
                                                  data?.order_status ==
                                                    "complete" && "selected"
                                                }
                                              >
                                                Completed
                                              </option>
                                            </Form.Select>
                                            <MdArrowDropDown className="dropdown" /> */}

                                            <Select
                                              options={[
                                                {
                                                  value: "pending",
                                                  label: "Pending",
                                                },
                                                {
                                                  value: "in-progress",
                                                  label: "In Progress",
                                                },
                                                {
                                                  value: "arriving-soon",
                                                  label: "Arriving Soon",
                                                },
                                                // {
                                                //   value: "hold",
                                                //   label: "Hold",
                                                // },
                                                // {
                                                //   value: "rejected",
                                                //   label: "Rejected",
                                                // },
                                                // {
                                                //   value: "delivered",
                                                //   label: "Delivered",
                                                // },
                                                {
                                                  value: "completed",
                                                  label: "Completed",
                                                },
                                              ]}
                                              defaultValue={
                                                (data?.order_status ==
                                                  "pending" && {
                                                  label: "Pending",
                                                }) ||
                                                (data?.order_status ==
                                                  "in-progress" && {
                                                  label: "In Progress",
                                                }) ||
                                                (data?.order_status ==
                                                  "arriving-soon" && {
                                                  label: "Arriving Soon",
                                                }) ||
                                                // ||
                                                // (data?.order_status ==
                                                //   "hold" && {
                                                //   label: "Hold",
                                                // }) ||
                                                // (data?.order_status ==
                                                //   "rejected" && {
                                                //   label: "Rejected",
                                                // }) ||
                                                // (data?.order_status ==
                                                //   "delivered" && {
                                                //   label: "Delivered",
                                                // })
                                                (data?.order_status ==
                                                  "completed" && {
                                                  label: "Completed",
                                                })
                                              }
                                              placeholder="Select Status"
                                              classNamePrefix="react-select"
                                              onChange={(value = "") => {
                                                handleStatus(value, data.id);
                                              }}
                                            />
                                          </Form.Group>
                                        </td>

                                        <td>
                                          {/* <Form.Group className=""> */}
                                          {/* <Form.Select
                                              ref={assignEmployee}
                                              onChange={(e) => {
                                                handleEmployeeAssign(
                                                  e,
                                                  data.id
                                                );
                                              }}
                                              className=""
                                              disabled={
                                                sessionStorage.getItem(
                                                  "LOGGED_IN_ADMIN_ID"
                                                ) == 35
                                                  ? "disabled"
                                                  : ""
                                              }
                                            >
                                              <option value="">
                                                Select Employee
                                              </option>
                                              {allEmployeeInfo?.map((employee) => (
                                                <option
                                                  value={employee.id}
                                                  key={employee.id}
                                                  selected={
                                                    employee.id ==
                                                    data?.employee_id
                                                      ? "selected"
                                                      : ""
                                                  }
                                                >
                                                  {employee.name}
                                                </option>
                                              ))}
                                            </Form.Select>
                                            <MdArrowDropDown className="dropdown" /> */}
                                          <Select
                                            isMulti
                                            isDisabled={
                                              sessionStorage.getItem(
                                                "LOGGED_IN_ADMIN_ID"
                                              ) == 35
                                                ? true
                                                : false
                                            }
                                            name="colors"
                                            options={allEmployeeInfo?.map(
                                              (employee) => {
                                                return {
                                                  label: employee?.name,
                                                  value: employee?.id,
                                                };
                                              }
                                            )}
                                            defaultValue={data?.employees?.map(
                                              (employee) => {
                                                return {
                                                  label: employee?.name,
                                                  value: employee?.id,
                                                };
                                              }
                                            )}
                                            placeholder="Select Assignee"
                                            classNamePrefix="react-select"
                                            onChange={(e) =>
                                              handleEmployeeAssign(e, data.id)
                                            }
                                            // hideSelectedOptions={false}
                                          />

                                          {/* {allEmployeeInfo.map((employee) => (
                                            <>
                                              <Form.Group>
                                                <Form.Check
                                                  onChange={handlePermission}
                                                  type="checkbox"
                                                  label={employee?.name}
                                                  value={employee?.id}
                                                  defaultChecked="checked"
                                                />

                                                {selectedEmployee.map((em) => {
                                                  console.log("employee.id", employee.id)
                                                  console.log("em?.employee_id", em[0]?.employee_id)
                                                  if (
                                                    employee.id ==
                                                    em[0]?.employee_id
                                                  ) {
                                                    selected = em[0]?.employee_id;
                                                  }
                                                  return (
                                                    employee.id ==
                                                      em[0]?.employee_id && (
                                                      <Form.Check
                                                        onChange={
                                                          handlePermission
                                                        }
                                                        type="checkbox"
                                                        label={employee?.name}
                                                        value={employee?.id}
                                                        defaultChecked="checked"
                                                        className="w-25"
                                                      />
                                                    )
                                                  );
                                                })}

                                                {selected != employee.id && (
                                                  <Form.Check
                                                    onChange={handlePermission}
                                                    type="checkbox"
                                                    label={employee?.name}
                                                    value={employee?.id}
                                                  />
                                                )}
                                              </Form.Group>
                                            </>
                                          ))} */}

                                          {/* </Form.Group> */}
                                        </td>
                                        <td>
                                          {moment(data?.created_at).format(
                                            "DD MMM, Y"
                                          )}
                                        </td>
                                        <td></td>
                                        <td>
                                          {data?.is_convert == "1" && (
                                            <Badge
                                              title="From Quote"
                                              style={{
                                                backgroundColor: "#0089c8",
                                              }}
                                            >
                                              From Quote
                                            </Badge>
                                          )}
                                          {data?.added_by && (
                                            <Badge
                                              title={data?.added_by}
                                              style={{
                                                backgroundColor: "#0089c8",
                                              }}
                                            >
                                              {data?.added_by}
                                            </Badge>
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                ))}
                            </tbody>
                          )}
                        </table>
                      </Form>
                      {isLoading && !error && <LinearProgress />}
                      {!isLoading && !error && tableData?.length < 1 && (
                        <div className="text-center text-danger py-5">
                          <h1>No data Found</h1>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="mt-3 mui_pagination">
                      <TablePagination
                        component="div"
                        count={filteredData.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[10, 20, 50]}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        showFirstButton
                        showLastButton
                      />
                    </div> */}
                </div>

                {/* {firstPage !== null && (
                  <>
                    <div className="flex_center py-5">
                      <Pagination
                        count={lastPage}
                        defaultPage={page}
                        selected={page}
                        siblingCount={1}
                        onChange={(e, value) => setPage(value)}
                      />
                    </div>
                  </>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminOrder;
