import React, { useEffect, useRef, useState } from "react";
import InboxSidebar from "../_partials/InboxSidebar";
import ConversationSection from "../_partials/ConversationSection";

import { Outlet } from "react-router-dom";

const AdminInboxConversation = ({ type, parentReload, setParentReload }) => {
  return (
    <div className="my-2"
      style={{
        margin: "0 auto",
        display: "flex",
        width: "75%",
        borderRadius: "8px 8px 0 0",
        border: "1px solid #D1D5DB",
        backgroundColor: "white",
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
      }}
    >

      <InboxSidebar type={type} parentReload={parentReload} setParentReload={setParentReload} />
      <Outlet />
    </div>
  );
};

export default AdminInboxConversation;
