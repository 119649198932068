import { LinearProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import Swal from "sweetalert2";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";

import { AiOutlineClose, AiOutlinePlusCircle } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever, MdSystemUpdateAlt } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import { FiSave } from "react-icons/fi";
import getCookie from "../../../Component/GetCookie";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";

const Departments = () => {
  const departmentName = useRef();
  const [tableData, setTableData] = useState([]);

  // Modal Section Data
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("lg");

  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/explore-openings/departments`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        // if(res.data?.status == 401){
        //   Swal.fire({
        //     icon: "success",
        //     text: res.data.message,
        //     confirmButtonColor: "#5eba86",
        //   });
        // }
        setTableData(res.data?.all_departments);
      });
  };

  // Add new
  const addNewData = (modalValue) => {
    setModalSize("lg");
    setModalData(modalValue);
    setModalShow(true);
  };

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();
    formdata.append("department_name", departmentName.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/explore-openings/departments/store`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllInfo();
          e.target.reset();
          setModalShow(false);
        }
      });
    e.preventDefault();
  };

  // Edit value
  const [editedDepartmentName, setEditedDepartmentName] = useState();

  // Edit data
  const fetchDataForEdit = (modalValue, data) => {
    setModalSize("lg");
    setEditedDepartmentName(data);
    setModalData(modalValue);
    setModalShow(true);
  };

  // Updated data to backend
  const updateClientInfo = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("department_name", departmentName.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/explore-openings/departments/update/${editedDepartmentName.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        Swal.fire({
          icon: "success",
          text: response.data?.message,
          confirmButtonColor: "#5eba86",
        });
        setModalShow(false);
        renderAllInfo();
      });

    e.preventDefault();
  };

  // Delete Data
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}/api/v2/rianaire/explore-openings/departments/delete/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllInfo();
        });
    }
  };

  useEffect(() => {
    renderAllInfo();
  }, []);

  const StatusUpdate = (id) => {
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/explore-openings/departments/status-update/${id}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        renderAllInfo();
      });
  };

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12 ">
                <div className="card-header flex_between">
                  <h3>Departments</h3>
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => addNewData("Add")}
                  >
                    Add &nbsp;
                    <span>
                      <AiOutlinePlusCircle className="mb-1" />
                    </span>
                  </Button>
                </div>
                {tableData.length > 0 ? (
                  <div className="table-responsive custom_table">
                    <div className="table_fixed">
                      <table className="table table-hover my-5">
                        <thead>
                          <tr>
                            <th scope="col">Handle</th>
                            <th scope="col">Department</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData?.map((data, index) => (
                            <tr key={index}>
                              <td>
                                <Dropdown style={{ position: "unset" }}>
                                  <Dropdown.Toggle>
                                    <BsThreeDotsVertical color="#000" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {/* edit */}

                                    <Dropdown.Item
                                      onClick={() =>
                                        fetchDataForEdit("Edit", data)
                                      }
                                    >
                                      Edit
                                    </Dropdown.Item>

                                    {/* delete */}

                                    <Dropdown.Item
                                      onClick={() => deleteData(data.id)}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>{data?.department_name}</td>

                              <td>
                                {data.status == 1 ? (
                                  <Link
                                    to=""
                                    onClick={() => StatusUpdate(data.id)}
                                  >
                                    <FaCheck
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",

                                        padding: "2px 4px",
                                        borderRadius: "4px",
                                      }}
                                      size="1.6em"
                                    />
                                  </Link>
                                ) : (
                                  <Link
                                    to=""
                                    onClick={() => StatusUpdate(data.id)}
                                  >
                                    <AiOutlineClose
                                      style={{
                                        color: "white",
                                        backgroundColor: "red",

                                        padding: "2px 4px",
                                        borderRadius: "4px",
                                      }}
                                      size="1.6em"
                                    />
                                  </Link>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <LinearProgress />
                )}
              </div>
            </div>
            <Modal
              show={modalShow}
              size={modalSize}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => setModalShow(false)}
            >
              <Modal.Header>
                <Modal.Title className="" id="contained-modal-title-vcenter">
                  {modalData}
                </Modal.Title>
                <Button
                  className="p-0 border-0 bg-danger"
                  onClick={() => setModalShow(false)}
                >
                  <CgClose
                    style={{
                      fontSize: "26px",
                    }}
                  />
                </Button>
              </Modal.Header>
              <Modal.Body>
                {/* Add Modal section */}
                {modalData === "Add" && (
                  <div className="">
                    <form onSubmit={storeData}>
                      <div className="content-wrapper">
                        <div className="card">
                          <div className="card-body">
                            <div className="row py-3">
                              {/* department Name */}
                              <div className="col-md-12">
                                <div className="form-group mb-3">
                                  <div>
                                    <Form.Label className="label fw-bold">
                                      Department Name
                                    </Form.Label>
                                  </div>

                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Department Name"
                                    required
                                    ref={departmentName}
                                  />
                                </div>
                              </div>

                              {/* Submit button */}
                              <button
                                type="submit"
                                className="btn-submit mt-5 rounded-3 border-0"
                              >
                                <FiSave /> &nbsp; Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                )}

                {/* Edit modal section */}
                {modalData === "Edit" && (
                  <form onSubmit={updateClientInfo}>
                    <div className="content-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <div className="row py-3">
                            {/*  Department Name */}
                            <div className="col-md-12">
                              <div className="form-group mb-3">
                                <div>
                                  <Form.Label className="label fw-bold">
                                    Department Name
                                  </Form.Label>
                                </div>
                                <div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    required
                                    ref={departmentName}
                                    defaultValue={
                                      editedDepartmentName?.department_name
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Submit button */}
                            <button
                              type="submit"
                              className="btn-submit mt-5 rounded-3 border-0 flex_center"
                            >
                              <MdSystemUpdateAlt className="me-2" />
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setModalShow(false)}
                  className="btn-danger"
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Departments;
