import { LinearProgress, TablePagination } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import Parse from "html-react-parser";
import Swal from "sweetalert2";
import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import { ToastAlert } from "../../../../Component/ToastAlert";
import RichTextEditor from "../../../../Component/RichTextEditor";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { CgClose } from "react-icons/cg";
import getCookie from "../../../../Component/GetCookie";
import { MdSystemUpdateAlt } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const AdminServiceAll = () => {
  const [search, setSearch] = useState("");
  const [value, setValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Fetch Table Data
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [parentService, setParentService] = useState();

  const fetchData = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/service`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })

      .then((res) => {
        setIsLoading(false);
        setError(false);
        setTableData(res.data?.all_service);
        setFilteredData(res.data?.all_service);
        setParentService(res.data?.parent_service);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(true);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Delete Data

  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}/api/v2/rianaire/our-work-categories/delete/${id}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data.message,
            confirmButtonColor: "#5eba86",
          });
          fetchData();
        });
    }
  };

  useEffect(() => {
    const result = tableData?.filter((data) => {
      return data.name.toLowerCase().match(search.toLowerCase());
    });
    setFilteredData(result);
  }, [search]);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar pageTitle="All Service" />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                {!isLoading && !error && tableData?.length > 0 && (
                  <div className="custom_table mb-5">
                    <Row className="flex_between">
                      <Col md={8} className="mb-2">
                        <Link
                          to="/admin/service-module/service/add-new"
                          state={{ parentService }}
                        >
                          <Button variant="success" size="sm">
                            Add &nbsp;
                            <span>
                              <AiOutlinePlusCircle className="mb-1" />
                            </span>
                          </Button>
                        </Link>
                      </Col>
                      <Col md={4} className="mb-2">
                        <Form.Control
                          type="text"
                          placeholder="search here..."
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <div className="table_fixed">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Handle</th>
                            <th>Name</th>
                            <th>Parent</th>
                            <th>Image</th>
                          </tr>
                        </thead>

                        <tbody>
                          {filteredData
                            // .slice(
                            //   page * rowsPerPage,
                            //   page * rowsPerPage + rowsPerPage
                            // )
                            .map((data, index) => (
                              <>
                                <tr key={index}>
                                  <td>
                                    <Dropdown style={{ position: "unset" }}>
                                      <Dropdown.Toggle>
                                        <BsThreeDotsVertical color="#000" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        {/* view */}
                                        <Dropdown.Item
                                          as={Link}
                                          to={`/admin/service-module/service/view/${data?.id}`}
                                        >
                                          View
                                        </Dropdown.Item>
                                        {/* edit */}
                                        <Dropdown.Item
                                          as={Link}
                                          to={`/admin/service-module/service/edit/${data?.id}`}
                                          state={{ parentService }}
                                        >
                                          Edit
                                        </Dropdown.Item>
                                        {/* delete */}

                                        <Dropdown.Item
                                          onClick={() => deleteData(data.id)}
                                        >
                                          Delete
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                  <td>{data?.name}</td>
                                  <td>{data?.parent?.name}</td>
                                  <td>
                                    <img
                                      src={`${BACKEND_BASE_URL}/${data?.image}`}
                                      alt=""
                                      width={80}
                                    />
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="mt-3 mui_pagination">
                      <TablePagination
                        component="div"
                        count={filteredData.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[10, 20, 50]}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        showFirstButton
                        showLastButton
                      />
                    </div> */}
                  </div>
                )}

                {isLoading && !error && <LinearProgress />}
                {!isLoading && !error && tableData?.length < 1 && (
                  <div className="text-center text-danger my-5 py-5">
                    <h1>No data Found</h1>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminServiceAll;
