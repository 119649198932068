import { LinearProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import { AiOutlineClose, AiOutlinePlusCircle } from "react-icons/ai";
import { FaCheck, FaEye } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import getCookie from "../../../../Component/GetCookie";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const ExploreOpeningsAll = () => {
  const [tableData, setTableData] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);
  const [allLocations, setAllLocations] = useState([]);

  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/explore-openings/job-posts`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setTableData(res.data?.all_job_posts);
        setAllDepartments(res.data?.all_departments);
        setAllLocations(res.data?.all_locations);
      });
  };

  const StatusUpdate = (id) => {
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/explore-openings/job-posts/status-update/${id}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        renderAllInfo();
      });
  };
  // Delete Data
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}/api/v2/rianaire/explore-openings/job-posts/delete/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllInfo();
        });
    }
  };

  useEffect(() => {
    renderAllInfo();
  }, []);
  return (
    <div className="main__container ">
      <div className="body-wrapper">
        <AdminDashboardNavbar />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="card-header flex_between">
                <h3>Job Openings</h3>
                <Link
                  to={`/admin/career/explore-openings/add-new`}
                  state={{ allDepartments, allLocations }}
                >
                  <Button variant="success" size="sm">
                    Add &nbsp;
                    <span>
                      <AiOutlinePlusCircle className="mb-1" />
                    </span>
                  </Button>
                </Link>
              </div>
              {tableData.length > 0 ? (
                <div className="table-responsive custom_table">
                  <div className="table_fixed">
                    <table className="table table-hover my-5">
                      <thead>
                        <tr>
                          <th scope="col">Handle</th>
                          <th scope="col">Title</th>
                          <th scope="col">Department</th>
                          <th scope="col">Location</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.map((data, index) => (
                          <tr key={index}>
                            <td>
                              <Dropdown style={{ position: "unset" }}>
                                <Dropdown.Toggle>
                                  <BsThreeDotsVertical color="#000" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {/* view */}
                                  <Dropdown.Item
                                    as={Link}
                                    to={`/admin/career/explore-openings/view/${data.id}`}
                                    className="text-black"
                                  >
                                    View
                                  </Dropdown.Item>
                                  {/* edit */}
                                  <Dropdown.Item
                                    as={Link}
                                    to={`/admin/career/explore-openings/edit/${data.id}`}
                                    state={{ allDepartments, allLocations }}
                                    className="text-black"
                                  >
                                    Edit
                                  </Dropdown.Item>

                                  {/* delete */}

                                  <Dropdown.Item
                                    onClick={() => deleteData(data.id)}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td>{data?.title}</td>
                            <td>{data?.department?.department_name}</td>
                            <td>{data?.location?.country_name}</td>

                            <td>
                              {data.status == 1 ? (
                                <Link
                                  to=""
                                  onClick={() => StatusUpdate(data.id)}
                                >
                                  <FaCheck
                                    style={{
                                      color: "white",
                                      backgroundColor: "green",

                                      padding: "2px 4px",
                                      borderRadius: "4px",
                                    }}
                                    size="1.6em"
                                  />
                                </Link>
                              ) : (
                                <Link
                                  to=""
                                  onClick={() => StatusUpdate(data.id)}
                                >
                                  <AiOutlineClose
                                    style={{
                                      color: "white",
                                      backgroundColor: "red",

                                      padding: "2px 4px",
                                      borderRadius: "4px",
                                    }}
                                    size="1.6em"
                                  />
                                </Link>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <LinearProgress />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreOpeningsAll;
