import axios from "axios";
import dayjs from "dayjs";
import { useMemo } from "react";
import { createContext, useEffect, useReducer, useState } from "react";
import Swal from "sweetalert2";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";

export const CalenderContext = createContext({
    monthIndex: 0,
    setMonthIndex: () => { },
    smallCalenderMonth: 0,
    setSmallCalenderMonth: (index) => { },
    daySelected: null,
    setDayselected: (day) => { },
    showEventModal: false,
    setShowEventModal: () => { },
    dispatchCalEvent: ({ type, payload }) => { },
    savedEvents: [],
    selectedEvent: null,
    setSelectedEvent: () => { },
    setLabels: () => { },
    labels: [],
    updateLabel: () => { },
    filteredEvents: [],

})

function savedEventRenderer(state, { type, payload }) {
    switch (type) {
        case "push":
            return [...state, payload]
        case "update":
            return state.map(event => event.id === payload.id ? payload : event)
        case "delete":
            return state.filter(event => event.id !== payload.id)
        default:
            throw new Error("")

    }
}

function initEvents() {
    const storageEvents = localStorage.getItem("savedEvents")
    const parsedEvents = storageEvents ? JSON.parse(storageEvents) : []
    return parsedEvents;
}


export function Contextwrapper(props) {
    const [monthIndex, setMonthIndex] = useState(dayjs().month())
    const [smallCalenderMonth, setSmallCalenderMonth] = useState(null)
    const [daySelected, setDayselected] = useState(dayjs())
    const [dayEventsApi, setDayEventsApi] = useState([])
    const [showEventModal, setShowEventModal] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [labels, setLabels] = useState([])
    const [savedEvents, dispatchCalEvent] = useReducer(savedEventRenderer, [], initEvents)
    const filteredEvents = useMemo(() => {
        return savedEvents.filter(evt => labels.filter(lbl => lbl.checked).map(lbl => lbl.label).includes(evt.label))
    }, [savedEvents, labels])

    useEffect(() => {
        setLabels((prevLabels) => {
            return [...new Set(savedEvents.map(evt => evt.label))].map((label) => {
                const currentLabel = prevLabels.find(lbl => lbl.label === label);
                return {
                    label,
                    checked: currentLabel ? currentLabel.checked : true
                }
            })
        })
    }, [savedEvents])

    useEffect(() => {
        localStorage.setItem('savedEvents', JSON.stringify(savedEvents))
    }, [savedEvents])

    useEffect(() => {
        if (smallCalenderMonth !== null) {
            setMonthIndex(smallCalenderMonth)
        }
    }, [smallCalenderMonth])

    useEffect(() => {
        if (!showEventModal) {
            setSelectedEvent(null)
        }
    }, [showEventModal])

    function updateLabel(label) {
        setLabels(labels.map((lbl) => lbl.label === label.label ? label : lbl))
    }


    return (
        <CalenderContext.Provider value={{ monthIndex, setMonthIndex, smallCalenderMonth, setSmallCalenderMonth, daySelected, setDayselected, showEventModal, setShowEventModal, dispatchCalEvent, savedEvents, selectedEvent, setSelectedEvent, labels, setLabels, updateLabel, filteredEvents ,dayEventsApi, setDayEventsApi }}>
            {props.children}
        </CalenderContext.Provider>
    )
}