import axios from "axios";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { FiSave } from "react-icons/fi";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import getCookie from "../../../../Component/GetCookie";

import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const ServiceDesignTypeAdd = () => {
  const serviceName = useRef();
  const subServiceName = useRef();
  const dropdownParent = useRef();
  const name = useRef();

  const [serviceCategory, setServiceCategory] = useState([]);
  const [subServiceCategory, setSubServiceCategory] = useState([]);
  const [customDropdownParent, setCustomDropdownParent] = useState([]);

  const [customDropdownParentBox, setCustomDropdownParentBox] = useState(false);

  const fetchServiceCategory = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/get-order-category`)
      .then((res) => {
        setServiceCategory(res.data?.get_category);
      });
  };

  const fetchSubServiceCategory = (e) => {
    setSubServiceCategory([]);

    e.preventDefault();
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/get-sub-services/${serviceName.current.value}`
      )
      .then((res) => {
        setSubServiceCategory(res.data?.get_sub_services);
      });
  };

  const fetchCustomParentDropdown = async () => {
    await axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/get-drop-parent/${subServiceName.current.value}`
      )
      .then((res) => {
        setCustomDropdownParent(res.data?.get_parent);
      });
    if (subServiceName.current.value == 8) {
      setCustomDropdownParentBox(true);
    } else {
      setCustomDropdownParentBox(false);
    }
  };
  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();
    formdata.append("name", name.current.value);
    if (customDropdownParentBox) {
      formdata.append("parent_id", dropdownParent.current.value);
    } else {
      formdata.append("parent_id", "0");
    }
    formdata.append("service_id", serviceName.current.value);
    formdata.append("sub_service_id", subServiceName.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/service-cost/design-type/store`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((response) => {
        if (response.data?.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          e.target.reset();
        }
      });
    e.preventDefault();
  };

  useEffect(() => {
    fetchServiceCategory();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_end mb-5">
                  <Link to="/admin/service-module/design-type">
                    <Button variant="success" size="sm">
                      All Service Design Type &nbsp;
                    </Button>
                  </Link>
                </div>
                <Form onSubmit={storeData}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3">
                          {/* Service Name */}
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Department Name
                            </Form.Label>
                            <Form.Select
                              aria-label="language example "
                              className=""
                              ref={serviceName}
                              onChange={fetchSubServiceCategory}
                            >
                              <option value="" disabled selected>
                                Select Department
                              </option>
                              {serviceCategory.map((parentCategory) => {
                               
                                return (
                                  <option
                                    key={parentCategory.id}
                                    value={parentCategory.id}
                                  >
                                    {parentCategory.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>

                          {/*Sub Service Name */}

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Service Name
                            </Form.Label>
                            <Form.Select
                              aria-label="language example "
                              className=""
                              ref={subServiceName}
                              onChange={fetchCustomParentDropdown}
                            >
                              <option value="" disabled selected>
                                Select Service
                              </option>
                              {subServiceCategory.length > 0 &&
                                subServiceCategory?.map((subCategory) => (
                                  <option
                                    key={subCategory.id}
                                    value={subCategory.id}
                                  >
                                    {subCategory.name}
                                  </option>
                                ))}
                            </Form.Select>
                          </Form.Group>

                          {/* add Section */}
                          {customDropdownParentBox == true && (
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Custom Dropdown Parent
                              </Form.Label>
                              <Form.Select
                                aria-label="language example "
                                className=""
                                ref={dropdownParent}
                              >
                                <option value="0" disabled selected>
                                  Select Custom Dropdown Parent
                                </option>
                                {customDropdownParent.length > 0 &&
                                  customDropdownParent?.map((parent) => (
                                    <option key={parent.id} value={parent.id}>
                                      {parent.name}
                                    </option>
                                  ))}
                              </Form.Select>
                            </Form.Group>
                          )}

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Custom Dropdown Name
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Custom Dropdown Name"
                              ref={name}
                            />
                          </Form.Group>
                        </div>
                        <div className="flex_center">
                          <Button
                            type="submit"
                            className="btn-submit mt-4 rounded-3 border-0"
                          >
                            <FiSave /> &nbsp; Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDesignTypeAdd;
