import axios from "axios";
import { useEffect, useState } from "react";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import getCookie from "../../../Component/GetCookie";
import Swal from "sweetalert2";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import { Col, Dropdown, Form, Row } from "react-bootstrap";
import { MdAdd } from "react-icons/md";
import { AiOutlineCaretDown } from "react-icons/ai";

import LinearProgressMui from "../../../Component/Custom Loader/LinearProgress";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";

const WebsiteBrandingProposal = () => {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAllInfo = async () => {
    setIsLoading(true);
    await axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/website-redesign-branding-proposal`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        setTableData(res.data?.data);
        console.log(res.data?.data);
      });
  };

  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}/api/v2/rianaire/website-redesign-branding-proposal/delete/${id}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: "Deleted successfully",
            confirmButtonColor: "#5eba86",
          });
          fetchAllInfo();
        });
    }
  };

  useEffect(() => {
    fetchAllInfo();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar pageTitle="Website Maintenance Domains" />
      </div>
      <div className="col-md-12 p-4">
        <div className="card">
          <div className="card-body">
            <div className="col-lg-12">
              <div className=""></div>
              {!isLoading && tableData?.length > 0 && (
                <div className="table-responsive custom_table">
                  <div className="table_fixed">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th className="">Handle</th>
                          <th className="">Domains</th>
                        </tr>
                      </thead>

                      <tbody className="">
                        {tableData.map((data, index) => (
                          <tr key={index}>
                            <td>
                              <Dropdown style={{ position: "unset" }}>
                                <Dropdown.Toggle>
                                  <span
                                    style={{
                                      backgroundColor: "#eeeeee",
                                      padding: "8px 12px",
                                      borderRadius: "8px",
                                    }}
                                  >
                                    <MdAdd color="#000" className="me-2" />
                                    <AiOutlineCaretDown color="#000" />
                                  </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    key={index}
                                    onClick={() => deleteData(data.id)}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td>{data?.domain}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              {isLoading && <LinearProgressMui />}
              {!isLoading && tableData?.length < 1 && (
                <>
                  <div className="text-center text-danger">
                    <h1>No data Found</h1>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteBrandingProposal;
