import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import { LinearProgress } from "@mui/material";
import Swal from "sweetalert2";
import moment from "moment";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";
import { ToastAlert } from "../../../Component/ToastAlert";
import Select from "react-select";

const AdminOrderQuoteAll = () => {
  const [allQuotes, setAllQuotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [allEmployeeInfo, setAllEmployeeInfo] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);

  let fetchPermissions = localStorage.getItem("LOGGED_IN_USER_PERMISSION_INFO");

  // Get All Blogs
  const renderAllInfo = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v3/rianaire/order`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setSelectedEmployee(
          res.data?.orders?.data.map((data) => data.employees)
        );
        setAllQuotes(res.data?.orders.data);
      });
  };

  const deleteData = async (id) => {
    console.log(id);
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(`${BACKEND_BASE_URL}/api/v2/rianaire/get-quote/delete/${id}`, {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        })
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllInfo();
        });
    }
  };

  const handleStatus = (status, id) => {
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v3/rianaire/orders/change-status/${id}/${status.value}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllInfo();
        }
      });
  };

  const fetchAllEmployee = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v3/rianaire/user/all`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setAllEmployeeInfo(res.data?.all_users);
      });
  };

  const handleEmployeeAssign = (info, orderId) => {
    const formdata = new FormData();
    const employeeId = info.map((info) => info.value);
    console.log(employeeId);

    employeeId.forEach((item) => {
      formdata.append("user_ids[]", item);
    });
    formdata.append("order_id", orderId);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v3/rianaire/order/assign/employee-to-order`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            title: response.data?.message,
          });
        } else {
          ToastAlert.fire({
            icon: "error",
            title: response.data?.message,
          });
        }
      });
  };

  useEffect(() => {
    renderAllInfo();
    fetchAllEmployee();
  }, []);

  useEffect(() => {
    let a = [];
    selectedEmployee.forEach((item) => {
      // a.push(item.permission_id);
    });

    // setSinglePermissionChecked(a);
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar pageTitle="All Quote" />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                </div>
                {!isLoading && !error && allQuotes.length > 0 && (
                  <div className="table-responsive custom_table">
                    <div className="table_fixed">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th scope="col">Handle</th>

                            {/* <th scope="col">Service</th> */}
                            {/* <th scope="col">Sub-Service</th> */}
                            {/* <th scope="col">Service Type</th> */}

                            {/* <th scope="col">Total USD Price</th>
                            <th scope="col">Total BDT Price</th>
                            <th scope="col">Total Duration</th> */}
                            <th scope="col">Name</th>
                            <th scope="col">Company</th>
                            <th scope="col">Status</th>
                            <th scope="col">Assignee</th>
                            {/* <th scope="col">Email</th> */}
                            <th scope="col">Quote Date</th>
                            {/* <th scope="col">Phone</th> */}
                            {/* <th scope="col">Status</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {allQuotes?.map((quoteData) => (
                            <tr key={quoteData.id}>
                              <td>
                                <Dropdown style={{ position: "unset" }}>
                                  <Dropdown.Toggle>
                                    <BsThreeDotsVertical color="#000" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {/* view */}
                                    {JSON.parse(fetchPermissions)?.map(
                                      (data, index) =>
                                        data?.permission_name === "view" && (
                                          <Dropdown.Item
                                            as={Link}
                                            to={`/admin/order-quote/view/${quoteData?.id}`}
                                            className="text-black"
                                          >
                                            View
                                          </Dropdown.Item>
                                        )
                                    )}
                                    {JSON.parse(fetchPermissions)?.map(
                                      (data, index) =>
                                        data?.permission_name === "delete" && (
                                          <Dropdown.Item
                                            onClick={() =>
                                              deleteData(quoteData.id)
                                            }
                                          >
                                            Delete
                                          </Dropdown.Item>
                                        )
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              {/* <td>{quoteData?.main_service_info?.name}</td>
                              <td>{quoteData?.sub_service_info?.name}</td>
                              <td>
                                {quoteData?.child_service_info?.name
                                  ? quoteData?.child_service_info?.name
                                  : " ----"}
                              </td>

                              <td className="text-end">
                                $ {quoteData?.total_usd_price}
                              </td>
                              <td className="text-end">
                                <span
                                  className="fs-5"
                                  style={{ lineHeight: "0" }}
                                >
                                  &#x09F3;
                                </span>{" "}
                                {quoteData?.total_bdt_price}
                              </td>
                              <td className="text-center">
                                {quoteData?.total_duration}
                              </td> */}

                              <td>{quoteData?.name}</td>
                              <td>{quoteData?.company}</td>
                              {/* <td>{quoteData?.email}</td> */}
                              <td>
                                <Form.Group className="position-relative">
                                  <Select
                                    // isDisabled
                                    options={[
                                      {
                                        value: "pending",
                                        label: "Pending",
                                      },
                                      {
                                        value: "review",
                                        label: "Review",
                                      },
                                      {
                                        value: "accepted",
                                        label: "Accepted",
                                      },
                                      {
                                        value: "hold",
                                        label: "Hold",
                                      },
                                      {
                                        value: "rejected",
                                        label: "Rejected",
                                      },
                                      {
                                        value: "delivered",
                                        label: "Delivered",
                                      },
                                      {
                                        value: "completed",
                                        label: "Completed",
                                      },
                                    ]}
                                    defaultValue={
                                      (quoteData?.order_status == "pending" && {
                                        label: "Pending",
                                      }) ||
                                      (quoteData?.order_status == "review" && {
                                        label: "Review",
                                      }) ||
                                      (quoteData?.order_status ==
                                        "accepted" && {
                                        label: "Accepted",
                                      }) ||
                                      (quoteData?.order_status == "hold" && {
                                        label: "Hold",
                                      }) ||
                                      (quoteData?.order_status ==
                                        "rejected" && {
                                        label: "Rejected",
                                      }) ||
                                      (quoteData?.order_status ==
                                        "delivered" && {
                                        label: "Delivered",
                                      }) ||
                                      (quoteData?.order_status ==
                                        "completed" && {
                                        label: "Completed",
                                      })
                                    }
                                    placeholder="Select Status"
                                    classNamePrefix="react-select"
                                    onChange={(value = "") => {
                                      handleStatus(value, quoteData.id);
                                    }}
                                  />
                                </Form.Group>
                              </td>
                              <td>
                                <Form.Group className="position-relative">
                                  <Select
                                    isMulti
                                    isDisabled={
                                      sessionStorage.getItem(
                                        "LOGGED_IN_ADMIN_ID"
                                      ) == 35
                                        ? "disabled"
                                        : ""
                                    }
                                    name="colors"
                                    options={allEmployeeInfo?.map(
                                      (employee) => {
                                        return {
                                          label: employee.name,
                                          value: employee.id,
                                        };
                                      }
                                    )}
                                    defaultValue={allEmployeeInfo?.map(
                                      (employee) => {
                                        if (
                                          employee?.id == quoteData?.employee_id
                                        ) {
                                          return {
                                            label: employee?.name,
                                            value: employee?.id,
                                          };
                                        }
                                      }
                                    )}
                                    placeholder="Select Assignee"
                                    classNamePrefix="react-select"
                                    onChange={(e) =>
                                      handleEmployeeAssign(e, quoteData.id)
                                    }
                                    // hideSelectedOptions={false}
                                  />
                                </Form.Group>
                              </td>
                              <td>
                                {" "}
                                {moment(quoteData?.created_at).format(
                                  "DD MMM, Y H:mm"
                                )}{" "}
                              </td>
                              {/* <td>{quoteData?.phone}</td> */}
                        
                            </tr>
                          ))}
                        </tbody>

                        {!isLoading && !error && allQuotes?.length < 1 && (
                          <>
                            <div className="text-center text-danger">
                              <h1>No data Found</h1>
                            </div>
                          </>
                        )}
                      </table>
                    </div>
                  </div>
                )}
                {isLoading && !error && <LinearProgress />}
                {!isLoading && !error && allQuotes?.length < 1 && (
                  <div className="text-center text-danger my-5 py-5">
                    <h1>No data Found</h1>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminOrderQuoteAll;
