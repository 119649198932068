import axios from "axios";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { AiOutlineCaretDown } from "react-icons/ai";
import { MdAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import { ToastAlert } from "../../../Component/ToastAlert";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";
import LinearProgressMui from "../../../Component/Custom Loader/LinearProgress";
import { FiEdit2 } from "react-icons/fi";
import "./employee.css";

const Employee = () => {
  const Role = useRef();
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [search, setSearch] = useState("");

  const [allRoles, setRoles] = useState([]);

  // Get All Info
  const renderAllInfo = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/employee`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setTableData(res.data?.all_employee_info);
        setFilteredData(res.data?.all_employee_info);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(true);
      });
  };

  const renderAllRoles = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/roles`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setRoles(res.data?.all_roles);
      });
  };

  useEffect(() => {
    renderAllInfo();
    renderAllRoles();
  }, []);

  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(`${BACKEND_BASE_URL}/api/v2/rianaire/employee/delete/${id}`, {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        })
        .then((res) => {
          if (res.data?.status === 200) {
            ToastAlert.fire({
              icon: "success",
              title: "employee deleted successfully",
            });
          }
          renderAllInfo();
        });
    }
  };

  useEffect(() => {
    const result = tableData.filter((data) => {
      return data?.name.toLowerCase().match(search.toLowerCase());
    });
    setFilteredData(result);
  }, [search]);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar pageTitle="All Employee" />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  {/* {JSON.parse(
                        getCookie("LOGGED_IN_USER_PERMISSION_INFO")
                      )?.map(
                        (data, index) =>
                          data?.permission_name === "create-service-cost" && (
                            <Button variant="success" size="sm">
                              Add &nbsp;
                              <span>
                                <AiOutlinePlusCircle className="mb-1" />
                              </span>
                            </Button>
                          )
                      )} */}
                </div>

                <div className="table-responsive custom_table ">
                  <div className="table_fixed">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">
                            <Dropdown style={{ position: "unset" }}>
                              <Dropdown.Toggle className="table_header"
                                style={{
                                  backgroundColor: "#eeeeee",
                                  padding: "8px 12px",
                                  borderRadius: "8px",
                                }}
                              >
                                <MdAdd color="#000" className="me-2" />
                                <AiOutlineCaretDown color="#000" />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item>
                                  {/* {JSON.parse(
                                        localStorage.getItem(
                                          "LOGGED_IN_USER_PERMISSION_INFO"
                                        )
                                      )?.map(
                                        (data, index) =>
                                          data?.permission_name ===
                                            "create-service-cost" && ( */}
                                  <Link
                                    to="/admin/employee/add-new"
                                    className="text-black"
                                  >
                                    Add &nbsp;
                                  </Link>
                                  {/* )
                                      )} */}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </th>
                          <th scope="col">Profile Photo</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Phone</th>
                          <th scope="col">Assign Role</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading &&
                          !error &&
                          tableData?.map((data, i) => (
                            <tr key={data.id}>
                              <td>
                                <Dropdown style={{ position: "unset" }}>
                                  <Dropdown.Toggle
                                    style={{
                                      backgroundColor: "#eeeeee",
                                      padding: "8px 12px",
                                      borderRadius: "8px",
                                    }}
                                  >
                                    <FiEdit2 color="#000" className="me-2" />
                                    <AiOutlineCaretDown color="#000" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {/* edit */}
                                    <Dropdown.Item
                                      as={Link}
                                      to={`/admin/employee/edit/${data?.id}`}
                                      state={{ data: allRoles }}
                                    >
                                      Edit
                                    </Dropdown.Item>
                                    {/* delete */}

                                    <Dropdown.Item
                                      onClick={() => deleteData(data.id)}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>

                              <td>
                                {data?.profile_photo != null ? (
                                  <img
                                    src={`${BACKEND_BASE_URL}/${data?.profile_photo}`}
                                    alt=""
                                    width={40}
                                    height={40}
                                    style={{
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../Assets/avatar.png")}
                                    alt=""
                                    width={40}
                                    height={40}
                                  />
                                )}
                              </td>
                              <td>{data?.name}</td>
                              <td>{data?.email}</td>
                              <td>{data?.phone ? data.phone : "-"}</td>
                              <td>
                                <Form.Select
                                  disabled
                                  name="department"
                                  ref={Role}
                                  className="w-75"
                                >
                                  <option value="">Select Role</option>
                                  {allRoles?.map((role) => (
                                    <option
                                      value={role.id}
                                      key={role.id}
                                      selected={
                                        role.id == data?.role_id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {role?.name}
                                    </option>
                                  ))}
                                </Form.Select>
                              </td>
                            </tr>
                          ))}
                        {isLoading && !error && (
                          <tr>
                            <td colspan="6">
                              <LinearProgressMui />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                {!isLoading && !error && tableData?.length < 1 && (
                  <>
                    <div className="text-center text-danger">
                      <h1>No data Found</h1>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Employee;
