import Swal from "sweetalert2";

const ToastAlert = Swal.mixin({
  toast: true,
  position: "bottom-start",
  showConfirmButton: false,
  customClass:"custom_sweetalert",
  timer: 3000,
  // timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export { ToastAlert };
