import React from "react";
import axios from "axios";
import { useRef, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Button, Col, Form } from "react-bootstrap";
import { FiSave } from "react-icons/fi";
import { LinearProgress } from "@mui/material";
import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import getCookie from "../../../../Component/GetCookie";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const TechUseCategoryEdit = () => {
  const { categoryId } = useParams();
  const name = useRef();

  // single table data
  const [singleData, setSingleData] = useState([]);

  // Get Single Info
  const renderSingleData = async () => {
    await axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/tech-use/category/edit/${categoryId}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        setSingleData(res.data?.edit_tech_use_category_info);
      });
  };

  // Updated data to backend
  const updatedData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("name", name.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/tech-use/category/update/${singleData?.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          e.target.reset();
          renderSingleData();
        } else {
          Swal.fire({
            icon: "warning",
            text: response.data.errors.name[0],
            confirmButtonColor: "#5eba86",
          });
        }
      });

    e.preventDefault();
  };
  useEffect(() => {
    renderSingleData();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_end mb-5">
                  <Link to="/admin/tech-use-category">
                    <Button variant="success" size="sm">
                      View All Category &nbsp;
                    </Button>
                  </Link>
                </div>
                {singleData ? (
                  <Form onSubmit={updatedData}>
                    <div className="content-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <div className="row py-3">
                            {/* add News title */}
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                News Title
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="name"
                                ref={name}
                                defaultValue={singleData?.name}
                              />
                            </Form.Group>

                            <Button
                              type="submit"
                              className="btn-submit mt-5 rounded-3 border-0"
                            >
                              <FiSave /> &nbsp; Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                ) : (
                  <LinearProgress />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechUseCategoryEdit;
