import axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import { LinearProgress } from "@mui/material";
import { Col, Dropdown } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";
import Swal from "sweetalert2";

const AdminAppointments = () => {
  // Initial table data
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  // Get All Info
  const renderAllInfo = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/appointments`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setTableData(res.data?.all_info);
        setFilteredData(res.data?.all_info);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(true);
      });
  };

  // Delete appointment
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}/api/v2/rianaire/appointments/delete/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllInfo();
        });
    }
  };

  useEffect(() => {
    // renderAllInfo();
    renderAllInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar pageTitle="Appointments" />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header"></div>

                <div className="py-5">
                  <div className="custom_table table-responsive">
                    <div className="table_fixed">
                      <table className="table ">
                        <thead>
                          <tr>
                            <th>Handle</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Appointment time zone</th>
                            <th>Appointment from time</th>
                            <th>Appointment to time</th>
                            <th>Appointment Date</th>
                            <th>Company Name</th>
                            <th>Company Region</th>
                            <th>Job Title</th>
                            <th>No of Employee</th>
                            <th>Website</th>
                            <th>Design Completed By</th>
                          </tr>
                        </thead>
                        {!isLoading && !error && tableData.length > 0 && (
                          <tbody>
                            {filteredData
                              // .slice(
                              //   page * rowsPerPage,
                              //   page * rowsPerPage + rowsPerPage
                              // )
                              ?.map((data, index) => (
                                <tr key={index}>
                                  <td>
                                    <Dropdown style={{ position: "unset" }}>
                                      <Dropdown.Toggle>
                                        <BsThreeDotsVertical color="#000" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        {/* view */}
                                        <Dropdown.Item
                                          as={Link}
                                          to={`/admin/appointments/view/${data?.id}`}
                                        >
                                          View
                                        </Dropdown.Item>
                                        {/* delete */}

                                        <Dropdown.Item
                                          onClick={() => deleteData(data.id)}
                                        >
                                          Delete
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                  <td>{data?.name}</td>
                                  <td>{data?.email}</td>
                                  <td>{data?.phone_number}</td>
                                  <td>{data?.applicant_time_zone}</td>
                                  <td>{data?.appointment_from_time}</td>
                                  <td>{data?.appointment_to_time}</td>
                                  <td>{data?.appointment_date}</td>
                                  <td>{data?.company_name}</td>
                                  <td>{data?.company_country_or_region}</td>
                                  <td>{data?.job_title}</td>
                                  <td>{data?.number_of_employee}</td>
                                  <td>{data?.website_address}</td>
                                  <td>{data?.designs_completed_by}</td>

                                  {/* <td>
                                    {moment(data?.created_at).format(
                                      "DD MMM, Y"
                                    )}
                                  </td> */}
                                </tr>
                              ))}
                          </tbody>
                        )}
                      </table>

                      {isLoading && !error && <LinearProgress />}
                      {!isLoading && !error && tableData?.length < 1 && (
                        <div className="text-center text-danger py-5">
                          <h1>No data Found</h1>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAppointments;
