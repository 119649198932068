import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FiSave } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import getCookie from "../../../Component/GetCookie";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import RichTextEditor from "../../../Component/RichTextEditor";
import { ToastAlert } from "../../../Component/ToastAlert";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";

const AdminTeamEdit = () => {
  const { teamId } = useParams();
  const name = useRef();
  const image = useRef();
  const designation = useRef();
  const description = useRef();

  // Image Preview
  const [files, setFile] = useState([]);

  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  const [value, setValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };
  // single table data
  const [singleData, setSingleData] = useState([]);

  // Get Single Info
  const renderSingleData = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/team-member/edit/${teamId}`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setSingleData(res.data?.edit_info);
      });
  };

  // form submit to backend
  const updateData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("name", name.current.value);
    if (image.current.files[0]) {
      formdata.append("image", image.current.files[0]);
    }
    formdata.append("designation", designation.current.value);
    formdata.append("description", description.current.value);
    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/team-member/update/${singleData?.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            icon: "",
            title: "updated successfully",
          });
          renderSingleData();
        } else {
          ToastAlert.fire({
            icon: "warning",
            text: "Something went wrong, try again",
            confirmButtonColor: "#5eba86",
          });
        }
      });
    e.preventDefault();
  };

  useEffect(() => {
    renderSingleData();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Add Team member</h3>
                  <Link to="/admin/team">
                    <Button variant="success" size="sm">
                      All Team member &nbsp;
                    </Button>
                  </Link>
                </div>
                <Row>
                  <form onSubmit={updateData}>
                    <div className="content-wrapper">
                      <div className="row py-3">
                        {/* Name */}
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                          className="mb-3"
                        >
                          <Form.Label className="label fw-bold">
                            Name <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Name"
                            ref={name}
                            defaultValue={singleData?.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            name is required
                          </Form.Control.Feedback>
                        </Form.Group>

                        {/* content Image */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <div>
                              <label className="label fw-bold">
                                Image
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className=" image-file">
                              <input
                                type="file"
                                className="form-control"
                                onChange={handleImgPreview}
                                ref={image}
                                defaultValue={singleData?.image}
                              />
                              {files.map((file, key) => {
                                return (
                                  <div key={key} className="Row">
                                    <span className="Filename">
                                      <img
                                        width={80}
                                        height={50}
                                        src={URL.createObjectURL(file)}
                                        alt={file.name}
                                      />
                                    </span>
                                  </div>
                                );
                              })}
                              {files.length == 0 && (
                                <img
                                  className="img-thumbnail"
                                  width={80}
                                  height={70}
                                  src={`${BACKEND_BASE_URL}${singleData?.image}`}
                                  alt={singleData?.name}
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        {/* Designation */}
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                          className="mb-3"
                        >
                          <Form.Label className="label fw-bold">
                            Designation
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Designation"
                            ref={designation}
                            defaultValue={singleData?.designation}
                          />
                          <Form.Control.Feedback type="invalid">
                            Designation is required
                          </Form.Control.Feedback>
                        </Form.Group>

                        {/* Project Description */}
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom02"
                          className="mb-3"
                        >
                          <Form.Label className="label fw-bold">
                            Description
                          </Form.Label>
                          <RichTextEditor
                            joditRef={description}
                            getValue={getValue}
                            value={singleData?.description}
                          />
                        </Form.Group>

                        {/* Submit button */}
                        <button
                          type="submit"
                          className="btn-submit mt-5 rounded-3 border-0"
                        >
                          <FiSave /> &nbsp; Update
                        </button>
                      </div>
                    </div>
                  </form>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTeamEdit;
