import React from "react";

const RabbitSVG = ({ width = 40, height = 40 }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width={width}
        height={height}
        x="0"
        y="0"
        viewBox="0 0 512 512"
      >
        <g>
          <g>
            <path
              d="m369.321 485.595c84.536-41.804 142.679-128.903 142.679-229.595 0-141.385-114.615-256-256-256s-256 114.615-256 256c0 100.692 58.143 187.791 142.679 229.595z"
              fill="#6595f5"
              data-original="#1dd882"
            ></path>
            <path
              d="m504.375 193.804-137.096-137.227-2.212-2.082-37.421 146.251-144.56-144.692-1.692-1.559-30.873 284.573 12.273 12.273 23.831-7.353 113.85 13.683 68.844 127.91c84.536-41.8 142.681-128.89 142.681-229.581 0-21.453-2.654-42.283-7.625-62.196z"
              fill="#5d6ed3"
              data-original="#0caa5f"
            ></path>
            <g>
              <path
                d="m204.579 137.021c0 59.732-18.095 127.002-40.416 127.002s-40.416-67.27-40.416-127.002 18.095-89.308 40.416-89.308 40.416 29.576 40.416 89.308z"
                fill="#e9edf5"
                data-original="#e9edf5"
              ></path>
            </g>
            <g>
              <path
                d="m186.616 145.687c-.351 33.02-10.748 70.1-23.087 69.969s-21.947-37.424-21.596-70.444 10.527-49.263 22.866-49.132c12.338.132 22.167 16.587 21.817 49.607z"
                fill="#cdd2e1"
                data-original="#cdd2e1"
              ></path>
            </g>
            <g>
              <path
                d="m307.421 137.021c0 59.732 18.095 127.002 40.416 127.002s40.416-67.27 40.416-127.002-18.095-89.308-40.416-89.308c-22.321.001-40.416 29.576-40.416 89.308z"
                fill="#cdd2e1"
                data-original="#cdd2e1"
              ></path>
            </g>
            <g>
              <path
                d="m325.384 145.687c.351 33.02 10.748 70.1 23.087 69.969s21.947-37.424 21.596-70.444-10.527-49.263-22.866-49.132c-12.338.132-22.167 16.587-21.817 49.607z"
                fill="#afb4c8"
                data-original="#afb4c8"
              ></path>
            </g>
            <path
              d="m184.299 463.544c0-39.599 32.102-71.701 71.701-71.701s71.701 32.102 71.701 71.701v38.26c14.491-4.22 28.458-9.67 41.763-16.259l-23.403-156.192h-180.122l-23.403 156.192c13.305 6.589 27.272 12.039 41.763 16.259z"
              fill="#e9edf5"
              data-original="#e9edf5"
            ></path>
            <path
              d="m256 391.843c39.599 0 71.701 32.102 71.701 71.701v38.26c14.491-4.22 28.458-9.67 41.763-16.259l-23.403-156.192h-90.075v62.491c.005 0 .009-.001.014-.001z"
              fill="#cdd2e1"
              data-original="#cdd2e1"
            ></path>
            <g>
              <path
                d="m381.541 287.176c0 61.042-56.207 83.508-125.541 83.508s-125.541-22.466-125.541-83.508 56.207-131.402 125.541-131.402 125.541 70.361 125.541 131.402z"
                fill="#ffffff"
                data-original="#ffffff"
              ></path>
            </g>
            <g>
              <path
                d="m256 155.774c-.005 0-.009 0-.014 0v214.91h.014c69.334 0 125.541-22.467 125.541-83.508s-56.207-131.402-125.541-131.402z"
                fill="#e9edf5"
                data-original="#e9edf5"
              ></path>
            </g>
            <g>
              <path
                d="m277.922 292.057c-8.588 0-35.256 0-43.844 0-16.95 0-30.69 13.741-30.69 30.69s13.74 30.69 30.69 30.69c8.588 0 16.337-3.514 21.908-9.199 5.57 5.685 13.348 9.199 21.936 9.199 16.95 0 30.69-13.741 30.69-30.69 0-16.95-13.74-30.69-30.69-30.69z"
                fill="#e9edf5"
                data-original="#e9edf5"
              ></path>
            </g>
            <g>
              <path
                d="m277.922 292.057c-4.296 0-13.117 0-21.936 0v52.182c.005-.005-.005.005 0 0 5.57 5.685 13.348 9.198 21.936 9.198 16.95 0 30.69-13.74 30.69-30.69s-13.74-30.69-30.69-30.69z"
                fill="#cdd2e1"
                data-original="#cdd2e1"
              ></path>
            </g>
            <g>
              <path
                d="m282.379 299.183c0 11.753-11.81 21.281-26.379 21.281s-26.379-9.528-26.379-21.281 11.81-12.702 26.379-12.702 26.379.949 26.379 12.702z"
                fill="#414952"
                data-original="#414952"
              ></path>
            </g>
            <g>
              <path
                d="m256 286.481c-.005 0-.009 0-.014 0v33.982h.014c14.569 0 26.379-9.528 26.379-21.281s-11.81-12.701-26.379-12.701z"
                fill="#23272b"
                data-original="#23272b"
              ></path>
            </g>
            <g>
              <g>
                <ellipse
                  cx="181.394"
                  cy="256.658"
                  fill="#555a66"
                  rx="17.267"
                  ry="17.267"
                  transform="matrix(.159 -.987 .987 .159 -100.886 394.842)"
                  data-original="#555a66"
                ></ellipse>
              </g>
              <g>
                <ellipse
                  cx="330.606"
                  cy="256.658"
                  fill="#333940"
                  rx="17.267"
                  ry="17.267"
                  transform="matrix(.159 -.987 .987 .159 24.553 542.149)"
                  data-original="#333940"
                ></ellipse>
              </g>
            </g>
            <g>
              <path
                d="m256 390.843c-39.599 0-71.701 32.102-71.701 71.701v39.279c22.751 6.625 46.811 10.177 71.701 10.177s48.95-3.552 71.701-10.177v-39.279c0-39.599-32.102-71.701-71.701-71.701z"
                fill="#ffffff"
                data-original="#ffffff"
              ></path>
            </g>
            <g>
              <path
                d="m256 390.843c-.005 0-.009 0-.014 0v121.157h.014c24.89 0 48.95-3.552 71.701-10.177v-39.279c0-39.599-32.102-71.701-71.701-71.701z"
                fill="#e9edf5"
                data-original="#e9edf5"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export { RabbitSVG };
