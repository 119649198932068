import React from "react";
import JoditEditor from "jodit-react";

// const config = {
//   // buttons: [
//   //   "bold",
//   //   "strikethrough",
//   //   "underline",
//   //   "italic",
//   //   "|",
//   //   "ul",
//   //   "ol",
//   //   "|",
//   //   "outdent",
//   //   "indent",
//   //   "|",
//   //   "font",
//   //   "fontsize",
//   //   "brush",
//   //   "paragraph",
//   //   "|",
//   //   "table",
//   //   "link",
//   //   "|",
//   //   "align",
//   //   "undo",
//   //   "redo",
//   //   "|",
//   //   "symbol",
//   //   "fullsize",
//   // ],
// };

const RichTextEditor = ({ value, getValue, joditRef, className }) => {

  return (
    <JoditEditor
      ref={joditRef}
      value={value}
      // config={config}
      tabIndex={1}
      onChange={(newContent) => getValue(newContent)}
      className={className}
    />
  );
};

export default RichTextEditor;
