import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Autocomplete, Button, TextField } from '@mui/material';
import { BsChevronDoubleRight, BsChevronRight } from "react-icons/bs"
import { FiSave } from "react-icons/fi"
import moment from "moment";
import Parse from "html-react-parser";
import RichTextEditor from '../../../../Component/RichTextEditor';
import Swal from 'sweetalert2';
import { BsFillTrashFill } from 'react-icons/bs';
import { useLocation } from 'react-router-dom'
import { getLocalStorageWithExpiry } from '../../../../Component/LocalStorageWithExpiry';
// import "./AsanaProjects.css"

const AsanaTasks = () => {
    const { state } = useLocation()
    const userInfo = state.userTask
    const buttonRef = useRef(null);
    const divRef = useRef(null);
    const taskDescRef = useRef(null);
    const [show, setShow] = useState(false)
    const [singleTaskInfo, setSingleTaskInfo] = useState([])
    const [userTasks, setUserTasks] = useState([])
    const [singleTaskId, setSingleTaskId] = useState()
    const apiKey = '1/1181186354658771:8ce46a50ca6dd71089785591c1f34bc6';

    const handleSingleTask = (taskId) => {
        setSingleTaskId(taskId)
        const singleTask = {
            method: 'GET',
            url: `https://app.asana.com/api/1.0/tasks/${taskId}`,
            headers: { Authorization: `Bearer ${apiKey}`, }
        };
        axios
            .request(singleTask)
            .then(function (response) {
                setSingleTaskInfo(response.data?.data);
            })

        // const taskComments = {
        //     method: 'GET',
        //     url: `https://app.asana.com/api/1.0/tasks/${taskId}/stories`,
        //     headers: { Authorization: `Bearer ${apiKey}`, }
        // };
        // axios
        //     .request(taskComments)
        //     .then(function (response) {
        //         setTaskComments(response.data?.data);
        //     })
    }

    // Task Submit
    // const storeTask = (e) => {
    //     axios
    //         .post(
    //             `https://app.asana.com/api/1.0/tasks`,
    //             {
    //                 data: {
    //                     workspace: '1181186357184897',
    //                     name: taskName?.current?.value,
    //                     assignee: assigneeName?.current?.value,
    //                     due_on: dueOn?.current?.value,
    //                     notes: taskDescRef?.current?.value
    //                 }
    //             },
    //             {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     Authorization: `Bearer ${apiKey}`,
    //                 },
    //             }
    //         )
    //         .then((response) => {
    //             if (response.status == 201) {
    //                 Swal.fire({
    //                     icon: "success",
    //                     text: "task created successfully",
    //                     confirmButtonColor: "#5eba86",
    //                 });
    //                 e.target.reset();
    //                 setTaskDesc("", "html");

    //             } else {
    //                 Swal.fire({
    //                     icon: "error",
    //                     text: response.data?.errors[0]?.message,
    //                     confirmButtonColor: "#5eba86",
    //                 });
    //             }

    //         });
    //     e.preventDefault();
    // };

    const deleteTask = async (taskId) => {
        const isConfirm = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "green",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            return result.isConfirmed;
        });

        if (!isConfirm) {
            return;
        }

        if (isConfirm) {
            axios
                .delete(`
                https://app.asana.com/api/1.0/tasks/${taskId}`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${apiKey}`,
                    },
                })
                .then((res) => {
                    Swal.fire({
                        icon: "success",
                        text: "comment deleted successfully",
                        confirmButtonColor: "#5eba86",
                    });
                    handleSingleTask()
                });
        }
    };

    useEffect(() => {
        const myTasks = {
            method: 'GET',
            url: `https://app.asana.com/api/1.0/tasks`,
            params: {
                assignee: `${getLocalStorageWithExpiry("adminUserInfo")?.email}`,
                workspace: '1181186357184897',
                opt_fields: 'projects.name,name,notes,due_on,completed_by.name,permalink_url'
            },
            headers: { Authorization: `Bearer ${apiKey}`, }
        };
        axios
            .request(myTasks)
            .then(function (response) {
                setUserTasks(response.data?.data);
            })
        handleSingleTask(singleTaskId)
    }, [])

    return (
        <div>
            <div className="asana_projects mt-5 p-3">
                <div className='content_header_top'>
                    <div className="flex_between">
                        <div className='p-3'>
                            <div className="flex_start">
                                <div className="img_wrapper">
                                    <img src={userInfo.photo.image_60x60} alt="" className='w-100 h-100 rounded-circle'/>
                                </div>
                                <div>
                                    <h5>My Task</h5>
                                    <div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div>

                        </div>
                    </div>
                </div>
                <div className='content_header_bottom'>
                    <div className="flex_between">
                        <div className='p-3'>
                            <div className="flex_start">
                                <div className="icon_wrapper">
                                </div>
                                <div>
                                    <h5>{userTasks?.name}</h5>
                                    <div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div>

                        </div>
                    </div>
                </div>

                <table className='project_table table'>
                    <thead>
                        <tr>
                            <th>Task name</th>
                            <th>Due date</th>
                            <th>Projects</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userTasks.map((task, index) => (
                            <tr key={index}>
                                <td ref={buttonRef} onClick={() => { setShow(true); handleSingleTask(task.gid) }} className={`task_name`} >
                                    <div className='flex_between'>
                                        <span> {task.name}</span>
                                        <span className={`arrow_right ms-auto opacity-0`}><BsChevronRight /></span>
                                    </div>
                                </td>
                                <td>
                                {task.due_on && moment(task.due_on).format("DD MMM")}
                                </td>
                                <td>
                                    {task.projects.map((project => project.name))}
                                </td>

                                {/* <td>
                                <button onClick={() => deleteRow(index + 1)}>Delete {index + 1}</button>
                            </td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Task Slide Div */}

                {show && <div ref={divRef} className={`task_slidebox ${show ? "show" : "hide"}`}>
                    <div className='topbar mt-5 '>
                        <div className='flex_between'>
                            <button>Completed</button>
                            <div>
                                <span onClick={() => deleteTask(singleTaskId)} style={{ cursor: "pointer" }} className="me-3"><BsFillTrashFill size={20} title='delete task' />

                                </span>
                                <span onClick={() => setShow(false)} style={{ cursor: "pointer" }}><BsChevronDoubleRight /></span>
                            </div>
                        </div>
                    </div>
                    <div className='task_body_wrapper'>
                        <div className="task_body">
                            <h4 className='mb-3'>
                                <input type="text" className='form-control w-75 ' defaultValue={singleTaskInfo?.name ? singleTaskInfo?.name : ""} />

                            </h4>
                            <div className=" d-flex">
                                <div className="w-25">
                                    <p className='mb-3'>Assignee</p>
                                    <p>Due date</p>
                                    <p>Projects</p>
                                    <p>Description</p>
                                </div>
                                <div className="w-75">
                                    <p><input type="date" className='form-control form-control-sm' defaultValue={singleTaskInfo?.due_on ? singleTaskInfo?.due_on : ""} /></p>
                                </div>
                            </div>
                            <div className="">
                                <textarea name="" id="" cols="30" rows="10" ref={taskDescRef} defaultValue={singleTaskInfo?.notes} />

                            </div>

                            {/* <div className='save_task'>
                                <Button onClick={storeTask} variant="outlined" startIcon={<FiSave />}>
                                    save
                                </Button>
                            </div> */}

                        </div>
                    </div>
                </div>
                }

            </div >

        </div>
    )
}

export default AsanaTasks