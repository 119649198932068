import { InputLabel, LinearProgress } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { BACKEND_BASE_URL } from "../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../Component/AdminDashboardNavbar";
import getCookie from "../../Component/GetCookie";
import { getLocalStorageWithExpiry } from "../../Component/LocalStorageWithExpiry";
const Settings = () => {
  const password = useRef();

  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [tableData, setTableData] = useState();
  const [maintenanceIsActive, setMaintenanceIsActive] = useState();

  // const check = async () => {
  //   await axios
  //     .get(
  //       `${BACKEND_BASE_URL}/api/v2/rianaire/server-maintenance/check/${sessionStorage.getItem(
  //         "ACCESS_TOKEN"
  //       )}`
  //     )
  //     .then((res) => {
  //       if (res.data.status == 200) {
  //         setMaintenanceIsActive(res.data?.is_active);
  //       }
  //       if (maintenanceIsActive == "1") {
  //         fetchTableData();
  //       }
  //     });
  // };

  const handleLive = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/server-maintenance/live`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        if (res.data.status == 200) {
          Swal.fire({
            icon: "success",
            text: res.data.message,
            confirmButtonColor: "#5eba86",
          });
          setMaintenanceIsActive(res.data?.is_active);
        }
        if (maintenanceIsActive == "1") {
          fetchTableData();
        }
      });
  };

  const fetchTableData = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/server-maintenance`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        if (res.data.status == 200) {
          setIsLoading(false);
          setError(false);
          setTableData(res.data?.maintenance_data);
          setMaintenanceIsActive(res.data?.maintenance_data?.is_active);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    // check();
    fetchTableData();
  }, []);

  // Form submit to backend
  const store = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);

    const formdata = new FormData();
    formdata.append("secret", password.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/server-maintenance/store`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data.message,
            confirmButtonColor: "#5eba86",
          });
          e.target.reset();
          setValidated(false);
          fetchTableData();
          sessionStorage.setItem("UNDER_MAINTENANCE", 1);
        }
      });
    e.preventDefault();
  };

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                {!isLoading && !error && (
                  <>
                    {maintenanceIsActive == "1" && (
                      <div className="table-responsive">
                        <table className="table table-hover ">
                          <thead>
                            <tr>
                              <th scope="col">Secret Code</th>
                              <th scope="col">Handle</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{tableData?.secret}</td>
                              <td>
                                <button
                                  onClick={handleLive}
                                  className="py-1 px-2 bg-info text-white border-0 rounded-3 me-1 mb-1"
                                >
                                  Live Now
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                    {maintenanceIsActive == "0" && (
                      <Form onSubmit={store} noValidate validated={validated}>
                        <div className="content-wrapper">
                          <div className="card">
                            <div className="card-body">
                              <div className="">
                                {/* Project Name */}
                                <Form.Group
                                  as={Col}
                                  md={12}
                                  controlId="validationCustom01"
                                  className="mb-3"
                                >
                                  <Form.Label className="label fw-bold">
                                    Secret Code
                                  </Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    ref={password}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Title is required
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <div className="text-center">
                                  <Button
                                    type="submit"
                                    className="btn-submit rounded-3 border-0"
                                  >
                                    Under Maintenance
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </>
                )}

                {isLoading && !error && <LinearProgress />}
                {!isLoading && !error && tableData?.length < 1 && (
                  <>
                    <div className="text-center text-danger">
                      <h1>No data Found</h1>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
