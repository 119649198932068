import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AiOutlineClose, AiOutlinePlusCircle } from "react-icons/ai";
import { FiSave } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import getCookie from "../../../../Component/GetCookie";
import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import { ToastAlert } from "../../../../Component/ToastAlert";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const DynamicDropdownEdit = () => {
  const { dropdownId } = useParams();
  const serviceName = useRef();
  const subServiceName = useRef();
  const childService = useRef();
  const dynamicDropdown = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const [mainServices, setMainServices] = useState([]);
  const [subServices, setSubServices] = useState([]);
  const [childServices, setChildServices] = useState([]);
  const [editInfo, setEditInfo] = useState([]);

  const fetchMainServices = async () => {
    await axios.get(`${BACKEND_BASE_URL}/api/v2/main-services`).then((res) => {
      setMainServices(res.data?.main_services);
    });
  };

  const fetchSubServices = (e) => {
    setSubServices([]);
    e.preventDefault();
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/sub-services/${serviceName.current.value}`
      )
      .then((res) => {
        setSubServices(res.data?.sub_services);
      });
  };

  const fetchChildService = () => {
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/child-services/${serviceName.current.value}/${subServiceName.current.value}`
      )
      .then((res) => {
        setChildServices(res.data?.child_services);
      });
  };

  const handleServiceType = (e) => {};

  const fetchData = async () => {
    setIsLoading(true);
    await axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/dynamic-dropdown/edit/${dropdownId}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((res) => {
        setIsLoading(false);
        setEditInfo(res.data?.edit_info);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
    fetchMainServices();
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/sub-services/${editInfo.main_service_id}`
      )
      .then((res) => {
        setSubServices(res.data?.sub_services);
      });
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/child-services/${editInfo.main_service_id}/${editInfo.sub_service_id}`
      )
      .then((res) => {
        setChildServices(res.data?.child_services);
      });
  }, [editInfo]);

  // Updated data to backend
  const updateData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("main_service_id", serviceName.current.value);
    formdata.append("sub_service_id", subServiceName.current.value);
    formdata.append("child_service_id", childService.current.value);
    formdata.append("name", dynamicDropdown.current.value);
    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/dynamic-dropdown/update/${dropdownId}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        ToastAlert.fire({
          title: "updated successfully",
        });
      });

    e.preventDefault();
  };

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar pageTitle="Dynamic Dropdown" />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <>
                  <div className="card-header flex_between">
                    {JSON.parse(
                      localStorage.getItem("LOGGED_IN_USER_PERMISSION_INFO")
                    )?.map(
                      (data, index) =>
                        data?.permission_name === "create-service-cost" && (
                          <Link to="/admin/service-costing" key={index}>
                            <Button variant="success" size="sm">
                              All Service Costing&nbsp;
                              <span>
                                <AiOutlinePlusCircle className="mb-1" />
                              </span>
                            </Button>
                          </Link>
                        )
                    )}
                  </div>

                  <div className="">
                    <Form onSubmit={updateData}>
                      <div className="content-wrapper">
                        <div className="card">
                          <div className="card-body table_fixed">
                            <div className="row py-3">
                              {/* Department */}
                              <Form.Group
                                as={Col}
                                md="6"
                                lg="4"
                                xl="4"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Department
                                </Form.Label>
                                <Form.Select
                                  required
                                  aria-label="language example "
                                  className=""
                                  ref={serviceName}
                                  onChange={(e) => {
                                    fetchSubServices(e);
                                  }}
                                >
                                  <option value="">Select Service</option>
                                  {mainServices?.map((parentCategory) => (
                                    <option
                                      key={parentCategory.id}
                                      value={parentCategory.id}
                                      selected={
                                        parentCategory.id ==
                                        editInfo?.main_service_id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {parentCategory.name}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>

                              {/* Service Name */}
                              <Form.Group
                                as={Col}
                                md="6"
                                lg="4"
                                xl="4"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Service Name
                                </Form.Label>
                                <Form.Select
                                  required
                                  aria-label="language example "
                                  className=""
                                  ref={subServiceName}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    fetchChildService();
                                  }}
                                >
                                  <option value="" selected>
                                    Select Sub Service
                                  </option>
                                  {subServices.length > 0 &&
                                    subServices?.map((subCategory) => (
                                      <option
                                        key={subCategory.id}
                                        value={subCategory.id}
                                        selected={
                                          subCategory.id ==
                                          editInfo?.sub_service_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {subCategory.name}
                                      </option>
                                    ))}
                                </Form.Select>
                              </Form.Group>
                              {/* Service Type */}

                              <Form.Group
                                as={Col}
                                md="6"
                                lg="4"
                                xl="4"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Service Type
                                </Form.Label>
                                <Form.Select
                                  required
                                  aria-label="language example "
                                  className=""
                                  ref={childService}
                                  onChange={(e) => {
                                    handleServiceType(e);
                                  }}
                                >
                                  <option value="0" selected>
                                    Select Service Type
                                  </option>

                                  {childServices?.map((data) => (
                                    <option
                                      option
                                      key={data.id}
                                      value={data.id}
                                      selected={
                                        data.id == editInfo?.child_service_id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {data.name}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="6"
                                lg="4"
                                xl="4"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Dynamic Dropdown
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={editInfo?.name}
                                  ref={dynamicDropdown}
                                />
                              </Form.Group>
                            </div>

                            <div className="flex_center">
                              <Button
                                type="submit"
                                className="btn-submit mt-5 rounded-3 border-0"
                              >
                                <FiSave /> &nbsp; Update
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicDropdownEdit;
