import React, { useState, useContext, useEffect } from 'react'
import {  CalenderHeader, EventModal, Month, Sidebar, getMonth } from "./Utils"
import AdminDashboardNavbar from '../../../Component/AdminDashboardNavbar'
import styles from "./Calender.module.css"
import { CalenderContext } from './CalenderContext'
const Calender = () => {
    const [currentMonth, setCurrentMonth] = useState(getMonth())
    const { monthIndex, showEventModal } = useContext(CalenderContext)
    useEffect(() => {
        setCurrentMonth(getMonth(monthIndex))
    }, [monthIndex])

    return (
        <div className="main__container">
            {showEventModal && <EventModal />}
            <div className="body-wrapper">
                <AdminDashboardNavbar pageTitle="" />
                <div className={`${styles.calenderWrapper} d-flex flex-column`}>
                    <CalenderHeader />
                    <div className="d-flex flex-grow-1">
                        <Sidebar />
                        <Month month={currentMonth} />
      
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Calender