import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { Link, useLocation, useParams } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import RichTextEditor from "../../../../Component/RichTextEditor";
import { FiSave } from "react-icons/fi";
import getCookie from "../../../../Component/GetCookie";
import { MdSystemUpdateAlt } from "react-icons/md";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const ExploreOpeningsEdit = () => {
  const { jobopeningId } = useParams();

  const { state } = useLocation();
  const { allDepartments, allLocations } = state;

  const jobTitle = useRef();
  const jobLocation = useRef();
  const jobDepartment = useRef();
  const jobDesc = useRef();

  const [editInfo, setEditInfo] = useState([]);

  const [value, setValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };

  // fetch Edit Info
  const renderSingleData = async () => {
    await axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/explore-openings/job-posts/edit/${jobopeningId}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        setEditInfo(res.data?.edit_job_post);
      });
  };

  // Updated data to backend
  const updateClientInfo = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("title", jobTitle.current.value);
    if (jobDepartment.current.value != "0") {
      formdata.append("department_id", jobDepartment.current.value);
    }
    if (jobLocation.current.value != "0") {
      formdata.append("location_id", jobLocation.current.value);
    }
    formdata.append("description", jobDesc.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/explore-openings/job-posts/update/${jobopeningId}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        Swal.fire({
          icon: "success",
          text: response.data?.message,
          confirmButtonColor: "#5eba86",
        });
      });

    e.preventDefault();
  };

  useEffect(() => {
    renderSingleData();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Job Openings</h3>
                  <Link to={`/admin/career/explore-openings`}>
                    <Button variant="success" size="sm">
                      All Job Openings &nbsp;
                    </Button>
                  </Link>
                </div>

                <div className="">
                  <form onSubmit={updateClientInfo}>
                    <div className="content-wrapper">
                      <div className="row py-3">
                        {/* Job Title */}
                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <div>
                              <Form.Label className="label fw-bold">
                                Job Title
                              </Form.Label>
                            </div>

                            <input
                              type="text"
                              className="form-control"
                              placeholder="Job Title"
                              ref={jobTitle}
                              defaultValue={editInfo?.title}
                            />
                          </div>
                        </div>

                        {/* Department */}
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                          className="mb-3"
                        >
                          <Form.Label
                            className="label fw-bold"
                            id="section-lebel"
                          >
                            Select Department
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            ref={jobDepartment}
                          >
                            <option>Select Department</option>
                            {allDepartments.map((data, index) => {
                              return (
                                <option
                                  selected={
                                    data.id == editInfo?.department_id
                                      ? "selected"
                                      : ""
                                  }
                                  key={index}
                                  value={data.id}
                                >
                                  {data.department_name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>

                        {/* Country */}
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                          className="mb-3"
                        >
                          <Form.Label
                            className="label fw-bold"
                            id="section-lebel"
                          >
                            Select Country
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            ref={jobLocation}
                          >
                            <option>Select Country</option>
                            {allLocations?.map((data, index) => {
                              return (
                                <option
                                  selected={
                                    data.id == editInfo?.location_id
                                      ? "selected"
                                      : ""
                                  }
                                  key={index}
                                  value={data.id}
                                >
                                  {data?.country_name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>

                        {/* Description */}

                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom02"
                          className="mb-3"
                        >
                          <Form.Label className="label fw-bold">
                            Description
                          </Form.Label>
                          <div
                            style={{
                              maxHeight: "50vh",
                              overflowY: "auto",
                            }}
                          >
                            <RichTextEditor
                              joditRef={jobDesc}
                              getValue={getValue}
                              value={editInfo?.description}
                            />
                          </div>
                        </Form.Group>

                        {/* Submit button */}
                        <button
                          type="submit"
                          className="btn-submit mt-5 rounded-3 border-0 flex_center"
                        >
                          <MdSystemUpdateAlt className="me-2" />
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreOpeningsEdit;
