import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";

import { UserContext } from "../../../App";
import { BiSearch } from "react-icons/bi";

const AdminOrderAdd = () => {
  const { modalShow, setModalShow, allMainServices, setAllMainServices } =
    useContext(UserContext);

  const [clickState, setClickState] = useState();
  const [search, setSearch] = useState("");

  const ToggleClass = (index) => {
    setClickState(index);
  };

  // Get All Info
  const fetchMainServices = async () => {
    await axios.get(`${BACKEND_BASE_URL}/api/v2/main-services`).then((res) => {
      setAllMainServices(res.data?.main_services);
    });
  };

  const TabItem = (tabnameId, tabitemId, tabDataName, tabItemName) => {
    sessionStorage.setItem("Service_name_id", tabnameId);
    sessionStorage.setItem("Service_categody_id", tabitemId);
    sessionStorage.setItem("Service_name", tabDataName);
    sessionStorage.setItem("Service_categody_name", tabItemName);
  };

  useEffect(() => {
    fetchMainServices();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />
        <Modal
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            // navigate("/user/projects");
          }}
          size="lg"
          centered
          className="modal_section"
        >
          <Modal.Header
            closeButton
            onClick={() => {
              setModalShow(false);
              //   navigate("/user/projects");
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              What do you want to create?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="t-mb-25">
            <Form>
              <Form.Control
                placeholder="Search"
                className="position-relative mb-4 search_box"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <BiSearch className="position-absolute search_icon" />
            </Form>

            <div className="services_category_tab">
              <Tabs
                defaultActiveKey="Development &amp; Technology"
                id="uncontrolled-tab-example"
                className="t-pb-30"
              >
                {allMainServices.length > 0 &&
                  allMainServices?.map((tabData) => (
                    <Tab
                      key={tabData.id}
                      eventKey={tabData.name}
                      title={
                        <h4 className="t-fs-17 t-fw-600 mb-0">
                          {tabData.name}
                        </h4>
                      }
                    >
                      <Row>
                        {tabData?.sub_service?.length != 0 &&
                          tabData?.sub_service?.map((tabItem, i) => (
                            <Col md={6} className="mb-4 tab_item_wrapper">
                              <div
                                key={tabItem.id}
                                className={`${
                                  clickState === i ? "clickActive" : ""
                                }`}
                                onClick={() => {
                                  TabItem(
                                    tabData.id,
                                    tabItem.id,
                                    tabData.name,
                                    tabItem.name
                                  );
                                  ToggleClass(i);
                                }}
                              >
                                <div className="tab_item t-pe-25" key={i}>
                                  <div className="flex_start">
                                    <img
                                      src={`${BACKEND_BASE_URL}/${tabItem?.image}`}
                                      alt=""
                                      className=" me-3"
                                      style={{
                                        width: "100px",
                                        height: "60px",
                                        objectFit: "cover",
                                        borderRadius: "8px",
                                      }}
                                    />
                                    <h3 className="mb-0">{tabItem.name}</h3>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          ))}

                        {tabData?.children?.length === 0 && (
                          <h2 className="text-center">Service Not Available</h2>
                        )}
                      </Row>
                    </Tab>
                  ))}
              </Tabs>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setModalShow(false)}>
              <Link to="/admin/orders" style={{ textDecoration: "none" }}>
                Cancel
              </Link>
            </Button>
            <Link to="/admin/orders/create-new-order">
              <Button onClick={() => setModalShow(false)}>
                Create Project
              </Button>
            </Link>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AdminOrderAdd;
