import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import Swal from "sweetalert2";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { BACKEND_BASE_URL } from "../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../Component/AdminDashboardNavbar";
import { LinearProgress } from "@mui/material";
import { CgClose } from "react-icons/cg";
import { MdDeleteForever } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import { AiOutlineClose, AiOutlinePlusCircle } from "react-icons/ai";
import { FiSave } from "react-icons/fi";
import RichTextEditor from "../../Component/RichTextEditor";
import getCookie from "../../Component/GetCookie";
import { getLocalStorageWithExpiry } from "../../Component/LocalStorageWithExpiry";

const AdminLearnngCenter = () => {
  const articleTitle = useRef();
  const articleFeaturedImage = useRef();
  const articleDescription = useRef();

  // Initial table data
  const [tableData, setTableData] = useState([]);

  // Modal Data
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("lg");

  // View single value
  const [singleData, setSingleData] = useState([]);
  const [value, setValue] = useState("");

  const getValue = (value) => {
    setValue(value);
  };

  // Image Preview
  const [files, setFile] = useState([]);

  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  // Image2 Preview
  const [files2, setFile2] = useState([]);

  // const handleImgPreview2 = (e) => {
  //   let allfiles2 = [];
  //   for (let i = 0; i < e.target.files.length; i++) {
  //     allfiles2.push(e.target.files[i]);
  //   }
  //   if (allfiles2.length > 0) {
  //     setFile2(allfiles2);
  //   }
  // };

  // Dynamic Input Field

  const [inputFields, setInputFields] = useState([
    { descTitle: "", description: "", file: "" },
  ]);

  // text input
  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);

  };

  // File input
  const handleFileChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.files[0];
    setInputFields(data);
  };

  const addFields = () => {
    let newfield = { descTitle: "", description: "", file: "" };
    setInputFields([...inputFields, newfield]);
  };

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  // Dynamic input fields edit

  const [editInputFields, setEditInputFields] = useState([]);
  const [editSingleData, setEditSingleData] = useState([]);

  const handleFormChangeEdit = (index, event) => {
    let data = [...editInputFields];
    data[index][event.target.name] = event.target.value;
    setEditInputFields(data);
  };

  const addFieldsEdit = () => {
    let addnewfield = { descTitle: "", description: "" };
    setEditInputFields([...editInputFields, addnewfield]);
  };

  const removeFieldsEdit = (index) => {
    let data = [...editInputFields];
    data.splice(index, 1);
    setEditInputFields(data);
  };

  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/learning-center`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setTableData(res.data?.learning_center);
      });
  };

  // Add new
  const addNewData = (modalValue) => {
    setModalSize("xl");
    setModalData(modalValue);
    setModalShow(true);
  };

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();

    formdata.append("title", articleTitle.current.value);
    formdata.append("image", articleFeaturedImage.current.files[0]);

    inputFields.forEach((item) => {
      formdata.append("desc_title[]", item.descTitle);
      formdata.append("desc_body[]", item.description);
    });

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/learning-center/store`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          e.target.reset();
          setValue("", "html");
          renderAllInfo();
          setModalShow(false);
        }
      });
    e.preventDefault();
  };

  // View single Data
  const showSingleData = (modalValue, data) => {
    setModalSize("");
    setModalSize("lg");
    setModalData(modalValue);
    setSingleData(data);
    setModalShow(true);
  };

  // Edit data
  const fetchDataForEdit = (modalValue, data) => {
    setModalSize("");
    setModalSize("lg");
    setModalData(modalValue);
    setEditSingleData(data);
    setModalShow(true);
    setEditInputFields([]);
  };

  let editnewfield = [];
  useEffect(() => {
    editSingleData?.descriptions?.forEach((data) => {
      editnewfield.push({
        descTitle: data?.desc_title,
        description: data?.desc_body,
      });
    });
    setEditInputFields(...editInputFields, editnewfield);
  }, [editSingleData]);

  // Updated data to backend
  const updatedData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("title", articleTitle.current.value);
    if (articleFeaturedImage.current.files[0]) {
      formdata.append("image", articleFeaturedImage.current.files[0]);
    }
    editInputFields.forEach((item) => {
      formdata.append("desc_title[]", item.descTitle);
      formdata.append("desc_body[]", item.description);
    });

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/learning-center/update/${editSingleData?.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          e.target.reset();
          renderAllInfo();
          setModalShow(false);
        } else {
          Swal.fire({
            icon: "warning",
            text: response.data.errors.cat_name[0],
            confirmButtonColor: "#5eba86",
          });
        }
      });

    e.preventDefault();
  };

  // Delete Data
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}/api/v2/rianaire/learning-center/delete/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllInfo();
        });
    }
  };

  useEffect(() => {
    renderAllInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>All Articles</h3>
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => addNewData("Add")}
                  >
                    Add &nbsp;
                    <span>
                      <AiOutlinePlusCircle className="mb-1" />
                    </span>
                  </Button>
                </div>
                {tableData.length > 0 ? (
                  <div className="table-responsive py-5">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Title</th>
                          <th scope="col">Featured Image</th>
                          <th scope="col">Description</th>
                          <th scope="col">Handle</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.map((data, i) => (
                          <tr key={data.i}>
                            <td> {i + 1}</td>
                            <td>{data?.title}</td>
                            <td>
                              <img
                                src={`${BACKEND_BASE_URL}/${data?.image}`}
                                alt=""
                                width={80}
                              />
                            </td>
                            <td style={{ width: "300px" }}>
                              {data?.descriptions?.[0].desc_body.slice(0, 100) +
                                "..."}
                            </td>
                            <td>
                              {/* view button */}
                              <button
                                onClick={() => showSingleData("View", data)}
                                className="py-1 px-2 bg-info border-0 rounded-3 me-1 mb-1"
                              >
                                <FaEye
                                  style={{
                                    color: "white",
                                  }}
                                  title="View"
                                  size="1.5em"
                                />
                              </button>

                              {/* edit button */}
                              <button
                                onClick={() => {
                                  fetchDataForEdit("Edit", data);
                                }}
                                className="py-1 px-2 bg-warning border-0 rounded-3 me-1 mb-1"
                              >
                                <BiEdit
                                  style={{
                                    color: "white",
                                  }}
                                  title="Edit"
                                  size="1.5em"
                                />
                              </button>

                              {/* delete button */}
                              <button
                                onClick={() => deleteData(data.id)}
                                className="py-1 px-2 bg-danger border-0 rounded-3 me-1 mb-1"
                              >
                                <MdDeleteForever
                                  style={{
                                    color: "white",
                                  }}
                                  title="Delete"
                                  size="1.5em"
                                />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <LinearProgress />
                )}
              </div>
            </div>

            <Modal
              show={modalShow}
              size={modalSize}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => setModalShow(false)}
            >
              <Modal.Header>
                <Modal.Title className="" id="contained-modal-title-vcenter">
                  {modalData}
                </Modal.Title>
                <Button
                  className="p-0 border-0 bg-danger"
                  onClick={() => setModalShow(false)}
                >
                  <CgClose
                    style={{
                      fontSize: "26px",
                    }}
                  />
                </Button>
              </Modal.Header>
              <Modal.Body>
                {/* Add Modal section */}
                {modalData === "Add" && (
                  <div className="">
                    <Form onSubmit={storeData}>
                      <div className="content-wrapper">
                        <div className="card">
                          <div className="card-body">
                            <div className="row py-3">
                              {/* Article title */}
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Title
                                </Form.Label>
                                <Form.Control
                                  // required
                                  type="text"
                                  placeholder="Title"
                                  ref={articleTitle}
                                />
                              </Form.Group>

                              {/* Article Featured Image */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Featured Image
                                </Form.Label>
                                <Form.Control
                                  // required
                                  type="file"
                                  ref={articleFeaturedImage}
                                  onChange={handleImgPreview}
                                />
                                {files.map((file, key) => {
                                  return (
                                    <div key={key} className="Row">
                                      <span className="Filename">
                                        <img
                                          width={80}
                                          height={50}
                                          src={URL.createObjectURL(file)}
                                          alt={file.name}
                                        />
                                      </span>
                                    </div>
                                  );
                                })}
                              </Form.Group>
                            </div>

                            {inputFields?.map((input, index) => {
                              return (
                                <>
                                  <div
                                    key={index}
                                    className="p-3 mb-2"
                                    style={{
                                      border: "1px solid #d1d1d1",
                                      backgroundColor: "#f3f3f3",
                                    }}
                                  >
                                    {/* Article title */}
                                    <Form.Group
                                      as={Col}
                                      md="12"
                                      controlId="validationCustom01"
                                      className="mb-3"
                                    >
                                      <Form.Label className="label fw-bold">
                                        Title
                                      </Form.Label>
                                      <Form.Control
                                        // required
                                        type="text"
                                        placeholder="Title"
                                        name="descTitle"
                                        value={input.descTitle}
                                        onChange={(event) =>
                                          handleFormChange(index, event)
                                        }
                                      />
                                    </Form.Group>

                                    {/* Description */}
                                    <Form.Group
                                      as={Col}
                                      md="12"
                                      controlId="validationCustom02"
                                      className="mb-3"
                                    >
                                      <Form.Label className="label fw-bold">
                                        Description
                                      </Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        placeholder="Leave a comment here"
                                        rows={7}
                                        name="description"
                                        value={input.description}
                                        onChange={(event) =>
                                          handleFormChange(index, event)
                                        }
                                      />
                                    </Form.Group>

                                    {/* Image */}
                                    {/* <Form.Group
                                      as={Col}
                                      md="6"
                                      controlId="validationCustom01"
                                      className="mb-3"
                                    >
                                      <Form.Label className="label fw-bold">
                                        Featured Image
                                      </Form.Label>
                                      <Form.Control
                                        type="file"
                                        name="file"
                                        value={input.image}
                                        onChange={(event) => {
                                          handleFileChange(index, event);
                                        }}
                                      />
                                      {files2.map((file, key) => {
                                        return (
                                          <div key={key} className="Row">
                                            <span className="Filename">
                                              <img
                                                width={80}
                                                height={50}
                                                src={URL.createObjectURL(file)}
                                                alt={file.name}
                                              />
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </Form.Group> */}

                                    {/*Select */}
                                    {/* <Form.Group
                                    as={Col}
                                    md="6"
                                    controlId="validationCustom01"
                                    className="mb-3"
                                  >
                                    <Form.Label className="label fw-bold">
                                      FileType
                                    </Form.Label>
                                    <Form.Select
                                      aria-label="Default select example"
                                      name="fileType"
                                      value={input.fileType}
                                      onChange={(event) =>
                                        handleFormChange(index, event)
                                      }
                                    >
                                      <option value="0">Select FileType</option>
                                      {addInfo?.data_fileType?.map((data) => (
                                        <option value={data?.id} key={data?.id}>
                                          {data.name}
                                        </option>
                                      ))}
                                    </Form.Select>
                                  </Form.Group> */}

                                    {/* <input value={input.description} /> */}
                                    <button
                                      onClick={() => removeFields(index)}
                                      className="remove_btn"
                                    >
                                      <AiOutlineClose size="1.5rem" />
                                    </button>
                                  </div>
                                </>
                              );
                            })}
                            <div className="flex_end">
                              <button
                                type="button"
                                onClick={addFields}
                                className="add_more_btn"
                              >
                                Add More
                              </button>
                            </div>

                            <div className="flex_center">
                              <Button
                                type="submit"
                                className="btn-submit mt-5 rounded-3 border-0"
                              >
                                <FiSave /> &nbsp; Save
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                )}

                {/* Edit Modal section */}
                {modalData === "Edit" && (
                  <div className="">
                    <Form onSubmit={updatedData}>
                      <div className="content-wrapper">
                        <div className="card">
                          <div className="card-body">
                            <div className="row py-3">
                              {/* Article title */}
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Title
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Title"
                                  ref={articleTitle}
                                  defaultValue={editSingleData?.title}
                                />
                              </Form.Group>
                              {/* Article Featured Image */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Featured Image
                                </Form.Label>
                                <Form.Control
                                  type="file"
                                  onChange={handleImgPreview}
                                  ref={articleFeaturedImage}
                                />
                                {files.map((file, index) => {
                                  return (
                                    <div key={index} className="Row">
                                      <span className="Filename">
                                        <img
                                          width={80}
                                          height={50}
                                          src={URL.createObjectURL(file)}
                                          alt={file.name}
                                        />
                                      </span>
                                    </div>
                                  );
                                })}
                                {files.length == 0 && (
                                  <img
                                    width={80}
                                    height={50}
                                    src={`${BACKEND_BASE_URL}${editSingleData?.image}`}
                                    alt={editSingleData?.title}
                                    name="img"
                                  />
                                )}
                              </Form.Group>
                              {/* Description */}

                              {editInputFields?.map((input, index) => {
                                return (
                                  <>
                                    <div
                                      key={index}
                                      className="p-3 mb-2"
                                      style={{
                                        border: "1px solid #d1d1d1",
                                        backgroundColor: "#f3f3f3",
                                      }}
                                    >
                                      <Form.Group
                                        as={Col}
                                        md="12"
                                        controlId="validationCustom01"
                                        className="mb-3"
                                      >
                                        <Form.Label className="label fw-bold">
                                          Title
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Title"
                                          name="descTitle"
                                          value={input.descTitle}
                                          onChange={(event) =>
                                            handleFormChangeEdit(index, event)
                                          }
                                        />
                                      </Form.Group>

                                      <Form.Group
                                        as={Col}
                                        md="12"
                                        controlId="validationCustom02"
                                        className="mb-3"
                                      >
                                        <Form.Label className="label fw-bold">
                                          Description
                                        </Form.Label>
                                        <Form.Control
                                          as="textarea"
                                          placeholder="Leave a comment here"
                                          rows={7}
                                          name="description"
                                          value={input.description}
                                          onChange={(event) =>
                                            handleFormChangeEdit(index, event)
                                          }
                                        />
                                      </Form.Group>

                                      <button
                                        type="button"
                                        onClick={() => removeFieldsEdit(index)}
                                        className="remove_btn"
                                      >
                                        <AiOutlineClose size="1.5rem" />
                                      </button>
                                    </div>
                                  </>
                                );
                              })}
                              <div className="flex_end">
                                <button
                                  type="button"
                                  onClick={addFieldsEdit}
                                  className="add_more_btn"
                                >
                                  Add More
                                </button>
                              </div>

                              <Button
                                type="submit"
                                className="btn-submit mt-5 rounded-3 border-0 "
                              >
                                <FiSave /> &nbsp; Save
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                )}

                {/* View Modal section */}
                {modalData === "View" && (
                  <>
                    <h4>{singleData?.title}</h4>
                    <div>
                      <img
                        src={`${BACKEND_BASE_URL}/${singleData?.image}`}
                        alt=""
                        className="img-thumbnail"
                      />
                    </div>
                    <div>
                      {singleData?.descriptions?.map((descVal) => (
                        <div>
                          <p>{descVal?.desc_title}</p>
                          <p>{descVal?.desc_body}</p>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setModalShow(false)}
                  className="btn-danger"
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLearnngCenter;
