// Function to set data in localStorage with an expiry time
export function setLocalStorageWithExpiry(key, value, hours) {
  const now = new Date();
  // const expiryTimestamp = now.getTime() + hours * 60 * 60 * 1000;
  const item = {
    value: value,
    // expiry: expiryTimestamp, // Calculate the expiry timestamp
  };
  localStorage.setItem(key, JSON.stringify(item));
}

// Function to get data from localStorage and check if it has expired
 export function getLocalStorageWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null; // Data not found
    }

    const item = JSON.parse(itemStr);
     const now = new Date().getTime();
    // if (now > item.expiry) {
    //   localStorage.removeItem(key); // Remove the data if it has expired

    //   return null;
    // }

    return item.value; // Return the data if it's still valid
  }


