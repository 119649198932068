import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FiSave } from "react-icons/fi";
import { Link, useLocation, useParams } from "react-router-dom";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import getCookie from "../../../../Component/GetCookie";

import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import RichTextEditor from "../../../../Component/RichTextEditor";
import { ToastAlert } from "../../../../Component/ToastAlert";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const AdminServiceEdit = () => {
  const { serviceId } = useParams();
  const serviceName = useRef();
  const serviceImage = useRef();
  const serviceJsonfile = useRef();
  const parentServiceName = useRef();
  const serviceDescription = useRef();

  const { state } = useLocation();
  const { parentService } = state;

  // Image Preview
  const [files, setFile] = useState([]);
  const [files2, setFile2] = useState([]);

  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  const [value, setValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };

  // single table data
  const [singleData, setSingleData] = useState([]);

  // Get Single Info
  const renderSingleData = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/service/edit/${serviceId}`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setSingleData(res.data?.edit_info);

        axios
          .get(
            `${BACKEND_BASE_URL}/api/v2/get-sub-services/${res.data?.edit_design_type_info?.service_id}`
          )
          .then((res) => {});
      });
  };

  // Updated data to backend
  const updateData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("name", serviceName.current.value);
    if (serviceImage.current.files[0]) {
      formdata.append("image", serviceImage.current.files[0]);
    }
    if (serviceJsonfile.current.files[0]) {
      formdata.append("json_image", serviceJsonfile.current.files[0]);
    }
    formdata.append("parrent_id", parentServiceName.current.value);
    formdata.append("description", serviceDescription.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/service/update/${serviceId}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        ToastAlert.fire({
          icon: "success",
          title: response.data?.message,
        });
        renderSingleData();
      });

    e.preventDefault();
  };

  useEffect(() => {
    renderSingleData();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Add Service</h3>
                  <Link to="/admin/service-module/service">
                    <Button variant="success" size="sm">
                      All Service &nbsp;
                    </Button>
                  </Link>
                </div>
                <Row>
                  <form onSubmit={updateData}>
                    <div className="content-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <div className="row py-3">
                            {/* Name */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Name <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Name"
                                ref={serviceName}
                                defaultValue={singleData?.name}
                              />
                              <Form.Control.Feedback type="invalid">
                                name is required
                              </Form.Control.Feedback>
                            </Form.Group>

                            {/* content Image */}
                            <div className="col-md-6">
                              <div className="form-group">
                                <div>
                                  <label className="label fw-bold">
                                    Image
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="image-file">
                                  <input
                                    type="file"
                                    className="form-control"
                                    onChange={handleImgPreview}
                                    ref={serviceImage}
                                    defaultValue={singleData?.image}
                                  />
                                  {files.map((file, key) => {
                                    return (
                                      <div key={key} className="Row">
                                        <span className="Filename">
                                          <img
                                            className="img-thumbnail"
                                            width={80}
                                            height={70}
                                            src={URL.createObjectURL(file)}
                                            alt={file.name}
                                          />
                                        </span>
                                      </div>
                                    );
                                  })}
                                  {files.length == 0 && (
                                    <img
                                      width={80}
                                      height={50}
                                      src={`${BACKEND_BASE_URL}${singleData?.image}`}
                                      alt={singleData?.title}
                                      name="img"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* Service JSON file */}
                            <div className="col-md-6">
                              <div className="form-group">
                                <div>
                                  <label className="label fw-bold">
                                    JSON file
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="image-file">
                                  <input
                                    type="file"
                                    className="form-control"
                                    ref={serviceJsonfile}
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Parent */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Parent
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                required
                                ref={parentServiceName}
                              >
                                <option value="0">Select Service</option>
                                {parentService?.map((data, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={data.id}
                                      selected={
                                        data.id == singleData?.parrent_id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {data.name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Parent is required
                              </Form.Control.Feedback>
                            </Form.Group>

                            {/* Service Description */}
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom02"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Description
                              </Form.Label>
                              <RichTextEditor
                                joditRef={serviceDescription}
                                getValue={getValue}
                                value={singleData?.description}
                              />
                            </Form.Group>

                            {/* Submit button */}
                            <button
                              type="submit"
                              className="btn-submit mt-5 rounded-3 border-0"
                            >
                              <FiSave /> &nbsp; Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminServiceEdit;
