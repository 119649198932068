import { InputLabel, LinearProgress } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../Component/AdminDashboardNavbar";
import { FaEye } from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import getCookie from "../../Component/GetCookie";
import { getLocalStorageWithExpiry } from "../../Component/LocalStorageWithExpiry";

const Visitors = () => {
  const [tableData, setTableData] = useState([]);

  // Modal Section Data
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("lg");

  const fetchTableData = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/visitors`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        if (res.data.status == 200) {
          setTableData(res.data?.visitors);
        }
      });
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  // View Single Data
  const [singleData, setSingleData] = useState([]);
  const showSingleData = (modalValue, id) => {
    setModalSize("");
    axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/visitors/show/${id}`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setModalSize("lg");
        setModalData(modalValue);
        setSingleData(res.data?.single_visitor);
        setModalShow(true);
      });
  };

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header">
                  <h3>Visitors</h3>
                </div>
                {tableData.length > 0 ? (
                  <div className="table-responsive">
                    <div className="table_fixed">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">IP</th>
                            <th scope="col">Device</th>
                            <th scope="col">OS</th>
                            <th scope="col">Country Name</th>
                            <th scope="col">Region Name</th>
                            <th scope="col">City Name</th>
                            <th scope="col">Handle</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData?.map((data, index) => (
                            <tr key={index}>
                              <td> {index + 1}</td>
                              <td> {data?.ip}</td>
                              <td> {data?.device_name}</td>
                              <td> {data?.operating_system}</td>
                              <td>{data?.country_name}</td>
                              <td>{data?.region_name}</td>
                              <td>{data?.city_name}</td>

                              <td>
                                {/* view button */}
                                <button
                                  onClick={() =>
                                    showSingleData("View", data.id)
                                  }
                                  className="py-1 px-2 bg-info border-0 rounded-3 me-1"
                                >
                                  <FaEye
                                    style={{
                                      color: "white",
                                    }}
                                    title="View"
                                    size="1.5em"
                                  />{" "}
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>{" "}
                    </div>
                  </div>
                ) : (
                  <LinearProgress />
                )}
              </div>
            </div>

            <Modal
              show={modalShow}
              size={modalSize}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => setModalShow(false)}
            >
              <Modal.Header>
                <Modal.Title className="" id="contained-modal-title-vcenter">
                  {modalData}
                </Modal.Title>
                <Button
                  className="p-0 border-0 bg-danger"
                  onClick={() => setModalShow(false)}
                >
                  <CgClose
                    style={{
                      fontSize: "26px",
                    }}
                  />
                </Button>
              </Modal.Header>
              <Modal.Body>
                {/* View Modal section */}
                {modalData === "View" && (
                  <>
                    {singleData?.ip && <h4>IP: {singleData?.ip}</h4>}
                    {singleData?.device_name && (
                      <h5>Device: {singleData?.device_name}</h5>
                    )}
                    {singleData?.operating_system && (
                      <h5>Operating System: {singleData?.operating_system}</h5>
                    )}
                    {singleData?.country_name && (
                      <h5>Country Name: {singleData?.country_name}</h5>
                    )}
                    {singleData?.country_code && (
                      <h5>Country Code: {singleData?.country_code}</h5>
                    )}
                    {singleData?.region_name && (
                      <h5>Region Name: {singleData?.region_name}</h5>
                    )}
                    {singleData?.city_name && (
                      <h5>City Name: {singleData?.city_name}</h5>
                    )}
                    {singleData?.zip_code && (
                      <h5>Zip Code: {singleData?.zip_code}</h5>
                    )}
                    {singleData?.timezone && (
                      <h5>Timezone: {singleData?.timezone}</h5>
                    )}
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setModalShow(false)}
                  className="btn-danger"
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Visitors;
