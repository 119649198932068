import { LinearProgress, TablePagination } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import Parse from "html-react-parser";
import Swal from "sweetalert2";
import { BACKEND_BASE_URL } from "../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../Component/AdminDashboardNavbar";
import { ToastAlert } from "../../Component/ToastAlert";
import RichTextEditor from "../../Component/RichTextEditor";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { CgClose } from "react-icons/cg";
import getCookie from "../../Component/GetCookie";
import { MdSystemUpdateAlt } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getLocalStorageWithExpiry } from "../../Component/LocalStorageWithExpiry";

const AdminOurWorkProjectCategory = () => {
  const workProjectCategoryTitle = useRef();
  const workProjectCategoryDropdown = useRef();
  const workProjectCategoryImage = useRef();
  const workProjectCategoryDescription = useRef();

  const [search, setSearch] = useState("");
  const [value, setValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Fetch Table Data
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [workProjectCategoryData, setworkProjectCategoryData] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/our-work-categories`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })

      .then((res) => {
        setIsLoading(false);
        setError(false);
        setTableData(res.data?.all_category);
        setFilteredData(res.data?.all_category);
        setworkProjectCategoryData(res.data?.main_category);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(true);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Image Preview
  const [files, setFile] = useState([]);

  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  // Add new
  const addNewData = (modalValue) => {
    setModalSize("lg");
    setModalData(modalValue);
    setModalShow(true);
  };

  // Form submit to backend
  const store = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);

    const formdata = new FormData();
    formdata.append("name", workProjectCategoryTitle.current.value);
    formdata.append(
      "parrent_cat_id",
      workProjectCategoryDropdown.current.value
    );
    formdata.append(
      "category_image",
      workProjectCategoryImage.current.files[0]
    );
    formdata.append(
      "category_description",
      workProjectCategoryDescription.current.value
    );

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/our-work-categories/store`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          ToastAlert.fire({
            title: response.data?.message,
          });
          setFile([]);
          e.target.reset();
          setValue("", "html");
          fetchData();
          setValidated(false);
          setModalShow(false);
        }
      });
    e.preventDefault();
  };

  // View Single Data
  const [singleData, setSingleData] = useState([]);

  const showSingleData = (modalValue, data) => {
    setSingleData(data);
    setModalShow(true);
    setModalSize("lg");
    setModalData(modalValue);
  };
  const fetchDataForEdit = (modalValue, data) => {
    setFile([]);
    setModalSize("");
    setModalSize("lg");
    setModalData(modalValue);
    setSingleData(data);

    setModalShow(true);
  };

  // Updated data to backend
  const updateData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("name", workProjectCategoryTitle.current.value);

    formdata.append(
      "parrent_cat_id",
      workProjectCategoryDropdown.current.value
    );
    if (workProjectCategoryImage.current.files[0]) {
      formdata.append(
        "category_image",
        workProjectCategoryImage.current.files[0]
      );
    }
    formdata.append(
      "category_description",
      workProjectCategoryDescription.current.value
    );

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/our-work-categories/update/${singleData?.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        ToastAlert.fire({
          icon: "success",
          title: response.data?.message,
        });
        setModalShow(false);
        fetchData();
      });

    e.preventDefault();
  };

  // Delete Data

  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}/api/v2/rianaire/our-work-categories/delete/${id}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data.message,
            confirmButtonColor: "#5eba86",
          });
          fetchData();
        });
    }
  };

  // Modal Section Data
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("lg");

  useEffect(() => {
    const result = tableData.filter((data) => {
      return data.name.toLowerCase().match(search.toLowerCase());
    });
    setFilteredData(result);
  }, [search]);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar pageTitle="Project Category" />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="row card-header flex_between">
                  <Col md={8}>
                    <Button
                      variant="success"
                      size="sm"
                      onClick={() => addNewData("Add")}
                    >
                      Add &nbsp;
                      <span>
                        <AiOutlinePlusCircle className="mb-1" />
                      </span>
                    </Button>
                  </Col>
                  <Col md={4}>
                    <Form.Control
                      type="text"
                      placeholder="search here..."
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Col>

                </div>

                {!isLoading && !error && tableData.length > 0 && (
                  <div className="table-responsive custom_table mb-5">

                    <div className="table_fixed">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Handle</th>
                            <th>Name</th>
                            <th>Parent</th>
                            <th>Image</th>
                          </tr>
                        </thead>

                        <tbody
                          style={{ maxHeight: "50vh", overflowY: "scroll" }}
                        >
                          {filteredData
                            // .slice(
                            //   page * rowsPerPage,
                            //   page * rowsPerPage + rowsPerPage
                            // )
                            .map((data, index) => (
                              <>
                                <tr key={index}>
                                  <td>
                                    <Dropdown style={{ position: "unset" }}>
                                      <Dropdown.Toggle>
                                        <BsThreeDotsVertical color="#000" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        {/* view */}
                                        <Dropdown.Item
                                          onClick={() => {
                                            showSingleData("View", data);
                                          }}
                                        >
                                          View
                                        </Dropdown.Item>
                                        {/* edit */}
                                        <Dropdown.Item
                                          onClick={() =>
                                            fetchDataForEdit("Edit", data)
                                          }
                                        >
                                          Edit
                                        </Dropdown.Item>
                                        {/* delete */}

                                        <Dropdown.Item
                                          onClick={() => deleteData(data.id)}
                                        >
                                          Delete
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                  <td>{data?.name}</td>
                                  <td>{data?.parent?.name}</td>
                                  <td>
                                    <img
                                      src={`${BACKEND_BASE_URL}/${data?.category_image}`}
                                      alt=""
                                      width={80}
                                      height={80}
                                      style={{ objectFit: "contain" }}
                                    />
                                  </td>
                                </tr>
                              </>
                            ))}
                          {!isLoading && !error && tableData?.length < 1 && (
                            <div className="text-center text-danger my-5 py-5">
                              <h1>No data Found</h1>
                            </div>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="mt-3 mui_pagination">
                      <TablePagination
                        component="div"
                        count={filteredData.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[10, 20, 50]}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        showFirstButton
                        showLastButton
                      />
                    </div> */}
                  </div>
                )}

                {isLoading && !error && <LinearProgress />}

              </div>
            </div>

            <Modal
              show={modalShow}
              size={modalSize}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => setModalShow(false)}
            >
              <Modal.Header>
                <Modal.Title
                  className="text-white"
                  id="contained-modal-title-vcenter"
                >
                  {modalData}
                </Modal.Title>
                <Button
                  className="p-0 border-0 bg-danger"
                  onClick={() => setModalShow(false)}
                >
                  <CgClose
                    style={{
                      fontSize: "26px",
                    }}
                  />
                </Button>
              </Modal.Header>
              <Modal.Body>
                {/* Add Modal section */}
                {modalData === "Add" && (
                  <div className="">
                    <Form onSubmit={store} noValidate validated={validated}>
                      <div className="content-wrapper">
                        <div className="card">
                          <div className="card-body">
                            <Row className="mb-3">
                              {/* category title */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Title
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  placeholder="Title"
                                  ref={workProjectCategoryTitle}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Title is required
                                </Form.Control.Feedback>
                              </Form.Group>

                              {/* category dropdown */}
                              <Form.Group as={Col} md="6" className="mb-3">
                                <Form.Label className="label fw-bold">
                                  Parent Category
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  required
                                  ref={workProjectCategoryDropdown}
                                >
                                  <option value="0">Select Category</option>
                                  {workProjectCategoryData?.map(
                                    (data, index) => {
                                      return (
                                        <option key={index} value={data.id}>
                                          {data.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </Form.Select>
                              </Form.Group>

                              {/* category image */}
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Category Image (Our Work)
                                </Form.Label>

                                <Form.Control
                                  required
                                  type="file"
                                  ref={workProjectCategoryImage}
                                  onChange={handleImgPreview}
                                />

                                {files.map((file, key) => {
                                  return (
                                    <div key={key} className="Row">
                                      <span className="Filename">
                                        <img
                                          width={80}
                                          height={50}
                                          src={URL.createObjectURL(file)}
                                          alt={file.name}
                                        />
                                      </span>
                                    </div>
                                  );
                                })}

                                <Form.Control.Feedback type="invalid">
                                  Please choose an image
                                </Form.Control.Feedback>
                              </Form.Group>

                              {/* category description */}
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom02"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Description
                                </Form.Label>

                                <RichTextEditor
                                  joditRef={workProjectCategoryDescription}
                                  getValue={getValue}
                                  value={value}
                                />
                              </Form.Group>
                            </Row>

                            <Button
                              type="submit"
                              className="btn-submit mt-5 rounded-3 border-0 d-flex justify-content-center"
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                )}

                {/* Edit modal section */}
                {modalData === "Edit" && (
                  <form onSubmit={updateData}>
                    <div className="content-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <div className="row py-3">
                            {/* category Name */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Title
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Title"
                                ref={workProjectCategoryTitle}
                                defaultValue={singleData?.name}
                              />
                              <Form.Control.Feedback type="invalid">
                                Title is required
                              </Form.Control.Feedback>
                            </Form.Group>

                            {/* Project Category */}
                            <Form.Group as={Col} md="6" className="mb-3">
                              <Form.Label className="label fw-bold">
                                Parent Category
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                required
                                ref={workProjectCategoryDropdown}
                              >
                                <option value="0">Select Category</option>
                                {workProjectCategoryData.map((data, index) => {
                                  return (
                                    <option
                                      selected={
                                        data.id == singleData?.parrent_cat_id
                                          ? "selected"
                                          : ""
                                      }
                                      key={index}
                                      value={data.id}
                                    >
                                      {data.name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Form.Group>

                            {/* category image */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Category Image
                              </Form.Label>

                              <Form.Control
                                type="file"
                                ref={workProjectCategoryImage}
                                onChange={handleImgPreview}
                              />

                              {files.map((file, key) => {
                                return (
                                  <div key={key} className="Row">
                                    <span className="Filename">
                                      <img
                                        width={80}
                                        height={50}
                                        src={URL.createObjectURL(file)}
                                        alt={file.name}
                                      />
                                    </span>
                                  </div>
                                );
                              })}

                              {files.length == 0 && (
                                <img
                                  width={80}
                                  height={50}
                                  src={`${BACKEND_BASE_URL}/${singleData?.category_image}`}
                                  alt={workProjectCategoryTitle}
                                  name="img"
                                />
                              )}

                              <Form.Control.Feedback type="invalid">
                                Please choose an image
                              </Form.Control.Feedback>
                            </Form.Group>

                            {/* category description */}
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom02"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Description
                              </Form.Label>

                              <RichTextEditor
                                joditRef={workProjectCategoryDescription}
                                getValue={getValue}
                                value={singleData?.category_description}
                              />
                            </Form.Group>

                            {/* Submit button */}
                            <button
                              type="submit"
                              className="btn-submit mt-5 rounded-3 border-0 flex_center"
                            >
                              <MdSystemUpdateAlt className="me-2" />
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}

                {/* View Modal section */}
                {modalData === "View" && (
                  <>
                    {singleData?.name && (
                      <h4 className="mb-3">
                        Category Name: {singleData?.name}
                      </h4>
                    )}
                    {singleData?.parent?.name && (
                      <h4 className="pb-4">
                        Parent category: {singleData?.parent?.name}
                      </h4>
                    )}

                    <img
                      src={`${BACKEND_BASE_URL}/${singleData?.category_image}`}
                      alt=""
                      className="img-thumbnail"
                    />
                    <div className="mt-2">
                      {singleData?.category_description &&
                        Parse(`${singleData?.category_description}`)}
                    </div>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setModalShow(false)}
                  className="btn-danger"
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminOurWorkProjectCategory;
