import axios from 'axios'
import dayjs from 'dayjs'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Col, Row, Tab, Tabs } from 'react-bootstrap'
import { AiOutlineClockCircle, AiOutlineMail, AiOutlinePlus } from 'react-icons/ai'
import { useParams } from 'react-router-dom'
import "./AsanaSingleUserInfo.css"
import { Button } from '@mui/material'

const AsanaSingleUserInfo = () => {
    const { userId } = useParams()
    const [userInfo, setUserInfo] = useState([])
    const [userTasks, setUserTasks] = useState([])
    const apiKey = '1/1181186354658771:8ce46a50ca6dd71089785591c1f34bc6';

    useEffect(() => {
        const userInfo = {
            method: 'GET',
            url: `https://app.asana.com/api/1.0/users/${userId}`,
            headers: { Authorization: `Bearer ${apiKey}`, }
        };
        axios
            .request(userInfo)
            .then(function (response) {
                setUserInfo(response.data?.data);
            })
        const userTasks = {
            method: 'GET',
            url: `https://app.asana.com/api/1.0/tasks?assignee=${userId}`,
            params: { workspace: '1181186357184897' },
            headers: { Authorization: `Bearer ${apiKey}`, }
        };
        axios
            .request(userTasks)
            .then(function (response) {
                setUserTasks(response.data?.data);
            })
    }, [userId])

    function extractInitials(inputString) {
        const regexPattern = /(?:\b\w|(?<=\.))+/g;
        const matches = inputString?.match(regexPattern);

        if (matches) {
            const initials = matches?.map(match => match[0].toUpperCase()).join('');
            return initials;
        } else {
            return null; // Return null if no initials are found in the input string.
        }
    }

    return (
        <div className="asana_dashboard_home custom_container mt-5">

            <Row>
                <Col md={8} className='me-auto mt-5'>
                    {/* <h6>{JSON.stringify(userInfo)}</h6>
                    <br />
                    <h6>{JSON.stringify(userTasks)}</h6> */}
                    <div className="user_info d-flex mb-5">
                        <div className='d-flex justify-content-center align-items-center'>
                            {userInfo.photo && <img src={userInfo.photo.image_128x128} alt="" />}
                            {!userInfo.photo && <div className="name_icon me-3">
                                <span>{extractInitials(userInfo.name)?.slice(0, 2)}</span>
                            </div>}
                            <div className="">
                                <h4>{userInfo.name}</h4>
                                <div className="d-flex">
                                    <div className="time me-3"><AiOutlineClockCircle color="#6d6e6f" className="me-1" size={18} />{dayjs(new Date().getTime()).format('hh:mma')} local time</div>
                                    <div className="mail"><AiOutlineMail color="#6d6e6f" className="me-1" size={18} />{userInfo.email}</div>
                                </div>
                            </div>
                        </div>



                    </div>
                    <div className="custom_container me-auto user_task_card">
                        <div className="d-flex justify-content-between mb-2">
                            <h4>Tasks</h4>
                            <button className='view_all_task_btn'>View all tasks</button>
                        </div>
                        <Tabs
                            defaultActiveKey="all"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            bsPrefix='task_tab'
                        >
                            <Tab eventKey="all" title="All" className='tab_body'>
                                <Button className='assign_task_btn' startIcon={<AiOutlinePlus size={13} color='#6d6e6f'/>}>
                                    Assign task
                                </Button>

                                {userTasks.map((task, i) => (
                                    <div key={i} className="d-flex justify-content-between task_list">
                                        <div>
                                            {task.name}
                                        </div>
                                    </div>
                                ))}
                            </Tab>
                            <Tab eventKey="Assigned_by_you" title="Assigned by you">
                                Assigned by you
                            </Tab>
                            <Tab eventKey="Collaborating_with_you" title="Collaborating with you" >
                                Collaborating with you
                            </Tab>
                            <p>If you have a particular design in mind then please give an example of the design or at least a screen so that i can understand what the design should be </p>
                        </Tabs>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default AsanaSingleUserInfo;