import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import getCookie from "../../../Component/GetCookie";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import { PassInputFieldFormControl } from "../../../Component/PassInputField";
import { ToastAlert } from "../../../Component/ToastAlert";
import Cookie from "cookie-universal";
import { control, Controller, useForm } from "react-hook-form";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";
const AdminProfileEdit = () => {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Fetch all User Info
  const [userInfo, setUserInfo] = useState([]);
  const cookies = Cookie();
  const fetchData = () => {
    axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/profile`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })

      .then((res) => {
        setUserInfo(res.data?.profile_info);
        // cookies.set(
        //   "LOGGED_IN_ADMIN_PROFILE_PIC",
        //   res.data?.user_profile?.profile_photo,
        //   { maxAge: 60 * 60 * 24 * 7 }
        // );
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  // form submit to backend
  const onSubmit = (data) => {

    const formdata = new FormData();
    formdata.append("_method", "PUT");

    // * General Info
    // if (data.firstName) {
    //   formdata.append("first_name", data.firstName);
    // }
    // if (data.lastName) {
    //   formdata.append("last_name", data.lastName);
    // }
    if (data.name) {
      formdata.append("first_name", data.name);
    }
    if (data.homePhone) {
      formdata.append("home_phone", data.homePhone);
    }
    if (data.cellPhone) {
      formdata.append("cell_phone", data.cellPhone);
    }
    if (data.userAddress) {
      formdata.append("address", data.userAddress);
    }
    if (data.gender) {
      formdata.append("gender", data.gender);
    }
    if (data.DOB) {
      formdata.append("dob", data.DOB);
    }
    if (data.govnId) {
      formdata.append("govt_id", data.govnId);
    }
    if (data.maritalStatus) {
      formdata.append("marital_status", data.maritalStatus);
    }
    if (data.spouseName) {
      formdata.append("spouse_name", data.spouseName);
    }
    if (data.spouseEmployer) {
      formdata.append("spouse_employer", data.spouseEmployer);
    }
    if (data.spouseWorkPhone) {
      formdata.append("spouse_work_phone", data.spouseWorkPhone);
    }
    if (data.country) {
      formdata.append("country", data.country);
    }

    // * Prev Job Info
    if (data.prevCompanyName) {
      formdata.append("prev_job_company_name", data.prevCompanyName);
    }
    if (data.prevJobPhone) {
      formdata.append("prev_job_phone", data.prevJobPhone);
    }
    if (data.prevJobTitle) {
      formdata.append("prev_job_title", data.prevJobTitle);
    }
    if (data.prevJobSupervisor) {
      formdata.append("prev_job_supervisor", data.prevJobSupervisor);
    }
    if (data.prevJobWorkLocation) {
      formdata.append("prev_job_work_location", data.prevJobWorkLocation);
    }
    if (data.prevJobEmail) {
      formdata.append("prev_job_email", data.prevJobEmail);
    }
    if (data.prevJobWorkPhone) {
      formdata.append("prev_job_work_phone", data.prevJobWorkPhone);
    }
    if (data.prevJobCellPhone) {
      formdata.append("prev_job_cell_phone", data.prevJobCellPhone);
    }
    if (data.prevJobStartDate) {
      formdata.append("prev_job_start_date", data.prevJobStartDate);
    }
    if (data.prevJobSalary) {
      formdata.append("prev_job_salary", data.prevJobSalary);
    }
    if (data.prevJobOtherSkills) {
      formdata.append("prev_job_other_skills", data.prevJobOtherSkills);
    }
    if (data.prevJobHobby) {
      formdata.append("prev_job_hobby", data.prevJobHobby);
    }
    if (data.prevJobAdditionalComments) {
      formdata.append(
        "prev_job_additional_comments",
        data.prevJobAdditionalComments
      );
    }

    // * Emergency Contact
    if (data.emergencyName1) {
      formdata.append("emergency_name1", data.emergencyName1);
    }
    if (data.emergencyRelationship1) {
      formdata.append("emergency_relationship1", data.emergencyRelationship1);
    }
    if (data.emergencyAddress1) {
      formdata.append("emergency_address1", data.emergencyAddress1);
    }
    if (data.emergencyPhone1) {
      formdata.append("emergency_phone1", data.emergencyPhone1);
    }
    if (data.emergencyEmail1) {
      formdata.append("emergency_email1", data.emergencyEmail1);
    }
    if (data.emergencyAdditionalInfo1) {
      formdata.append(
        "emergency_additional_information1",
        data.emergencyAdditionalInfo1
      );
    }

    if (data.emergencyName2) {
      formdata.append("emergency_name2", data.emergencyName2);
    }
    if (data.emergencyRelationship2) {
      formdata.append("emergency_relationship2", data.emergencyRelationship2);
    }
    if (data.emergencyAddress2) {
      formdata.append("emergency_address2", data.emergencyAddress2);
    }
    if (data.emergencyPhone2) {
      formdata.append("emergency_phone2", data.emergencyPhone2);
    }
    if (data.emergencyEmail2) {
      formdata.append("emergency_email2", data.emergencyEmail2);
    }
    if (data.emergencyAdditionalInfo2) {
      formdata.append(
        "emergency_additional_information2",
        data.emergencyAdditionalInfo2
      );
    }

    axios
      .post(`${BACKEND_BASE_URL}/api/v2/rianaire/profile/update`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })

      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            title: response.data?.message,
          });

          data.target.reset();
          fetchData();
        }
      });
  };

  return (
    <div className="main__container">
      <div className="body-wrapper ">
        <div className="p-4">
          <div
            className="bg-white p-3 admin_edit_profile"
            style={{ borderRadius: "8px", maxHeight: "85vh", overflow: "auto" }}
          >
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col
                  md="12"
                  style={{ backgroundColor: "#f8f8f8" }}
                  className="border mb-4"
                >
                  <Row>
                    <h2 className="my-4">General Information</h2>
                    {/* <Form.Group as={Col} className="mb-2">
                      <Form.Label className="">
                        First Name: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        defaultValue={userInfo?.first_name}
                        // ref={first_name}
                        {...register("firstName", {})}
                      />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                      <Form.Label className="">
                        Last Name: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=" Last Name"
                        defaultValue={userInfo?.last_name}
                        // ref={last_name}
                        {...register("lastName", {})}
                      />
                    </Form.Group> */}
                    <Form.Group as={Col} className="mb-2">
                      <Form.Label className="">
                        Name: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=" Name"
                        defaultValue={userInfo?.name}
                        // ref={last_name}
                        {...register("name", {})}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label>
                        Address: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        type="textarea"
                        rows={3}
                        placeholder="Enter your address"
                        className="mb-2"
                        defaultValue={userInfo?.address}
                        // ref={userAddress}
                        {...register("userAddress", {})}
                      />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                      <Form.Label className="">
                        Home Phone: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Home Phone"
                        defaultValue={
                          userInfo?.employee_information?.home_phone
                        }
                        // ref={first_name}
                        {...register("homePhone", {})}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="mb-2">
                      <Form.Label className="">
                        Cell Phone: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Cell Phone"
                        defaultValue={userInfo?.phone}
                        // ref={first_name}
                        {...register("cellPhone", {})}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="">Email:</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        disabled
                        defaultValue={userInfo?.email}
                        // ref={userEmail}
                        {...register("userEmail", {})}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="">Government ID:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Govn. ID"
                        defaultValue={userInfo?.employee_information?.govt_id}
                        // ref={userEmail}
                        {...register("govnId", {})}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="">Birth Date:</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Birth Date"
                        defaultValue={userInfo?.dob}
                        // ref={userEmail}
                        {...register("DOB", {})}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="">Marital Status:</Form.Label>
                      <Form.Select
                        type="text"
                        placeholder="Marital Status"
                        defaultValue={userInfo?.email}
                        {...register("maritalStatus", {})}
                      >
                        <option value="single">Single</option>
                        <option value="married">Married</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="">Spouse's Name:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Spouse's Name"
                        defaultValue={
                          userInfo?.employee_information?.spouse_name
                        }
                        // ref={userEmail}
                        {...register("spouseName", {})}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="">Spouse's Employer:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Spouse's Employer"
                        defaultValue={
                          userInfo?.employee_information?.spouse_employer
                        }
                        {...register("spouseEmployer", {})}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="">Spouse's Work Phone:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Spouse's Work Phone"
                        defaultValue={
                          userInfo?.employee_information?.spouse_work_phone
                        }
                        // ref={userEmail}
                        {...register("spouseWorkPhone", {})}
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label className=" mb-2">
                        Country or region
                      </Form.Label>
                      <Form.Select
                        className=""
                        type="text"
                        {...register("country", {})}
                      >
                        <option selected value="Bangladesh">
                          Bangladesh
                        </option>
                        <option value="United States">United States</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>
                </Col>
                <Col
                  md="12"
                  style={{ backgroundColor: "#f8f8f8" }}
                  className="border mb-4"
                >
                  <Row>
                    <h2 className="my-4">Previous Job Information</h2>
                    <Form.Group as={Col} className="mb-2">
                      <Form.Label className="">
                        Company Name: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Company Name"
                        defaultValue={
                          userInfo?.emp_previous_job_info?.company_name
                        }
                        // ref={first_name}
                        {...register("prevCompanyName", {})}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label>
                        Phone Number: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Phone Number"
                        className="mb-2"
                        defaultValue={userInfo?.emp_previous_job_info?.phone}
                        // ref={userAddress}
                        {...register("prevJobPhone", {})}
                      />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                      <Form.Label className="">
                        Title: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Title"
                        defaultValue={userInfo?.name}
                        // ref={first_name}
                        {...register("prevJobTitle", {})}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="mb-2">
                      <Form.Label className="">
                        Supervisor: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Supervisor"
                        defaultValue={
                          userInfo?.emp_previous_job_info?.supervisor
                        }
                        // ref={first_name}
                        {...register("prevJobSupervisor", {})}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="">Work Location:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Work Location"
                        defaultValue={
                          userInfo?.emp_previous_job_info?.work_location
                        }
                        // ref={userEmail}
                        {...register("prevJobWorkLocation", {})}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="">Email Address:</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email Address"
                        defaultValue={userInfo?.emp_previous_job_info?.email}
                        // ref={userEmail}
                        {...register("prevJobEmail", {})}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="">Work Phone:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Work Phone"
                        defaultValue={
                          userInfo?.emp_previous_job_info?.work_phone
                        }
                        // ref={userEmail}
                        {...register("prevJobWorkPhone", {})}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="">Cell Phone:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Cell Phone"
                        defaultValue={
                          userInfo?.emp_previous_job_info?.cell_phone
                        }
                        // ref={userEmail}
                        {...register("prevJobCellPhone", {})}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="">Start Date:</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Start Date"
                        defaultValue={
                          userInfo?.emp_previous_job_info?.start_date
                        }
                        // ref={userEmail}
                        {...register("prevJobStartDate", {})}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="">Salary Taka:</Form.Label>
                      <Form.Control
                        type="number"
                        min={0}
                        placeholder="Salary Taka"
                        defaultValue={userInfo?.emp_previous_job_info?.salary}
                        {...register("prevJobSalary", {})}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="">Other Skills:</Form.Label>
                      <Form.Control
                        as="textarea"
                        type="textarea"
                        rows={5}
                        placeholder="Other Skills"
                        defaultValue={
                          userInfo?.emp_previous_job_info?.other_skills
                        }
                        // ref={userEmail}
                        {...register("prevJobOtherSkills", {})}
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>
                        Hobby: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        placeholder="Hobby"
                        defaultValue={userInfo?.emp_previous_job_info?.hobby}
                        // ref={userPhone}
                        {...register("prevJobHobby", {})}
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label className=" mb-2">
                        Additional Comments
                      </Form.Label>
                      <Form.Control
                        className=""
                        type="text"
                        placeholder="Additional Comments"
                        defaultValue={
                          userInfo?.emp_previous_job_info?.additional_comments
                        }
                        {...register("prevJobAdditionalComments", {})}
                      />
                    </Form.Group>
                  </Row>
                </Col>
                <Col
                  md="12"
                  style={{ backgroundColor: "#f8f8f8" }}
                  className="border "
                >
                  <Row>
                    <h2 className="my-4">Employee Contact - 1</h2>
                    <Form.Group as={Col} className="mb-2">
                      <Form.Label className="">
                        Name: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        defaultValue={userInfo?.emp_emergency_contacts?.name1}
                        {...register("emergency_name1", {})}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label>
                        Address: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        type="textarea"
                        rows={3}
                        placeholder="Enter your address"
                        className="mb-2"
                        defaultValue={
                          userInfo?.emp_emergency_contacts?.address1
                        }
                        {...register("emergency_address1", {})}
                      />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                      <Form.Label className="">
                        Relationship: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Relationship"
                        defaultValue={
                          userInfo?.emp_emergency_contacts?.relationship1
                        }
                        {...register("emergency_relationship1", {})}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="mb-2">
                      <Form.Label className="">
                        Phone: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Phone"
                        defaultValue={userInfo?.emp_emergency_contacts?.phone1}
                        {...register("emergency_phone1", {})}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="">Email:</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        defaultValue={userInfo?.emp_emergency_contacts?.email1}
                        {...register("emergency_email1", {})}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="">
                        Additional Information:
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Govn. ID"
                        defaultValue={
                          userInfo?.emp_emergency_contacts
                            ?.additional_information1
                        }
                        {...register("emergency_additional_information1", {})}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <h2 className="my-4">Employee Contact - 2</h2>
                    <Form.Group as={Col} className="mb-2">
                      <Form.Label className="">
                        Name: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        defaultValue={userInfo?.emp_emergency_contacts?.name2}
                        {...register("emergency_name2", {})}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label>
                        Address: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        type="textarea"
                        rows={3}
                        placeholder="Enter your address"
                        className="mb-2"
                        defaultValue={
                          userInfo?.emp_emergency_contacts?.address2
                        }
                        {...register("emergency_address2", {})}
                      />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                      <Form.Label className="">
                        Relationship: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Relationship"
                        defaultValue={
                          userInfo?.emp_emergency_contacts?.relationship2
                        }
                        {...register("emergency_relationship2", {})}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="mb-2">
                      <Form.Label className="">
                        Phone: <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Phone"
                        defaultValue={userInfo?.emp_emergency_contacts?.phone2}
                        {...register("emergency_phone2", {})}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="">Email:</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        defaultValue={userInfo?.emp_emergency_contacts?.email2}
                        {...register("emergency_email2", {})}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="">
                        Additional Information:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Additional Information"
                        defaultValue={
                          userInfo?.emp_emergency_contacts
                            ?.additional_information2
                        }
                        {...register("emergency_additional_information2", {})}
                      />
                    </Form.Group>

                    <div className="flex_center">
                      <Button type="submit" className="btn_submit my-4">
                        Submit
                      </Button>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProfileEdit;
