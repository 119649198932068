import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AiOutlineClose, AiOutlinePlusCircle } from "react-icons/ai";
import { FiSave } from "react-icons/fi";
import { Link } from "react-router-dom";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import getCookie from "../../../../Component/GetCookie";

import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import { ToastAlert } from "../../../../Component/ToastAlert";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const DynamicModuleAdd = () => {
  const serviceName = useRef();
  const subServiceName = useRef();
  const childService = useRef();
  const [mainServices, setMainServices] = useState([]);
  const [subServices, setSubServices] = useState([]);
  const [childServices, setChildServices] = useState([]);
  
  const [dynamicdropdown, setDynamicdropdown] = useState([]);
  
  const [serviceTypeBox, setServiceTypeBox] = useState(true);
  const [sectionTypeBox, setSectionTypeBox] = useState(false);
  const [wordpressWebsiteType, setWordpressWebsiteType] = useState(false);
  const [serverType, setServerType] = useState(false);
  const [packageType, setPackageType] = useState(false);

  const handleServiceType = (e) => {

    if (
      // dev&Tech -> wordpress -> Page Design
      serviceName.current.value == 1 &&
      subServiceName.current.value == 1 &&
      childService.current.value == 1
    ) {
      setSectionTypeBox(false);
      setWordpressWebsiteType(true);
      setServerType(false);
      setPackageType(false);
    }
    if (
      //dev&Tech -> wordpress -> Complete Website
      serviceName.current.value == 1 &&
      subServiceName.current.value == 1 &&
      childService.current.value == 2
    ) {
      setSectionTypeBox(false);

      setWordpressWebsiteType(true);
      setServerType(false);
      setPackageType(false);
    }
    if (
      // dev&Tech -> shopify -> Section Design
      serviceName.current.value == 1 &&
      subServiceName.current.value == 2 &&
      childService.current.value == 4
    ) {
      setSectionTypeBox(true);
      setWordpressWebsiteType(false);
      setServerType(false);
      setPackageType(false);
    }
    if (
      // dev&Tech -> shopify -> Complete Website
      serviceName.current.value == 1 &&
      subServiceName.current.value == 2 &&
      childService.current.value == 5
    ) {
      setSectionTypeBox(false);
      setWordpressWebsiteType(false);
      setServerType(false);
      setPackageType(false);
    }
    if (
      // dev&Tech -> Web programming -> React JS , React Laravel, PHP Laravel
      serviceName.current.value == 1 &&
      subServiceName.current.value == 4 &&
      (childService.current.value == 7 ||
        childService.current.value == 8 ||
        childService.current.value == 9)
    ) {
      setWordpressWebsiteType(true);
      setSectionTypeBox(false);
      setServerType(false);
      setPackageType(false);
    }

    if (
      // dev&Tech -> Website Maintenance -> Server installation
      serviceName.current.value == 1 &&
      subServiceName.current.value == 5 &&
      childService.current.value == 12
    ) {
      setSectionTypeBox(false);
      setWordpressWebsiteType(false);
      setServerType(true);
      setPackageType(false);
    }

    if (
      // marketing -> digital marketing -> Social Media Management
      serviceName.current.value == 3 &&
      subServiceName.current.value == 11 &&
      childService.current.value == 21
    ) {
      setSectionTypeBox(false);
      setWordpressWebsiteType(true);
      setServerType(false);
      setPackageType(false);
    }
    if (
      // dev&Tech -> Wordpress -> Custom Page
      serviceName.current.value == 1 &&
      subServiceName.current.value == 1 &&
      childService.current.value == 32
    ) {
      setSectionTypeBox(false);
      setWordpressWebsiteType(false);
      setServerType(false);
      setPackageType(false);
    }

  };

  const fetchMainServices = async () => {
    await axios.get(`${BACKEND_BASE_URL}/api/v2/main-services`).then((res) => {
      setMainServices(res.data?.main_services);
    });
  };

  const fetchSubServices = (e) => {
    setSubServices([]);
    e.preventDefault();
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/sub-services/${serviceName.current.value}`
      )
      .then((res) => {
        setSubServices(res.data?.sub_services);
      });
  };

  const fetchChildService = () => {
    // UI/UX design
    if (subServiceName.current.value == 3) {

      setSectionTypeBox(false);
      setWordpressWebsiteType(false);
      setServerType(false);
      setServiceTypeBox(false);
      setPackageType(false);
    }
    //  Website Redesign
    else if (subServiceName.current.value == 6) {

      setSectionTypeBox(false);
      setWordpressWebsiteType(false);
      setServerType(false);
      setServiceTypeBox(true);
      setPackageType(false);
    }

    // else if (
    //   subServiceName.current.value == 13 ||
    //   subServiceName.current.value == 14
    // ) {
    //   setNoOfPageBox(false);
    //   setSectionTypeBox(false);
    //   setWordpressWebsiteType(false);
    //   setServerType(false);
    //   setServiceTypeBox(false);
    //   setPackageType(true);
    // }
    else {

      setSectionTypeBox(false);
      setWordpressWebsiteType(false);
      setServerType(false);
      setServiceTypeBox(true);
      setPackageType(false);
    }

    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/child-services/${serviceName.current.value}/${subServiceName.current.value}`
      )
      .then((res) => {
        setChildServices(res.data?.child_services);

      });
  };

  const fetchSectionType = () => {
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/dynamic-dropdown/${serviceName.current.value}/${subServiceName.current.value}/${childService.current.value}`
      )
      .then((res) => {
        setDynamicdropdown(res.data?.dynamic_dropdown_info);
      });
  };

  // Dynamic Input Field 1

  const [inputFields, setInputFields] = useState([
    {
      dynamicModule: "",
      usdPrice: "",
      bdtPrice: "",
      duration: "",
    },
  ]);

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;

    setInputFields(data);
  };

  const handleFileChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.files[0];
    setInputFields(data);
  };

  const addFields = () => {
    let newfield = {
      dynamicModule: "",
      usdPrice: "",
      bdtPrice: "",
      duration: "",
    };
    setInputFields([...inputFields, newfield]);
  };

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();
    // formdata.append("main_service_id", serviceName.current.value);
    // formdata.append("sub_service_id", subServiceName.current.value);
    // if (childService.current) {
    //   formdata.append("child_service_id", childService.current.value);
    // }
    inputFields.forEach((item) => {
      // if (item.sectiontype != "" && item.sectiontype != null) {
      //   formdata.append("section_type[]", item.sectiontype);
      // }
      if (item.dynamicModule != "" && item.dynamicModule != null) {
        formdata.append("dynamic_name[]", item.dynamicModule);
      }
      if (item.usdPrice != "" && item.usdPrice != null) {
        formdata.append("dynamic_usd_price[]", item.usdPrice);
      }
      if (item.bdtPrice != "" && item.bdtPrice != null) {
        formdata.append("dynamic_bdt_price[]", item.bdtPrice);
      }
      if (item.duration != "" && item.duration != null) {
        formdata.append("dynamic_duration[]", item.duration);
      }
    });

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/dynamic-module-cost/store`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            title: "Submitted Successfully",
          });

          setInputFields([
            {
              usdPrice: "",
              bdtPrice: "",
              duration: "",
              dynamicModule: "",
            },
          ]);

          setMainServices([]);
          setSubServices([]);
          fetchMainServices();
        }
      });
    e.preventDefault();
  };

  useEffect(() => {
    fetchMainServices();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar pageTitle="Add Dynamic Module" />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <>
                  <div className="card-header flex_between">
                    {JSON.parse(
                      localStorage.getItem("LOGGED_IN_USER_PERMISSION_INFO")
                    )?.map(
                      (data, index) =>
                        data?.permission_name === "create-service-cost" && (
                          <Link
                            to="/admin/service-module/additional-services"
                            key={index}
                          >
                            <Button variant="success" size="sm">
                              All Additional Services&nbsp;
                              <span>
                                <AiOutlinePlusCircle className="mb-1" />
                              </span>
                            </Button>
                          </Link>
                        )
                    )}
                  </div>

                  <div className="">
                    <Form onSubmit={storeData}>
                      <div className="content-wrapper">
                        <div className="row py-3">
                          {/* Main Service */}
                          {/* <Form.Group
                            as={Col}
                            md="6"
                            lg="4"
                            xl="4"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Main Service
                            </Form.Label>
                            <Form.Select
                              required
                              aria-label="language example "
                              className=""
                              ref={serviceName}
                              onChange={(e) => {
                                fetchSubServices(e);
                              }}
                            >
                              <option>Select Service</option>
                              {mainServices.map((parentCategory) => (
                                <option
                                  key={parentCategory.id}
                                  value={parentCategory.id}
                                >
                                  {parentCategory.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group> */}

                          {/* Sub Service Name */}
                          {/* <Form.Group
                            as={Col}
                            md="6"
                            lg="4"
                            xl="4"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Sub Service Name
                            </Form.Label>
                            <Form.Select
                              required
                              aria-label="language example "
                              className=""
                              ref={subServiceName}
                              onChange={(e) => {
                                fetchChildService(e);
                              }}
                            >
                              <option value="" selected>
                                Select Sub Service
                              </option>
                              {subServices?.length > 0 &&
                                subServices?.map((subCategory) => (
                                  <option
                                    key={subCategory.id}
                                    value={subCategory.id}
                                  >
                                    {subCategory.name}
                                  </option>
                                ))}
                            </Form.Select>
                          </Form.Group> */}

                          {/* Service Type */}
                          {/* {serviceTypeBox == true && (
                            <Form.Group
                              as={Col}
                              md="6"
                              lg="4"
                              xl="4"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Service Type
                              </Form.Label>
                              <Form.Select
                                required
                                aria-label="language example "
                                className=""
                                ref={childService}
                                onChange={(e) => {
                                  handleServiceType(e);
                                  fetchSectionType();
                                }}
                              >
                                <option value="0" selected>
                                  Select Service Type
                                </option>

                                {childServices?.map((data) => (
                                  <option key={data.id} value={data.id}>
                                    {data.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          )} */}
                        </div>

                        {inputFields?.map((input, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                className="p-3 mb-2"
                                style={{
                                  border: "1px solid #d1d1d1",
                                  backgroundColor: "ghostwhite",
                                }}
                              >
                                <Row>
                                  {/* Section Type */}
                                  {sectionTypeBox == true && (
                                    <Form.Group
                                      as={Col}
                                      md="6"
                                      lg="4"
                                      xl="3"
                                      controlId="validationCustom01"
                                      className="mb-3"
                                    >
                                      <Form.Label className="label fw-bold">
                                        Section Type
                                      </Form.Label>
                                      <Form.Select
                                        aria-label="language example "
                                        className=""
                                        name="sectiontype"
                                        onChange={(event) =>
                                          handleFormChange(index, event)
                                        }
                                      >
                                        <option value="">
                                          Select Section Type
                                        </option>

                                        {dynamicdropdown?.map((data) => (
                                          <option key={data.id} value={data.id}>
                                            {data.name}
                                          </option>
                                        ))}
                                      </Form.Select>
                                    </Form.Group>
                                  )}

                                  {serverType == true && (
                                    <Form.Group
                                      as={Col}
                                      md="6"
                                      lg="4"
                                      xl="3"
                                      controlId="validationCustom01"
                                      className="mb-3"
                                    >
                                      <Form.Label className="label fw-bold">
                                        Server Type
                                      </Form.Label>
                                      <Form.Select
                                        required
                                        aria-label="language example "
                                        className=""
                                        name="sectiontype"
                                        onChange={(event) =>
                                          handleFormChange(index, event)
                                        }
                                      >
                                        <option value="" disabled selected>
                                          Select Plugin Type
                                        </option>

                                        {dynamicdropdown?.map((data) => (
                                          <option key={data.id} value={data.id}>
                                            {data.name}
                                          </option>
                                        ))}
                                      </Form.Select>
                                    </Form.Group>
                                  )}

                                  {/* Website type (wordpress) */}
                                  {wordpressWebsiteType == true && (
                                    <Form.Group
                                      as={Col}
                                      md="6"
                                      lg="4"
                                      xl="3"
                                      className="mb-3"
                                    >
                                      <Form.Label className="label fw-bold">
                                        Website Type
                                      </Form.Label>

                                      <Form.Select
                                        required
                                        aria-label="language example "
                                        className=""
                                        name="sectiontype"
                                        onChange={(event) =>
                                          handleFormChange(index, event)
                                        }
                                      >
                                        <option value="" disabled selected>
                                          Select Website Type
                                        </option>

                                        {dynamicdropdown?.map((data) => (
                                          <option key={data.id} value={data.id}>
                                            {data.name}
                                          </option>
                                        ))}
                                      </Form.Select>
                                    </Form.Group>
                                  )}

                                  {/* Package Type (writing & translation) */}
                                  {packageType == true && (
                                    <Form.Group
                                      as={Col}
                                      md="6"
                                      lg="4"
                                      xl="3"
                                      className="mb-3"
                                    >
                                      <Form.Label className="label fw-bold">
                                        Package Type
                                      </Form.Label>

                                      <Form.Select
                                        required
                                        aria-label="language example "
                                        className=""
                                        name="sectiontype"
                                        onChange={(event) =>
                                          handleFormChange(index, event)
                                        }
                                      >
                                        <option value="" disabled selected>
                                          Select Package Type
                                        </option>

                                        {dynamicdropdown?.map((data) => (
                                          <option key={data.id} value={data.id}>
                                            {data.name}
                                          </option>
                                        ))}
                                      </Form.Select>
                                    </Form.Group>
                                  )}

                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    lg="4"
                                    xl="3"
                                    className="mb-3"
                                  >
                                    <Form.Label className="label fw-bold">
                                      Dynamic Module
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="additional services"
                                      name="dynamicModule"
                                      value={input.dynamicModule}
                                      onChange={(event) =>
                                        handleFormChange(index, event)
                                      }
                                    />
                                  </Form.Group>

                                  {/*USD Price */}
                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    lg="4"
                                    xl="3"
                                    className="mb-3"
                                  >
                                    <Form.Label className="label fw-bold">
                                      USD Price
                                    </Form.Label>
                                    <Form.Control
                                      type="number"
                                      step="0.01"
                                      min={0}
                                      placeholder="USD Price"
                                      name="usdPrice"
                                      value={input.usdPrice}
                                      onChange={(event) =>
                                        handleFormChange(index, event)
                                      }
                                    />
                                  </Form.Group>

                                  {/* BDT Price */}
                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    lg="4"
                                    xl="3"
                                    className="mb-3"
                                  >
                                    <Form.Label className="label fw-bold">
                                      BDT Price
                                    </Form.Label>
                                    <Form.Control
                                      type="number"
                                      step="0.01"
                                      min={0}
                                      placeholder="BDT Price"
                                      name="bdtPrice"
                                      value={input.bdtPrice}
                                      onChange={(event) =>
                                        handleFormChange(index, event)
                                      }
                                    />
                                  </Form.Group>

                                  {/* Project duration */}
                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    lg="3"
                                    xl="2"
                                    controlId="validationCustom01"
                                    className="mb-3"
                                  >
                                    <Form.Label className="label fw-bold">
                                      Duration
                                    </Form.Label>
                                    <Form.Control
                                      type="number"
                                      placeholder="Duration"
                                      name="duration"
                                      min="0"
                                      value={input.duration}
                                      onChange={(event) =>
                                        handleFormChange(index, event)
                                      }
                                    />
                                  </Form.Group>

                                  <div className="col-lg-1 col-xl-1 align-self-center mt-3">
                                    <div className="">
                                      <AiOutlinePlusCircle
                                        onClick={addFields}
                                        className="cursor"
                                        size="2rem"
                                      />
                                    </div>
                                  </div>
                                  <div className="d-inline">
                                    {inputFields.length > 1 && (
                                      <button
                                        onClick={() => removeFields(index)}
                                        className="remove_btn"
                                      >
                                        <AiOutlineClose size="1.5rem" />
                                      </button>
                                    )}
                                  </div>
                                </Row>
                              </div>
                            </>
                          );
                        })}

                        <div className="flex_center">
                          <Button
                            type="submit"
                            className="btn-submit mt-5 rounded-3 border-0"
                          >
                            <FiSave /> &nbsp; Save
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicModuleAdd;
