import { LinearProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import Parse from "html-react-parser";
import moment from "moment";
import Swal from "sweetalert2";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import RichTextEditor from "../../../Component/RichTextEditor";

import { AiOutlinePlusCircle } from "react-icons/ai";
import { MdDeleteForever, MdSystemUpdateAlt } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import { FiSave } from "react-icons/fi";
import getCookie from "../../../Component/GetCookie";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";

const BlogPost = () => {
  const postTitle = useRef();
  const postImage = useRef();
  const postDescription = useRef();
  const postCategory = useRef();
  const postAuthor = useRef();

  const [tableData, setTableData] = useState([]);
  const [blogCategory, setBlogCategory] = useState([]);
  const [value, setValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };

  // Modal Section Data
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("lg");

  // Get All Info
  const renderAllPost = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/blogs/posts`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setTableData(res.data?.all_blog_posts);
        setBlogCategory(res.data?.blog_categories);
      });
  };

  // Add new
  const addNewData = (modalValue) => {
    setFile([]);
    setModalSize("lg");
    setModalData(modalValue);
    setModalShow(true);
  };

  // Image Preview
  const [files, setFile] = useState([]);

  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  // Get Date
  var date = new Date();

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();
    formdata.append("title", postTitle.current.value);
    formdata.append("image", postImage.current.files[0]);
    formdata.append("description", postDescription.current.value);
    formdata.append("category_id", postCategory.current.value);
    formdata.append("author", postAuthor.current.value);
    formdata.append("post_date", moment(date).format("MMMM Y"));

    axios
      .post(`${BACKEND_BASE_URL}/api/v2/rianaire/blogs/posts/store`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })

      .then((response) => {
        if (response.data?.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllPost();
          setModalShow(false);
          setValue("", "html");
          e.target.reset();
        } else {
        }
      });
    e.preventDefault();
  };

  // View single value
  const [singlePostInfo, setSinglePostInfo] = useState([]);

  // View single Data
  const showSingleData = (modalValue, data) => {
    setModalSize("lg");
    setModalData(modalValue);
    setSinglePostInfo(data);
    setModalShow(true);
  };

  // Edit data

  const fetchDataForEdit = (modalValue, data) => {
    setFile([]);
    setModalSize("lg");
    setSinglePostInfo(data);
    setModalData(modalValue);
    setModalShow(true);
  };

  // Updated data to backend
  const updatePostInfo = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("title", postTitle.current.value);
    if (postImage.current.files[0]) {
      formdata.append("image", postImage.current.files[0]);
    }
    formdata.append("description", postDescription.current.value);
    formdata.append("category_id", postCategory.current.value);
    formdata.append("author", postAuthor.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/blogs/posts/update/${singlePostInfo.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        Swal.fire({
          icon: "success",
          text: response.data?.message,
          confirmButtonColor: "#5eba86",
        });
        setModalShow(false);
        renderAllPost();
      });

    e.preventDefault();
  };

  // Delete Data
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}/api/v2/rianaire/blogs/posts/delete/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllPost();
        });
    }
  };

  useEffect(() => {
    renderAllPost();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Blog Posts</h3>
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => addNewData("Add")}
                  >
                    Add &nbsp;
                    <span>
                      <AiOutlinePlusCircle className="mb-1" />
                    </span>
                  </Button>
                </div>
                {tableData.length > 0 ? (
                  <div className="table-responsive custom_table">
                    <div className="table_fixed">
                      <table className="table table-hover my-5">
                        <thead>
                          <tr>
                            <th scope="col">Handle</th>
                            <th scope="col">Title</th>
                            <th scope="col">Image</th>
                            <th scope="col">Category</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData?.map((data, index) => (
                            <tr key={index}>
                              <td>
                                <Dropdown style={{ position: "unset" }}>
                                  <Dropdown.Toggle>
                                    <BsThreeDotsVertical color="#000" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {/* view */}
                                    <Dropdown.Item
                                      onClick={() =>
                                        showSingleData("View", data)
                                      }
                                    >
                                      View
                                    </Dropdown.Item>

                                    {/* edit */}
                                    <Dropdown.Item
                                      onClick={() =>
                                        fetchDataForEdit("Edit", data)
                                      }
                                    >
                                      Edit
                                    </Dropdown.Item>

                                    {/* delete */}
                                    <Dropdown.Item
                                      onClick={() => deleteData(data.id)}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>{data?.title}</td>
                              <td>
                                <img
                                  src={`${BACKEND_BASE_URL}/${data?.image}`}
                                  alt={data?.title}
                                  className="img-fluid"
                                  height={80}
                                  width={150}
                                />
                              </td>
                              <td>{data?.category?.name}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <LinearProgress />
                )}
              </div>
            </div>
            <Modal
              show={modalShow}
              size={modalSize}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => setModalShow(false)}
            >
              <Modal.Header>
                <Modal.Title className="" id="contained-modal-title-vcenter">
                  {modalData}
                </Modal.Title>
                <Button
                  className="p-0 border-0 bg-danger"
                  onClick={() => setModalShow(false)}
                >
                  <CgClose
                    style={{
                      fontSize: "26px",
                    }}
                  />
                </Button>
              </Modal.Header>
              <Modal.Body>
                {/* Add Modal section */}
                {modalData === "Add" && (
                  <div className="">
                    <Form onSubmit={storeData}>
                      <div className="content-wrapper">
                        <div className="card">
                          <div className="card-body">
                            <Row className="py-3">
                              {/* Post Title */}
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom00"
                                className="mb-3"
                              >
                                <Form.Label required className="label fw-bold">
                                  Title
                                </Form.Label>

                                <Form.Control
                                  required
                                  type="text"
                                  placeholder="Title"
                                  ref={postTitle}
                                />
                              </Form.Group>

                              {/* Post Image */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Image Link
                                </Form.Label>

                                <Form.Control
                                  required
                                  type="file"
                                  ref={postImage}
                                  onChange={handleImgPreview}
                                />

                                {files.map((file, key) => {
                                  return (
                                    <div key={key} className="Row">
                                      <span className="Filename">
                                        <img
                                          width={80}
                                          height={50}
                                          src={URL.createObjectURL(file)}
                                          alt={file.name}
                                        />
                                      </span>
                                    </div>
                                  );
                                })}

                                <Form.Control.Feedback type="invalid">
                                  Please choose an image
                                </Form.Control.Feedback>
                              </Form.Group>

                              {/* Post Category */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label
                                  className="label fw-bold"
                                  id="section-lebel"
                                >
                                  Select Category
                                </Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  required
                                  ref={postCategory}
                                >
                                  <option value="0">Select Category</option>
                                  {blogCategory?.map((data, index) => {
                                    return (
                                      <option key={index} value={data.id}>
                                        {data?.name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>

                              {/* Post Author */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom00"
                                className="mb-3"
                              >
                                <Form.Label required className="label fw-bold">
                                  Author
                                </Form.Label>

                                <Form.Control
                                  required
                                  type="text"
                                  placeholder="Author"
                                  ref={postAuthor}
                                  defaultValue={sessionStorage.getItem(
                                    "LOGGED_IN_USER_NAME"
                                  )}
                                />
                              </Form.Group>

                              {/* Post Description */}
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom02"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Description
                                </Form.Label>

                                <RichTextEditor
                                  joditRef={postDescription}
                                  getValue={getValue}
                                  value={value}
                                />
                              </Form.Group>

                              {/* Submit button */}
                              <button
                                type="submit"
                                className="btn-submit mt-5 rounded-3 border-0"
                              >
                                <FiSave /> &nbsp; Save
                              </button>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                )}

                {/* Edit modal section */}
                {modalData === "Edit" && (
                  <Form onSubmit={updatePostInfo}>
                    <div className="content-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <Row className="py-3">
                            {/* Post Title */}
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom00"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Title
                              </Form.Label>

                              <Form.Control
                                type="text"
                                placeholder="Title"
                                ref={postTitle}
                                defaultValue={singlePostInfo?.title}
                              />
                            </Form.Group>

                            {/* Post Image */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Image Link
                              </Form.Label>

                              <Form.Control
                                type="file"
                                ref={postImage}
                                onChange={handleImgPreview}
                              />

                              {files.map((file, key) => {
                                return (
                                  <div key={key} className="Row">
                                    <span className="Filename">
                                      <img
                                        width={80}
                                        height={50}
                                        src={URL.createObjectURL(file)}
                                        alt={file.name}
                                      />
                                    </span>
                                  </div>
                                );
                              })}
                              {files.length == 0 && (
                                <img
                                  width={80}
                                  height={50}
                                  src={`${BACKEND_BASE_URL}/${singlePostInfo?.image}`}
                                  alt={postTitle}
                                  name="img"
                                />
                              )}

                              <Form.Control.Feedback type="invalid">
                                Please choose an image
                              </Form.Control.Feedback>
                            </Form.Group>

                            {/* Post Category */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label
                                className="label fw-bold"
                                id="section-lebel"
                              >
                                Select Category
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                ref={postCategory}
                              >
                                <option defaultValue="0">
                                  Select Department
                                </option>
                                {blogCategory?.map((data, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={data.id}
                                      selected={
                                        data.id == singlePostInfo?.category_id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {data?.name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Form.Group>

                            {/* Post Author */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom00"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Author
                              </Form.Label>

                              <Form.Control
                                type="text"
                                placeholder="Author"
                                ref={postAuthor}
                                defaultValue={singlePostInfo?.author}
                              />
                            </Form.Group>

                            {/* Post Description */}
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom02"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Description
                              </Form.Label>

                              <RichTextEditor
                                joditRef={postDescription}
                                getValue={getValue}
                                value={singlePostInfo?.description}
                              />
                            </Form.Group>

                            {/* Submit button */}
                            <button
                              type="submit"
                              className="btn-submit mt-5 rounded-3 border-0 flex_center"
                            >
                              <MdSystemUpdateAlt className="me-2" />
                              Update
                            </button>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}

                {/* View Modal section */}
                {modalData === "View" && (
                  <>
                    <h2>Title: {singlePostInfo?.title}</h2>
                    <div>
                      <img
                        src={`${BACKEND_BASE_URL}/${singlePostInfo?.image}`}
                        alt={singlePostInfo?.title}
                        className="img-fluid"
                      />
                    </div>
                    <h4>Author: {singlePostInfo?.author}</h4>
                    <h4>Category: {singlePostInfo?.category?.name}</h4>
                    <h4>Date: {singlePostInfo?.post_date}</h4>
                    <div className="mt-2">
                      {Parse(`${singlePostInfo?.description}`)}
                    </div>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setModalShow(false)}
                  className="btn-danger"
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
