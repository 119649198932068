import { Button } from "@mui/material"
import "./AsanaDashboard.css"
import { RiMenuLine } from "react-icons/ri"
import { AiOutlinePlus, AiOutlineTeam, AiOutlineCaretDown } from "react-icons/ai"
import { Accordion } from "react-bootstrap"
import axios from "axios"
import { useContext, useState } from "react"
import { BsChevronDown } from "react-icons/bs"
import { useEffect } from "react"
import { Link, NavLink, Outlet } from "react-router-dom"
import { UserContext } from "../../../../App"
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry"

const AsanaDashboard = () => {
    const [userTask, setUserTask] = useState([])
    const [asanaProjects, setAsanaProjects] = useState([])
    const [asanaTeam, setAsanaTeam] = useState([])
    const [asanaPeople, setAsanaPeople] = useState([])
    const apiKey = '1/1181186354658771:8ce46a50ca6dd71089785591c1f34bc6';

    useEffect(() => {
        const userTask = {
            method: 'GET',
            url: `https://app.asana.com/api/1.0/users/${getLocalStorageWithExpiry("adminUserInfo")?.email}`,
            headers: { Authorization: `Bearer ${apiKey}`, }
        };
        axios
            .request(userTask)
            .then(function (response) {
                setUserTask(response.data?.data);
            })
        const projects = {
            method: 'GET',
            url: 'https://api.asana.com/api/1.0/projects',
            params: {
                workspace: '1181186357184897',
                opt_fields: 'color,members.name,name,created_from_template.name,icon,followers.name'
            },
            headers: { Authorization: `Bearer ${apiKey}`, }
        };
        axios
            .request(projects)
            .then(function (response) {
                setAsanaProjects(response.data?.data);
            })
        const team = {
            method: 'GET',
            url: `https://app.asana.com/api/1.0/users/${getLocalStorageWithExpiry("adminUserInfo")?.email}/teams`,
            params: { organization: '1181186357184897' },
            headers: { Authorization: `Bearer ${apiKey}`, }
        };
        axios
            .request(team)
            .then(function (response) {
                setAsanaTeam(response.data?.data);
            })
        const people = {
            method: 'GET',
            url: `https://app.asana.com/api/1.0/workspaces/1181186357184897/users`,
            params: { opt_fields: 'name,email,photo,' },
            headers: { Authorization: `Bearer ${apiKey}`, }
        };
        axios
            .request(people)
            .then(function (response) {
                setAsanaPeople(response.data?.data);
            })




    }, [])


    return (<>
        <div className="asana_topbar">
            <RiMenuLine size={22} className="me-4" />
            <Button size="small" variant="outlined" className={`text-capitalize text-white rounded-pill create_btn`} startIcon={<div className="rounded-circle bg-danger flex_center"
                style={{ width: "17px", height: "17px" }}>
                <AiOutlinePlus color="#fff" size={15} />
            </div>}>
                <span className="" style={{ fontSize: "15px" }}>Create</span>
            </Button>
            <div className="ms-auto me-3">
                <Link to="/admin" className='text-white'>Back to dashboard</Link>
            </div>
        </div>
        <div className="asana_dashboard">

            <div className="sidebar">
                <div className={`mb-3 text-white top_section`}>
                    <li><NavLink to="/admin/asana-dashboard/">Home</NavLink></li>
                    <li> <NavLink to="my-tasks/" state={{ userTask }}>My tasks</NavLink> </li>
                    <li> <NavLink to="/" >Inbox</NavLink> </li>
                </div>
                <div >
                    <Accordion bsPrefix="projectList" defaultActiveKey="0" alwaysOpen>
                        <Accordion.Item eventKey="0" className="mb-3">
                            <Accordion.Header className="accordionHeader">Projects <AiOutlineCaretDown className="ms-4" /></Accordion.Header>
                            <Accordion.Body>
                                {asanaProjects.map((project) => (
                                    project?.members.map((member) => (member.name == (getLocalStorageWithExpiry("adminUserInfo")?.name)) && <li className="flex_start">
                                        <Link to={`projects/${project.gid}`}>
                                            <div className="color_label" style={{
                                                backgroundColor: `${(project?.color == `dark-red` && `#E53B52`)
                                                    || (project?.color == `dark-orange` && `#F96237`)
                                                    || (project?.color == `light-orange` && `#FA9A27`)
                                                    || (project?.color == ` dark-brown` && `#ECC42C`)
                                                    || (project?.color == `light-green` && `#A5CF3F`)
                                                    || (project?.color == `dark-green` && `#66D273`)
                                                    || (project?.color == `light-teal` && `#42C4AB`)
                                                    || (project?.color == `dark-teal` && `#32A9E8`)
                                                    || (project?.color == `light-blue` && `#4886DD`)
                                                    || (project?.color == `dark-purple` && ` #4886DD`)
                                                    || (project?.color == `light-purple` && `#A962E0`)
                                                    || (project?.color == `light-pink` && `#f9aaef`)
                                                    || (project?.color == `dark-pink` && `#E84F9C`)
                                                    || (project?.color == `light-red` && `#FA91AE`)
                                                    || (project?.color == `light-warm-gray` && `#8DA3A6`)
                                                    || (project?.color == `none` && `#C7C4C4`)}`
                                            }}></div>{project.name}
                                        </Link>
                                    </li>)
                                ))}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className="mb-3">
                            <Accordion.Header className="accordionHeader">Team <AiOutlineCaretDown className="ms-4" /></Accordion.Header>
                            <Accordion.Body>
                                {asanaTeam.map((team) => (
                                    <li className="text-truncate">
                                        <Link to={`team/${team.gid}`} state={{ teamName: team.name }}>
                                            <AiOutlineTeam className="me-2" /> {team.name}
                                        </Link>
                                    </li>
                                ))}
                            </Accordion.Body>
                        </Accordion.Item>
                        {getLocalStorageWithExpiry("adminUserInfo")?.role_id == 1 &&
                            <Accordion.Item eventKey="2" className="">
                                <Accordion.Header className="accordionHeader">People <AiOutlineCaretDown className="ms-4" /></Accordion.Header>
                                <Accordion.Body>
                                    {asanaPeople.map((people) => (
                                        <li className="text-truncate">
                                            <Link to={`users/${people.gid}`}>
                                                <AiOutlineTeam className="me-2" /> {people.name}
                                            </Link>
                                        </li>
                                    ))}
                                </Accordion.Body>
                            </Accordion.Item>
                        }

                    </Accordion>

                </div>
            </div>
            <div className="content">
                <Outlet />
            </div>
        </div >
    </>)
}

export default AsanaDashboard