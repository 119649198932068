import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { Link, useLocation } from "react-router-dom";

import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import RichTextEditor from "../../../../Component/RichTextEditor";
import { FiSave } from "react-icons/fi";
import getCookie from "../../../../Component/GetCookie";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const ExploreOpeningsAdd = () => {
  const { state } = useLocation();

  const { allDepartments, allLocations } = state;

  const jobTitle = useRef();
  const jobLocation = useRef();
  const jobDepartment = useRef();
  const jobDesc = useRef();

  const [errorMsg, setErrorMsg] = useState([]);

  const [value, setValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();
    formdata.append("title", jobTitle.current.value);
    if (jobDepartment.current.value != "0") {
      formdata.append("department_id", jobDepartment.current.value);
    }
    if (jobLocation.current.value != "0") {
      formdata.append("location_id", jobLocation.current.value);
    }
    formdata.append("description", jobDesc.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/explore-openings/job-posts/store`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        setErrorMsg("");
        if (response.data?.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          setErrorMsg("");
          e.target.reset();
          setValue("", "html");
        } else {
          setErrorMsg(response.data?.errors);
          // Swal.fire({
          //   icon: "danger",
          //   text: response.data?.errors,
          //   confirmButtonColor: "#5eba86",
          // });
        }
      });
    e.preventDefault();
  };

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Job Openings</h3>
                  <Link to={`/admin/career/explore-openings`}>
                    <Button variant="success" size="sm">
                      All Job Openings &nbsp;
                    </Button>
                  </Link>
                </div>
                <div className="">
                  <form onSubmit={storeData}>
                    <div className="content-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <div className="row py-3">
                            {/* Job Title */}
                            <div className="col-md-12">
                              <div className="form-group mb-3">
                                <div>
                                  <Form.Label className="label fw-bold">
                                    Job Title
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                </div>

                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Job Title"
                                  required
                                  ref={jobTitle}
                                />
                              </div>
                            </div>

                            {/* Department */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label
                                className="label fw-bold"
                                id="section-lebel"
                              >
                                Select Department
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                required
                                ref={jobDepartment}
                              >
                                <option value="0">Select Department</option>
                                {allDepartments?.map((data, index) => {
                                  return (
                                    <option key={index} value={data.id}>
                                      {data?.department_name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              <small className="text-danger">
                                {errorMsg.department_id}
                              </small>
                            </Form.Group>

                            {/* Country */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label
                                className="label fw-bold"
                                id="section-lebel"
                              >
                                Select Country
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                required
                                ref={jobLocation}
                              >
                                <option value="0">Select Country</option>
                                {allLocations.map((data, index) => {
                                  return (
                                    <option key={index} value={data.id}>
                                      {data.country_name}
                                    </option>
                                  );
                                })}
                              </Form.Select>

                              <small className="text-danger">
                                {errorMsg.location_id}
                              </small>
                            </Form.Group>

                            {/* Description */}

                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom02"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Description
                              </Form.Label>

                              <RichTextEditor
                                joditRef={jobDesc}
                                getValue={getValue}
                                value={value}
                              />
                            </Form.Group>
                            {/* Submit button */}
                            <button
                              type="submit"
                              className="btn-submit mt-5 rounded-3 border-0"
                            >
                              <FiSave /> &nbsp; Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreOpeningsAdd;
