import axios from 'axios';
import dayjs from 'dayjs';
import React, { useRef } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import "./AsanaDashboardHome.css"
import { Link } from 'react-router-dom';
import { AiOutlinePlus, AiOutlineUnorderedList } from 'react-icons/ai';
import { InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material';
import { Form, Col, Tab, Tabs } from 'react-bootstrap';
import { getLocalStorageWithExpiry } from '../../../../Component/LocalStorageWithExpiry';


const AsanaDashboardHome = () => {
    const projectName = useRef();
    const selectView = useRef();
    const time = dayjs();
    const [userInfo, setUserinfo] = useState([])
    const [allProjects, setAllProjects] = useState([])
    const [allPeoples, setAllPeoples] = useState([])
    const [projectModalShow, setProjectModalShow] = useState(false);
    const apiKey = '1/1181186354658771:8ce46a50ca6dd71089785591c1f34bc6';
    const pattern = /\b[A-Za-z]+\b/;
    const text = userInfo.name
    const match = text?.match(pattern);
    if (match) {
        var firstName = match[0];
    }
  
    useEffect(() => {
        const userInfo = {
            method: 'GET',
            url: `https://app.asana.com/api/1.0/users/${getLocalStorageWithExpiry("adminUserInfo")?.email}`,
            headers: { Authorization: `Bearer ${apiKey}`, }
        };
        axios
            .request(userInfo)
            .then(function (response) {
                setUserinfo(response.data?.data);
            })
        const projects = {
            method: 'GET',
            url: 'https://api.asana.com/api/1.0/projects',
            params: {
                workspace: '1181186357184897',
                opt_fields: 'color,members.name,name,created_from_template.name,icon,followers.name'
            },
            headers: { Authorization: `Bearer ${apiKey}`, }
        };
        axios
            .request(projects)
            .then(function (response) {
                setAllProjects(response.data?.data);
            })
        const peoples = {
            method: 'GET',
            url: `https://app.asana.com/api/1.0/workspaces/1181186357184897/users`,
            params: { opt_fields: 'name,email,photo,' },
            headers: { Authorization: `Bearer ${apiKey}`, }
        };
        axios
            .request(peoples)
            .then(function (response) {
                setAllPeoples(response.data?.data);
            })
    }, [])

    function extractInitials(inputString) {
        const regexPattern = /(?:\b\w|(?<=\.))+/g;
        const matches = inputString?.match(regexPattern);

        if (matches) {
            const initials = matches?.map(match => match[0].toUpperCase()).join('');
            return initials;
        } else {
            return null; // Return null if no initials are found in the input string.
        }
    }

    const handleAsanaPost = (e) => {
        e.preventDefault();

        const options = {
            method: 'POST',
            url: 'https://app.asana.com/api/1.0/projects',
            headers: { Authorization: `Bearer ${apiKey}`, 'content-type': 'application/json' },
            data: { data: { workspace: '1181186357184897', name: projectName?.current?.value, default_view: selectView?.current?.value } }
        };

        axios
            .request(options)
            .then(function (response) {
                console.log(response.data);
            })
            .catch(function (error) {
                console.error(error);
            });

    };
    return (
        <div className="asana_dashboard_home mt-5" data-aos="zoom-out">
            <h3 className="pt-4 ms-4">Home</h3>


            <div className='text-center'>
                <span className='fw-bold lead'>{dayjs(new Date()).format('dddd, MMMM DD')}</span>
                <h2 className='pt-3 mb-4'>Good {(time.format("H") < 12) ? `morning` : (time.format("H") >= 12 && (time.format("H") < 19)) ? `afternoon` : `evening`} , {firstName}</h2>
                <div className="task_nav flex_between px-4 py-3 rounded-5 mb-4" >
                    <div>My week</div>
                    <div> 0 Tasks completed</div>
                    <div>0 collaboratores</div>
                </div>
            </div>
            <div className="custom_container mx-auto dashboard_card_wrapper ">
                <div className="dashboard_card p-0">
                    <div className="task_bar flex_start">
                        {userInfo?.photo ? <img src={userInfo?.photo?.image_60x60} alt="" className='rounded-circle' /> : <div className="my_task_icon_box"></div>}
                        <h5 className='mb-0 ms-3'> My tasks</h5>
                    </div>
                    <Tabs
                        defaultActiveKey="upcoming"
                        id="noanim-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="upcoming" title="Upcoming">


                        </Tab>
                        <Tab eventKey="overdue" title="Overdue">
                            Tab content for Profile
                        </Tab>
                        <Tab eventKey="completed" title="Completed" >
                            Tab content for Contact
                        </Tab>
                    </Tabs>

                </div>
                <div className="dashboard_card">
                    <h5>Projects</h5>
                    <div className="row ">
                        <div className="col-md-6">

                            <div className="project_name" onClick={() => { setProjectModalShow(true) }}>
                                <div className="create_project_icon_box"><AiOutlinePlus size={22} color="grey" /></div>
                                <p className='mb-0'>Create project</p>
                            </div>
                        </div>
                        {allProjects.map((project) => (
                            project?.members.map((member) => (member.name === getLocalStorageWithExpiry("adminUserInfo")?.name) && <li className="flex_start">
                                <Link to={`projects/${project.gid}`}>
                                    <div className="project_name">
                                        <div className="icon_box" style={{
                                            backgroundColor: `${(project?.color == `dark-red` && `#E53B52`)
                                                || (project?.color == `dark-orange` && `#F96237`)
                                                || (project?.color == `light-orange` && `#FA9A27`)
                                                || (project?.color == ` dark-brown` && `#ECC42C`)
                                                || (project?.color == `light-green` && `#A5CF3F`)
                                                || (project?.color == `dark-green` && `#66D273`)
                                                || (project?.color == `light-teal` && `#42C4AB`)
                                                || (project?.color == `dark-teal` && `#32A9E8`)
                                                || (project?.color == `light-blue` && `#4886DD`)
                                                || (project?.color == `dark-purple` && ` #4886DD`)
                                                || (project?.color == `light-purple` && `#A962E0`)
                                                || (project?.color == `light-pink` && `#f9aaef`)
                                                || (project?.color == `dark-pink` && `#E84F9C`)
                                                || (project?.color == `light-red` && `#FA91AE`)
                                                || (project?.color == `light-warm-gray` && `#8DA3A6`)
                                                || (project?.color == `none` && `#C7C4C4`)}`
                                        }}><AiOutlineUnorderedList size={22} color="#fff" /></div>
                                        <p className='mb-0'>{project.name}</p>
                                    </div>
                                </Link>
                            </li>)
                        ))}
                    </div>
                </div>

            </div>
            <div className="custom_container mx-auto people_card_wrapper">
                <div className="dashboard_card">
                    <h5>People</h5>
                    <div className="row ">
                        <div className="col-md-4">
                            <div className="people_name " onClick={() => { setProjectModalShow(true) }}>
                                <div className="create_project_icon_box"><AiOutlinePlus size={22} color="grey" /></div>
                                <p className='mb-0'>Invite</p>
                            </div>
                        </div>
                        {allPeoples.map((people) => (
                            <div className="col-md-4">
                                <Link to={`users/${people.gid}`}>
                                    <div className="people_name">
                                        {people?.photo ? <img src={people?.photo?.image_60x60} alt="" style={{ borderRadius: "50%", marginRight: "15px" }} /> : <div className="icon_box"> <span>{extractInitials(people.name)?.slice(0, 2)}</span> </div>}
                                        <p className='mb-0'>{people.name}</p>
                                    </div>


                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Modal
                open={projectModalShow}
                onClose={() => setProjectModalShow(false)}
                className='asana_new_project_modal'
            >
                <div className='modal_body'>
                    <h2 class="title lead mb-5">New project</h2>
                    <div className='form_box'>
                        <Form onSubmit={handleAsanaPost}>
                            <Form.Group as={Col} className="position-relative d-flex flex-column mb-4">

                                <Form.Label>
                                    Project name
                                </Form.Label>
                                <TextField required hiddenLabel size="small" id="filled-basic" variant="filled" ref={projectName} />

                            </Form.Group>
                            <Form.Group as={Col} className="position-relative d-flex flex-column mb-4">
                                <InputLabel id="demo-select-small-label">Starting View</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    size="small"
                                    ref={selectView}
                                >
                                    <MenuItem value="">
                                        <em>  Select a starting view</em>
                                    </MenuItem>
                                    <MenuItem value="list"> List</MenuItem>
                                    <MenuItem value="board">board</MenuItem>
                                    <MenuItem value="calender">Calender</MenuItem>
                                </Select>
                            </Form.Group>

                            <button type="submit" className='asana_create_project_btn'>Submit</button>
                        </Form>
                    </div>
                </div>
            </Modal>

        </div>
    )
}

export default AsanaDashboardHome