import { LinearProgress } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import { ToastAlert } from "../../../Component/ToastAlert";
import getCookie from "../../../Component/GetCookie";
import { Link, useParams } from "react-router-dom";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { PassInputFieldFormControl } from "../../../Component/PassInputField";
import { FiSave } from "react-icons/fi";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";

const RegisteredUserEdit = () => {
  const { userId } = useParams();
  const name = useRef();
  const email = useRef();
  const password = useRef();
  const confirmPassword = useRef();
  const phone = useRef();

  const [userEmailError, setUserEmailError] = useState();
  const [userPassError, setUserPassError] = useState();

  // single table data
  const [singleData, setSingleData] = useState([]);

  // Get Single Info
  const renderSingleData = async () => {
    await axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/registered-user-management/edit/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        setSingleData(res.data?.edit_user_info);
      });
  };

  // form submit to backend
  const updateData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("name", name.current.value);
    formdata.append("email", email.current.value);
    if (password.current.value) {
      formdata.append("password", password.current.value);
    }
    formdata.append("phone", phone.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/registered-user-management/update/${singleData?.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            title: "updated successfully",
          });
          renderSingleData();
        } else {
          setUserEmailError(response.data?.errors?.email);
          setUserPassError(response.data?.errors?.password);

          //   ToastAlert.fire({
          //     icon: "warning",
          //     text: "Something went wrong, try again",
          //     confirmButtonColor: "#5eba86",
          //   });
        }
      });
    e.preventDefault();
  };

  useEffect(() => {
    renderSingleData();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Registered User</h3>
                  <Link to="/admin/registered-user">
                    <Button variant="success" size="sm">
                      All registered user &nbsp;
                      <span>
                        <AiOutlinePlusCircle className="mb-1" />
                      </span>
                    </Button>
                  </Link>
                </div>
                <div className="content-wrapper">
                  <Form id="form" className="mt-4" onSubmit={updateData}>
                    <Row>
                      {/* ================== Name =================== */}
                      <Form.Group className="mb-4" as={Col} md="6">
                        <Form.Label>
                          Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your name"
                          ref={name}
                          defaultValue={singleData?.name}
                        />
                      </Form.Group>
                      {/* ================== Email =================== */}
                      <Form.Group className="mb-4" as={Col} md="6">
                        <Form.Label>
                          Email <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          id="email"
                          placeholder="Enter email"
                          name="email"
                          ref={email}
                          defaultValue={singleData?.email}
                        />
                        <small className="small_msg text-danger">
                          {userEmailError && userEmailError}
                        </small>
                      </Form.Group>

                      {/* ============== Password ===================== */}
                      <Form.Group className="mb-4" as={Col} md="6">
                        <Form.Label>
                          Password <span className="text-danger">*</span>
                        </Form.Label>
                        <PassInputFieldFormControl
                          placeholderValue="Password"
                          inputRef={password}
                        />
                        <small className="small_msg text-danger">
                          {userPassError && userPassError}
                        </small>
                      </Form.Group>

                      <Form.Group className="mb-4" as={Col} md="6">
                        <Form.Label>
                          Phone <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your name"
                          ref={phone}
                        />
                        {/* <small className="small_msg text-danger">
                      {adminEmailError && adminEmailError}
                        </small> */}
                      </Form.Group>

                      <div className="flex_center">
                        <button
                          type="submit"
                          className="btn-submit mt-5 rounded-3 border-0 "
                        >
                          <FiSave /> Update
                        </button>
                      </div>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisteredUserEdit;
