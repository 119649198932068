import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../Component/GlobalVariables";
import axios from "axios";
import Swal from "sweetalert2";
import { ToastAlert } from "../../Component/ToastAlert";
import AdminDashboardNavbar from "../../Component/AdminDashboardNavbar";
import { LinearProgress } from "@mui/material";
import { AiOutlineClose, AiOutlinePlusCircle } from "react-icons/ai";
import { FaCheck, FaEye } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import getCookie from "../../Component/GetCookie";
import { getLocalStorageWithExpiry } from "../../Component/LocalStorageWithExpiry";


const QuickServices = () => {
  const serviceTitle = useRef();
  const slugName = useRef();

  const [validated, setValidated] = useState(false);

  // Modal Section Data
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("lg");

  // Fetch Table Data
  const [tableData, setTableData] = useState([]);
  const fetchData = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/quick-services`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setTableData(res.data?.quick_services);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Add new
  const addNewData = (modalValue) => {
    setModalSize("lg");
    setModalData(modalValue);
    setModalShow(true);
  };

  // View Single Data
  const [singleData, setSingleData] = useState([]);
  const showSingleData = (modalValue, data) => {
    setModalSize("lg");
    setModalData(modalValue);
    setSingleData(data);
    setModalShow(true);
  };

  // Form submit to backend
  const store = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);

    const formdata = new FormData();
    formdata.append("service_name", serviceTitle.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/quick-services/store`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          ToastAlert.fire({
            title: response.data?.message,
          });
          e.target.reset();
          fetchData();
          setValidated(false);
          setModalShow(false);
        }
      });
    e.preventDefault();
  };

  // Edit data
  const fetchDataForEdit = (modalValue, data) => {
    setModalSize("lg");
    setModalData(modalValue);
    setSingleData(data);
    setModalShow(true);
  };

  // Updated data to backend
  const updateData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("service_name", serviceTitle.current.value);
    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/quick-services/update/${singleData.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        ToastAlert.fire({
          icon: "success",
          title: response.data?.message,
        });
        setModalShow(false);
        fetchData();
      });

    e.preventDefault();
  };

  // Delete Data
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}/api/v2/rianaire/quick-services/delete/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data.message,
            confirmButtonColor: "#5eba86",
          });
          fetchData();
        });
    }
  };

  const StatusUpdate = (id) => {
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/quick-services/status-update/${id}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        fetchData();
      });
  };

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Quick Services</h3>
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => addNewData("Add")}
                  >
                    Add &nbsp;
                    <span>
                      <AiOutlinePlusCircle className="mb-1" />
                    </span>
                  </Button>
                </div>
                {tableData.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table table-hover ">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Title</th>
                          <th scope="col">Is Home</th>
                          <th scope="col">Handle</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.map((data, index) => (
                          <tr key={index}>
                            <td> {index + 1}</td>
                            <td>{data.service_name}</td>
                            <td>
                              {data.is_home == 1 ? (
                                <Link
                                  to=""
                                  onClick={() => StatusUpdate(data.id)}
                                >
                                  <FaCheck
                                    style={{
                                      color: "white",
                                      backgroundColor: "green",

                                      padding: "2px 4px",
                                      borderRadius: "4px",
                                    }}
                                    size="1.6em"
                                  />
                                </Link>
                              ) : (
                                <Link
                                  to=""
                                  onClick={() => StatusUpdate(data.id)}
                                >
                                  <AiOutlineClose
                                    style={{
                                      color: "white",
                                      backgroundColor: "red",

                                      padding: "2px 4px",
                                      borderRadius: "4px",
                                    }}
                                    size="1.6em"
                                  />
                                </Link>
                              )}
                            </td>
                            <td>
                              {/* view button */}
                              <button
                                onClick={() => showSingleData("View", data)}
                                className="py-1 px-2 bg-info border-0 rounded-3 me-1"
                              >
                                <FaEye
                                  style={{
                                    color: "white",
                                  }}
                                  title="View"
                                  size="1.5em"
                                />{" "}
                              </button>
                              {/* edit button */}
                              <button
                                onClick={() => fetchDataForEdit("Edit", data)}
                                className="py-1 px-2 bg-warning border-0 rounded-3 me-1 mb-1"
                              >
                                <BiEdit
                                  style={{
                                    color: "white",
                                  }}
                                  title="Edit"
                                  size="1.5em"
                                />
                              </button>
                              {/* delete button */}
                              <button
                                onClick={() => deleteData(data.id)}
                                className="py-1 px-2 bg-danger border-0 rounded-3 me-1 mb-1"
                              >
                                <MdDeleteForever
                                  style={{
                                    color: "white",
                                  }}
                                  title="Delete"
                                  size="1.5em"
                                />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <LinearProgress />
                )}
              </div>
            </div>

            <Modal
              show={modalShow}
              size={modalSize}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => setModalShow(false)}
            >
              <Modal.Header>
                <Modal.Title
                  className="text-white"
                  id="contained-modal-title-vcenter"
                >
                  {modalData}
                </Modal.Title>
                <Button
                  className="p-0 border-0 bg-danger"
                  onClick={() => setModalShow(false)}
                >
                  <CgClose
                    style={{
                      fontSize: "26px",
                    }}
                  />
                </Button>
              </Modal.Header>
              <Modal.Body>
                {/* Add Modal section */}
                {modalData === "Add" && (
                  <div className="">
                    <Form onSubmit={store} noValidate validated={validated}>
                      <div className="content-wrapper">
                        <div className="card">
                          <div className="card-body">
                            <Row className="mb-3">
                              {/* content name */}
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Service Name
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  placeholder=" Enter service name"
                                  ref={serviceTitle}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Service name is required
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>

                            <Button
                              type="submit"
                              className="btn-submit mt-5 rounded-3 border-0 d-flex justify-content-center"
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                )}

                {/* Edit modal section */}
                {modalData === "Edit" && (
                  <form onSubmit={updateData}>
                    <div className="content-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <div className="row py-3">
                            {/* content Name */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Service Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                required
                                ref={serviceTitle}
                                defaultValue={singleData?.service_name}
                              />
                            </Form.Group>
                            {/* Slug */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Slug
                              </Form.Label>
                              <Form.Control
                                disabled
                                type="text"
                                placeholder="Enter your slug"
                                ref={slugName}
                                defaultValue={singleData?.slug}
                              />
                            </Form.Group>

                            {/* Submit button */}
                            <button
                              type="submit"
                              className="btn-submit mt-5 rounded-3 border-0"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}

                {/* View Modal section */}
                {modalData === "View" && (
                  <>
                    <h4>Name: {singleData?.service_name}</h4>
                    <h6>Slug: {singleData?.slug}</h6>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setModalShow(false)}
                  className="btn-danger"
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickServices;
