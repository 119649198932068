import axios from "axios";
import React, { useContext, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { TbArrowsRightLeft } from "react-icons/tb";
import { Link, useParams } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import { LinearProgress } from "@mui/material";
import moment from "moment";
import getCookie from "../../../Component/GetCookie";
import { useReactToPrint } from "react-to-print";
import { AiOutlinePrinter } from "react-icons/ai";
import "./orderQuote.css";
import { UserContext } from "../../../App";
import { ToastAlert } from "../../../Component/ToastAlert";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";

const AdminOrderQuoteView = () => {
  const { quoteId } = useParams();
  const { currUserInfo } = useContext(UserContext);
  const [singleData, setSingleData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  var count = 0;
  const date = new Date();
  // single quote info
  const renderAllInfo = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/get-quote/show/${quoteId}`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setSingleData(res.data?.single_info);
      });
  };

  const handleOrder = async () => {
    await axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/get-quote/transfer-order/${quoteId}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        ToastAlert.fire({
          icon: "success",
          title: " get quote to order converted",
        });
        setIsLoading(false);
        setError(false);
        renderAllInfo();
      });
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "quote",
  });

  useEffect(() => {
    renderAllInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div
              className="card-body"
              style={{ maxHeight: "90vh", overflowY: "auto" }}
            >
              <div className="">
              
                <div className="row email_template">
                  <div className="col-md-6">
                    <h5 className="fw-bold pt-1">Project Details</h5>
                    <hr style={{color:"#c7c7c7" , width:"75%"}} />
                    {singleData?.main_service_info?.name ? (
                      <div className="my-4">
                        <h6>
                          <strong>Project Name : </strong>
                          {singleData?.project_name}
                        </h6>
                        <h6>
                          <strong>About Project : </strong>
                          {singleData?.about_project}
                        </h6>
                        <h6>
                          <strong>Update Site Content : </strong>
                          {singleData?.update_site_content}
                        </h6>
                        <h6>
                          <strong>LInk :</strong>
                        </h6>
                        <h6>
                          <strong>Parent Service Name : </strong>
                          {singleData?.main_service_info?.name}
                        </h6>
                        <h6>
                          <strong>Sub Service Name : </strong>
                          {singleData?.sub_service_info?.name}
                        </h6>
                        <h6>
                          {singleData?.child_service_info?.name && (
                            <>
                              <strong>Service Type : </strong>
                              {singleData?.child_service_info?.name}
                            </>
                          )}

                          {singleData?.web_maintanance_info?.bdt_price &&
                          currUserInfo?.countryCode == "BD"
                            ? singleData?.web_maintanance_info?.bdt_price +
                              " tk"
                            : singleData?.web_maintanance_info?.usd_price}
                          {/* {singleData?.pages_info && currUserInfo?.countryCode == "BD"
                            ? singleData?.pages_info?.bdt_price + " Tk"
                            : "$ " + singleData?.pages_info?.usd_price} */}
                        </h6>
                        <h6>
                          {singleData?.section_type_dropdown && (
                            <>
                              <strong>Website Type : </strong>
                              {singleData?.section_type_dropdown?.split(",")}
                            </>
                          )}
                        </h6>
                        <h6>
                          {singleData?.pages_info && (
                            <>
                              <strong>Number of Pages :</strong>
                              {singleData?.pages_info?.number_of_page}{" "}
                              {`${
                                singleData?.pages_info?.number_of_page == 1
                                  ? "page"
                                  : "pages"
                              }`}
                            </>
                          )}
                        </h6>
                        <h6>
                          {singleData?.additional_app_plugin_info?.length >
                            0 && (
                            <>
                              <strong>Included App/Plugin: </strong>
                              {singleData?.additional_app_plugin_info?.map(
                                (info) => (
                                  <h6 className="mb-1">
                                    {" "}
                                    {info?.name}
                                    {" - "}
                                    {currUserInfo?.countryCode == "BD"
                                      ? info?.bdt_price + " Tk"
                                      : +"$ " + info?.usd_price}{" "}
                                  </h6>
                                )
                              )}
                            </>
                          )}
                        </h6>
                        <h6>
                          {singleData?.additional_service_info?.length > 0 && (
                            <>
                              <strong> Supplimentary:</strong>
                              {singleData?.additional_service_info?.map(
                                (info) => (
                                  <h6 className="mb-1">
                                    {info?.name}
                                    {" - "}
                                    {currUserInfo?.countryCode == "BD"
                                      ? info?.bdt_price + " Tk"
                                      : +"$ " + info?.usd_price}{" "}
                                  </h6>
                                )
                              )}
                            </>
                          )}
                        </h6>
                        {/* <h6>
                          {singleData?.dynamic_module_info?.length > 0 && (
                            <>
                              <strong>Dynamic Module: </strong>
                              {singleData?.dynamic_module_info?.map((info) => (
                                <h6 className="mb-1">
                                  {" "}
                                  {info?.name}
                                  {" - "}
                                  {currUserInfo?.countryCode == "BD"
                                    ? info?.bdt_price + " Tk"
                                    : +"$ " + info?.usd_price}{" "}
                                </h6>
                              ))}
                            </>
                          )}
                        </h6> */}
                        <h6>
                          <strong>Total Cost : </strong>
                          {currUserInfo?.countryCode == "BD"
                            ? singleData?.total_bdt_price + " Tk"
                            : "$ " + singleData?.total_usd_price}
                        </h6>
                        <h6>
                          <strong>Total Duration : </strong>
                          {singleData?.total_duration}
                        </h6>

                        <div className="d-flex print_btn mt-4 pt-2 ">
                          <button className="me-4" onClick={handlePrint}>
                            Print Quote <AiOutlinePrinter />
                          </button>
                          {singleData?.is_convert == "0" && (
                            <button onClick={handleOrder}>
                              Convert to Order{" "}
                              <TbArrowsRightLeft className="ms-1" />
                            </button>
                          )}
                        </div>
                      </div>
                    ) : (
                      <LinearProgress />
                    )}
                  </div>
                  <div className="col-md-6">
                  <h5 className="fw-bold pt-1">Client Details</h5>
                  <hr style={{color:"#c7c7c7" , width:"75%"}} />
                    {singleData?.main_service_info?.name ? (
                      <div className="my-4">
                        <h6>
                          <strong>Name : </strong>
                          {singleData?.name}
                        </h6>
                        <h6>
                          <strong>Email : </strong>
                          {singleData?.email}
                        </h6>
                        <h6>
                          <strong>Phone : </strong>
                          {singleData?.phone}
                        </h6>
                        <h6>
                          <strong>Company : </strong>
                          {singleData?.company}
                        </h6>
                        <h6>
                          <strong>Address : </strong>
                          {singleData?.address}
                        </h6>
                      </div>
                    ) : (
                      <LinearProgress />
                    )}
                  </div>
                  <div className="col-md-12 p-3">
                    <div className="border">
                      <div
                        className="pdf_wrapper"
                        style={{ marginLeft: "50px", marginRight: "50px" }}
                        ref={componentRef}
                      >
                        <div
                          className="table_header "
                          style={{ marginTop: "103px" }}
                        >
                          <div className="flex_between">
                            <div className="d-flex flex-column">
                              <img
                                className="mb-2"
                                src={require("../../../Assets/logo.png")}
                                alt=""
                                width={150}
                              />
                              <p>PROJECT NO: {singleData?.invoice_no}</p>
                            </div>

                            <p>
                              Date issued:{" "}
                              {moment(singleData?.updated_at).format(
                                "DD MMM, Y"
                              )}
                            </p>
                          </div>
                        </div>
                        <hr style={{ border: "2px solid #8080804d" }} />
                        <div className="order_list">
                          <div className="d-flex align-items-center">
                            <div className="ms-4 ps-2" style={{ width: "65%" }}>
                              <h4 className="fw-bold">
                                <i>Estimation For</i>
                              </h4>
                              <h6>
                                <span>
                                  {singleData?.sub_service_info?.slug !=
                                    "web-programming" &&
                                    singleData?.sub_service_info?.name}
                                </span>{" "}
                                <span>
                                  {singleData?.sub_service_info?.slug ==
                                    "wordpress" &&
                                  singleData?.child_service_info?.name ==
                                    "Complete Website" ? (
                                    ""
                                  ) : (
                                    <>{singleData?.child_service_info?.name}</>
                                  )}
                                  &nbsp;
                                  {singleData?.section_type_dropdown && (
                                    <>
                                      {singleData?.main_service_id != "3" &&
                                        singleData?.main_service_id != "4" &&
                                        singleData?.section_type_dropdown}
                                    </>
                                  )}
                                </span>
                              </h6>
                              <div
                                style={{
                                  borderTop: "2px solid #8080804d",
                                  borderBottom: "2px solid #8080804d",
                                }}
                                className="my-4"
                              >
                                <p
                                  className="py-3 mb-0"
                                  style={{
                                    fontSize: "11px",
                                    lineHeight: "17px",
                                  }}
                                >
                                  Dear {singleData?.name}, <br /> Thank you for
                                  providing us with the opportunity to do
                                  business with you. Below you'll find an
                                  estimate for each service you'll need in the
                                  future to do your work efficiently. If you
                                  believe these services need your assistance,
                                  please review them and contact us. <br />
                                  Thanks
                                </p>
                              </div>
                            </div>
                            <div
                              style={{
                                width: "35%",
                                paddingLeft: "55px",
                              }}
                            >
                              <h5
                                style={{ position: "relative", left: "-30px" }}
                              >
                                Clients
                              </h5>
                              <div className="comapny_name position-relative">
                                <h6
                                  style={{
                                    letterSpacing: "0.5px",
                                    fontWeight: "600",
                                    margin: "11px 0",
                                  }}
                                >
                                  <span
                                    className="circle"
                                    style={{
                                      position: "absolute",
                                      left: "-30px",
                                      top: " 0",
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "50%",
                                      border: "2px solid black",
                                    }}
                                  ></span>
                                  COMPANY NAME
                                </h6>
                                <p
                                  className="mb-0"
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "17px",
                                  }}
                                >
                                  {singleData?.company}
                                </p>
                                <p
                                  className="mb-0"
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "17px",
                                  }}
                                >
                                  ATTN: {singleData?.name}
                                </p>
                              </div>
                              <div className="comapny_address position-relative">
                                <h6
                                  style={{
                                    letterSpacing: "0.5px",
                                    fontWeight: "600",
                                    margin: "11px 0",
                                  }}
                                >
                                  <span
                                    className="circle"
                                    style={{
                                      position: "absolute",
                                      left: "-30px",
                                      top: " 0",
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "50%",
                                      border: "2px solid black",
                                    }}
                                  ></span>{" "}
                                  ADDRESS
                                </h6>
                                <p
                                  className="mb-0"
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "17px",
                                  }}
                                >
                                  {singleData?.address}
                                </p>
                                <p
                                  className="mb-0"
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "17px",
                                  }}
                                >
                                  Phone: {singleData?.phone}
                                </p>
                                <p
                                  className="mb-0"
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "17px",
                                  }}
                                >
                                  Email: {singleData?.email}
                                </p>
                              </div>
                            </div>
                          </div>

                          <hr
                            style={{
                              border: "1px solid #8080804d",
                              margin: "1rem 15px",
                            }}
                          />

                          <div
                            style={{ minHeight: "360px", padding: "15px 25px" }}
                          >
                            <table className="table invoice_table mb-0 w-100">
                              <thead>
                                <tr>
                                  <th scope="col">No</th>
                                  <th scope="col" className="text-center">
                                    Item
                                  </th>
                                  <th scope="col">Price</th>
                                  <th scope="col">Qty</th>
                                  <th scope="col">Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {singleData?.child_service_id != "4" &&
                                  singleData?.child_service_id != "3" &&
                                  singleData?.sub_service_id != "5" && (
                                    <tr>
                                      <td>
                                        {singleData?.child_service_id != "4" &&
                                        singleData?.child_service_id != "3"
                                          ? ++count
                                          : count}
                                      </td>
                                      <td>
                                        {singleData?.child_service_info?.name &&
                                        singleData?.child_service_id != "21" &&
                                        singleData?.child_service_id != "3" &&
                                        singleData?.child_service_id != "4"
                                          ? singleData?.child_service_info?.name
                                          : singleData?.child_service_id !=
                                              "21" &&
                                            singleData?.sub_service_id != "5" &&
                                            singleData?.child_service_id !=
                                              "3" &&
                                            singleData?.child_service_id !=
                                              "4" &&
                                            singleData?.sub_service_info
                                              ?.name}{" "}
                                        {singleData?.web_maintanance_info
                                          ?.name &&
                                          " - " +
                                            singleData?.web_maintanance_info
                                              ?.name}{" "}
                                        {singleData?.pages_info != "null" &&
                                        singleData?.pages_info?.number_of_page >
                                          "1"
                                          ? " ( " +
                                            singleData?.pages_info
                                              ?.number_of_page +
                                            " ) "
                                          : singleData?.pages_info &&
                                            " ( " +
                                              singleData?.pages_info
                                                ?.number_of_page +
                                              "  page)"}{" "}
                                        {singleData?.section_type_dropdown
                                          ? singleData?.section_type_dropdown
                                          : ""}
                                      </td>

                                      <td>
                                        {(singleData?.child_service_id ==
                                          "10" ||
                                          singleData?.child_service_id ==
                                            "12" ||
                                          singleData?.main_service_id == "2" ||
                                          singleData?.child_service_id ==
                                            "21" ||
                                          singleData?.child_service_id ==
                                            "22" ||
                                          singleData?.child_service_id ==
                                            "23" ||
                                          singleData?.child_service_id ==
                                            "24" ||
                                          singleData?.child_service_id ==
                                            "25" ||
                                          singleData?.main_service_id == "4") &&
                                          (currUserInfo?.countryCode == "BD"
                                            ? singleData.total_bdt_price + " tk"
                                            : "$ " +
                                              singleData.total_usd_price)}

                                        {currUserInfo?.countryCode == "BD"
                                          ? (singleData?.pages_info
                                              ?.bdt_price ||
                                              singleData?.pages_info
                                                ?.usd_price) &&
                                            singleData?.pages_info?.bdt_price +
                                              " tk"
                                          : "$ " +
                                              (singleData?.pages_info
                                                ?.bdt_price ||
                                                singleData?.pages_info
                                                  ?.usd_price) &&
                                            singleData?.pages_info?.usd_price}

                                        {singleData?.web_maintanance_info
                                          ?.bdt_price &&
                                        currUserInfo?.countryCode == "BD"
                                          ? singleData?.web_maintanance_info
                                              ?.bdt_price + " tk"
                                          : singleData?.web_maintanance_info
                                              ?.usd_price}
                                      </td>
                                      <td>1</td>
                                      <td>
                                        {currUserInfo?.countryCode == "BD"
                                          ? (singleData?.pages_info
                                              ?.bdt_price ||
                                              singleData?.pages_info
                                                ?.usd_price) &&
                                            singleData?.pages_info?.bdt_price +
                                              " tk"
                                          : "$ " +
                                              (singleData?.pages_info
                                                ?.bdt_price ||
                                                singleData?.pages_info
                                                  ?.usd_price) &&
                                            singleData?.pages_info?.usd_price}

                                        {singleData?.web_maintanance_info
                                          ?.bdt_price &&
                                        currUserInfo?.countryCode == "BD"
                                          ? singleData?.web_maintanance_info
                                              ?.bdt_price + " tk"
                                          : singleData?.web_maintanance_info
                                              ?.usd_price}
                                        {singleData?.child_service_id == "10" ||
                                        singleData?.child_service_id == "12" ||
                                        singleData?.main_service_info?.id ==
                                          "2" ||
                                        singleData?.main_service_info?.id ==
                                          "3" ||
                                        singleData?.main_service_info?.id == "4"
                                          ? currUserInfo?.countryCode == "BD"
                                            ? singleData.total_bdt_price + " tk"
                                            : "$ " + singleData.total_usd_price
                                          : ""}
                                      </td>
                                    </tr>
                                  )}

                                {singleData?.section_type_info?.map((type) => {
                                  return (
                                    <tr key={type.id}>
                                      <td>{++count}</td>
                                      <td>{type.name} </td>
                                      <td>
                                        {type.bdt_price &&
                                        currUserInfo?.countryCode == "BD"
                                          ? type.bdt_price + " tk"
                                          : "$ " + type.usd_price}
                                      </td>
                                      <td>1</td>
                                      <td>
                                        {" "}
                                        {currUserInfo?.countryCode == "BD"
                                          ? type.bdt_price + " tk"
                                          : "$ " + type.usd_price}
                                      </td>
                                    </tr>
                                  );
                                })}

                                {singleData.additional_service_info?.length >
                                  0 && (
                                  <tr>
                                    {" "}
                                    <td colSpan="5" className="text-center">
                                      <strong> Additional Services</strong>
                                    </td>
                                  </tr>
                                )}

                                {singleData.additional_service_info?.map(
                                  (service_info, index) => {
                                    count++;
                                    return (
                                      <>
                                        <tr key={service_info.id}>
                                          <td>{count}</td>
                                          <td>{service_info.name} </td>
                                          <td>
                                            {service_info.bdt_price &&
                                            currUserInfo?.countryCode == "BD"
                                              ? service_info.bdt_price + " tk"
                                              : "$ " + service_info.usd_price}
                                          </td>
                                          <td>1</td>
                                          <td>
                                            {" "}
                                            {currUserInfo?.countryCode == "BD"
                                              ? service_info.bdt_price + " tk"
                                              : "$ " + service_info.usd_price}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  }
                                )}
                                {singleData.additional_app_plugin_info?.length >
                                  0 && (
                                  <tr>
                                    {" "}
                                    <td colSpan="5" className="text-center">
                                      {singleData?.sub_service_id == 1 && (
                                        <strong>Additional Plugin</strong>
                                      )}
                                      {singleData?.sub_service_id == 2 && (
                                        <strong>Additional App</strong>
                                      )}
                                    </td>
                                  </tr>
                                )}

                                {singleData.additional_app_plugin_info?.map(
                                  (service_info, index) => {
                                    count++;
                                    return (
                                      <tr key={service_info.id}>
                                        <td>{count}</td>
                                        <td>{service_info.name}</td>
                                        <td>
                                          {currUserInfo?.countryCode == "BD"
                                            ? service_info.bdt_price + " tk"
                                            : "$ " +
                                              service_info.usd_price}{" "}
                                        </td>
                                        <td>1</td>
                                        <td>
                                          {" "}
                                          {currUserInfo?.countryCode == "BD"
                                            ? service_info.bdt_price + " tk"
                                            : "$ " + service_info.usd_price}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                                {singleData.dynamic_module_info?.length > 0 && (
                                  <tr>
                                    {" "}
                                    <td colSpan="5" className="text-center">
                                      <strong> Dynamic module</strong>
                                    </td>
                                  </tr>
                                )}
                                {singleData.dynamic_module_info?.map(
                                  (service_info) => {
                                    count++;
                                    return (
                                      <tr key={service_info.id}>
                                        <td>{count}</td>
                                        <td>{service_info.name} </td>
                                        <td>
                                          {currUserInfo?.countryCode == "BD"
                                            ? service_info.bdt_price + " tk"
                                            : "$ " +
                                              service_info.usd_price}{" "}
                                        </td>
                                        <td>1</td>
                                        <td>
                                          {" "}
                                          {currUserInfo?.countryCode == "BD"
                                            ? service_info.bdt_price + " tk"
                                            : "$ " + service_info.usd_price}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                            <table className="table invoice_table_total w-100">
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td>SUBTOTAL</td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {" "}
                                    {currUserInfo?.countryCode == "BD"
                                      ? singleData.total_bdt_price + " tk"
                                      : "$ " + singleData.total_usd_price}
                                  </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>TOTAL</td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {" "}
                                    {currUserInfo?.countryCode == "BD"
                                      ? singleData.total_bdt_price + " tk"
                                      : "$ " + singleData.total_usd_price}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div style={{ height: "150px" }}>
                            <div className="d-flex justify-content-between">
                              <h5 style={{ fontWeight: "bold" }}>
                                <i>Thank You</i>
                              </h5>
                              <h5>Project Manager</h5>
                            </div>
                            <p style={{ fontSize: "11px" }}>
                              Please note that the final Invoice may differ from
                              the Estimated Number, in case we change the
                              project scope and/or final deliverables.
                            </p>
                          </div>
                          <div>
                            <hr style={{ border: "2px solid #8080804d" }} />
                            <div className="flex_center">
                              <p
                                className="mt-2 pt-4"
                                style={{ fontSize: "11px" }}
                              >
                                Copyright &copy;{date.getFullYear()} TRODAD
                                International LTD. All Right Reserved.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminOrderQuoteView;
