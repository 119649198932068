import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import "./admindashboard.css";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import { FaWindowRestore } from "react-icons/fa";
import getCookie from "../../../Component/GetCookie";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";

const AdminDashboard = () => {
  const [customerCount, setCustomerCount] = useState([]);
  const [productCount, setProductCount] = useState([]);
  const [brandsCount, setBrandsCount] = useState([]);
  const [productCategoryCount, setProductCategoryCount] = useState([]);
  const [ordersCount, setOrdersCount] = useState([]);
  const DashboardInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/dashboard-master-get`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setCustomerCount(res.data.numOfCustomers);
        setProductCount(res.data.numOfProducts);
        setBrandsCount(res.data.numOfBrands);
        setProductCategoryCount(res.data.numOfProductCategory);
        setOrdersCount(res.data.numOfOrders);
      });
  };

  useEffect(() => {
    DashboardInfo();
  }, []);


  const apiKey = '1/1181186354658771:e0da781254129faa1cfae882d1acfbef';
  const workspace_id = '1181186357184897'

  // Fetch a list of tasks
  axios.get(`https://app.asana.com/api/1.0/workspaces`, {
    headers: {
      'Authorization': `Bearer ${apiKey}`,
    },
  })
    .then(response => {
      // Handle the response data (e.g., update your React state)
      console.log(response.data.data);
    })
    .catch(error => {
      // Handle errors
      console.error(error);
    });

  return (
    <div className="admin__dashboard">
      <div className="body-wrapper">
        <AdminDashboardNavbar admin_profile_logo="admin_profile_logo" />
        <div className="main_section p-4">
          <div className="dashboard_card_wrapper">
            <div className="dashboard_card">
              <div className="flex_between">
                <div className="d-flex flex-column">
                  <h5 className="h5">Order</h5>
                  <h4 className="h4">20</h4>
                </div>
                <div className="icon_round"></div>
              </div>
            </div>
            <div className="dashboard_card">
              <div className="flex_between">
                <div className="d-flex flex-column">
                  <h5 className="h5">Appointments</h5>
                  <h4 className="h4">20</h4>
                </div>
                <div className="icon_round"></div>
              </div>
            </div>
            <div className="dashboard_card">
              <div className="flex_between">
                <div className="d-flex flex-column">
                  <h5 className="h5">Career</h5>
                  <h4 className="h4">15</h4>

                </div>
                <div className="icon_round"></div>
              </div>
            </div>
            <div className="dashboard_card">
              <div className="flex_between">
                <div className="d-flex flex-column">
                  <h5 className="h5">Clients</h5>
                  <h4 className="h4">5</h4>
                </div>
                <div className="icon_round"></div>
              </div>
            </div>
         

          </div>
        </div>
      </div>



    </div>
  );
};

export default AdminDashboard;
