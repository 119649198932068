import axios from "axios";

import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  Form,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import getCookie from "../../Component/GetCookie";
import { BACKEND_BASE_URL } from "../../Component/GlobalVariables";
import { PassInputFieldFormControl } from "../../Component/PassInputField";
import Cookie from "cookie-universal";
import {
  getLocalStorageWithExpiry,
  setLocalStorageWithExpiry,
} from "../../Component/LocalStorageWithExpiry";

const AdminLogin = () => {
  const AdminEmail = useRef();
  const AdminPassword = useRef();
  const cookies = Cookie();
  const [toast_bg, setToast_bg] = useState("info");
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [adminEmailError, setAdminEmailError] = useState("");
  const [adminPassError, setAdminPassError] = useState("");
  const displaysize = window.innerWidth;
  const navigate = useNavigate();
  const location = useLocation();

  var LOGGED_IN_ADMIN_USER_TYPE = null;
  var ACCESS_TOKEN = false;

  // if user is logged in ,  redirect to desired page
  LOGGED_IN_ADMIN_USER_TYPE = getLocalStorageWithExpiry(
    "LOGGED_IN_ADMIN_USER_TYPE"
  );
  ACCESS_TOKEN = getLocalStorageWithExpiry("ACCESS_TOKEN");

  useEffect(() => {
    if (ACCESS_TOKEN) {
      if (LOGGED_IN_ADMIN_USER_TYPE == 1 || LOGGED_IN_ADMIN_USER_TYPE == 2) {
        navigate("/admin");
      }
    }
  }, []);
  let from = location.state?.from?.pathname || "/admin";
  const handleSubmit = (e) => {
    const formdata = new FormData();
    formdata.append("email", AdminEmail.current.value);
    formdata.append("password", AdminPassword.current.value);

    axios
      .post(`${BACKEND_BASE_URL}/api/v2/rianaire/login`, formdata, {
        headers: { "Content-Type": "multipart/form-data" },
      })

      .then((response) => {
        if (response.data.status == 400) {
          if (response.data.errors) {
            setAdminEmailError(response.data?.errors?.email);
            setAdminPassError(response.data?.errors?.password);
          }
          const { message } = response.data;
          setMessage(message);
          setToast_bg("danger");
          setShow(true);
        } else {
          localStorage.setItem(
            "LOGGED_IN_USER_PERMISSION_INFO",
            JSON.stringify(response.data?.user_permission_info)
          );
          setLocalStorageWithExpiry("ACCESS_TOKEN", response.data?.token); // 1 day
          setLocalStorageWithExpiry(
            "LOGGED_IN_ADMIN_USER_TYPE",
            response.data?.user?.user_type
          ); // 1 day
          setLocalStorageWithExpiry(
            "LOGGED_IN_ADMIN_ID",
            response.data?.user?.id
          ); // 1 day

          // cookies.set("ACCESS_TOKEN", response.data?.token, {
          //   maxAge: 60 * 60,
          // });
          // cookies.set(
          //   "LOGGED_IN_ADMIN_USER_TYPE",
          //   response.data?.user?.user_type,
          //   {
          //     maxAge: 60 * 60 * 24,
          //   }
          // );
          // cookies.set("LOGGED_IN_ADMIN_NAME", response.data?.user?.name, {
          //   maxAge: 60 * 60 * 24,
          // });
          // cookies.set("LOGGED_IN_ADMIN_EMAIL", response.data?.user?.email, {
          //   maxAge: 60 * 60 * 24,
          // });
          // cookies.set("LOGGED_IN_ADMIN_ID", response.data?.user?.id, {
          //   maxAge: 60 * 60 * 24,
          // });
          // cookies.set(
          //   "LOGGED_IN_ADMIN_PROFILE_PIC",
          //   response.data?.user?.profile_photo,
          //   {
          //     maxAge: 60 * 60 * 24,
          //   }
          // );

          // document.cookie =
          //   "LOGGED_IN_USER_PERMISSION_INFO=" +
          //   JSON.stringify(response.data?.user_permission_info) +
          //   "; max-age=" +
          //   1 * 24 * 60 * 60;

          const { message } = response.data;
          setMessage(message);
          setToast_bg("success");
          setShow(true);
          // navigate(from, { replace: true });
          setTimeout(() => {
            navigate(from, { replace: true });
          }, 2500);
        }
      });
    e.preventDefault();
  };

  return (
    <div
      className="form_wrapper flex_center vh-100"
      style={{ backgroundColor: "#f9fafb" }}
    >
      <Container>
        <ToastContainer position="top-center" className="p-3 toast_msg">
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            bg={toast_bg}
            autohide
          >
            <Toast.Body>
              <strong className="ms-3">{message ? message : ""}</strong>
            </Toast.Body>
          </Toast>
        </ToastContainer>
        <div className="w-100 text-center">
          <img
            src={require("../../Assets/logo.png")}
            alt=""
            className="img-fluid "
          />
        </div>
        {displaysize <= 767 ? (
          <h1 className="text-center mt-5">
            Use the desktop browser to access the TRODAD admin area.
          </h1>
        ) : (
          <Form id="form" className="admin_form" onSubmit={handleSubmit}>
            {/* ================== Email =================== */}
            <Form.Group className="mb-4">
              <Form.Label>
                Email <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                id="email"
                placeholder="Enter email"
                name="email"
                ref={AdminEmail}
              />
              <small className="small_msg">
                {adminEmailError && adminEmailError}
              </small>
            </Form.Group>

            {/* ============== Password ===================== */}
            <Form.Group className="mb-4">
              <Form.Label>
                Password <span className="text-danger">*</span>
              </Form.Label>
              <PassInputFieldFormControl
                placeholderValue="Password"
                inputRef={AdminPassword}
              />
              <small className="small_msg">
                {adminPassError && adminPassError}
              </small>
            </Form.Group>

            <Button type="submit" className="w-100 login_btn mt-3">
              Log In
            </Button>
          </Form>
        )}
      </Container>
    </div>
  );
};

export default AdminLogin;
