import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { cn } from "../utils/cn";

export default function InboxDropdownButton({ className, children, dropdownIcon, ...props }) {
  const [show, setShow] = useState(false);
  const ref = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref]);
  return (
    <div ref={ref} onClick={() => setShow((prev) => !prev)} className={cn(`relative cursor-pointer select-none`, className)} {...props}>
      <div className="cursor-pointer rounded-full p-2 transition-all duration-300 hover:bg-[#F3F3F3]">{dropdownIcon}</div>
      <AnimatePresence>
        {show && (
          <motion.ul initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.1 }} className={`absolute right-0 top-[calc(100%+5px)] z-50 min-w-max rounded-md border bg-white shadow-[0_10px_20px_rgba(0,0,0,0.19),_0_6px_6px_rgba(0,0,0,0.23)]`}>
            {children}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
}
