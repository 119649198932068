import axios from "axios";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { FiSave } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import getCookie from "../../../../Component/GetCookie";
import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const TechUseEdit = () => {
  const { techUseId } = useParams();
  const name = useRef();
  const image = useRef();
  const techCatDropdown = useRef();
  const description = useRef();

  // Image Preview
  const [files, setFile] = useState([]);

  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };
  // All tech use category
  const [allTechCategories, setAllTechCategories] = useState([]);
  const renderAllCategory = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/tech-use/category`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setAllTechCategories(res.data?.all_tech_use_category_info);
      });
  };

  // single table data
  const [singleData, setSingleData] = useState([]);

  // Get Single Info
  const renderSingleData = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/tech-use/edit/${techUseId}`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setSingleData(res.data?.edit_tech_use_info);
      });
  };

  // form submit to backend
  const updateData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("name", name.current.value);
    if (image.current.files[0]) {
      formdata.append("image", image.current.files[0]);
    }
    formdata.append("tech_use_category_id", techCatDropdown.current.value);
    formdata.append("description", description.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/tech-use/update/${singleData?.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((response) => {
        setFile([]);
        if (response.data?.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          e.target.reset();
          renderSingleData();
        } else {
          Swal.fire({
            icon: "warning",
            text: response.data.errors.name[0],
            confirmButtonColor: "#5eba86",
          });
        }
      });
    e.preventDefault();
  };

  useEffect(() => {
    renderAllCategory();
    renderSingleData();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_end mb-5">
                  <Link to="/admin/tech-use">
                    <Button variant="success" size="sm">
                      View All Tech &nbsp;
                    </Button>
                  </Link>
                </div>
                <Form onSubmit={updateData}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3">
                          {/* add News title */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Name
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Name"
                              ref={name}
                              defaultValue={singleData?.name}
                            />
                          </Form.Group>

                          {/* add Image */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Featured Image
                            </Form.Label>
                            <Form.Control
                              type="file"
                              onChange={handleImgPreview}
                              ref={image}
                              defaultValue={singleData?.image}
                            />
                            {files.map((file, index) => {
                              return (
                                <div key={index} className="Row">
                                  <span className="Filename">
                                    <img
                                      width={80}
                                      height={50}
                                      src={URL.createObjectURL(file)}
                                      alt={file.name}
                                    />
                                  </span>
                                </div>
                              );
                            })}
                            {files.length == 0 && (
                              <img
                                width={80}
                                height={50}
                                src={`${BACKEND_BASE_URL}${singleData?.image}`}
                                alt={singleData?.title}
                                name="img"
                              />
                            )}
                          </Form.Group>

                          {/* Dropdown */}
                          <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>
                              Tech Category
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              required
                              ref={techCatDropdown}
                            >
                              <option>Select Category</option>
                              {allTechCategories?.map((data, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={data.id}
                                    selected={
                                      singleData?.tech_use_category_id ==
                                      data?.id
                                        ? "selected"
                                        : ""
                                    }
                                  >
                                    {data.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              ref={description}
                              defaultValue={singleData?.description}
                            />
                          </Form.Group>

                          <Button
                            type="submit"
                            className="btn-submit mt-5 rounded-3 border-0"
                          >
                            <FiSave /> &nbsp; Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechUseEdit;
