import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputLabel,
  LinearProgress,
} from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Parse from "html-react-parser";
import Swal from "sweetalert2";
import { ToastAlert } from "../../Component/ToastAlert";
import { BACKEND_BASE_URL } from "../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../Component/AdminDashboardNavbar";
import RichTextEditor from "../../Component/RichTextEditor";
import { AiOutlineClose, AiOutlinePlusCircle } from "react-icons/ai";
import { FaCheck, FaEye } from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import getCookie from "../../Component/GetCookie";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getLocalStorageWithExpiry } from "../../Component/LocalStorageWithExpiry";

const TopServices = () => {
  const serviceName = useRef();
  const imageLink = useRef();
  const pageUrl = useRef();
  const shortDesc = useRef();

  const [value, setValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };
  const [validated, setValidated] = useState(false);

  // Modal Section Data
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("lg");

  // Fetch Table Data
  const [tableData, setTableData] = useState([]);
  const fetchData = () => {
    axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/top-services`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setTableData(res.data?.top_services);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Add new
  const addNewData = (modalValue) => {
    setFile([]);
    setModalSize("lg");
    setModalData(modalValue);
    setModalShow(true);
  };

  // View Single Data
  const [singleData, setSingleData] = useState([]);

  const showSingleData = (modalValue, data) => {
    setModalSize("lg");
    setModalData(modalValue);
    setSingleData(data);
    setModalShow(true);
  };

  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  // Form submit to backend
  const store = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);

    const formdata = new FormData();
    formdata.append("name", serviceName.current.value);
    formdata.append("icon_image", imageLink.current.files[0]);
    formdata.append("page_link", pageUrl.current.value);
    formdata.append("short_description", shortDesc.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/top-services/store`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          ToastAlert.fire({
            title: response.data?.message,
          });
          e.target.reset();
          // setValue("", "html");
          fetchData();
          setValidated(false);
          setModalShow(false);
        }
      });
    e.preventDefault();
  };

  // Edit data
  const fetchDataForEdit = (modalValue, data) => {
    setFile([]);
    setModalData(modalValue);
    setModalSize("lg");
    setSingleData(data);
    setModalShow(true);
  };

  // Updated data to backend
  const updateData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("name", serviceName.current.value);
    formdata.append("page_link", pageUrl.current.value);
    formdata.append("short_description", shortDesc.current.value);
    if (imageLink.current.files[0]) {
      formdata.append("icon_image", imageLink.current.files[0]);
    }

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/top-services/update/${singleData.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        ToastAlert.fire({
          icon: "success",
          title: response.data?.message,
        });
        setModalShow(false);
        fetchData();
      });

    e.preventDefault();
  };

  // Delete Data
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}/api/v2/rianaire/top-services/delete/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data.message,
            confirmButtonColor: "#5eba86",
          });
          fetchData();
        });
    }
  };

  const StatusUpdate = (id) => {
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/happy-clients/status-update/${id}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        fetchData();
      });
  };

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Top Services</h3>
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => addNewData("Add")}
                  >
                    Add &nbsp;
                    <span>
                      <AiOutlinePlusCircle className="mb-1" />
                    </span>
                  </Button>
                </div>
                {tableData.length > 0 ? (
                  <div className="table-responsive custom_table">
                    <div className="table_fixed">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Handle</th>
                            <th scope="col">Title</th>
                            <th scope="col">Featured Image</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData?.map((data, index) => (
                            <tr key={index}>
                              <td>
                                <Dropdown style={{ position: "unset" }}>
                                  <Dropdown.Toggle>
                                    <BsThreeDotsVertical color="#000" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {/* view */}
                                    <Dropdown.Item
                                      onClick={() => {
                                        showSingleData("View", data);
                                      }}
                                    >
                                      View
                                    </Dropdown.Item>
                                    {/* edit */}
                                    <Dropdown.Item
                                      onClick={() =>
                                        fetchDataForEdit("Edit", data)
                                      }
                                    >
                                      Edit
                                    </Dropdown.Item>
                                    {/* delete */}

                                    <Dropdown.Item
                                      onClick={() => deleteData(data.id)}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>{data.name}</td>
                              <td>
                                <img
                                  className="img-thumbnail"
                                  width={80}
                                  height={50}
                                  src={`${BACKEND_BASE_URL}/${data.icon_image}`}
                                  alt={data.name}
                                />
                              </td>
                              <td>
                                {data.is_home == 1 ? (
                                  <Link
                                    to=""
                                    onClick={() => StatusUpdate(data.id)}
                                  >
                                    <FaCheck
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",

                                        padding: "2px 4px",
                                        borderRadius: "4px",
                                      }}
                                      size="1.6em"
                                    />
                                  </Link>
                                ) : (
                                  <Link
                                    to=""
                                    onClick={() => StatusUpdate(data.id)}
                                  >
                                    <AiOutlineClose
                                      style={{
                                        color: "white",
                                        backgroundColor: "red",

                                        padding: "2px 4px",
                                        borderRadius: "4px",
                                      }}
                                      size="1.6em"
                                    />
                                  </Link>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <LinearProgress />
                )}
              </div>
            </div>

            <Modal
              show={modalShow}
              size={modalSize}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => setModalShow(false)}
            >
              <Modal.Header>
                <Modal.Title className="" id="contained-modal-title-vcenter">
                  {modalData}
                </Modal.Title>
                <Button
                  className="p-0 border-0 bg-danger"
                  onClick={() => setModalShow(false)}
                >
                  <CgClose
                    style={{
                      fontSize: "26px",
                    }}
                  />
                </Button>
              </Modal.Header>
              <Modal.Body>
                {/* Add Modal section */}
                {modalData === "Add" && (
                  <div className="">
                    <Form onSubmit={store} noValidate validated={validated}>
                      <div className="content-wrapper">
                        <div className="card">
                          <div className="card-body">
                            <Row className="mb-3">
                              {/* content name */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Service Name
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  placeholder="Title"
                                  ref={serviceName}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Service name is required
                                </Form.Control.Feedback>
                              </Form.Group>

                              {/* image link */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Image Link
                                </Form.Label>

                                <Form.Control
                                  required
                                  type="file"
                                  ref={imageLink}
                                  onChange={handleImgPreview}
                                />

                                {files.map((file, key) => {
                                  return (
                                    <div key={key} className="Row">
                                      <span className="Filename">
                                        <img
                                          width={80}
                                          height={50}
                                          src={URL.createObjectURL(file)}
                                          alt={file.name}
                                        />
                                      </span>
                                    </div>
                                  );
                                })}

                                <Form.Control.Feedback type="invalid">
                                  Please choose an image
                                </Form.Control.Feedback>
                              </Form.Group>

                              {/* page Url */}
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Page URL
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  placeholder="Page url"
                                  ref={pageUrl}
                                />
                              </Form.Group>

                              {/* Short Desc */}
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom02"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Description
                                </Form.Label>

                                <RichTextEditor
                                  joditRef={shortDesc}
                                  getValue={getValue}
                                  value={value}
                                />
                              </Form.Group>
                            </Row>

                            <Button
                              type="submit"
                              className="btn-submit mt-5 rounded-3 border-0 d-flex justify-content-center"
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                )}

                {/* Edit modal section */}
                {modalData === "Edit" && (
                  <form onSubmit={updateData}>
                    <div className="content-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <div className="row py-3">
                            {/* content Name */}
                            <div className="col-md-6">
                              <div className="form-group mb-3">
                                <Form.Label className="label fw-bold">
                                  Service Name
                                </Form.Label>

                                <div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    ref={serviceName}
                                    defaultValue={singleData?.name}
                                  />
                                </div>
                              </div>
                            </div>

                            {/* content Image */}
                            <div className="col-md-6">
                              <div className="form-group">
                                <Form.Label className="label fw-bold">
                                  Image Link
                                </Form.Label>
                                <div className=" image-file">
                                  <input
                                    type="file"
                                    className="form-control"
                                    ref={imageLink}
                                    onChange={handleImgPreview}
                                  />

                                  {files.map((file, key) => {
                                    return (
                                      <div key={key} className="Row">
                                        <span className="Filename">
                                          <img
                                            width={80}
                                            height={50}
                                            src={URL.createObjectURL(file)}
                                            alt={file.name}
                                          />
                                        </span>
                                      </div>
                                    );
                                  })}

                                  {files.length == 0 && (
                                    <img
                                      width={80}
                                      height={50}
                                      src={`${BACKEND_BASE_URL}${singleData?.icon_image}`}
                                      alt={singleData?.name}
                                      name="img"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* page Url */}
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Page URL
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Page url"
                                ref={pageUrl}
                                defaultValue={singleData?.page_link}
                              />
                            </Form.Group>

                            {/* content Description */}
                            <div className="col-md-12">
                              <div className="form-group mb-3">
                                <Form.Label className="label fw-bold">
                                  Description
                                </Form.Label>

                                <RichTextEditor
                                  joditRef={shortDesc}
                                  getValue={getValue}
                                  value={singleData?.short_description}
                                />
                              </div>
                            </div>
                            {/* Submit button */}
                            <button
                              type="submit"
                              className="btn-submit mt-5 rounded-3 border-0"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}

                {/* View Modal section */}
                {modalData === "View" && (
                  <>
                    <h4>Name: {singleData?.name}</h4>
                    <img
                      className="img-thumbnail"
                      src={`${BACKEND_BASE_URL}${singleData?.icon_image}`}
                      alt=""
                    />
                    <div className="mt-2">
                      {Parse(`${singleData?.short_description}`)}
                    </div>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setModalShow(false)}
                  className="btn-danger"
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopServices;
