import { InputLabel, MenuItem, Select, TextField } from '@mui/material'
import axios from 'axios'
import React, { useRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Col, Modal, Row, Tab, Tabs } from 'react-bootstrap'
import { AiOutlinePlus, AiOutlineUnorderedList } from 'react-icons/ai'
import { Form, Link, useLocation, useParams } from 'react-router-dom'
import "./AsanaTeam.css"
const AsanaTeam = () => {
    const { teamGid } = useParams()
    const { state } = useLocation();
    const projectName = useRef();
    const selectView = useRef();
    const [projectModalShow, setProjectModalShow] = useState(false);
    const [teamProjects, setTeamProjects] = useState([])
    const [teamMembers, setTeamMemebers] = useState([])
    const apiKey = '1/1181186354658771:8ce46a50ca6dd71089785591c1f34bc6';

    useEffect(() => {
        const teamProject = {
            method: 'GET',
            url: `https://app.asana.com/api/1.0/teams/${teamGid}/projects`,
            params: { opt_fields: 'color,name,icon' },
            headers: {
                accept: 'application/json',
                authorization: 'Bearer 1/1181186354658771:8ce46a50ca6dd71089785591c1f34bc6'
            }
        };
        axios
            .request(teamProject)
            .then(function (response) {
                setTeamProjects(response.data?.data);
            })
        const userTasks = {
            method: 'GET',
            url: `https://app.asana.com/api/1.0/teams/${teamGid}/users`,
            params: { workspace: '1181186357184897', opt_fields: 'name,email,photo,' },
            headers: { Authorization: `Bearer ${apiKey}`, }
        };
        axios
            .request(userTasks)
            .then(function (response) {
                setTeamMemebers(response.data?.data);
            })
    }, [teamGid])

    function extractInitials(inputString) {
        const regexPattern = /(?:\b\w|(?<=\.))+/g;
        const matches = inputString?.match(regexPattern);

        if (matches) {
            const initials = matches?.map(match => match[0].toUpperCase()).join('');
            return initials;
        } else {
            return null; // Return null if no initials are found in the input string.
        }
    }

    const handleAsanaPost = (e) => {
        e.preventDefault();

        const options = {
            method: 'POST',
            url: 'https://app.asana.com/api/1.0/projects',
            headers: { Authorization: `Bearer ${apiKey}`, 'content-type': 'application/json' },
            data: { data: { workspace: '1181186357184897', name: projectName?.current?.value, default_view: selectView?.current?.value } }
        };

        axios
            .request(options)
            .then(function (response) {
                console.log(response.data);
            })
            .catch(function (error) {
                console.error(error);
            });

    };

    return (
        <div className="asana_team my-5 overflow-hidden">
            <div className='pt-3'>
                <h4 className='ps-3 fw-bold'> {state?.teamName}</h4>
                <Tabs
                    defaultActiveKey="overview"
                    id="noanim-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="overview" title="Overview">
                        <Row>
                            <Col md={8} className='mx-auto'>
                                <Row>
                                    <Col md={8} className='me-auto'>
                                        <div className='p-4 border rounded-3 mb-3'>
                                            <h4 className='mb-3'>Projects</h4>
                                            {/* <div className="d-flex align-items-center cursor mb-3" onClick={() => setProjectModalShow(true)}>
                                                <div className="create_project_icon_box"><AiOutlinePlus size={22} color="grey" /></div>
                                                <p className='mb-0'>New project</p>
                                            </div> */}
                                            {teamProjects?.map((teamProject) => (
                                                <Link to={`/admin/asana-dashboard/projects/${teamProject.gid}`} className='d-flex align-items-center cursor teamProjects text-dark mb-2 px-2 py-2 rounded-2'>
                                                    <div className="icon_box" style={{
                                                        backgroundColor: `${(teamProject?.color == `dark-red` && `#E53B52`)
                                                            || (teamProject?.color == `dark-orange` && `#F96237`)
                                                            || (teamProject?.color == `light-orange` && `#FA9A27`)
                                                            || (teamProject?.color == ` dark-brown` && `#ECC42C`)
                                                            || (teamProject?.color == `light-green` && `#A5CF3F`)
                                                            || (teamProject?.color == `dark-green` && `#66D273`)
                                                            || (teamProject?.color == `light-teal` && `#42C4AB`)
                                                            || (teamProject?.color == `dark-teal` && `#32A9E8`)
                                                            || (teamProject?.color == `light-blue` && `#4886DD`)
                                                            || (teamProject?.color == `dark-purple` && ` #4886DD`)
                                                            || (teamProject?.color == `light-purple` && `#A962E0`)
                                                            || (teamProject?.color == `light-pink` && `#f9aaef`)
                                                            || (teamProject?.color == `dark-pink` && `#E84F9C`)
                                                            || (teamProject?.color == `light-red` && `#FA91AE`)
                                                            || (teamProject?.color == `light-warm-gray` && `#8DA3A6`)
                                                            || (teamProject?.color == `none` && `#C7C4C4`)}`
                                                    }}><AiOutlineUnorderedList size={22} color="#fff" /></div>
                                                    {teamProject?.name}
                                                </Link>
                                            ))}
                                        </div>
                                        <div className='p-4 border rounded-3'>
                                            <h4 className='mb-3'>Members ({teamMembers.length})</h4>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="people_name" onClick={() => { setProjectModalShow(true) }}>
                                                        <div className="add_member_icon_box"><AiOutlinePlus size={22} color="grey" /></div>
                                                        <p className='mb-0'>Add member</p>
                                                    </div>
                                                </div>

                                                {teamMembers?.map((teamMember) => (
                                                    <div className="col-md-4">
                                                        <Link to={`/admin/asana-dashboard/users/${teamMember.gid}`} className='d-flex align-items-center cursor text-dark mb-2'>
                                                            <div className="people_name ">
                                                                {teamMember?.photo ? <img src={teamMember?.photo?.image_36x36} alt="" style={{ borderRadius: "50%", marginRight: "15px" }} /> :

                                                                    <div className="icon_box">
                                                                        <span>{extractInitials(teamMember.name)?.slice(0, 2)}</span>
                                                                    </div>}

                                                                <p className='mb-0 text-truncate'>{teamMember.name}</p>
                                                            </div>

                                                        </Link>
                                                    </div>
                                                ))}

                                            </div>

                                        </div>

                                    </Col>
                                </Row>
                            </Col>

                        </Row>

                    </Tab>
                    <Tab eventKey="messages" title="Messages">
                        Tab content for Profile
                    </Tab>
                    <Tab eventKey="calender" title="Calender" >
                        Tab content for Contact
                    </Tab>
                </Tabs>
            </div>

            {/* <Modal
                open={projectModalShow}
                onClose={() => setProjectModalShow(false)}
                className='asana_new_project_modal'
            >
                <div className='modal_body'>
                    <h2 class="title lead mb-5">New project</h2>
                    <div className='form_box'>
                        <Form onSubmit={handleAsanaPost}>
                            <Form.Group as={Col} className="position-relative d-flex flex-column mb-4">

                                <Form.Label>
                                    Project name
                                </Form.Label>
                                <TextField required hiddenLabel size="small" id="filled-basic" variant="filled" ref={projectName} />

                            </Form.Group>
                            <Form.Group as={Col} className="position-relative d-flex flex-column mb-4">
                                <InputLabel id="demo-select-small-label">Starting View</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    size="small"
                                    ref={selectView}
                                >
                                    <MenuItem value="">
                                        <em>  Select a starting view</em>
                                    </MenuItem>
                                    <MenuItem value="list"> List</MenuItem>
                                    <MenuItem value="board">board</MenuItem>
                                    <MenuItem value="calender">Calender</MenuItem>
                                </Select>
                            </Form.Group>

                            <button type="submit" className='asana_create_project_btn'>Submit</button>
                        </Form>
                    </div>
                </div>
            </Modal> */}
        </div>
    )
}

export default AsanaTeam