import { LinearProgress } from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Parse from "html-react-parser";
import { useParams } from "react-router-dom";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import getCookie from "../../../Component/GetCookie";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";

const AdminPressView = () => {
  const { newsId } = useParams();

  // Initial table data
  const [tableData, setTableData] = useState([]);

  // Get Single Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/news-room/show/${newsId}`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setTableData(res.data?.single_info);
      });
  };

  useEffect(() => {
    renderAllInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_end mb-5">
                  <Link to="/admin/press">
                    <Button variant="success" size="sm">
                      View All News &nbsp;
                    </Button>
                  </Link>
                </div>

                {tableData ? (
                  <>
                    <h6>
                      <strong>Name :</strong> {tableData?.title}
                    </h6>
                    <img
                      src={`${BACKEND_BASE_URL}/${tableData?.image}`}
                      alt=""
                      className="img-thumbnail my-4"
                    />
                    <h6>
                      <strong>Description :</strong>{" "}
                      {Parse(`${tableData?.description}`)}
                    </h6>
                  </>
                ) : (
                  <LinearProgress />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPressView;
