import "./customLoader.css";

const PulseLoader = (props) => {
  return (
    <>
      <div
        className={`pulse_loader ${props.custom_className}`}
        style={props.custom_css}
      >
        <span style={props.custom_color}></span>
        <span style={props.custom_color}></span>
      </div>
    </>
  );
};

const DotLoader = (props) => {
  return (
    <>
      <div className={`flex_center `} style={props.custom_css}>
        <div className="stage">
          <div className="dot-falling"></div>
        </div>
      </div>
    </>
  );
};

export { PulseLoader, DotLoader };
