import axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Badge, Button, Col, Form, Row, Stack } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import { LinearProgress } from "@mui/material";
import { useRef } from "react";
import { ToastAlert } from "../../../Component/ToastAlert";
import getCookie from "../../../Component/GetCookie";
import { UserContext } from "../../../App";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";

const AdminOrderView = () => {
  const orderStatus = useRef();
  const { orderId } = useParams();
  const { currUserInfo } = useContext(UserContext);

  const [singleOrderInfo, setSingleOrderInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  // Get All Info
  const renderAllInfo = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v3/rianaire/order/${orderId}`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setSingleOrderInfo(res.data?.single_order);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(true);
      });
  };

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("order_status", orderStatus.current.value);

    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/orders/change-status/${orderId}/${orderStatus.current.value}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          e.target.reset();
          renderAllInfo();
        }
      });
    e.preventDefault();
  };

  useEffect(() => {
    renderAllInfo();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_end mb-5">
                  <Link to="/admin/orders">
                    <Button variant="success" size="sm">
                      All Orders
                    </Button>
                  </Link>
                </div>
                {!isLoading && !error && (
                  <Row className="view-order">
                    <Col lg={8} xl={7} className="order-2 order-md-1">
                      <div className="order_details">
                        <h2 className="text-center mb-4">Order details</h2>
                        <Row>
                          <Col lg={7} xl={7}>
                            <div className="">
                              <div className="mb-4 mb-md-2">
                                <p className="align-self-center mb-0 me-2">
                                  <b>Order Status:</b>
                                </p>
                                <Form onSubmit={storeData}>
                                  <Form.Group className="d-md-flex">
                                    <Form.Select
                                      aria-label="language example "
                                      className=" mb-3 mb-md-2 mb-lg-0"
                                      ref={orderStatus}
                                    >
                                      <option
                                        value="pending"
                                        selected={
                                          singleOrderInfo?.order_status ==
                                            "pending" && "selected"
                                        }
                                      >
                                        Pending
                                      </option>
                                      <option
                                        value="in-progress"
                                        selected={
                                          singleOrderInfo?.order_status ==
                                            "in-progress" && "selected"
                                        }
                                      >
                                        In Progress
                                      </option>
                                      <option
                                        value="arriving-soon"
                                        selected={
                                          singleOrderInfo?.order_status ==
                                            "arriving-soon" && "selected"
                                        }
                                      >
                                        Arriving Soon
                                      </option>
                                      <option
                                        value="complete"
                                        selected={
                                          singleOrderInfo?.order_status ==
                                            "complete" && "selected"
                                        }
                                      >
                                        Completed
                                      </option>
                                    </Form.Select>
                                    <span className="ps-md-3">
                                      <Button
                                        type="submit"
                                        className="update_btn"
                                      >
                                        Update Status
                                      </Button>
                                    </span>
                                  </Form.Group>
                                </Form>
                              </div>
                            </div>
                          </Col>
                          <Col lg={5} xl={5} className="align-self-lg-end">
                            <div className="d-flex justify-content-between justify-content-md-start justify-content-lg-end mb-4 mb-md-2">
                              <Link
                                to={`/admin/orders/user/${orderId}/${singleOrderInfo.user_id}`}
                              >
                                <button>user info</button>
                              </Link>
                              <button>message</button>
                            </div>
                          </Col>
                        </Row>
                        {singleOrderInfo?.order_status && (
                          <>
                            {singleOrderInfo?.order_status == 0 && (
                              <Badge bg="danger">Pending</Badge>
                            )}
                            {singleOrderInfo?.order_status == 1 && (
                              <Badge bg="warning">Review</Badge>
                            )}
                            {singleOrderInfo?.order_status == 2 && (
                              <Badge bg="success">Accepted</Badge>
                            )}
                            {singleOrderInfo?.order_status == 3 && (
                              <Badge bg="info">Hold</Badge>
                            )}
                            {singleOrderInfo?.order_status == 4 && (
                              <Badge bg="danger">Rejected</Badge>
                            )}
                            {singleOrderInfo?.order_status == 5 && (
                              <Badge bg="success">Delivered</Badge>
                            )}
                            {singleOrderInfo?.order_status == 6 && (
                              <Badge bg="success">Complete</Badge>
                            )}
                          </>
                        )}
                        <h6>
                          <strong>Service Name : </strong>
                          {singleOrderInfo?.main_service_info?.name}
                        </h6>
                        <h6>
                          <strong>Sub Service Name : </strong>
                          {singleOrderInfo?.sub_service_info?.name}
                        </h6>
                        <h6>
                          <strong>Design Type : </strong>
                          {singleOrderInfo?.child_service_info?.name}

                          {singleOrderInfo?.web_maintanance_info?.bdt_price &&
                          currUserInfo?.countryCode == "BD"
                            ? singleOrderInfo?.web_maintanance_info?.bdt_price +
                              " tk"
                            : singleOrderInfo?.web_maintanance_info?.usd_price}
                          {/* {singleOrderInfo?.pages_info && currUserInfo?.countryCode == "BD"
                            ? singleOrderInfo?.pages_info?.bdt_price + " Tk"
                            : "$ " + singleOrderInfo?.pages_info?.usd_price} */}
                        </h6>
                        <h6>
                          {singleOrderInfo?.pages_info && (
                            <>
                              <strong>Number of Pages :</strong>
                              {singleOrderInfo?.pages_info?.number_of_page}{" "}
                              {`${
                                singleOrderInfo?.pages_info?.number_of_page == 1
                                  ? "page"
                                  : "pages"
                              }`}
                            </>
                          )}
                        </h6>

                        <h6>
                          {singleOrderInfo?.section_type_dropdown && (
                            <>
                              <strong>Section Type Dropdown : </strong>
                              {singleOrderInfo?.section_type_dropdown?.split(
                                ","
                              )}
                            </>
                          )}
                        </h6>
                        <h6>
                          {singleOrderInfo?.additional_service_info?.length >
                            0 && (
                            <>
                              <strong> Additional Services:</strong>
                              {singleOrderInfo?.additional_service_info?.map(
                                (info) => (
                                  <h6 className="mb-1">
                                    {info?.name}
                                    {" - "}
                                    {currUserInfo?.countryCode == "BD"
                                      ? info?.bdt_price + " Tk"
                                      : +"$ " + info?.usd_price}{" "}
                                  </h6>
                                )
                              )}
                            </>
                          )}
                        </h6>
                        <h6>
                          {singleOrderInfo?.additional_app_plugin_info?.length >
                            0 && (
                            <>
                              <strong>Additional App/Plugin: </strong>
                              {singleOrderInfo?.additional_app_plugin_info?.map(
                                (info) => (
                                  <h6 className="mb-1">
                                    {" "}
                                    {info?.name}
                                    {" - "}
                                    {currUserInfo?.countryCode == "BD"
                                      ? info?.bdt_price + " Tk"
                                      : +"$ " + info?.usd_price}{" "}
                                  </h6>
                                )
                              )}
                            </>
                          )}
                        </h6>
                        <h6>
                          {singleOrderInfo?.dynamic_module_info?.length > 0 && (
                            <>
                              <strong>Dynamic Module: </strong>
                              {singleOrderInfo?.dynamic_module_info?.map(
                                (info) => (
                                  <h6 className="mb-1">
                                    {" "}
                                    {info?.name}
                                    {" - "}
                                    {currUserInfo?.countryCode == "BD"
                                      ? info?.bdt_price + " Tk"
                                      : +"$ " + info?.usd_price}{" "}
                                  </h6>
                                )
                              )}
                            </>
                          )}
                        </h6>
                        <h6>
                          <strong>Total Cost : </strong>
                          {currUserInfo?.countryCode == "BD"
                            ? singleOrderInfo?.total_bdt_price + " Tk"
                            : "$ " + singleOrderInfo?.total_usd_price}
                        </h6>
                        <h6>
                          <strong>Total Duration : </strong>
                          {singleOrderInfo?.total_duration}
                        </h6>
                        <h6>
                          <strong>Name : </strong>
                          {singleOrderInfo?.name}
                        </h6>
                        <h6>
                          <strong>Email : </strong>
                          {singleOrderInfo?.email}
                        </h6>
                        <h6>
                          <strong>Phone : </strong>
                          {singleOrderInfo?.phone}
                        </h6>
                        <h6>
                          <strong>Company : </strong>
                          {singleOrderInfo?.company}
                        </h6>
                        <h6>
                          <strong>Address : </strong>
                          {singleOrderInfo?.address}
                        </h6>
                      </div>
                    </Col>
                    <Col lg={4} xl={5} className="order-1 order-md-2">
                      <div className="order_timeline px-1 p-md-3">
                        <div className="timeline_box">
                          <div className="flex_between">
                            <p className="mb-0">lorem</p>
                            <span>Duration</span>
                          </div>
                          <h6>Lorem ipsum dolor sit.</h6>

                          <div className="status flex_start">
                            <span>1</span>
                            <p className="mb-0">Accepted</p>
                          </div>
                        </div>
                        <div className="timeline_box">
                          <div className="flex_between">
                            <p className="mb-0">lorem</p>
                            <span>Duration</span>
                          </div>
                          <h6>Lorem ipsum dolor sit.</h6>
                          <div className="status flex_start">
                            <span>1</span>
                            <p className="mb-0">Accepted</p>
                          </div>
                        </div>
                        <div className="timeline_box">
                          <div className="flex_between">
                            <p className="mb-0">lorem</p>
                            <span>Duration</span>
                          </div>
                          <h6>Lorem ipsum dolor sit.</h6>

                          <div className="status flex_start">
                            <span>1</span>
                            <p className="mb-0">Accepted</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
                {isLoading && !error && <LinearProgress />}

                {!isLoading && error && (
                  <div className="text-center text-danger">
                    <h1>No data Found</h1>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminOrderView;
