import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import { AiOutlineCaretDown, AiOutlinePlusCircle } from "react-icons/ai";
import { BsCheck2, BsCurrencyDollar } from "react-icons/bs";
import { Link } from "react-router-dom";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import { LinearProgress, TablePagination } from "@mui/material";
import { CSVLink } from "react-csv";
import getCookie from "../../../../Component/GetCookie";
import "../serviceCosting.css";
import { MdAdd, MdClose } from "react-icons/md";
import { FiEdit2 } from "react-icons/fi";
import { ToastAlert } from "../../../../Component/ToastAlert";
import Swal from "sweetalert2";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";
import { Reorder, useDragControls } from "framer-motion";
import { GrDrag } from "react-icons/gr";
import LinearProgressMui from "../../../../Component/Custom Loader/LinearProgress";

function DragItem({ data, mainServices, handleDeleteClick }) {
  const controls = useDragControls();
  const elementRef = useRef();

  const handlePointerDown = (e) => {
    const tr = e.target.closest("tr");
    elementRef.current = tr;
    tr.style.transition = "none";

    tr.style.position = "relative";
    tr.style.zIndex = 999;
    tr.style.background = "#F1F1F1";
    controls.start(e);
  };

  useEffect(() => {
    const handlePointerUp = () => {
      const tr = elementRef.current;
      if (tr) {
        tr.style.transition = "all linear 0.3s";
        tr.style.background = "inherit";
        tr.style.position = "static";
        tr.style.zIndex = 0;
      }
    };
    window.addEventListener("pointerup", handlePointerUp);
    return () =>
      setTimeout(() => {
        window.removeEventListener("pointerup", handlePointerUp);
      }, 2000);
  }, []);

  return (
    <Reorder.Item
      as="tr"
      value={data}
      dragListener={false}
      dragControls={controls}
      style={{ userSelect: "none" }}
    >
      <td className="d-flex align-items-center">
        <GrDrag
          style={{
            marginTop: "4px",
            cursor: "grab",
          }}
          onPointerDown={handlePointerDown}
        />
        <Dropdown style={{ position: "unset" }}>
          <Dropdown.Toggle>
            <span
              style={{
                backgroundColor: "#eeeeee",
                padding: "8px 12px",
                borderRadius: "8px",
              }}
            >
              <FiEdit2 color="#000" className="me-2" />
              <AiOutlineCaretDown color="#000" />
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              as={Link}
              to={`/admin/service-module/dynamic-dropdown/edit/${data?.id}`}
            >
              Edit
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleDeleteClick(data.id)}>
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
      <td>{data?.main_service?.name}</td>
      <td>{data?.sub_service?.name}</td>
      <td>{data?.child_service?.name ? data?.child_service?.name : "----"}</td>
      <td className="text-start">{data?.name}</td>
    </Reorder.Item>
  );
}

const DynamicDropdown = () => {
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSorting, setIsSorting] = useState(false);

  const [error, setError] = useState(false);
  const [search, setSearch] = useState("");

  const fetchAllInfo = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/dynamic-dropdown`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setTableData(res.data?.all_info);
        setFilteredData(res.data?.all_info);
      });
  };

  const handleReorder = (e) => {
    setFilteredData(e);
    updateOrder(e);
  };
  const debounceRef = useRef();

  function updateOrder(state) {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      if (state.length === 0) return;
      const formdata = new FormData();
      state.forEach((item, index) => {
        formdata.append("ids[]", item.id);
        formdata.append("display_orders[]", index + 1);
      });

      setIsSorting(true);
      axios
        .post(
          `${BACKEND_BASE_URL}/api/v3/rianaire/reorder/dynamic-dropdown`,
          formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {})
        .catch((error) => {
          // setIsLoading(false);
          setError(true);
        })
        .finally(() => {
          setIsSorting(false);
        });
    }, 300);
  }

  useEffect(() => {
    fetchAllInfo();
  }, []);

  const headers = [
    { label: "Department", key: "department.name" },
    { label: "Service Name", key: "service.name" },
    { label: "Service Type", key: "service_type.name" },
    { label: "Num of Page", key: "total_page" },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDeleteClick = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}/api/v2/rianaire/dynamic-dropdown/delete/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data.message,
            confirmButtonColor: "#5eba86",
          });
          fetchAllInfo();
        });
    }
  };

  useEffect(() => {
    const result = tableData.filter((data) => {
      return (
        data.main_service?.name.toLowerCase().match(search.toLowerCase()) ||
        data.sub_service?.name.toLowerCase().match(search.toLowerCase()) ||
        data?.name.toLowerCase().match(search.toLowerCase())
      );
    });
    if (result.length != 0) {
      setFilteredData(result);
    }
  }, [search]);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar pageTitle="Dynamic Dropdown" />
      </div>
      <div className="col-md-12 p-4">
        <div className="card">
          <div className="card-body">
            <div className="col-lg-12">
              <div className=""></div>
              {!isLoading && !error && tableData?.length > 1 && (
                <div className="table-responsive custom_table">
                  <Row className="flex_end">
                    <Col className="mb-2"></Col>
                    <Col md={4} className="mb-2">
                      <Form.Control
                        type="text"
                        placeholder="search here..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </Col>
                  </Row>

                  <div className="table_fixed">
                  { isSorting &&  <LinearProgressMui />}
                    <table className="table ">
                      <thead>
                        <tr>
                          <th>
                            <Dropdown style={{ position: "unset" }}>
                              <Dropdown.Toggle>
                                <span
                                  style={{
                                    backgroundColor: "#eeeeee",
                                    padding: "8px 12px",
                                    borderRadius: "8px",
                                  }}
                                >
                                  <MdAdd color="#000" className="me-2" />
                                  <AiOutlineCaretDown color="#000" />
                                </span>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  as={Link}
                                  to="/admin/service-module/dynamic-dropdown/add-new"
                                >
                                  Add new &nbsp;
                                  <span>
                                    <AiOutlinePlusCircle className="mb-1" />
                                  </span>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="csv_export_btn"
                                >
                                  <CSVLink
                                    data={filteredData}
                                    headers={headers}
                                    filename={"service-cost.csv"}
                                    target="_blank"
                                  >
                                    CSV Export
                                  </CSVLink>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </th>
                          <th>Department</th>
                          <th>Service Name</th>
                          <th>Service Type</th>
                          <th>Name</th>
                        </tr>
                      </thead>

                      <Reorder.Group
                        layoutScroll
                        as="tbody"
                        axis="y"
                        values={filteredData}
                        onReorder={handleReorder}
                      >
                        {filteredData.map((data, index) => (
                          <DragItem
                            key={data.id}
                            data={data}
                            // mainServices={mainServices}
                            handleDeleteClick={handleDeleteClick}
                          />
                        ))}
                      </Reorder.Group>
                    </table>
                  </div>
                  {/* <div className="mt-3 mui_pagination">
                    <TablePagination
                      component="div"
                      count={filteredData.length}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPageOptions={[10, 20, 50]}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      showFirstButton
                      showLastButton
                    />
                  </div> */}
                </div>
              )}
              {isLoading && !error && <LinearProgress />}
              {!isLoading && !error && tableData?.length < 1 && (
                <>
                  <div className="text-center text-danger">
                    <h1>No data Found</h1>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicDropdown;
