import { LinearProgress } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { BACKEND_BASE_URL } from "../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../Component/AdminDashboardNavbar";

import { FaEye } from "react-icons/fa";
import getCookie from "../../Component/GetCookie";
import { Dropdown } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getLocalStorageWithExpiry } from "../../Component/LocalStorageWithExpiry";

const AdminSubscriber = () => {
  // Initial table data
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  // Get All Info
  const renderAllInfo = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/get-subscribers`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setTableData(res.data?.subscribers);
        setFilteredData(res.data?.subscribers);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    renderAllInfo();
  }, []);

  useEffect(() => {
    const result = tableData.filter((data) => {
      return data.subscribed_from.toLowerCase().match(search.toLowerCase());
    });
    setFilteredData(result);
  }, [search]);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <>
                  <div className="card-header">
                    <h2>Subscriber</h2>
                  </div>
                  {!isLoading && !error && tableData.length > 0 && (
                    <div className="table-responsive custom_table">
                      <div className="table_fixed my-5">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">Action</th>
                              <th scope="col">Page Title</th>
                              <th scope="col">Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData?.map((data, index) => (
                              <tr key={index}>
                                <td>
                                  <Dropdown style={{ position: "unset" }}>
                                    <Dropdown.Toggle>
                                      <BsThreeDotsVertical color="#000" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      {/* edit */}

                                      <Dropdown.Item
                                      // onClick={() =>
                                      //   fetchDataForEdit("Edit", data)
                                      // }
                                      >
                                        Edit
                                      </Dropdown.Item>

                                      {/* delete */}

                                      <Dropdown.Item
                                      // onClick={() => deleteData(data.id)}
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                                <td>{data?.subscribed_from}</td>
                                <td>{data?.subscribed_email}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </>

                {isLoading && !error && <LinearProgress />}

                {!isLoading && !error && tableData?.length < 1 && (
                  <>
                    <div className="text-center text-danger">
                      <h1>No data Found</h1>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSubscriber;
