import axios from "axios";
import React, { useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FiSave } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import getCookie from "../../../../Component/GetCookie";
import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import { ToastAlert } from "../../../../Component/ToastAlert";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const AdminChildServiceAdd = () => {
  const serviceName = useRef();
  const invoicePrefix = useRef();
  const subServiceName = useRef();
  const childService = useRef();

  const { state } = useLocation();
  const { mainServices } = state;

  const [subServices, setSubServices] = useState([]);
  const fetchSubService = async () => {
    await axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/sub-services/${serviceName.current.value}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        setSubServices(res.data?.sub_services);
      });
  };

  // Form submit to backend
  const store = (e) => {
    const formdata = new FormData();
    formdata.append("main_service_id", serviceName.current.value);
    formdata.append("sub_service_id", subServiceName.current.value);
    formdata.append("name", childService.current.value);
    formdata.append("invoice_prefix", invoicePrefix.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/child-services/store`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          ToastAlert.fire({
            title: "added successfully",
          });
          // e.target.reset();
        }
      });
    e.preventDefault();
  };

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Add Service</h3>
                  <Link to="/admin/final-service-module/child-services">
                    <Button variant="success" size="sm">
                      All Child Services &nbsp;
                    </Button>
                  </Link>
                </div>
                <Row>
                  <form onSubmit={store}>
                    <div className="content-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <div className="row py-3">
                            {/* Main service */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Main Service
                              </Form.Label>
                              <Form.Select
                                aria-label="language example "
                                className=""
                                ref={serviceName}
                                onChange={fetchSubService}
                              >
                                <option value="" disabled selected>
                                  Select main service
                                </option>
                                {mainServices.map((service) => {
                                  return (
                                    <option key={service.id} value={service.id}>
                                      {service.name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Form.Group>

                            {/*Sub Service Name */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Service Name
                              </Form.Label>
                              <Form.Select
                                aria-label="language example "
                                className=""
                                ref={subServiceName}
                              >
                                <option value="" disabled selected>
                                  Select Service
                                </option>
                                {subServices?.length > 0 &&
                                  subServices?.map((subService) => (
                                    <option
                                      key={subService.id}
                                      value={subService.id}
                                    >
                                      {subService.name}
                                    </option>
                                  ))}
                              </Form.Select>
                            </Form.Group>

                            {/* Child Service */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Child Service{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Name"
                                ref={childService}
                              />
                              <Form.Control.Feedback type="invalid">
                                name is required
                              </Form.Control.Feedback>
                            </Form.Group>

                             {/* Invoice Prefix */}
                             <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                              Invoice Prefix
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Invoice Prefix"
                                ref={invoicePrefix}
                              />
                              <Form.Control.Feedback type="invalid">
                              Invoice Prefix is required
                              </Form.Control.Feedback>
                            </Form.Group>

                            {/* Submit button */}
                            <div className="flex_center">
                              <Button
                                type="submit"
                                className="btn-submit mt-5 rounded-3 border-0"
                              >
                                <FiSave /> &nbsp; Save
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminChildServiceAdd;
