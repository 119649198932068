import dayjs from 'dayjs'
import { Fragment, useContext, useEffect, useState } from 'react'
import { BsBookmark, BsCheck, BsChevronLeft, BsChevronRight, BsTrash } from 'react-icons/bs'
import { CalenderContext } from './CalenderContext'
import styles from "./Calender.module.css"
import { AiOutlineClose } from 'react-icons/ai'
import { MdDragHandle, MdSegment } from 'react-icons/md'
import { BiTime } from 'react-icons/bi'
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";
import axios from 'axios'
import Swal from 'sweetalert2'


export function getMonth(month = dayjs().month()) {
    month = Math.floor(month)
    const year = dayjs().year()
    const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day()
    let currentMonthCount = 0 - firstDayOfTheMonth
    const daysMatrix = new Array(5).fill([]).map(() => {
        return new Array(7).fill(null).map(() => {
            currentMonthCount++
            return dayjs(new Date(year, month, currentMonthCount))
        })
    })
    return daysMatrix
}

export function CalenderHeader(header) {
    const { monthIndex, setMonthIndex } = useContext(CalenderContext)
    function handlePrevMonth() {
        setMonthIndex(monthIndex - 1)
    }
    function handleNextMonth() {
        setMonthIndex(monthIndex + 1)
    }
    function handleReset() {
        setMonthIndex(monthIndex === dayjs().month() ? monthIndex + Math.random() : dayjs().month())
    }
    return (
        <div className="px-3 py-2 d-flex align-items-center">
            <img src={require("../../../Assets/google-calender.png")} alt="calender" width={40} />
            <h1 className='mb-0 mx-4 fs-3 text-muted fw-bold'>Calender</h1>
            <button className="border rounded py-2 px-3 me-4" onClick={handleReset}>
                Today
            </button>

            <span className='me-4 cursor' style={{ userSelect: "none" }} onClick={handlePrevMonth}>
                <BsChevronLeft />
            </span>
            <span className='cursor' style={{ userSelect: "none" }} onClick={handleNextMonth}>
                <BsChevronRight />
            </span>
            <h2 className='mb-0 ms-4 fs-3 text-muted fw-bold'>{dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM YYYY")}</h2>
        </div>
    )

}

const CreateEventBtn = () => {
    const { setShowEventModal } = useContext(CalenderContext)

    return (
        <button onClick={() => setShowEventModal(true)} className='border px-2 py-1 rounded-5 bg-white d-flex justify-content-center align-items-center'>
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" className="-ml-3 mr-3">
                <path fill="#34A853" d="M16 16v14h4V20z"></path>
                <path fill="#4285F4" d="M30 16H20l-4 4h14z"></path>
                <path fill="#FBBC05" d="M6 16v4h10l4-4z"></path>
                <path fill="#EA4335" d="M20 16V6h-4v14z"></path>
                <path fill="none" d="M0 0h36v36H0z"></path>
            </svg>
            <span className='ps-3 pe-4 fs-6'>Create</span>
        </button>
    )
}

export function GetDayClass(day) {
    const { daySelected } = useContext(CalenderContext)
    const format = "DD-MM-YY"
    const nowDay = dayjs().format(format)
    const currDay = day.format(format)
    const slcDay = daySelected && daySelected.format(format)
    if (nowDay === currDay) {
        return `${styles.currentDay}`
    } else if (currDay === slcDay) {
        return `${styles.selectedDay}`
    } else {
        return ""
    }
}

const SmallCalender = () => {
    const { monthIndex, setSmallCalenderMonth, setDayselected } = useContext(CalenderContext)
    const [currentMonthIdx, setCurrentMonthIdx] = useState(dayjs().month())
    const [currentMonth, setCurrentMonth] = useState(getMonth())

    function handlePrevMonth() {
        setCurrentMonthIdx(currentMonthIdx - 1)
    }
    function handleNextMonth() {
        setCurrentMonthIdx(currentMonthIdx + 1)
    }

    useEffect(() => {
        setCurrentMonth(getMonth(currentMonthIdx))
    }, [currentMonthIdx])

    useEffect(() => {
        setCurrentMonthIdx(monthIndex)
    }, [monthIndex])


    return (
        <div className="mt-4 ms-2">
            <header className="d-flex justify-content-between">
                <h5 className='text-black-50'>{dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM YYYY")}</h5>
                <div>
                    <span className='me-4 cursor' style={{ userSelect: "none" }} onClick={handlePrevMonth}>
                        <BsChevronLeft />
                    </span>
                    <span className='cursor' style={{ userSelect: "none" }} onClick={handleNextMonth}>
                        <BsChevronRight />
                    </span>
                </div>

            </header>
            <div className={`${styles.smallCalenderGrid} justify-content-between mt-4`}>
                {currentMonth[0].map((day, i) => (
                    <span key={i} className="text-center px-2">
                        {day.format('dd').charAt(0)}
                    </span>
                ))}
                {currentMonth.map((row, i) => (
                    <Fragment key={i}>
                        {row.map((day, idx) => (
                            <span key={idx} onClick={() => { setSmallCalenderMonth(currentMonthIdx); setDayselected(day) }} className={`${GetDayClass(day)} py-1 px-2 text-center cursor`}>
                                {day.format("D")}
                            </span>

                        ))}
                    </Fragment>
                ))}
            </div>
            <div className='small'>

            </div>


        </div>
    )
}

export function Sidebar(header) {
    return (
        <aside className="border p-4 ">
            <CreateEventBtn />
            <SmallCalender />
            {/* <Labels /> */}
        </aside>
    )
}


function Day({ dayEventsApi, day, rowIdx }) {
    const [dayEvents, setDayEvents] = useState([])

    const { setDayselected, setShowEventModal, setSelectedEvent } = useContext(CalenderContext)

    useEffect(() => {
        const events = dayEventsApi.filter((evt) => evt.event_date == day.format("YYYY-MM-DD"))
        setDayEvents(events)

    }, [dayEventsApi, day])


    function getCurrentDayClass() {
        return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY") ? `${styles.currentDay} p-2` : ""
    }

    return (<>
        <div className={'flex-grow-1 border cursor d-flex flex-column align-items-center position-relative w-100 h-100'} onClick={() => { setDayselected(day); setShowEventModal(true) }}>
            {rowIdx === 0 && <p className='fs-6 mt-1' style={{ width: "38px" }}>{day.format("ddd").toUpperCase()}</p>}
            <p className={`fs-6 my-1 text-center ${getCurrentDayClass()}`} style={{ width: "38px" }} >
                {day.format("DD")}
            </p>
            {dayEvents?.map((evt, idx) => (
                <div key={idx} style={{ backgroundColor: `${evt.label}` }} className={`${styles.eventTitle} mb-1`} onClick={() => setSelectedEvent(evt)}>
                    <p className='mb-0'>{evt.title}</p>
                </div>
            ))}
        </div >

    </>)
}


export function Month({ month }) {
    const { dayEventsApi, setDayEventsApi } = useContext(CalenderContext)

    useEffect(() => {
        axios
            .get(`${BACKEND_BASE_URL}/api/v2/rianaire/calenders`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
                },
            })
            .then((response) => {
                setDayEventsApi(response.data?.calender_data)
            });
    }, [])


    return (

        <div className="flex-grow-1">
            <div className="row h-100">
                {month.map((row, i) =>
                (<Fragment key={i}>
                    <div className="d-flex">
                        {row?.map((day, idx) => (
                            <Day dayEventsApi={dayEventsApi} day={day} key={idx} rowIdx={i} />
                        ))}
                    </div>
                </Fragment>)
                )}
            </div>

        </div>
    )

}

export function EventModal() {
    const { setShowEventModal, daySelected, dispatchCalEvent, selectedEvent, dayEventsApi } = useContext(CalenderContext)
    const labelClasses = ["tomato", "green", "skyblue", "gold", "darkturquoise", "mediumpurple"]
    const [title, setTitle] = useState(selectedEvent ? selectedEvent.title : "")
    const [description, setDescription] = useState(selectedEvent ? selectedEvent.description : "")
    const [selectedLabel, setSelectedLabel] = useState(selectedEvent ? labelClasses.find((lbl) => lbl === selectedEvent.label) : labelClasses[0])

    function handleSubmit(e) {
        e.preventDefault();
        const calenderEvent = {
            title,
            description,
            label: selectedLabel,
            day: daySelected.valueOf(),
            id: selectedEvent ? selectedEvent.id : Date.now()
        }
        if (selectedEvent) {
            dispatchCalEvent({ type: "update", payload: calenderEvent });
            const formdata = new FormData();
            formdata.append("_method", "PUT");
            formdata.append("title", title);
            formdata.append("description", description);
            formdata.append("label", selectedLabel);
            formdata.append("event_date", daySelected?.format("YYYY-MM-DD"));
            axios
                .post(`${BACKEND_BASE_URL}/api/v2/rianaire/calenders/update/${calenderEvent.id}`, formdata, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
                    },
                })
                .then((response) => {
                    if (response.data?.status == 200) {
                        Swal.fire({
                            icon: "success",
                            text: "event updated successfully",
                            showConfirmButton: false,
                        });
                        setTimeout(() => {
                            window.location.reload()
                        }, 1200);
                    }
                });

        } else {
            dispatchCalEvent({ type: "push", payload: calenderEvent });
            const formdata = new FormData();
            formdata.append("title", title);
            formdata.append("description", description);
            formdata.append("label", selectedLabel);
            formdata.append("event_date", daySelected?.format("YYYY-MM-DD"));
            axios
                .post(`${BACKEND_BASE_URL}/api/v2/rianaire/calenders/store`, formdata, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
                    },
                })
                .then((response) => {
                    if (response.data?.status == 200) {
                        Swal.fire({
                            icon: "success",
                            text: "event saved successfully",
                            showConfirmButton: false,
                        });
                        setTimeout(() => {
                            window.location.reload()
                        }, 1200);
                    }
                });
        }
        setShowEventModal(false)
    }

    const deleteEvent = async (id) => {
        const isConfirm = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "green",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            return result.isConfirmed;
        });

        if (!isConfirm) {
            return;
        }

        if (isConfirm) {
            axios
                .delete(`${BACKEND_BASE_URL}/api/v2/rianaire/calenders/delete/${id}`, {
                    headers: {
                        Authorization: `Bearer ${getLocalStorageWithExpiry(
                            "ACCESS_TOKEN"
                        )}`,
                    },
                })
                .then((res) => {
                    Swal.fire({
                        icon: "success",
                        text: "event deleted successfully",
                        showConfirmButton: false,
                    });
                    setTimeout(() => {
                        window.location.reload()
                    }, 1200);
                });
        }
    };

    return (
        <div className={styles.eventModal}>
            <form className={styles.modalForm}>
                <header className={styles.modalHeader}>
                    <MdDragHandle color="#8d8d8d" size={"1.2rem"} />
                    <div>
                        {selectedEvent && (dayEventsApi[0]?.user_id == getLocalStorageWithExpiry("LOGGED_IN_ADMIN_ID")) && (
                            <BsTrash color="#8d8d8d" size={"1.2rem"} className='cursor me-2' onClick={() => { dispatchCalEvent({ type: "delete", payload: selectedEvent }); deleteEvent(selectedEvent.id); setShowEventModal(false) }} />
                        )}
                        <AiOutlineClose color="#8d8d8d" size={"1.2rem"} className='cursor' onClick={() => setShowEventModal(false)} />
                    </div>

                </header>
                <div className={styles.modalBody}>
                    <div className="row align-items-baseline">
                        <div className="col-md-2 ">
                        </div>
                        <div className="col-md-10 mb-2">
                            <input type="text" name="title" value={title} required placeholder='Add title' className='form-control p-2 rounded-0' onChange={(e) => setTitle(e.target.value)} />
                        </div>
                        <div className="col-md-2 ps-4">
                            <BiTime color="#8d8d8d" size={"1.5rem"} />
                        </div>
                        <div className="col-md-10 mb-2">
                            <p className='mb-0 p-2 fw-bold'>{daySelected?.format("dddd, MMMM DD")}</p>
                        </div>
                        <div className="col-md-2 ps-4">
                            <MdSegment color="#8d8d8d" size={"1.5rem"} />
                        </div>
                        <div className="col-md-10">
                            <textarea rows={3} name="description" value={description} required placeholder='Add a description' className='form-control p-2 rounded-0' onChange={(e) => setDescription(e.target.value)} />
                        </div>
                        <div className="col-md-2 ps-4  position-relative" style={{ top: "-5px" }}>
                            <BsBookmark color="#8d8d8d" size={"1.5rem"} />
                        </div>
                        <div className="col-md-10 d-flex align-items-center mt-4 ">
                            {labelClasses?.map((label, i) => (
                                <span key={i} className="rounded-circle d-flex justify-content-center align-items-center me-1" style={{ backgroundColor: `${label}`, height: "23px", width: "23px", color: "#fff" }} onClick={(e) => setSelectedLabel(label)}>
                                    {selectedLabel === label && <BsCheck size={"1.5rem"} />}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
                <footer className={styles.formFooter}>
                    {(dayEventsApi[0]?.user_id == getLocalStorageWithExpiry("LOGGED_IN_ADMIN_ID")) && <button type="submit" className={styles.formFooterBtn} onClick={handleSubmit}>
                        Save
                    </button>}

                </footer>
            </form>
        </div>

    )
}


// export function Labels() {
//     const { labels, updateLabel } = useContext(CalenderContext)

//     return (<>
//         {labels.length > 0 && <p className="fw-bold mt-4 mb-2">Label</p>}
//         {labels.map(({ label: lbl, checked }, i) => (

//             <div className={"form-check user-select-none mt-3" + styles.checkbox}>
//                 <input className="form-check-input border-0 " type="checkbox" value="" id={lbl} checked={checked} style={{ backgroundColor: lbl, padding: "8px 8px", }} onChange={() => updateLabel({ label: lbl, checked: !checked })} />
//                 <label className="form-check-label" for={lbl} >
//                     {lbl == "tomato" && "red"}
//                     {lbl == "green" && "green"}
//                     {lbl == "skyblue" && "blue"}
//                     {lbl == "gold" && "yellow"}
//                     {lbl == "darkturquoise" && "cyan"}
//                     {lbl == "mediumpurple" && "purple"}
//                 </label>
//             </div>

//         ))}

//     </>)
// }


