import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AiOutlineClose, AiOutlinePlusCircle } from "react-icons/ai";
import { FiSave } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import getCookie from "../../../../Component/GetCookie";
import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import { ToastAlert } from "../../../../Component/ToastAlert";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const AdditionalServiceEdit = () => {
  const { serviceId } = useParams();
  const serviceName = useRef();
  const subServiceName = useRef();
  const childService = useRef();
  const [mainServices, setMainServices] = useState([]);
  const [subServices, setSubServices] = useState([]);
  const [childServices, setChildServices] = useState([]);

  // const [dropdownVal, setDropdownVal] = useState();

  const [dynamicdropdown, setDynamicdropdown] = useState([]);

  const [serviceTypeBox, setServiceTypeBox] = useState(true);
  const [sectionTypeBox, setSectionTypeBox] = useState(false);
  const [wordpressWebsiteType, setWordpressWebsiteType] = useState(false);
  const [serverType, setServerType] = useState(false);
  const [packageType, setPackageType] = useState(false);

  const [editedAdditionalService, setEditedAdditionalService] = useState([]);

  const handleServiceType = (e) => {
    if (
      // dev&Tech -> wordpress -> Page Design
      serviceName.current.value == 1 &&
      subServiceName.current.value == 1 &&
      childService.current.value == 1
    ) {
      setSectionTypeBox(false);
      setWordpressWebsiteType(true);
      setServerType(false);
      setPackageType(false);
    }
    if (
      //dev&Tech -> wordpress -> Complete Website
      serviceName.current.value == 1 &&
      subServiceName.current.value == 1 &&
      childService.current.value == 2
    ) {
      setSectionTypeBox(false);

      setWordpressWebsiteType(true);
      setServerType(false);
      setPackageType(false);
    }
    if (
      // dev&Tech -> shopify -> Section Design
      serviceName.current.value == 1 &&
      subServiceName.current.value == 2 &&
      childService.current.value == 4
    ) {
      setSectionTypeBox(true);
      setWordpressWebsiteType(false);
      setServerType(false);
      setPackageType(false);
    }
    if (
      // dev&Tech -> shopify -> Complete Website
      serviceName.current.value == 1 &&
      subServiceName.current.value == 2 &&
      childService.current.value == 5
    ) {
      setSectionTypeBox(false);
      setWordpressWebsiteType(false);
      setServerType(false);
      setPackageType(false);
    }
    if (
      // dev&Tech -> Web programming -> React JS , React Laravel, PHP Laravel
      serviceName.current.value == 1 &&
      subServiceName.current.value == 4 &&
      (childService.current.value == 7 ||
        childService.current.value == 8 ||
        childService.current.value == 9)
    ) {
      setWordpressWebsiteType(true);
      setSectionTypeBox(false);
      setServerType(false);
      setPackageType(false);
    }

    if (
      // dev&Tech -> Website Maintenance -> Server installation
      serviceName.current.value == 1 &&
      subServiceName.current.value == 5 &&
      childService.current.value == 12
    ) {
      setSectionTypeBox(false);
      setWordpressWebsiteType(false);
      setServerType(true);
      setPackageType(false);
    }

    if (
      // marketing -> digital marketing -> Social Media Management
      serviceName.current.value == 3 &&
      subServiceName.current.value == 11 &&
      childService.current.value == 21
    ) {
      setSectionTypeBox(false);
      setWordpressWebsiteType(true);
      setServerType(false);
      setPackageType(false);
    }
    if (
      // dev&Tech -> Wordpress -> Custom Page
      serviceName.current.value == 1 &&
      subServiceName.current.value == 1 &&
      childService.current.value == 32
    ) {
      setSectionTypeBox(false);
      setWordpressWebsiteType(false);
      setServerType(false);
      setPackageType(false);
    }
  };

  // Dynamic Input Field 1
  const [inputFields, setInputFields] = useState([
    {
      sectiontype: null,
      additionalServices: "",
      usdPrice: "",
      bdtPrice: "",
      duration: "",
    },
  ]);

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;

    setInputFields(data);
  };

  const handleFileChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.files[0];
    setInputFields(data);
  };

  const fetchMainServices = async () => {
    await axios.get(`${BACKEND_BASE_URL}/api/v2/main-services`).then((res) => {
      setMainServices(res.data?.main_services);
    });
  };

  const fetchSubServices = (e) => {
    setSubServices([]);
    e.preventDefault();
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/sub-services/${serviceName.current.value}`
      )
      .then((res) => {
        setSubServices(res.data?.sub_services);
      });
  };

  const fetchChildService = () => {
    // UI/UX design
    if (subServiceName.current.value == 3) {
      setSectionTypeBox(false);
      setWordpressWebsiteType(false);
      setServerType(false);
      setServiceTypeBox(false);
      setPackageType(false);
    }
    //  Website Redesign
    else if (subServiceName.current.value == 6) {
      setSectionTypeBox(false);
      setWordpressWebsiteType(false);
      setServerType(false);
      setServiceTypeBox(true);
      setPackageType(false);
    }

    // else if (
    //   subServiceName.current.value == 13 ||
    //   subServiceName.current.value == 14
    // ) {
    //   setNoOfPageBox(false);
    //   setSectionTypeBox(false);
    //   setWordpressWebsiteType(false);
    //   setServerType(false);
    //   setServiceTypeBox(false);
    //   setPackageType(true);
    // }
    else {
      setSectionTypeBox(false);
      setWordpressWebsiteType(false);
      setServerType(false);
      setServiceTypeBox(true);
      setPackageType(false);
    }

    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/child-services/${serviceName.current.value}/${subServiceName.current.value}`
      )
      .then((res) => {
        setChildServices(res.data?.child_services);
      });
  };

  const fetchSectionType = () => {
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/dynamic-dropdown/${serviceName.current.value}/${subServiceName.current.value}/${childService.current.value}`
      )
      .then((res) => {
        setDynamicdropdown(res.data?.dynamic_dropdown_info);
      });
  };

  const renderSingleData = async () => {
    await axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/additional-service-cost/edit/${serviceId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        setEditedAdditionalService(res.data?.edit_info);
      });
  };

  // form submit to backend
  const updateData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    // formdata.append("main_service_id", serviceName.current.value);
    // formdata.append("sub_service_id", subServiceName.current.value);
    // if (childService.current) {
    //   formdata.append("child_service_id", childService.current.value);
    // }

    inputFields.forEach((item) => {
      // if (item.sectiontype != "" && item.sectiontype != null) {
      //   formdata.append("section_type", item.sectiontype);
      // }
      if (item.additionalServices != "" && item.additionalServices != null) {
        formdata.append("additional_name", item.additionalServices);
      }
      if (item.usdPrice != "" && item.usdPrice != null) {
        formdata.append("additional_usd_price", item.usdPrice);
      }
      if (item.bdtPrice != "" && item.bdtPrice != null) {
        formdata.append("additional_bdt_price", item.bdtPrice);
      }
      if (item.duration != "" && item.duration != null) {
        formdata.append("additional_duration", item.duration);
      }
    });

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/additional-service-cost/update/${serviceId}`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            title: "Submitted Successfully",
          });

          // setInputFields([
          //   {
          //     sectiontype: null,
          //     totalPages: "",
          //     usdPrice: "",
          //     bdtPrice: "",
          //     duration: "",
          //     additionalServices: "",
          //     additionalAppPlugin: "",
          //     dynamicModule: "",
          //   },
          // ]);
          // setMainServices([]);
          // setSubServices([]);
          // setChildServices([]);
          // setDynamicdropdown([]);
          setServiceTypeBox(true);
          fetchMainServices();
        }
      });
    e.preventDefault();
  };

  useEffect(() => {
    fetchMainServices();
    renderSingleData();
  }, []);

  useEffect(() => {
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/sub-services/${editedAdditionalService.main_service_id}`
      )
      .then((res) => {
        setSubServices(res.data?.sub_services);
      });
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/child-services/${editedAdditionalService.main_service_id}/${editedAdditionalService.sub_service_id}`
      )
      .then((res) => {
        setChildServices(res.data?.child_services);
      });
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/dynamic-dropdown/${editedAdditionalService.main_service_id}/${editedAdditionalService.sub_service_id}/${editedAdditionalService.child_service_id}`
      )
      .then((res) => {
        setDynamicdropdown(res.data?.dynamic_dropdown_info);
      });

    if (
      // dev&Tech -> wordpress -> Page Design
      editedAdditionalService.main_service_id == 1 &&
      editedAdditionalService.sub_service_id == 1 &&
      editedAdditionalService.child_service_id == 1
    ) {
      setSectionTypeBox(false);
      setWordpressWebsiteType(true);
      setServerType(false);
      setPackageType(false);
    }
    if (
      //dev&Tech -> wordpress -> Complete Website
      editedAdditionalService.main_service_id == 1 &&
      editedAdditionalService.sub_service_id == 1 &&
      editedAdditionalService.child_service_id == 2
    ) {
      setSectionTypeBox(false);

      setWordpressWebsiteType(true);
      setServerType(false);
      setPackageType(false);
    }
    if (
      // dev&Tech -> shopify -> Section Design
      editedAdditionalService.main_service_id == 1 &&
      editedAdditionalService.sub_service_id == 2 &&
      editedAdditionalService.child_service_id == 4
    ) {
      setSectionTypeBox(true);
      setWordpressWebsiteType(false);
      setServerType(false);
      setPackageType(false);
    }
    if (
      // dev&Tech -> shopify -> Complete Website
      editedAdditionalService.main_service_id == 1 &&
      editedAdditionalService.sub_service_id == 2 &&
      editedAdditionalService.child_service_id == 5
    ) {
      setSectionTypeBox(false);
      setWordpressWebsiteType(false);
      setServerType(false);
      setPackageType(false);
    }
    if (
      // dev&Tech -> Web programming -> React JS , React Laravel, PHP Laravel
      editedAdditionalService.main_service_id == 1 &&
      editedAdditionalService.sub_service_id == 4 &&
      (editedAdditionalService.child_service_id == 7 ||
        editedAdditionalService.child_service_id == 8 ||
        editedAdditionalService.child_service_id == 9)
    ) {
      setWordpressWebsiteType(true);
      setSectionTypeBox(false);
      setServerType(false);
      setPackageType(false);
    }

    if (
      // dev&Tech -> Website Maintenance -> Server installation
      editedAdditionalService.main_service_id == 1 &&
      editedAdditionalService.sub_service_id == 5 &&
      editedAdditionalService.child_service_id == 12
    ) {
      setSectionTypeBox(false);
      setWordpressWebsiteType(false);
      setServerType(true);
      setPackageType(false);
    }

    if (
      // marketing -> digital marketing -> Social Media Management
      editedAdditionalService.main_service_id == 3 &&
      editedAdditionalService.sub_service_id == 11 &&
      editedAdditionalService.child_service_id == 21
    ) {
      setSectionTypeBox(false);
      setWordpressWebsiteType(true);
      setServerType(false);
      setPackageType(false);
    }
    if (
      // dev&Tech -> Wordpress -> Custom Page
      editedAdditionalService.main_service_id == 1 &&
      editedAdditionalService.sub_service_id == 1 &&
      editedAdditionalService.child_service_id == 32
    ) {
      setSectionTypeBox(false);
      setWordpressWebsiteType(false);
      setServerType(false);
      setPackageType(false);
    }
  }, [editedAdditionalService]);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar pageTitle="Additional Services" />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <>
                  <div className="card-header flex_between">
                    {JSON.parse(
                      localStorage.getItem("LOGGED_IN_USER_PERMISSION_INFO")
                    )?.map(
                      (data, index) =>
                        data?.permission_name === "create-service-cost" && (
                          <Link to="/admin/service-costing" key={index}>
                            <Button variant="success" size="sm">
                              All Service Costing&nbsp;
                              <span>
                                <AiOutlinePlusCircle className="mb-1" />
                              </span>
                            </Button>
                          </Link>
                        )
                    )}
                  </div>

                  <div className="">
                    <Form onSubmit={updateData}>
                      <div className="content-wrapper">
                        <div className="card">
                          <div className="card-body table_fixed">
                            <div className="row py-3">
                              {/* Department */}
                              {/* <Form.Group
                                as={Col}
                                md="6"
                                lg="4"
                                xl="4"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Department
                                </Form.Label>
                                <Form.Select
                                  required
                                  aria-label="language example "
                                  className=""
                                  ref={serviceName}
                                  onChange={(e) => {
                                    fetchSubServices(e);
                                  }}
                                >
                                  <option value="">Select Service</option>
                                  {mainServices?.map((parentCategory) => (
                                    <option
                                      key={parentCategory.id}
                                      value={parentCategory.id}
                                      selected={
                                        parentCategory.id ==
                                        editedAdditionalService.main_service_id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {parentCategory.name}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group> */}

                              {/* Service Name */}
                              {/* <Form.Group
                                as={Col}
                                md="6"
                                lg="4"
                                xl="4"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Service Name
                                </Form.Label>
                                <Form.Select
                                  required
                                  aria-label="language example "
                                  className=""
                                  ref={subServiceName}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    fetchChildService();
                                    fetchSectionType(e);
                                  }}
                                >
                                  <option value="" selected>
                                    Select Sub Service
                                  </option>
                                  {subServices.length > 0 &&
                                    subServices?.map((subCategory) => (
                                      <option
                                        key={subCategory.id}
                                        value={subCategory.id}
                                        selected={
                                          subCategory.id ==
                                          editedAdditionalService.sub_service_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {subCategory.name}
                                      </option>
                                    ))}
                                </Form.Select>
                              </Form.Group> */}
                              {/* Service Type */}

                              {/* {serviceTypeBox == true && (
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  lg="4"
                                  xl="4"
                                  controlId="validationCustom01"
                                  className="mb-3"
                                >
                                  <Form.Label className="label fw-bold">
                                    Service Type
                                  </Form.Label>
                                  <Form.Select
                                    required
                                    aria-label="language example "
                                    className=""
                                    ref={childService}
                                    onChange={(e) => {
                                      handleServiceType(e);
                                      fetchSectionType();
                                    }}
                                  >
                                    <option value="0" selected>
                                      Select Service Type
                                    </option>

                                    {childServices?.map((data) => (
                                      <option
                                        option
                                        key={data.id}
                                        value={data.id}
                                        selected={
                                          data.id ==
                                          editedAdditionalService.child_service_id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {data.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              )} */}
                            </div>

                            {inputFields?.map((input, index) => {
                              return (
                                <>
                                  <div
                                    key={index}
                                    className="p-3 mb-2"
                                    style={{
                                      border: "1px solid #d1d1d1",
                                      backgroundColor: "antiquewhite",
                                    }}
                                  >
                                    <Row>
                                     
                                      {/* {sectionTypeBox == true && (
                                        <Form.Group
                                          as={Col}
                                          md="6"
                                          lg="4"
                                          xl="3"
                                          controlId="validationCustom01"
                                          className="mb-3"
                                        >
                                          <Form.Label className="label fw-bold">
                                            Section Type
                                          </Form.Label>
                                          <Form.Select
                                            aria-label="language example "
                                            className=""
                                            name="sectiontype"
                                            onChange={(event) =>
                                              handleFormChange(index, event)
                                            }
                                          >
                                            <option value="">
                                              Select Section Type
                                            </option>

                                            {dynamicdropdown?.map((data) => (
                                              <option
                                                key={data.id}
                                                value={data.id}
                                                selected={
                                                  data.id ==
                                                  editedAdditionalService.section_type
                                                }
                                              >
                                                {data.name}
                                              </option>
                                            ))}
                                          </Form.Select>
                                        </Form.Group>
                                      )}

                                      {serverType == true && (
                                        <Form.Group
                                          as={Col}
                                          md="6"
                                          lg="4"
                                          xl="3"
                                          controlId="validationCustom01"
                                          className="mb-3"
                                        >
                                          <Form.Label className="label fw-bold">
                                            Server Type
                                          </Form.Label>
                                          <Form.Select
                                            required
                                            aria-label="language example "
                                            className=""
                                            name="sectiontype"
                                            onChange={(event) =>
                                              handleFormChange(index, event)
                                            }
                                          >
                                            <option value="" disabled selected>
                                              Select Plugin Type
                                            </option>

                                            {dynamicdropdown?.map((data) => (
                                              <option
                                                key={data.id}
                                                value={data.id}
                                                selected={
                                                  data.id ==
                                                  editedAdditionalService.section_type
                                                }
                                              >
                                                {data.name}
                                              </option>
                                            ))}
                                          </Form.Select>
                                        </Form.Group>
                                      )}

                                     
                                      {wordpressWebsiteType == true && (
                                        <Form.Group
                                          as={Col}
                                          md="6"
                                          lg="4"
                                          xl="3"
                                          className="mb-3"
                                        >
                                          <Form.Label className="label fw-bold">
                                            Website Type
                                          </Form.Label>

                                          <Form.Select
                                            required
                                            aria-label="language example "
                                            className=""
                                            name="sectiontype"
                                            onChange={(event) =>
                                              handleFormChange(index, event)
                                            }
                                          >
                                            <option value="" disabled selected>
                                              Select Website Type
                                            </option>

                                            {dynamicdropdown?.map((data) => (
                                              <option
                                                key={data.id}
                                                value={data.id}
                                                selected={
                                                  data.id ==
                                                  editedAdditionalService.section_type
                                                }
                                              >
                                                {data.name}
                                              </option>
                                            ))}
                                          </Form.Select>
                                        </Form.Group>
                                      )}

                                      
                                      {packageType == true && (
                                        <Form.Group
                                          as={Col}
                                          md="6"
                                          lg="4"
                                          xl="3"
                                          className="mb-3"
                                        >
                                          <Form.Label className="label fw-bold">
                                            Package Type
                                          </Form.Label>

                                          <Form.Select
                                            required
                                            aria-label="language example "
                                            className=""
                                            name="sectiontype"
                                            onChange={(event) =>
                                              handleFormChange(index, event)
                                            }
                                          >
                                            <option value="" disabled selected>
                                              Select Package Type
                                            </option>

                                            {dynamicdropdown?.map((data) => (
                                              <option
                                                key={data.id}
                                                value={data.id}
                                                selected={
                                                  data.id ==
                                                  editedAdditionalService.section_type
                                                }
                                              >
                                                {data.name}
                                              </option>
                                            ))}
                                          </Form.Select>
                                        </Form.Group>
                                      )} */}

                                      {/*Additional Service */}
                                      <Form.Group
                                        as={Col}
                                        md="6"
                                        lg="4"
                                        xl="3"
                                        className="mb-3"
                                      >
                                        <Form.Label className="label fw-bold">
                                          Additional Service
                                        </Form.Label>
                                        <Form.Control
                                          required
                                          type="text"
                                          placeholder="Additional Service"
                                          name="additionalServices"
                                          defaultValue={
                                            editedAdditionalService.name
                                          }
                                          onChange={(event) =>
                                            handleFormChange(index, event)
                                          }
                                        />
                                      </Form.Group>

                                      {/*USD Price */}
                                      <Form.Group
                                        as={Col}
                                        md="6"
                                        lg="4"
                                        xl="3"
                                        className="mb-3"
                                      >
                                        <Form.Label className="label fw-bold">
                                          USD Price
                                        </Form.Label>
                                        <Form.Control
                                          required
                                          type="text"
                                          placeholder="USD Price"
                                          name="usdPrice"
                                          defaultValue={
                                            editedAdditionalService.usd_price
                                          }
                                          onChange={(event) =>
                                            handleFormChange(index, event)
                                          }
                                        />
                                      </Form.Group>

                                      {/* BDT Price */}
                                      <Form.Group
                                        as={Col}
                                        md="6"
                                        lg="4"
                                        xl="3"
                                        className="mb-3"
                                      >
                                        <Form.Label className="label fw-bold">
                                          BDT Price
                                        </Form.Label>
                                        <Form.Control
                                          required
                                          type="text"
                                          placeholder="BDT Price"
                                          name="bdtPrice"
                                          defaultValue={
                                            editedAdditionalService.bdt_price
                                          }
                                          onChange={(event) =>
                                            handleFormChange(index, event)
                                          }
                                        />
                                      </Form.Group>

                                      {/* Project duration */}
                                      <Form.Group
                                        as={Col}
                                        md="6"
                                        lg="3"
                                        xl="2"
                                        controlId="validationCustom01"
                                        className="mb-3"
                                      >
                                        <Form.Label className="label fw-bold">
                                          Duration
                                        </Form.Label>
                                        <Form.Control
                                          required
                                          type="number"
                                          placeholder="Duration"
                                          name="duration"
                                          min="0"
                                          defaultValue={
                                            editedAdditionalService.duration
                                          }
                                          onChange={(event) =>
                                            handleFormChange(index, event)
                                          }
                                        />
                                      </Form.Group>
                                    </Row>
                                  </div>
                                </>
                              );
                            })}

                            <div className="flex_center">
                              <Button
                                type="submit"
                                className="btn-submit mt-5 rounded-3 border-0"
                              >
                                <FiSave /> &nbsp; Update
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalServiceEdit;
