import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import { LinearProgress } from "@mui/material";
import Swal from "sweetalert2";
import getCookie from "../../../Component/GetCookie";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";

const AdminTrodadian = () => {
    const [allEmail, setAllEmail] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    // Get All Blogs
    const renderAllInfo = async () => {
      setIsLoading(true);
      await axios
        .get(`${BACKEND_BASE_URL}/api/v2/rianaire/trodadian-email`, {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        })
        .then((res) => {
          setIsLoading(false);
          setError(false);
          setAllEmail(res.data?.trodadian_emails);
        });
    };

    const deleteData = async (id) => {
      const isConfirm = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "green",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        return result.isConfirmed;
      });

      if (!isConfirm) {
        return;
      }

      if (isConfirm) {
        axios
          .delete(
            `${BACKEND_BASE_URL}/api/v2/rianaire/trodadian-email/delete/${id}`,
            {
              headers: {
                Authorization: `Bearer ${getLocalStorageWithExpiry(
                  "ACCESS_TOKEN"
                )}`,
              },
            }
          )
          .then((res) => {
            Swal.fire({
              icon: "success",
              text: res.data?.message,
              confirmButtonColor: "#5eba86",
            });
            renderAllInfo();
          });
      }
    };

    useEffect(() => {
        renderAllInfo();
    }, []);

    return (
        <div className="main__container">
            <div className="body-wrapper">
                <AdminDashboardNavbar pageTitle="Trodadian" />

                <div className="col-md-12 p-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="col-lg-12">
                                <div className="card-header flex_between">
                                    {/* <h3>All Quote</h3> */}
                                </div>
                                {!isLoading && !error && allEmail.length > 0 && (
                                    <div className="table-responsive custom_table">
                                        <div className="table_fixed">
                                            <table className="table table-hover ">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Handle</th>
                                                        <th scope="col">Email</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allEmail?.map((data) => (
                                                        <tr key={data.id}>
                                                            <td>
                                                                <Dropdown style={{ position: "unset" }}>
                                                                    <Dropdown.Toggle>
                                                                        <BsThreeDotsVertical color="#000" />
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item
                                                                            onClick={() => deleteData(data.id)}
                                                                        >
                                                                            Delete
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td>
                                                            <td>{data?.email}</td>

                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                                {!isLoading && !error && allEmail?.length < 1 && (
                                    <>
                                        <div className="text-center text-danger">
                                            <h1>No data Found</h1>
                                        </div>
                                    </>
                                )}
                                {isLoading && !error && <LinearProgress />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminTrodadian;
