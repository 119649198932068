import { InputLabel, LinearProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../Component/GlobalVariables";
import Swal from "sweetalert2";
import { Button, Modal, Form, Dropdown } from "react-bootstrap";
import AdminDashboardNavbar from "../../Component/AdminDashboardNavbar";
import { AiOutlineClose, AiOutlinePlusCircle } from "react-icons/ai";
import { FaCheck, FaEye } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import { FiSave } from "react-icons/fi";
import getCookie from "../../Component/GetCookie";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getLocalStorageWithExpiry } from "../../Component/LocalStorageWithExpiry";

const TopCompany = () => {
  const clientName = useRef();
  const clientLogo = useRef();

  const [tableData, setTableData] = useState([]);

  // Modal Section Data
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("lg");

  // Vew single value
  const [singleClientValue, setSingleClientValue] = useState([]);

  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/happy-clients`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setTableData(res.data?.happy_clients);
      });
  };

  // Add new
  const addNewData = (modalValue) => {
    setFile([]);
    setModalSize("lg");
    setModalData(modalValue);
    setModalShow(true);
  };

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();
    formdata.append("clients_name", clientName.current.value);
    formdata.append("clients_logo", clientLogo.current.files[0]);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/happy-clients/store`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllInfo();
          e.target.reset();
          setModalShow(false);
        }
      });
    e.preventDefault();
  };

  // View single Data
  const showSingleData = (modalValue, data) => {
    setModalSize("sm");
    setModalData(modalValue);
    setSingleClientValue(data);
    setModalShow(true);
  };

  // Edit data
  const fetchDataForEdit = (modalValue, data) => {
    setFile([]);
    setModalSize("lg");
    setModalData(modalValue);
    setSingleClientValue(data);
    setModalShow(true);
  };

  // Image Preview
  const [files, setFile] = useState([]);

  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  // Updated data to backend
  const updateClientInfo = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("clients_name", clientName.current.value);
    if (clientLogo.current.files[0]) {
      formdata.append("clients_logo", clientLogo.current.files[0]);
    }
    // if (IsHome === true) {
    //   formdata.append("isHome", IsHome);
    // }

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/happy-clients/update/${singleClientValue.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        Swal.fire({
          icon: "success",
          text: response.data?.message,
          confirmButtonColor: "#5eba86",
        });
        setModalShow(false);
        renderAllInfo();
      });

    e.preventDefault();
  };

  // Delete Data
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}/api/v2/rianaire/happy-clients/delete/${id}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllInfo();
        });
    }
  };

  useEffect(() => {
    renderAllInfo();
  }, []);

  const StatusUpdate = (id) => {
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/happy-clients/status-update/${id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        renderAllInfo();
      });
  };

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Clients</h3>
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => addNewData("Add")}
                  >
                    Add &nbsp;
                    <span>
                      <AiOutlinePlusCircle className="mb-1" />
                    </span>
                  </Button>
                </div>
                {tableData.length > 0 ? (
                  <div className="table-responsive custom_table">
                    <div className="table_fixed">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Handle</th>
                            <th scope="col">Title</th>
                            <th scope="col">Featured Image</th>
                            <th scope="col">Is Home</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData?.map((data, index) => (
                            <tr key={index}>
                              <td>
                                <Dropdown style={{ position: "unset" }}>
                                  <Dropdown.Toggle>
                                    <BsThreeDotsVertical color="#000" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {/* view */}
                                    <Dropdown.Item
                                      onClick={() => {
                                        showSingleData("View", data);
                                      }}
                                    >
                                      View
                                    </Dropdown.Item>
                                    {/* edit */}
                                    <Dropdown.Item
                                      onClick={() =>
                                        fetchDataForEdit("Edit", data)
                                      }
                                    >
                                      Edit
                                    </Dropdown.Item>
                                    {/* delete */}

                                    <Dropdown.Item
                                      onClick={() => deleteData(data.id)}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>

                              <td>{data?.clients_name}</td>

                              <td>
                                <img
                                  className="img-thumbnail"
                                  width={80}
                                  height={50}
                                  src={`${BACKEND_BASE_URL}${data?.clients_logo}`}
                                  alt={data?.clients_name}
                                />
                              </td>
                              <td>
                                {data.is_home == 1 ? (
                                  <Link
                                    to=""
                                    onClick={() => StatusUpdate(data.id)}
                                  >
                                    <FaCheck
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",

                                        padding: "2px 4px",
                                        borderRadius: "4px",
                                      }}
                                      size="1.6em"
                                    />
                                  </Link>
                                ) : (
                                  <Link
                                    to=""
                                    onClick={() => StatusUpdate(data.id)}
                                  >
                                    <AiOutlineClose
                                      style={{
                                        color: "white",
                                        backgroundColor: "red",

                                        padding: "2px 4px",
                                        borderRadius: "4px",
                                      }}
                                      size="1.6em"
                                    />
                                  </Link>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <LinearProgress />
                )}
              </div>
            </div>
            <Modal
              show={modalShow}
              size={modalSize}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => setModalShow(false)}
            >
              <Modal.Header>
                <Modal.Title className="" id="contained-modal-title-vcenter">
                  {modalData}
                </Modal.Title>
                <Button
                  className="p-0 border-0 bg-danger"
                  onClick={() => setModalShow(false)}
                >
                  <CgClose
                    style={{
                      fontSize: "26px",
                    }}
                  />
                </Button>
              </Modal.Header>
              <Modal.Body>
                {/* Add Modal section */}
                {modalData === "Add" && (
                  <div className="">
                    <Form onSubmit={storeData}>
                      <div className="content-wrapper">
                        <div className="card">
                          <div className="card-body">
                            <div className="row py-3">
                              {/* content Name */}
                              <div className="col-md-6">
                                <div className="form-group mb-3">
                                  <div>
                                    <Form.Label className="label fw-bold">
                                      Client Name
                                    </Form.Label>
                                  </div>

                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Client Name"
                                    required
                                    ref={clientName}
                                  />
                                </div>
                              </div>
                              {/* content Image */}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div>
                                    <Form.Label className="label fw-bold">
                                      Client Logo
                                    </Form.Label>
                                  </div>
                                  <div className=" image-file">
                                    <input
                                      type="file"
                                      className="form-control"
                                      required
                                      onChange={handleImgPreview}
                                      ref={clientLogo}
                                    />
                                    {files.map((file, key) => {
                                      return (
                                        <div key={key} className="Row">
                                          <span className="Filename">
                                            <img
                                              width={80}
                                              height={50}
                                              src={URL.createObjectURL(file)}
                                              alt={file.name}
                                            />
                                          </span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>

                              {/* Submit button */}
                              <button
                                type="submit"
                                className="btn-submit mt-5 rounded-3 border-0"
                              >
                                <FiSave /> &nbsp; Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                )}

                {/* Edit modal section */}
                {modalData === "Edit" && (
                  <form onSubmit={updateClientInfo}>
                    <div className="content-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <div className="row py-3">
                            {/* content Name */}
                            <div className="col-md-6">
                              <div className="form-group mb-3">
                                <div>
                                  <Form.Label className="label fw-bold">
                                    Client Name
                                  </Form.Label>
                                </div>
                                <div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    required
                                    ref={clientName}
                                    defaultValue={
                                      singleClientValue?.clients_name
                                    }
                                  />
                                  {/* <div className="mt-2">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            isChecked == 1 ? "checked" : ""
                                          }
                                          // ref={isHome}
                                          onChange={handleCheckboxEdit}
                                        />
                                      }
                                      label="Is Home"
                                    />
                                  </div> */}
                                </div>
                              </div>
                            </div>

                            {/* content Image */}
                            <div className="col-md-6">
                              <div className="form-group">
                                <div>
                                  <label className="label fw-bold">
                                    Client Logo
                                  </label>
                                </div>
                                <div className=" image-file">
                                  <input
                                    type="file"
                                    className="form-control"
                                    ref={clientLogo}
                                    onChange={handleImgPreview}
                                  />

                                  {files.map((file, key) => {
                                    return (
                                      <div key={key} className="Row">
                                        <span className="Filename">
                                          <img
                                            width={80}
                                            height={50}
                                            src={URL.createObjectURL(file)}
                                            alt={file.name}
                                          />
                                        </span>
                                      </div>
                                    );
                                  })}

                                  {files.length == 0 && (
                                    <img
                                      width={80}
                                      height={50}
                                      src={`${BACKEND_BASE_URL}${singleClientValue?.clients_logo}`}
                                      alt={singleClientValue?.clients_name}
                                      name="img"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* Submit button */}
                            <button
                              type="submit"
                              className="btn-submit mt-5 rounded-3 border-0"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}

                {/* View Modal section */}
                {modalData === "View" && (
                  <>
                    <h4>Name: {singleClientValue?.clients_name}</h4>
                    <img
                      className="img-thumbnail"
                      src={`${BACKEND_BASE_URL}/${singleClientValue?.clients_logo}`}
                      alt=""
                    />
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setModalShow(false)}
                  className="btn-danger"
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopCompany;
