import axios from "axios";
import Cookie from "cookie-universal";
import React, { useContext, useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import {
  AiFillDashboard,
  AiOutlineAntDesign,
  AiOutlineHome,
  AiOutlineMenu,
  AiOutlineSolution,
  AiOutlineTeam,
} from "react-icons/ai";
import { BiCategoryAlt, BiMenu, BiSupport } from "react-icons/bi";
import {
  BsFillChatDotsFill,
  BsFillPersonCheckFill,
  BsFillPersonLinesFill,
} from "react-icons/bs";
import {
  FaBlogger,
  FaCode,
  FaProductHunt,
  FaQuora,
  FaRegRegistered,
  FaServicestack,
  FaSignOutAlt,
  FaUserTie,
} from "react-icons/fa";
import { FiActivity, FiSettings } from "react-icons/fi";
import { GrTechnology } from "react-icons/gr";
import { HiUserGroup } from "react-icons/hi";
import { IoIosFolderOpen } from "react-icons/io";
import { IoChevronDownOutline } from "react-icons/io5";
import {
  MdAdminPanelSettings,
  MdBorderColor,
  MdCategory,
  MdDesignServices,
  MdDeveloperMode,
  MdOutlineArrowDropDownCircle,
  MdOutlinePriceChange,
  MdOutlineReviews,
  MdOutlineUnsubscribe,
  MdRateReview,
} from "react-icons/md";
import { RiAdminFill, RiListCheck2 } from "react-icons/ri";
import { SiKnowledgebase, SiNounproject } from "react-icons/si";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { UserContext } from "../../App";
import { BACKEND_BASE_URL } from "../../Component/GlobalVariables";
import {
  getLocalStorageWithExpiry,
  setLocalStorageWithExpiry,
} from "../../Component/LocalStorageWithExpiry";
import "./AdminContent.css";

const AdminContent = (props) => {
  const { toggleNav, setToggleNav, adminUserInfo, setAdminUserInfo } =
    useContext(UserContext);
  const cookies = Cookie();

  const [open, setOpen] = useState(false);
  const [openOne, setOpenOne] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);
  const [openThree, setOpenThree] = useState(false);
  const [openFour, setOpenFour] = useState(false);
  const [openFive, setOpenFive] = useState(false);

  const [asanaTeam, setAsanaTeam] = useState([]);
  const apiKey = "1/1181186354658771:8ce46a50ca6dd71089785591c1f34bc6";
  const cusToggole = (id) => {
    var colDiv = document.getElementById(id + "Collapse");
    if (colDiv.getAttribute("class", "show") == "collapse show ") {
      document.getElementById(id + "Collapse").classList.remove("show");
    } else {
      colDiv.setAttribute("class", "collapse show ");
    }
  };

  const navigate = useNavigate();

  const fetchData = () => {
    axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/admin-user-profile`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })

      .then((res) => {
        setAdminUserInfo(res.data?.user_profile);
        setLocalStorageWithExpiry("adminUserInfo", res.data?.user_profile, 24);
      });

    const team = {
      method: "GET",
      url: `https://app.asana.com/api/1.0/users/${
        getLocalStorageWithExpiry("adminUserInfo")?.email
      }/teams`,
      params: { organization: "1181186357184897" },
      headers: { Authorization: `Bearer ${apiKey}` },
    };
    axios.request(team).then(function (response) {
      setAsanaTeam(response.data?.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const LogoutAdmin = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/logout`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        if (res.data?.status == 200) {
          Swal.fire({
            icon: "success",
            text: res.data.message,
            confirmButtonColor: "#5eba86",
          });

          cookies.removeAll();
          localStorage.removeItem("LOGGED_IN_USER_PERMISSION_INFO");
          localStorage.removeItem("adminUserInfo");
          localStorage.removeItem("LOGGED_IN_ADMIN_USER_TYPE");
          localStorage.removeItem("ACCESS_TOKEN");
          localStorage.removeItem("LOGGED_IN_ADMIN_ID");
          navigate("/");
        }
      });
  };

  let fetchPermissions = localStorage.getItem("LOGGED_IN_USER_PERMISSION_INFO");

  return (
    <div className="d-flex admin__dashboard">
      <nav className={`sidebar ${toggleNav ? "close" : ""}`}>
        <header className="mt-4 mb-2">
          <div className="mx-4 image-text d-flex align-items-center justify-content-between">
            <div className="text logo_img_wrapper">
              <Link className="" to="/" target="_blank">
                <img
                  src={require("../../Assets/logo.png")}
                  alt=""
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>

          <AiOutlineMenu
            className={`toggle`}
            onClick={() => setToggleNav(!toggleNav)}
          />
        </header>
        <p
          className="role text-muted ps-5 ms-1 mb-4"
          style={{ height: "22px" }}
        >
          {adminUserInfo.role_id == "1" && "Super Admin"}
          {adminUserInfo.role_id == "7" && "Project Manager"}
          {adminUserInfo.role_id == "14" && "Brand Manager"}
          {adminUserInfo.role_id == "15" && "Web Developer"}
          {adminUserInfo.role_id == "16" && "Graphic Designer"}
        </p>
        <div className="menu-bar">
          <div className="menu">
            <ul className="menu-links ps-0">
              <NavLink to="/admin/">
                <li className={`nav-link `} title="Dashboard">
                  <AiFillDashboard className="icon" />
                  <span className={`text nav-text `}>Dashboard</span>
                </li>
              </NavLink>

              {JSON.parse(fetchPermissions)?.map(
                (data, index) =>
                  data?.permission_name === "all-trodadian" && (
                    <NavLink
                      key={data.permission_id}
                      to="trodadian-email"
                      title="Trodadian"
                    >
                      <li className={`nav-link`}>
                        <BsFillPersonCheckFill className="icon" />
                        <span className={`text nav-text `}>Trodadian</span>
                      </li>
                    </NavLink>
                  )
              )}

              {JSON.parse(fetchPermissions)?.map(
                (data, index) =>
                  data?.permission_name === "home-menu" && (
                    <li
                      key={data.permission_id}
                      className="nav-link d-flex align-items-center cursor"
                      id="home"
                      title="Home"
                      onClick={() => cusToggole("home")}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <AiOutlineHome className="icon" />
                        <div className="text nav-text dropdown_menu">
                          Home
                          <span>
                            <IoChevronDownOutline
                              className={` ms-4 ${open ? "rotate_icon" : ""}`}
                            />
                          </span>
                        </div>
                      </div>
                    </li>
                  )
              )}

              <div className="dropdown_menu_item w-100">
                <Collapse id="homeCollapse" in={false}>
                  <div className="home_dropdown">
                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "view-top-company" && (
                          <NavLink
                            key={data.permission_id}
                            title="Top Company"
                            to="top-company"
                          >
                            <p className="text nav-text mb-0  py-2">
                              <FiActivity className="icon" /> Top Company
                            </p>
                          </NavLink>
                        )
                    )}
                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "view-top-service" && (
                          <NavLink
                            key={data.permission_id}
                            title="Top Services"
                            to="top-services"
                          >
                            <p className="text nav-text mb-0  py-2">
                              <MdDesignServices className="icon" />
                              Top Services
                            </p>
                          </NavLink>
                        )
                    )}
                    <NavLink
                      title="Masonary Latest Projects"
                      to="masonary-latest-projects"
                    >
                      <p className="text nav-text mb-0  py-2">
                        <BiCategoryAlt className="icon" /> Latest Projects
                      </p>
                    </NavLink>

                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "view-client-review" && (
                          <NavLink
                            key={data.permission_id}
                            title="Client Review"
                            to="client-review"
                          >
                            <p className="text nav-text mb-0  py-2">
                              <MdOutlineReviews className="icon" /> Client
                              Review
                            </p>
                          </NavLink>
                        )
                    )}
                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "view-team" && (
                          <NavLink
                            key={data.permission_id}
                            to="team"
                            title="Team"
                          >
                            <p className="text nav-text mb-0  py-2">
                              <AiOutlineTeam className="icon" /> Team
                            </p>
                          </NavLink>
                        )
                    )}
                  </div>
                </Collapse>
              </div>

              {JSON.parse(fetchPermissions)?.map(
                (data, index) =>
                  data?.permission_name === "view-service" && (
                    <li
                      title="Services"
                      key={data.permission_id}
                      className="nav-link d-flex align-items-center cursor"
                      id="Services"
                      onClick={() => cusToggole("Services")}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <FaServicestack className="icon" />
                        <div className="text nav-text dropdown_menu">
                          Services
                          <span>
                            <IoChevronDownOutline
                              className={`ms-4 ${openTwo ? "rotate_icon" : ""}`}
                            />
                          </span>
                        </div>
                      </div>
                    </li>
                  )
              )}
              <div className="dropdown_menu_item w-100 ps-4">
                <Collapse id="ServicesCollapse" in={false}>
                  <div id="example-collapse-text">
                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "view-service" && (
                          <li
                            title="Service Name"
                            key={data.permission_id}
                            className="nav-link d-flex align-items-center cursor"
                            id="Service"
                            onClick={() => cusToggole("Service")}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <FaServicestack className="icon" />
                              <div className="text nav-text dropdown_menu">
                                Service Name
                                <span>
                                  <IoChevronDownOutline
                                    className={`ms-4 ${
                                      openTwo ? "rotate_icon" : ""
                                    }`}
                                  />
                                </span>
                              </div>
                            </div>
                          </li>
                        )
                    )}
                    <div className="dropdown_menu_item w-100">
                      <Collapse id="ServiceCollapse" in={false}>
                        <div id="example-collapse-text">
                          <NavLink to="final-service-module/main-services">
                            <p className="text nav-text mb-0 py-2">
                              <AiOutlineAntDesign className="icon" /> Main
                              Services
                            </p>
                          </NavLink>

                          <NavLink to="final-service-module/sub-services">
                            <p className="text nav-text mb-0 py-2">
                              <AiOutlineAntDesign className="icon" /> Sub
                              Services
                            </p>
                          </NavLink>

                          <NavLink to="final-service-module/child-services">
                            <p className="text nav-text mb-0 py-2">
                              <MdOutlineArrowDropDownCircle className="icon" />
                              Child Services
                            </p>
                          </NavLink>
                        </div>
                      </Collapse>
                    </div>

                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "view-pricing" && (
                          <li
                            title="Service Pricing"
                            key={data.permission_id}
                            className="nav-link d-flex align-items-center cursor"
                            id="ServiceCost"
                            onClick={() => cusToggole("ServiceCost")}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <FaServicestack className="icon" />
                              <div className="text nav-text dropdown_menu">
                                Service Pricing
                                <span>
                                  <IoChevronDownOutline
                                    className={`ms-4 ${
                                      openTwo ? "rotate_icon" : ""
                                    }`}
                                  />
                                </span>
                              </div>
                            </div>
                          </li>
                        )
                    )}
                    <div className="dropdown_menu_item w-100">
                      <Collapse id="ServiceCostCollapse" in={false}>
                        <div id="example-collapse-text">
                          <NavLink to="service-costing">
                            <p
                              className="text nav-text mb-0 py-2"
                              title="Service Costing"
                            >
                              <MdOutlinePriceChange className="icon" />
                              <span className={`text nav-text `}>
                                Service Costing
                              </span>
                            </p>
                          </NavLink>

                          <NavLink to="service-module/additional-services">
                            <p className="text nav-text mb-0 py-2">
                              <AiOutlineAntDesign className="icon" /> Additional
                              Services
                            </p>
                          </NavLink>
                          <NavLink to="service-module/additional-app-plugin">
                            <p className="text nav-text mb-0 py-2">
                              <AiOutlineAntDesign className="icon" /> Additional
                              App/Plugin
                            </p>
                          </NavLink>
                          <NavLink to="service-module/dynamic-module">
                            <p className="text nav-text mb-0 py-2">
                              <AiOutlineAntDesign className="icon" /> Dynamic
                              Module
                            </p>
                          </NavLink>
                          <NavLink to="service-module/dynamic-dropdown">
                            <p className="text nav-text mb-0 py-2">
                              <MdOutlineArrowDropDownCircle className="icon" />
                              Dynamic Dropdown
                            </p>
                          </NavLink>
                        </div>
                      </Collapse>
                    </div>

                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "all" && (
                          <NavLink key={data.permission_id} to="order-quote">
                            <li className="nav-link" title="Order Quote">
                              <AiOutlineSolution className="icon" />
                              <span className="text nav-text">
                                Service Order
                              </span>
                            </li>
                          </NavLink>
                        )
                    )}
                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "order-menu" && (
                          <NavLink to="orders" key={data.permission_id}>
                            <li className="nav-link" title="Orders">
                              <MdBorderColor className="icon" />
                              {adminUserInfo.role_id &&
                              adminUserInfo.role_id == "15" ? (
                                <span className="text nav-text">
                                  My Projects
                                </span>
                              ) : (
                                <span className="text nav-text">
                                  Order Details
                                </span>
                              )}
                            </li>
                          </NavLink>
                        )
                    )}
                  </div>
                </Collapse>
              </div>

              {JSON.parse(fetchPermissions)?.map(
                (data, index) =>
                  data?.permission_name === "view-our-work" && (
                    <li
                      key={data.permission_id}
                      className={`nav-link d-flex align-items-center cursor`}
                      id="ourworks"
                      title="Our Works"
                      onClick={() => cusToggole("ourworks")}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <FaProductHunt className="icon" />
                        <div className="text nav-text dropdown_menu">
                          Our Works
                          <span>
                            <IoChevronDownOutline
                              className={`ms-4 ${open ? "rotate_icon" : ""}`}
                            />
                          </span>
                        </div>
                      </div>
                    </li>
                  )
              )}

              <div className={`dropdown_menu_item w-100`}>
                <Collapse id="ourworksCollapse" in={false}>
                  <div id="">
                    <div>
                      {JSON.parse(fetchPermissions)?.map(
                        (data, index) =>
                          data?.permission_name ===
                            "view-our-work-category" && (
                            <NavLink
                              key={data.permission_id}
                              to="our-works/project-category"
                              title="Project Category"
                            >
                              <p className="text nav-text mb-0  py-2">
                                <SiNounproject className="icon" /> Project
                                Category
                              </p>
                            </NavLink>
                          )
                      )}
                    </div>

                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "view-our-work" && (
                          <NavLink
                            key={data.permission_id}
                            to="our-works/projects"
                            title="Our Projects"
                          >
                            <p className="text nav-text mb-0 py-2">
                              <RiListCheck2 className="icon" /> Our Projects
                            </p>
                          </NavLink>
                        )
                    )}
                  </div>
                </Collapse>
              </div>

              {JSON.parse(fetchPermissions)?.map(
                (data, index) =>
                  data?.permission_name === "all-appointment" && (
                    <NavLink key={data.permission_id} to="appointments">
                      <li className="nav-link" title="Appointments">
                        <AiOutlineSolution className="icon" />
                        <span className="text nav-text">Appointments</span>
                      </li>
                    </NavLink>
                  )
              )}

              {JSON.parse(fetchPermissions)?.map(
                (data, index) =>
                  data?.permission_name === "all-inbox" && (
                    <NavLink
                      key={data.permission_id}
                      to="inbox/order-conversation"
                    >
                      <li className="nav-link" title="Registered User">
                        <BsFillChatDotsFill className="icon" />
                        <span className="text nav-text">Live Chat</span>
                      </li>
                    </NavLink>
                  )
              )}

              {/* {JSON.parse(fetchPermissions)?.map(
                (data, index) =>
                  data?.permission_name === "news-room-menu" && (
                    <NavLink to="newsroom" key={index}>
                      <li className="nav-link" title="Newsroom">
                        <FaRegNewspaper className="icon" />
                        <span className="text nav-text">Newsroom</span>
                      </li>
                    </NavLink>
                  )
              )} */}

              {JSON.parse(fetchPermissions)?.map(
                (data, index) =>
                  data?.permission_name === "settings-menu" && (
                    <li
                      key={data.permission_id}
                      className="nav-link d-flex align-items-center cursor"
                      id="Settings"
                      onClick={() => cusToggole("Settings")}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <FiSettings className="icon" />
                        <div className="text nav-text dropdown_menu">
                          Settings
                          <span>
                            <IoChevronDownOutline
                              className={`ms-4 ${
                                openFour ? "rotate_icon" : ""
                              }`}
                            />
                          </span>
                        </div>
                      </div>
                    </li>
                  )
              )}
              <div
                className="dropdown_menu_item w-100"
                style={{ paddingLeft: "32px" }}
              >
                <Collapse id="SettingsCollapse" in={false}>
                  <div id="example-collapse-text ps-4">
                    <NavLink to="settings">
                      <p className="text nav-text mb-0 py-2">Maintenance</p>
                    </NavLink>

                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "view-role" && (
                          <NavLink
                            key={data.permission_id}
                            to="role-management"
                            title="Roles"
                          >
                            <p className={`text nav-text mb-0 py-2 `}>Roles</p>
                          </NavLink>
                        )
                    )}
                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "view-admin-user" && (
                          <NavLink
                            key={data.permission_id}
                            to="manage-admin"
                            title="Admin"
                          >
                            <p className={`text nav-text mb-0 py-2`}>Admin</p>
                          </NavLink>
                        )
                    )}
                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "employee-menu" && (
                          <NavLink
                            key={data.permission_id}
                            to="employee"
                            title="Employee"
                          >
                            <p className="text nav-text mb-0 py-2">Employee</p>
                          </NavLink>
                        )
                    )}
                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "view-visitors" && (
                          <NavLink to="visitors" key={data.permission_id}>
                            <p className="text nav-text mb-0 py-2">Visitors</p>
                          </NavLink>
                        )
                    )}
                  </div>
                </Collapse>
              </div>

              <li
                className="nav-link d-flex align-items-center cursor"
                id="Resources"
                onClick={() => cusToggole("Resources")}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <FaBlogger className="icon" />
                  <div className="text nav-text dropdown_menu">
                    Resources
                    <span>
                      <IoChevronDownOutline
                        className={`ms-4 ${openThree ? "rotate_icon" : ""}`}
                      />
                    </span>
                  </div>
                </div>
              </li>

              <div className="dropdown_menu_item w-100 ps-4">
                <Collapse id="ResourcesCollapse" in={false}>
                  <div id="example-collapse-text">
                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "blog-menu" && (
                          <li
                            key={data.permission_id}
                            className="nav-link d-flex align-items-center cursor"
                            id="Blogs"
                            onClick={() => cusToggole("Blogs")}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <FaBlogger className="icon" />
                              <div className="text nav-text dropdown_menu">
                                Blogs
                                <span>
                                  <IoChevronDownOutline
                                    className={`ms-4 ${
                                      openThree ? "rotate_icon" : ""
                                    }`}
                                  />
                                </span>
                              </div>
                            </div>
                          </li>
                        )
                    )}
                    <div className="dropdown_menu_item w-100 ps-5">
                      <Collapse id="BlogsCollapse" in={false}>
                        <div id="example-collapse-text">
                          {JSON.parse(fetchPermissions)?.map(
                            (data, index) =>
                              data?.permission_name ===
                                "view-post-category" && (
                                <NavLink
                                  to="blogs/category"
                                  key={data.permission_id}
                                >
                                  <p className="text nav-text mb-0 py-2">
                                    Category
                                  </p>
                                </NavLink>
                              )
                          )}
                          {JSON.parse(fetchPermissions)?.map(
                            (data, index) =>
                              data?.permission_name === "view-post" && (
                                <NavLink
                                  to="blogs/post"
                                  key={data.permission_id}
                                >
                                  <p className="text nav-text mb-0 py-2">
                                    Posts
                                  </p>
                                </NavLink>
                              )
                          )}
                        </div>
                      </Collapse>
                    </div>

                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "view-learning-center" && (
                          <NavLink
                            key={data.permission_id}
                            to="learning-center"
                          >
                            <li className="nav-link cursor">
                              <SiKnowledgebase className="icon" />
                              <span className="text nav-text">
                                Learning Center
                              </span>
                            </li>{" "}
                          </NavLink>
                        )
                    )}

                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "view-faq" && (
                          <li
                            key={data.permission_id}
                            className="nav-link d-flex align-items-center cursor"
                            id="FAQ"
                            onClick={() => cusToggole("FAQ")}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <FaQuora className="icon" />
                              <div className="text nav-text dropdown_menu">
                                Manage Faqs
                                <span>
                                  <IoChevronDownOutline
                                    className={`ms-4 ${
                                      openTwo ? "rotate_icon" : ""
                                    }`}
                                  />
                                </span>
                              </div>
                            </div>
                          </li>
                        )
                    )}
                    <div className="dropdown_menu_item w-100 ps-5">
                      <Collapse id="FAQCollapse" in={false}>
                        <div id="example-collapse-text">
                          <NavLink to="faq-category">
                            <p className="text nav-text mb-0 py-2">
                              Categories
                            </p>
                          </NavLink>

                          <NavLink to="faq">
                            <p className="text nav-text mb-0 py-2">Faqs</p>
                          </NavLink>
                        </div>
                      </Collapse>
                    </div>

                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "view-case-study" && (
                          <NavLink to="case-study" key={data.permission_id}>
                            <li className="nav-link" title="Case Study">
                              <IoIosFolderOpen className="icon" />
                              <span className="text nav-text">Case Study</span>
                            </li>
                          </NavLink>
                        )
                    )}
                  </div>
                </Collapse>
              </div>

              <li
                className={`nav-link d-flex align-items-center cursor`}
                id="company"
                title="Our Works"
                onClick={() => cusToggole("company")}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <FaProductHunt className="icon" />
                  <div className="text nav-text dropdown_menu">
                    Company
                    <span>
                      <IoChevronDownOutline
                        className={`ms-4 ${open ? "rotate_icon" : ""}`}
                      />
                    </span>
                  </div>
                </div>
              </li>

              <div className={`dropdown_menu_item w-100 ps-4`}>
                <Collapse id="companyCollapse" in={false}>
                  <div id="">
                    <div>
                      {JSON.parse(fetchPermissions)?.map(
                        (data, index) =>
                          data?.permission_name === "press-menu" && (
                            <li
                              key={data.permission_id}
                              to="press"
                              title="Press"
                            >
                              <p className="text nav-text mb-0 py-2">Press</p>
                            </li>
                          )
                      )}
                    </div>
                  </div>
                </Collapse>
              </div>

              <li
                className="nav-link d-flex align-items-center cursor"
                id="More"
                onClick={() => cusToggole("More")}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <FaBlogger className="icon" />
                  <div className="text nav-text dropdown_menu">
                    More
                    <span>
                      <IoChevronDownOutline
                        className={`ms-4 ${openThree ? "rotate_icon" : ""}`}
                      />
                    </span>
                  </div>
                </div>
              </li>

              <div className="dropdown_menu_item w-100 ps-4">
                <Collapse id="MoreCollapse" in={false}>
                  <div id="example-collapse-text">
                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "career-menu" && (
                          <li
                            key={data.permission_id}
                            className="nav-link d-flex align-items-center cursor"
                            id="Careers"
                            onClick={() => cusToggole("Careers")}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <FaUserTie className="icon" />
                              <div className="text nav-text dropdown_menu">
                                Careers
                                <span>
                                  <IoChevronDownOutline
                                    className={`ms-4 ${
                                      openTwo ? "rotate_icon" : ""
                                    }`}
                                  />
                                </span>
                              </div>
                            </div>
                          </li>
                        )
                    )}
                    <div className="dropdown_menu_item w-100 ps-4">
                      <Collapse id="CareersCollapse" in={false}>
                        <div id="example-collapse-text">
                          {JSON.parse(fetchPermissions)?.map(
                            (data, index) =>
                              data?.permission_name === "view-department" && (
                                <NavLink
                                  to="career/departments"
                                  key={data.permission_id}
                                >
                                  <p className="text nav-text mb-0 py-2">
                                    Departments
                                  </p>
                                </NavLink>
                              )
                          )}
                          {JSON.parse(fetchPermissions)?.map(
                            (data, index) =>
                              data?.permission_name === "view-location" && (
                                <NavLink
                                  to="career/locations"
                                  key={data.permission_id}
                                >
                                  <p className="text nav-text mb-0 py-2">
                                    Locations
                                  </p>
                                </NavLink>
                              )
                          )}
                          {JSON.parse(fetchPermissions)?.map(
                            (data, index) =>
                              data?.permission_name ===
                                "view-explore-openings" && (
                                <NavLink
                                  to="career/explore-openings"
                                  key={data.permission_id}
                                >
                                  <p className="text nav-text mb-0 py-2">
                                    Explore Openings
                                  </p>
                                </NavLink>
                              )
                          )}
                          {JSON.parse(fetchPermissions)?.map(
                            (data, index) =>
                              data?.permission_name ===
                                "view-job-application" && (
                                <NavLink
                                  to="career/application"
                                  key={data.permission_id}
                                >
                                  <p className="text nav-text mb-0 py-2">
                                    Job Applications
                                  </p>
                                </NavLink>
                              )
                          )}
                        </div>
                      </Collapse>
                    </div>
                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "view-tech-use" && (
                          <li
                            key={data.permission_id}
                            className="nav-link d-flex align-items-center cursor"
                            id="TechUse"
                            onClick={() => cusToggole("TechUse")}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <FaCode className="icon" />
                              <div className="text nav-text dropdown_menu">
                                Tech Use
                                <span>
                                  <IoChevronDownOutline
                                    className={`ms-4 ${
                                      openTwo ? "rotate_icon" : ""
                                    }`}
                                  />
                                </span>
                              </div>
                            </div>
                          </li>
                        )
                    )}
                    <div className="dropdown_menu_item w-100">
                      <Collapse id="TechUseCollapse" in={false}>
                        <div id="example-collapse-text">
                          <NavLink to="tech-use">
                            <p className="text nav-text mb-0 py-2">
                              <GrTechnology className="icon" />
                              Tech Use
                            </p>
                          </NavLink>

                          <NavLink to="tech-use-category">
                            <p className="text nav-text mb-0 py-2">
                              <MdCategory className="icon" /> Tech Use Category
                            </p>
                          </NavLink>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </Collapse>
              </div>

              <li
                className="nav-link d-flex align-items-center cursor"
                id="FutureWorks"
                onClick={() => cusToggole("FutureWorks")}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <FaBlogger className="icon" />
                  <div className="text nav-text dropdown_menu">
                    Future Works
                    <span>
                      <IoChevronDownOutline
                        className={`ms-4 ${openThree ? "rotate_icon" : ""}`}
                      />
                    </span>
                  </div>
                </div>
              </li>

              <div className="dropdown_menu_item w-100 ps-5">
                <Collapse id="FutureWorksCollapse" in={false}>
                  <div id="example-collapse-text">
                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "view-feedback" && (
                          <NavLink key={data.permission_id} to="feedback">
                            <li className="nav-link cursor">
                              <HiUserGroup className="icon" />
                              <span className="text nav-text">Feedback</span>
                            </li>
                          </NavLink>
                        )
                    )}
                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "subscriber-menu" && (
                          <NavLink to="subscriber" key={data.permission_id}>
                            <li className="nav-link" title="Subscriber">
                              <MdOutlineUnsubscribe className="icon" />
                              <span className="text nav-text">Subscriber</span>
                            </li>
                          </NavLink>
                        )
                    )}
                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "email-template" && (
                          <NavLink key={data.permission_id} to="email-template">
                            <li className="nav-link" title="Email Template">
                              <AiOutlineSolution className="icon" />
                              <span className="text nav-text">
                                Email Template
                              </span>
                            </li>
                          </NavLink>
                        )
                    )}
                    <NavLink to="asana-dashboard" state={{ asanaTeam }}>
                      <li className="nav-link" title="Asana">
                        <MdBorderColor className="icon" />
                        {adminUserInfo.role_id &&
                        adminUserInfo.role_id == "15" ? (
                          <span className="text nav-text">My Projects</span>
                        ) : (
                          <span className="text nav-text">Asana</span>
                        )}
                      </li>
                    </NavLink>
                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "calender-view" && (
                          <Link key={data.permission_id} to="calender">
                            <li className="nav-link cursor">
                              <SiKnowledgebase className="icon" />
                              <span className="text nav-text">Calendar</span>
                            </li>{" "}
                          </Link>
                        )
                    )}
                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "view-quality-work" && (
                          <NavLink to="quality-works">
                            <li className="nav-link" title="Quality Works">
                              <BiMenu className="icon" />
                              <span className="text nav-text">
                                Quality Works
                              </span>
                            </li>{" "}
                          </NavLink>
                        )
                    )}
                    <NavLink to="service-module/service">
                      <p className="text nav-text mb-0 py-2">
                        <AiOutlineAntDesign className="icon" /> Service
                      </p>
                    </NavLink>

                    <NavLink to="service-module/design-type">
                      <p className="text nav-text mb-0 py-2">
                        <AiOutlineAntDesign className="icon" /> Service Type
                      </p>
                    </NavLink>

                    <NavLink to="service-module/dynamic-dropdown">
                      <p className="text nav-text mb-0 py-2">
                        <MdOutlineArrowDropDownCircle className="icon" />
                        Dynamic Dropdown
                      </p>
                    </NavLink>
                    {JSON.parse(fetchPermissions)?.map(
                      (data, index) =>
                        data?.permission_name === "view-client" && (
                          <NavLink
                            key={data.permission_id}
                            to="registered-user"
                          >
                            <li className="nav-link" title="Registered User">
                              <FaRegRegistered className="icon" />
                              <span className="text nav-text">Clients</span>
                            </li>
                          </NavLink>
                        )
                    )}
                  </div>
                </Collapse>
              </div>

              <div onClick={LogoutAdmin}>
                <li className="nav-link cursor">
                  <FaSignOutAlt className="icon" />
                  <span className="text nav-text">Logout</span>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </nav>

      <section className={`rightbar `}>
        {/* <section style={{ marginBottom: "100px" }}> */}
        <Outlet />
        {/* </section> */}
      </section>
    </div>
  );
};

export default AdminContent;
