import { LinearProgress } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";
import Swal from "sweetalert2";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import getCookie from "../../../../Component/GetCookie";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const AdminFaq = () => {
  // fetch table data
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const renderAllInfo = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/faq`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setTableData(res.data?.all_info);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(true);
      });
  };

  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(`${BACKEND_BASE_URL}/api/v2/rianaire/faq/delete/${id}`, {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        })
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllInfo();
        });
    }
  };

  useEffect(() => {
    renderAllInfo();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar pageTitle="All Faq" />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <Link to="/admin/faq/add-new">
                    <Button variant="success" size="sm">
                      Add new Faq &nbsp;
                      <span>
                        <AiOutlinePlusCircle className="" />
                      </span>
                    </Button>
                  </Link>
                </div>
                {!isLoading && !error && tableData.length > 0 && (
                  <div className="table-responsive custom_table">
                    <div className="table_fixed">
                      <table className="table table-hover my-5">
                        <thead>
                          <tr>
                            <th scope="col">Handle</th>
                            <th scope="col">FAQ </th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData?.map((data, i) => (
                            <tr key={data.id}>
                              <td>
                                <Dropdown style={{ position: "unset" }}>
                                  <Dropdown.Toggle>
                                    <BsThreeDotsVertical color="#000" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {/* edit */}

                                    <Dropdown.Item
                                      as={Link}
                                      to={`/admin/faq/edit/${data?.id}`}
                                      className="text-black"
                                    >
                                      Edit
                                    </Dropdown.Item>

                                    {/* delete */}

                                    <Dropdown.Item
                                      onClick={() => deleteData(data.id)}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>{data?.title}</td>
                            </tr>
                          ))}
                          {!isLoading && !error && tableData?.length < 1 && (
                            <>
                              <div className="text-center text-danger">
                                <h1>No data Found</h1>
                              </div>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {isLoading && !error && <LinearProgress />}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminFaq;
