import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Col, Dropdown, Form, Row } from "react-bootstrap";
import { AiOutlineCaretDown } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiEdit2, FiSave } from "react-icons/fi";
import { Link } from "react-router-dom";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import getCookie from "../../../../Component/GetCookie";

import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import { ToastAlert } from "../../../../Component/ToastAlert";
import LinearProgressMui from "../../../../Component/Custom Loader/LinearProgress";
import { MdAdd } from "react-icons/md";
import Swal from "sweetalert2";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";
import { Reorder, useDragControls } from "framer-motion";
import { GrDrag } from "react-icons/gr";

function DragItem({ data, mainServices, handleDeleteData }) {
  const controls = useDragControls();
  const elementRef = useRef();

  const handlePointerDown = (e) => {
    const tr = e.target.closest("tr");
    elementRef.current = tr;
    tr.style.transition = "none";

    tr.style.position = "relative";
    tr.style.zIndex = 999;
    tr.style.background = "#F1F1F1";
    controls.start(e);
  };

  useEffect(() => {
    const handlePointerUp = () => {
      const tr = elementRef.current;
      if (tr) {
        tr.style.transition = "all linear 0.3s";
        tr.style.background = "inherit";
        tr.style.position = "static";
        tr.style.zIndex = 0;
      }
    };
    window.addEventListener("pointerup", handlePointerUp);
    return () =>
      setTimeout(() => {
        window.removeEventListener("pointerup", handlePointerUp);
      }, 2000);
  }, []);

  return (
    <Reorder.Item
      as="tr"
      value={data}
      dragListener={false}
      dragControls={controls}
      style={{ userSelect: "none" }}
    >
      <td className="d-flex align-items-center">
        <GrDrag
          style={{
            marginTop: "4px",
            cursor: "grab",
          }}
          onPointerDown={handlePointerDown}
        />
        <Dropdown style={{ position: "unset" }}>
          <Dropdown.Toggle>
            <span
              style={{
                backgroundColor: "#eeeeee",
                padding: "8px 12px",
                borderRadius: "8px",
              }}
            >
              <FiEdit2 color="#000" className="me-2" />
              <AiOutlineCaretDown color="#000" />
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {/* edit */}
            <Dropdown.Item
              as={Link}
              to={`/admin/service-module/additional-app-plugin/edit/${data?.id}`}
            >
              Edit
            </Dropdown.Item>
            {/* delete */}
            <Dropdown.Item onClick={() => handleDeleteData(data.id)}>
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>

      <td>{data?.main_service?.name}</td>
      <td>{data?.sub_service?.name}</td>
      <td>{data?.name}</td>
      <td>$ {data?.usd_price}</td>
      <td>Tk {data?.bdt_price}</td>
      <td>{data?.duration}</td>
    </Reorder.Item>
  );
}

const AdditionalAppAll = () => {
  const [additionalApp, setAdditionalApp] = useState([]);
  const [filterAdditionalApp, setFilterAdditionalApp] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [search, setSearch] = useState("");
  const [isSorting, setIsSorting] = useState(false);

  const fetchAdditionalAppService = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/additional-app-plugin-cost`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setAdditionalApp(
          res.data?.additional_app_plugins
          // .sort((a, b) =>
          //   a.name.localeCompare(b.name)
          // )
        );
        setFilterAdditionalApp(
          res.data?.additional_app_plugins
          // .sort((a, b) =>
          //   a.name.localeCompare(b.name)
          // )
        );
      });
  };

  const handleDeleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}/api/v2/rianaire/additional-app-plugin-cost/delete/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data.message,
            confirmButtonColor: "#5eba86",
          });
          fetchAdditionalAppService();
        });
    }
  };

  useEffect(() => {
    fetchAdditionalAppService();
  }, []);

  function filterWordpress(str) {
    if (str === "all") {
      setFilterAdditionalApp(additionalApp);
    } else {
      const result = additionalApp?.filter((data) => {
        return data.sub_service?.name.toLowerCase().match(str);
      });
      if (result.length != 0) {
        setFilterAdditionalApp(result);
      }
    }
  }

  const handleReorder = (e) => {
    setFilterAdditionalApp(e);
    updateOrder(e);
  };
  const debounceRef = useRef();

  function updateOrder(state) {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      if (state.length === 0) return;
      const formdata = new FormData();
      state.forEach((item, index) => {
        formdata.append("ids[]", item.id);
        formdata.append("display_orders[]", index + 1);
      });

      setIsSorting(true);
      axios
        .post(
          `${BACKEND_BASE_URL}/api/v3/rianaire/reorder/additional-app-plugin-cost`,
          formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {})
        .catch((error) => {
          // setIsLoading(false);
          setError(true);
        })
        .finally(() => {
          setIsSorting(false);
        });
    }, 300);
  }

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar pageTitle="All Additional App/Plugin" />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_end">
                  <ButtonGroup aria-label="Basic example">
                    <Button
                      variant="secondary"
                      onClick={() => filterWordpress("all")}
                    >
                      All
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => filterWordpress("wordpress")}
                    >
                      WordPress
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => filterWordpress("shopify")}
                    >
                      Shopify
                    </Button>
                  </ButtonGroup>
                </div>
                <div className="custom_table">
                  {isSorting && <LinearProgressMui />}
                  <div className="table_fixed">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            <Dropdown style={{ position: "unset" }}>
                              <Dropdown.Toggle>
                                <span
                                  style={{
                                    backgroundColor: "#eeeeee",
                                    padding: "8px 12px",
                                    borderRadius: "8px",
                                  }}
                                >
                                  <MdAdd color="#000" className="me-2" />
                                  <AiOutlineCaretDown color="#000" />
                                </span>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  as={Link}
                                  to={`/admin/service-module/additional-app-plugin/add-new`}
                                >
                                  Add additional app/plugin
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </th>
                          <th>Main Service</th>
                          <th>Sub Service</th>
                          <th>Additional</th>
                          <th>USD Price</th>
                          <th>BDT Price</th>
                          <th>Duration</th>
                        </tr>
                      </thead>
                      <Reorder.Group
                        layoutScroll
                        as="tbody"
                        axis="y"
                        values={filterAdditionalApp}
                        onReorder={handleReorder}
                      >
                        {isLoading && (
                          <tr>
                            <td colspan="7">
                              <LinearProgressMui />
                            </td>
                          </tr>
                        )}

                        {!isLoading && !error && additionalApp?.length > 0 && (
                          <>
                            {filterAdditionalApp.map((data, index) => {
                              return (
                                <DragItem
                                  key={data.id}
                                  data={data}
                                  handleDeleteData={handleDeleteData}
                                />
                              );
                            })}
                          </>
                        )}
                        {!isLoading && !error && additionalApp?.length < 1 && (
                          <>
                            <tr className="text-center text-danger">
                              <td colspan="7">No data Found</td>
                            </tr>
                          </>
                        )}
                      </Reorder.Group>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalAppAll;
