import { InputLabel, LinearProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../Component/GlobalVariables";
import Swal from "sweetalert2";
import { Button, Col, Dropdown, Form, Modal } from "react-bootstrap";
import AdminDashboardNavbar from "../../Component/AdminDashboardNavbar";
import { ToastAlert } from "../../Component/ToastAlert";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { CgAssign, CgClose } from "react-icons/cg";
import { FiSave } from "react-icons/fi";
import getCookie from "../../Component/GetCookie";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getLocalStorageWithExpiry } from "../../Component/LocalStorageWithExpiry";


const ManageAdmin = () => {
  const adminUsername = useRef();
  const adminUserEmail = useRef();
  const adminUserPassword = useRef();
  const adminUserConfirmPassword = useRef();
  const adminUserRoleId = useRef();
  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);

  // Modal Section Data
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("lg");

  // Vew single value
  const [singleAdminUserInfo, setSingleAdminUserInfo] = useState([]);

  // NOTE: last digit of axios request (example: 6) is permission id.

  // Get All Info
  const renderAllInfo = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/admin-user-management/6`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        if (res.data.status == 403) {
          navigate("/admin/bad-request");
        }

        setTableData(res.data?.all_admin_users);
      });
  };

  const [allRoles, setRoles] = useState([]);
  const renderAllRoles = async () => {
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/roles`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        if (res.data.status == 403) {
          navigate("/admin/bad-request");
        }
        setRoles(res.data?.all_roles);
      });
  };

  // Add new
  const addNewData = (modalValue) => {
    setModalSize("lg");
    setModalData(modalValue);
    setModalShow(true);
  };

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();
    formdata.append("name", adminUsername.current.value);
    formdata.append("email", adminUserEmail.current.value);
    formdata.append("password", adminUserPassword.current.value);
    formdata.append("confirm_password", adminUserConfirmPassword.current.value);
    formdata.append("role_id", adminUserRoleId.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/admin-user-management/store-new-admin/7`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        if (response.data.status == 403) {
          navigate("/admin/bad-request");
        }
        if (response.data?.status === 200) {
          ToastAlert.fire({
            title: response.data?.message,
          });
          renderAllInfo();
          e.target.reset();
          setModalShow(false);
        }
      });
    e.preventDefault();
  };

  //Show single data
  const showSingleData = (modalValue, data) => {
    setSingleAdminUserInfo(data);
    setModalShow(true);
    setModalSize("lg");
    setModalData(modalValue);
  };

  // Edit data
  const fetchDataForEdit = (modalValue, data) => {
    setModalData(modalValue);
    setModalSize("lg");
    setModalShow(true);
    setSingleAdminUserInfo(data);
  };
  // Updated data to backend
  const updateUserRole = (e) => {
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/admin-user-management/assign-role/${singleAdminUserInfo.id}/${adminUserRoleId.current.value}/10`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status == 403) {
          navigate("/admin/bad-request");
        }

        if (res.data?.status == 200) {
          ToastAlert.fire({
            title: res.data?.message,
          });
        } else {
          ToastAlert.fire({
            icon: "error",
            text: res.data?.errors?.message,
            confirmButtonColor: "#5eba86",
          });
        }
        setModalShow(false);
        renderAllInfo();
      });

    e.preventDefault();
  };

  // Delete Data
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}/api/v2/rianaire/admin-user-management/delete-admin-user/${id}/9`,
          {
            headers: {
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          if (res.data.status == 403) {
            navigate("/admin/bad-request");
          }
          Swal.fire({
            icon: "success",
            text: res.data?.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllInfo();
        });
    }
  };

  useEffect(() => {
    renderAllInfo();
    renderAllRoles();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>Manage Admin</h3>
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => addNewData("Add")}
                  >
                    Add &nbsp;
                    <span>
                      <AiOutlinePlusCircle className="mb-1" />
                    </span>
                  </Button>
                </div>
                {tableData.length > 0 ? (
                  <div className="table-responsive custom_table">
                    <div className="table_fixed">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Handle</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Role</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData?.map((data, index) => (
                            <tr key={index}>
                              <td>
                                <Dropdown style={{ position: "unset" }}>
                                  <Dropdown.Toggle>
                                    <BsThreeDotsVertical color="#000" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {/* view */}
                                    <Dropdown.Item
                                      onClick={() => {
                                        showSingleData("View", data);
                                      }}
                                    >
                                      View
                                    </Dropdown.Item>
                                    {/* Role assign */}
                                    {data?.name != "TRODAD" && (
                                      <Dropdown.Item
                                        onClick={() =>
                                          fetchDataForEdit("Edit", data)
                                        }
                                      >
                                        Assign Role
                                      </Dropdown.Item>
                                    )}
                                    {/* delete */}
                                    {data?.name != "TRODAD" && (
                                      <Dropdown.Item
                                        onClick={() => deleteData(data.id)}
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>{data?.name}</td>
                              <td>{data?.email}</td>
                              <td>{data?.role?.name}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <LinearProgress />
                )}
              </div>
            </div>
            <Modal
              show={modalShow}
              size={modalSize}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => setModalShow(false)}
            >
              <Modal.Header>
                <Modal.Title className="" id="contained-modal-title-vcenter">
                  {modalData}
                </Modal.Title>
                <Button
                  className="p-0 border-0 bg-danger"
                  onClick={() => setModalShow(false)}
                >
                  <CgClose
                    style={{
                      fontSize: "26px",
                    }}
                  />
                </Button>
              </Modal.Header>
              <Modal.Body>
                {/* Add Modal section */}
                {modalData === "Add" && (
                  <div className="">
                    <form onSubmit={storeData}>
                      <div className="content-wrapper">
                        <div className="card">
                          <div className="card-body">
                            <div className="row py-3">
                              {/* Name */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Name <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  placeholder="Name"
                                  ref={adminUsername}
                                />
                                <Form.Control.Feedback type="invalid">
                                  name is required
                                </Form.Control.Feedback>
                              </Form.Group>

                              {/* Email */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Email <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="email"
                                  placeholder="Email"
                                  ref={adminUserEmail}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Email is required
                                </Form.Control.Feedback>
                              </Form.Group>

                              {/* Password */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Password{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="password"
                                  placeholder="Enter password"
                                  ref={adminUserPassword}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Password is required
                                </Form.Control.Feedback>
                              </Form.Group>

                              {/* Confirm Password */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Confirm Password{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="password"
                                  placeholder="Retype password"
                                  ref={adminUserConfirmPassword}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Password is required
                                </Form.Control.Feedback>
                              </Form.Group>

                              {/* Role */}
                              <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label>
                                  Select Role
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  required
                                  ref={adminUserRoleId}
                                >
                                  <option>Select Role</option>
                                  {allRoles?.map((data, index) => {
                                    return (
                                      data.id != 10 && (
                                        <option key={index} value={data.id}>
                                          {data.name}
                                        </option>
                                      )
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>

                              {/* Submit button */}
                              <button
                                type="submit"
                                className="btn-submit mt-5 rounded-3 border-0"
                              >
                                <FiSave /> &nbsp; Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                )}

                {/* Edit modal section */}
                {modalData === "Edit" && (
                  <>
                    <form onSubmit={updateUserRole}>
                      <div className="content-wrapper">
                        <div className="card">
                          <div className="card-body">
                            <div className="row py-3">
                              <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label>
                                  Select Role
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  required
                                  ref={adminUserRoleId}
                                >
                                  <option>Select Role</option>
                                  {allRoles?.map((data, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={data.id}
                                        selected={
                                          singleAdminUserInfo.role_id == data.id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {data.name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>
                              <button
                                type="submit"
                                className="btn-submit mt-5 rounded-3 border-0"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </>
                )}

                {/* View Modal section */}
                {modalData === "View" && (
                  <>
                    <h4>Name: {singleAdminUserInfo?.name}</h4>
                    <p>Email: {singleAdminUserInfo?.email}</p>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setModalShow(false)}
                  className="btn-danger"
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageAdmin;
