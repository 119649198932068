import React from "react";
import axios from "axios";
import { useRef, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Button, Col, Form } from "react-bootstrap";
import RichTextEditor from "../../../Component/RichTextEditor";
import { FiSave } from "react-icons/fi";
import { LinearProgress } from "@mui/material";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import getCookie from "../../../Component/GetCookie";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";

const AdminCaseStudyEdit = () => {
  const { caseStudyId } = useParams();
  const title = useRef();
  const image = useRef();
  const description = useRef();
  const [value, setValue] = useState("");

  const getValue = (value) => {
    setValue(value);
  };
  // single table data
  const [singleData, setSingleData] = useState([]);

  // Image Preview
  const [files, setFile] = useState([]);

  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  // Get Single Info
  const renderSingleData = async () => {
    await axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/case-study/edit/${caseStudyId}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        setSingleData(res.data?.edit_info);
      });
  };

  // Updated data to backend
  const updatedData = (e) => {
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("title", title.current.value);
    if (image.current.files[0]) {
      formdata.append("image", image.current.files[0]);
    }
    formdata.append("description", description.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/case-study/update/${singleData?.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          e.target.reset();
          renderSingleData();
        } else {
          Swal.fire({
            icon: "warning",
            text: response.data.errors.name[0],
            confirmButtonColor: "#5eba86",
          });
        }
      });

    e.preventDefault();
  };
  useEffect(() => {
    renderSingleData();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_end mb-5">
                  <Link to="/admin/case-study">
                    <Button variant="success" size="sm">
                      View All News &nbsp;
                    </Button>
                  </Link>
                </div>
                {singleData ? (
                  <Form onSubmit={updatedData}>
                    <div className="content-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <div className="row py-3">
                            {/* add News title */}
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                News Title
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder=" News Title"
                                ref={title}
                                defaultValue={singleData?.title}
                              />
                            </Form.Group>

                            {/* add Image */}
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Featured Image
                              </Form.Label>
                              <Form.Control
                                type="file"
                                onChange={handleImgPreview}
                                ref={image}
                                defaultValue={singleData?.image}
                              />
                              {files.map((file, index) => {
                                return (
                                  <div key={index} className="Row">
                                    <span className="Filename">
                                      <img
                                        width={80}
                                        height={50}
                                        src={URL.createObjectURL(file)}
                                        alt={file.name}
                                      />
                                    </span>
                                  </div>
                                );
                              })}
                              {files.length == 0 && (
                                <img
                                  width={80}
                                  height={50}
                                  src={`${BACKEND_BASE_URL}${singleData?.image}`}
                                  alt={singleData?.title}
                                  name="img"
                                />
                              )}
                            </Form.Group>
                            {/* Description */}
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom02"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Description
                              </Form.Label>

                              <RichTextEditor
                                joditRef={description}
                                value={singleData?.description}
                                getValue={getValue}
                              />
                            </Form.Group>
                            <Button
                              type="submit"
                              className="btn-submit mt-5 rounded-3 border-0"
                            >
                              <FiSave /> &nbsp; Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                ) : (
                  <LinearProgress />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCaseStudyEdit;
