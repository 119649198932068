import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AiOutlineClose, AiOutlinePlusCircle } from "react-icons/ai";
import { FiSave } from "react-icons/fi";
import { Link } from "react-router-dom";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import getCookie from "../../../../Component/GetCookie";
import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import RichTextEditor from "../../../../Component/RichTextEditor";
import { ToastAlert } from "../../../../Component/ToastAlert";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const DynamicDropdownAdd = () => {
  const serviceName = useRef();
  const subServiceName = useRef();
  const childServiceName = useRef();
  const Name = useRef();
  const Details = useRef();
  const Note = useRef();

  const [mainServices, setMainServices] = useState([]);
  const [subServices, setSubServices] = useState([]);
  const [childServices, setChildServices] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [value, setValue] = useState("");
  const [noteValue, setNoteValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };
  const getNoteValue = (value) => {
    setNoteValue(value);
  };

  const fetchMainServices = async () => {
    await axios.get(`${BACKEND_BASE_URL}/api/v2/main-services`).then((res) => {
      setMainServices(res.data?.main_services);
    });
  };

  const fetchSubServices = (e) => {
    setSubServices([]);
    e.preventDefault();
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/sub-services/${serviceName.current.value}`
      )
      .then((res) => {
        setSubServices(res.data?.sub_services);
      });
  };

  const fetchDesignType = () => {
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/child-services/${serviceName.current.value}/${subServiceName.current.value}`
      )
      .then((res) => {
        setChildServices(res.data?.child_services);
      });
  };

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();
    formdata.append("main_service_id", serviceName.current.value);
    formdata.append("sub_service_id", subServiceName.current.value);
    if (
      childServiceName.current.value &&
      childServiceName.current.value != "0"
    ) {
      formdata.append("child_service_id", childServiceName.current.value);
    }
    formdata.append("name", Name.current.value);
    if (Details.current.value) {
      formdata.append("details", Details.current.value);
    }
    if (Note.current.value) {
      formdata.append("note", Note.current.value);
    }

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/dynamic-dropdown/store`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            title: "Submitted Successfully",
          });
          //   e.target.reset();
        }
      });
    e.preventDefault();
  };

  useEffect(() => {
    fetchMainServices();
  }, []);
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                {!isLoading && !error && (
                  <>
                    <div className="card-header flex_between">
                      <h3>Dynamic Dropdown</h3>
                      {JSON.parse(
                        localStorage.getItem("LOGGED_IN_USER_PERMISSION_INFO")
                      )?.map(
                        (data, index) =>
                          data?.permission_name === "create-service-cost" && (
                            <Link
                              to="/admin/service-module/dynamic-dropdown"
                              key={index}
                            >
                              <Button variant="success" size="sm">
                                All Dynamic Dropdown &nbsp;
                              </Button>
                            </Link>
                          )
                      )}
                    </div>

                    <div className="">
                      <Form onSubmit={storeData}>
                        <div className="content-wrapper">
                          <div className="card">
                            <div className="card-body">
                              <div className="row py-3">
                                {/* Department */}
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  lg="4"
                                  xl="4"
                                  controlId="validationCustom01"
                                  className="mb-3"
                                >
                                  <Form.Label className="label fw-bold">
                                    Main Service
                                  </Form.Label>
                                  <Form.Select
                                    required
                                    aria-label="language example "
                                    className=""
                                    ref={serviceName}
                                    onChange={(e) => {
                                      fetchSubServices(e);
                                    }}
                                  >
                                    <option>Select Main Service</option>
                                    {mainServices.map((mainService) => (
                                      <option
                                        key={mainService.id}
                                        value={mainService.id}
                                      >
                                        {mainService.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>

                                {/* Service Name */}
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  lg="4"
                                  xl="4"
                                  controlId="validationCustom01"
                                  className="mb-3"
                                >
                                  <Form.Label className="label fw-bold">
                                    Sub Service
                                  </Form.Label>
                                  <Form.Select
                                    required
                                    aria-label="language example "
                                    className=""
                                    ref={subServiceName}
                                    onChange={fetchDesignType}
                                  >
                                    <option value="" selected>
                                      Select Sub Service
                                    </option>
                                    {subServices?.length > 0 &&
                                      subServices?.map((subCategory) => (
                                        <option
                                          key={subCategory.id}
                                          value={subCategory.id}
                                        >
                                          {subCategory.name}
                                        </option>
                                      ))}
                                  </Form.Select>
                                </Form.Group>

                                {/* Service Type */}
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  lg="4"
                                  xl="4"
                                  controlId="validationCustom01"
                                  className="mb-3"
                                >
                                  <Form.Label className="label fw-bold">
                                    Child Service
                                  </Form.Label>
                                  <Form.Select
                                    aria-label="language example "
                                    className=""
                                    ref={childServiceName}
                                  >
                                    <option value="0" selected>
                                      Select Child Type
                                    </option>

                                    {childServices?.map((data) => (
                                      <option key={data.id} value={data.id}>
                                        {data.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>

                                <Form.Group
                                  as={Col}
                                  md="6"
                                  lg="6"
                                  xl="6"
                                  controlId="validationCustom01"
                                  className="mb-3"
                                >
                                  <Form.Label className="label fw-bold">
                                    Name
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="name"
                                    ref={Name}
                                  />
                                </Form.Group>
                              </div>

                              <div className="row">
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  lg="6"
                                  xl="6"
                                  controlId="validationCustom01"
                                  className="mb-3"
                                >
                                  <Form.Label className="label fw-bold">
                                    Details
                                  </Form.Label>
                                  <RichTextEditor
                                    joditRef={Details}
                                    value={value}
                                    getValue={getValue}
                                  />
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  lg="6"
                                  xl="6"
                                  controlId="validationCustom01"
                                  className="mb-3"
                                >
                                  <Form.Label className="label fw-bold">
                                    Note
                                  </Form.Label>
                                  <RichTextEditor
                                    joditRef={Note}
                                    value={noteValue}
                                    getValue={getNoteValue}
                                  />
                                </Form.Group>
                              </div>

                              <div className="flex_center">
                                <Button
                                  type="submit"
                                  className="btn-submit mt-5 rounded-3 border-0"
                                >
                                  <FiSave /> &nbsp; Save
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicDropdownAdd;
