import { LinearProgress } from "@mui/material";
import axios from "axios";
import { Reorder, useDragControls } from "framer-motion";
import moment from "moment";
import { memo, startTransition, useEffect, useRef, useState } from "react";
import { Col, Dropdown, Form, Row } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { AiOutlineCaretDown } from "react-icons/ai";
import { BsCheck2, BsCurrencyDollar } from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import { GrDrag } from "react-icons/gr";
import { MdAdd, MdArrowDropDown, MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import LinearProgressMui from "../../../../Component/Custom Loader/LinearProgress";
import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";
import { ToastAlert } from "../../../../Component/ToastAlert";
import "../serviceCosting.css";

const DragItem = memo(
  function DragItem({ data, editedTableId, handleDeleteClick, handleCancelClick, handleEditFormChange, fetchSubServiceCategory, fetchDesignType, additoinalAppServices, handleIncludeAppPlugin, handleExcludeAppPlugin, additoinalServices, handleAdditionalService, dynamicModule, handleEditClick, duplicateRow, handledynamicModule }) {
    const controls = useDragControls();
    const elementRef = useRef();
    const handlePointerDown = (e) => {
      const tr = e.target.closest("tr");
      elementRef.current = tr;
      tr.style.transition = "none";

      tr.style.position = "relative";
      tr.style.zIndex = 999;
      tr.style.background = "#F1F1F1";
      controls.start(e);
    };

    console.log("re render");

    useEffect(() => {
      const handlePointerUp = () => {
        const tr = elementRef.current;
        if (tr) {
          tr.style.transition = "all linear 0.3s";
          tr.style.background = "inherit";
          tr.style.position = "static";
          tr.style.zIndex = 0;
        }
      };
      window.addEventListener("pointerup", handlePointerUp);
      return () => window.removeEventListener("pointerup", handlePointerUp);
    }, []);

    return (
      <>
        {editedTableId === data.id ? (
          <Reorder.Item as="tr" value={data} dragListener={false} dragControls={controls} style={{ userSelect: "none", backgroundColor: "#f0f0f0" }}>
            <td>
              <div className="flex">
                <button type="submit" className="save_btn">
                  <BsCheck2 size="1.7rem" className="me-1 mb-1 mb-lg-0 cursor" color="green" />
                </button>

                <MdClose size="1.7rem" color="red" onClick={handleCancelClick} className="cursor" />
              </div>
            </td>
            <td>
              <Form.Group className="position-relative">
                <Form.Select
                  // name="main_service"
                  onChange={(e) => {
                    handleEditFormChange(e);
                    // fetchSubServiceCategory(e);
                  }}
                  name={"serviceName"}
                  className=""
                  disabled
                >
                  <option value={data?.main_service?.id}>{data?.main_service?.name}</option>
                </Form.Select>
                <MdArrowDropDown className="dropdown" />
              </Form.Group>
            </td>

            <td>
              <Form.Group className="position-relative">
                <Form.Select
                  // name="sub_service"
                  onChange={(e) => {
                    handleEditFormChange(e);
                    // fetchDesignType(e);
                  }}
                  name={"subServiceName"}
                  disabled
                >
                  <option value={data?.sub_service?.id}>{data?.sub_service?.name}</option>
                </Form.Select>
                <MdArrowDropDown className="dropdown" />
              </Form.Group>
            </td>
            <td>
              <Form.Group className="position-relative">
                <Form.Select
                  // name="child_service"
                  onChange={handleEditFormChange}
                  name={"ServiceType"}
                  disabled
                >
                  <option value={data?.child_service?.id}>{data?.child_service?.name ? data?.child_service?.name : "----"}</option>
                </Form.Select>
                <MdArrowDropDown className="dropdown" />
              </Form.Group>
            </td>
            <td>
              <Form.Group className="position-relative">
                <Form.Select
                  // name="section_type"
                  onChange={handleEditFormChange}
                  name={"SectionType"}
                  disabled
                >
                  <option value={data?.section_type?.id}>{data?.section_type?.name ? data?.section_type?.name : "----"}</option>
                  {/* {sectionType?.map((stype) => (
                                                <option
                                                  value={stype.id}
                                                  key={stype.id}
                                                  selected={
                                                    stype.id ==
                                                    data.section_type?.id
                                                      ? "selected"
                                                      : ""
                                                  }
                                                >
                                                  {stype.name}
                                                </option>
                                              ))} */}
                </Form.Select>
                <MdArrowDropDown className="dropdown" />
              </Form.Group>
            </td>

            <td>
              <input
                type="text"
                placeholder="page number"
                name="total_pages"
                defaultValue={data?.total_page}
                onChange={(e) => {
                  handleEditFormChange(e);
                }}
                // ref={totalPages}
              ></input>
            </td>
            <td>
              <input
                type="text"
                placeholder="duration"
                name="duration"
                defaultValue={data?.duration}
                onChange={(e) => {
                  handleEditFormChange(e);
                }}
                // ref={duration}
              ></input>
            </td>
            <td>
              <input
                type="text"
                placeholder="usd_price"
                name="usd_price"
                defaultValue={data?.usd_price}
                onChange={(e) => {
                  handleEditFormChange(e);
                }}
                // ref={usdPrice}
              ></input>
            </td>
            <td>
              <input
                type="text"
                placeholder="bdt_price"
                name="bdt_price"
                defaultValue={data?.bdt_price}
                onChange={(e) => {
                  handleEditFormChange(e);
                }}
                // ref={bdtPrice}
              ></input>
            </td>
            <td>
              <Select
                isMulti
                // options={[
                //   { value: "option1", label: "Option 1" },
                //   { value: "option2", label: "Option 2" },
                // ]}
                options={additoinalAppServices?.map((additional) => {
                  return {
                    label: additional.name,
                    value: additional.id,
                  };
                })}
                // ref={includeAppPluginRef}
                placeholder="Additional app"
                classNamePrefix="react-select"
                onChange={(e) => handleIncludeAppPlugin(e, data.id)}
              />
              {/* <input type="hidden" ref={includeAppPluginHidden} /> */}
            </td>
            <td>
              <Select
                isMulti
                options={additoinalAppServices?.map((additional) => {
                  return {
                    label: additional.name,
                    value: additional.id,
                  };
                })}
                placeholder="Additional app"
                classNamePrefix="react-select"
                onChange={(e) => {
                  handleExcludeAppPlugin(e, data.id);
                }}
              />
              {/* <input type="hidden" ref={excludeAppPluginHidden} /> */}
            </td>
            <td>
              <Select
                // ref={additionalServiceRef}
                isMulti
                options={additoinalServices?.map((additional) => {
                  return {
                    label: additional.name,
                    value: additional.id,
                  };
                })}
                placeholder="Additional app"
                classNamePrefix="react-select"
                onChange={(e) => {
                  handleAdditionalService(e, data.id);
                }}
              />
              {/* <input type="hidden" ref={additionalServiceHidden} /> */}
            </td>
            <td>
              <Select
                isMulti
                options={dynamicModule?.map((dynamic) => {
                  return {
                    label: dynamic.name,
                    value: dynamic.id,
                  };
                })}
                placeholder="Select Status"
                classNamePrefix="react-select"
                onChange={(e) => {
                  handledynamicModule(e, data.id);
                }}
              />
              {/* <input type="hidden" ref={dynamicModuleHidden} /> */}
            </td>
            <td>{moment(data?.updated_at).format("DD MMM, Y")}</td>
          </Reorder.Item>
        ) : (
          <Reorder.Item as="tr" value={data} dragListener={false} dragControls={controls} style={{ userSelect: "none" }}>
            <td className="d-flex align-items-center">
              <GrDrag
                style={{
                  marginTop: "4px",
                  cursor: "grab",
                }}
                onPointerDown={handlePointerDown}
              />
              <Dropdown style={{ position: "unset" }}>
                <Dropdown.Toggle>
                  <span
                    style={{
                      backgroundColor: "#eeeeee",
                      padding: "8px 12px",
                      borderRadius: "8px",
                    }}
                  >
                    <FiEdit2 color="#000" className="me-2" />
                    <AiOutlineCaretDown color="#000" />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={(event) => handleEditClick(event, data)}>Edit</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleDeleteClick(data.id)}>Delete</Dropdown.Item>
                  {/* )
                                              )} */}
                  <Dropdown.Item onClick={(e) => duplicateRow(e)}>Duplicate</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
            <td>
              {" "}
              <span className="input_box">{data?.main_service?.name}</span>
            </td>
            <td>
              {" "}
              <span className="input_box">{data?.sub_service?.name}</span>
            </td>
            <td>
              <span className="input_box">{data?.child_service?.name ? data?.child_service?.name : "----"}</span>
            </td>

            <td>{data?.section_type?.name ? data?.section_type?.name : "----"}</td>
            <td>{data?.total_page ? data?.total_page : "----"}</td>
            <td>{data?.duration}</td>
            <td>
              <BsCurrencyDollar /> {data?.usd_price}
            </td>
            <td>
              <span style={{ fontWeight: "900" }}>&#2547;&nbsp;</span>
              {data?.bdt_price}
            </td>
            <td className="position:relative">
              <Select
                isMulti
                isDisabled
                defaultValue={data?.include_app_plugins?.map((app_plugin) => {
                  return {
                    label: app_plugin?.additional_app_plugin?.name,
                    value: app_plugin?.additional_app_plugin?.id,
                  };
                })}
                classNamePrefix="react-select"
                // onChange={(value = "") => {
                //   handleStatus(value, data.id);
                // }}
              />
            </td>

            <td>
              <Select
                isDisabled
                defaultValue={data?.exclude_app_plugins?.map((app_plugin) => {
                  return {
                    label: app_plugin?.additional_app_plugin?.name,
                    value: app_plugin?.additional_app_plugin?.id,
                  };
                })}
                classNamePrefix="react-select"
                // onChange={(value = "") => {
                //   handleStatus(value, data.id);
                // }}
              />
            </td>
            <td>
              <Select
                isDisabled
                defaultValue={data?.additional_services?.map((service) => {
                  return {
                    label: service?.additional_service?.name,
                    value: service?.additional_service?.id,
                  };
                })}
                classNamePrefix="react-select"
                // onChange={(value = "") => {
                //   handleStatus(value, data.id);
                // }}
              />
            </td>
            <td>
              <Select
                isDisabled
                defaultValue={data?.dynamic_module?.map((service) => {
                  console.log(service);
                  return {
                    label: service?.dynamic_module?.name,
                    value: service?.dynamic_module?.id,
                  };
                })}
                classNamePrefix="react-select"
                // onChange={(value = "") => {
                //   handleStatus(value, data.id);
                // }}
              />
            </td>
            <td>{moment(data?.updated_at).format("DD MMM, Y")}</td>
          </Reorder.Item>
        )}
      </>
    );
  },
  function (prevProp, newProp) {
    let isSame = true;
    if (prevProp.editedTableId !== newProp.editedTableId) {
      isSame = false;
    }
    return isSame;
  }
);

const AllServiceCosting = () => {
  const serviceName = useRef("");
  const subServiceName = useRef();
  const ServiceType = useRef();
  const SectionType = useRef();
  const totalPages = useRef();
  const usdPrice = useRef();
  const bdtPrice = useRef();
  const duration = useRef();
  const additionalServiceRef = useRef();
  const includeAppPluginRef = useRef();

  const includeAppPluginHidden = useRef();
  const excludeAppPluginHidden = useRef();
  const additionalServiceHidden = useRef();
  const dynamicModuleHidden = useRef();

  const [tableData, setTableData] = useState([]);
  const [editedTableId, setEditedTableId] = useState(null);
  const [additoinalAppServices, setAdditoinalAppServices] = useState(null);
  const [additoinalServices, setAdditionalServices] = useState(null);
  const [dynamicModule, setDynamicModule] = useState([]);
  const [subServiceCategory, setSubServiceCategory] = useState([]);
  const [designType, setDesignType] = useState([]);

  const [includeAppPlugin, setIncludeAppPlugin] = useState([]);
  const [excludeAppPlugin, setExcludeAppPlugin] = useState([]);
  const [additionalService, setAdditionalService] = useState([]);
  const [dynamicModules, setdynamicModules] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isSorting, setIsSorting] = useState(false);

  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const headers = [
    { label: "Department", key: "department.name" },
    { label: "Service Name", key: "service.name" },
    { label: "Service Type", key: "service_type.name" },
    { label: "Section Type", key: "section_type.name" },

    { label: "Num of Page", key: "total_page" },
    { label: "Duration", key: "duration" },
    { label: "USD Price", key: "usd_price" },
    { label: "BDT Price", key: "bdt_price" },
  ];

  const fetchAdditionalAppService = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/additional-app-plugin-cost`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setAdditoinalAppServices(res.data?.additional_app_plugins);
        setIsLoading(false);
        setError(false);
      });
  };
  const fetchAdditionalServices = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/additional-service-cost`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setAdditionalServices(res.data?.additional_services);
      });
  };

  const fetchDynamicModuleServices = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/dynamic-module-cost`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setError(false);
        setDynamicModule(res.data?.dynamic_modules);
      });
  };

  const fetchSubServiceCategory = (e) => {
    setSubServiceCategory([]);
    e.preventDefault();
    axios.get(`${BACKEND_BASE_URL}/api/v2/get-sub-services/${e.target.value}`).then((res) => {
      setSubServiceCategory(res.data?.get_sub_services);
    });
  };

  const fetchDesignType = (e) => {
    axios.get(`${BACKEND_BASE_URL}/api/v2/child-services/${serviceName.current.value}/${e.target.value}`).then((res) => {
      setDesignType(res.data?.child_services);
    });
  };

  const fetchAllInfo = async () => {
    setIsLoading(true);
    await axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/new-service-cost`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setTableData(res.data?.all_info);
        setFilteredData(res.data?.all_info);
      });
  };

  const [editFormData, setEditFormData] = useState({});

  const handleEditClick = (event, data) => {
    event.preventDefault();
    setEditedTableId(data.id);
    console.log("data", data);

    setEditFormData({
      serviceName: data?.main_service_id,
      subServiceName: data?.sub_service.id,
      ServiceType: data?.child_service?.id,
      SectionType: data?.section_type?.id,
      total_pages: data?.total_page,
      bdt_price: data?.bdt_price,
      usd_price: data?.usd_price,
      duration: data?.duration,
      include_plugins: data?.include_app_plugins,
      exclude_plugins: data?.exclude_app_plugins,
      additional_services: data?.additional_services,
      dynamic_modules: data?.dynamic_modules,
    });
  };

  const handleEditFormChange = (e) => {
    e.preventDefault();
    setEditFormData({ ...editFormData, [e.target.name]: e.target.value });
  };

  const handleIncludeAppPlugin = (info) => {
    const appPlugin = info.map((info) => info.value);
    setIncludeAppPlugin(appPlugin);
    setEditFormData({ ...editFormData, includeAppPluginHidden: "includeAppPluginHidden" });
    // includeAppPluginHidden.current.value = "includeAppPluginHidden";
  };
  const handleExcludeAppPlugin = (info) => {
    const appPluginExclude = info.map((info) => info.value);
    setExcludeAppPlugin(appPluginExclude);
    setEditFormData({ ...editFormData, excludeAppPluginHidden: "excludeAppPluginHidden" });
    // excludeAppPluginHidden.current.value = "excludeAppPluginHidden";
  };
  const handleAdditionalService = (info) => {
    const additionalService = info.map((info) => info.value);
    setAdditionalService(additionalService);
    setEditFormData({ ...editFormData, additionalServiceHidden: "additionalServiceHidden" });
    // additionalServiceHidden.current.value = "additionalServiceHidden";
  };
  const handledynamicModule = (info) => {
    console.log("dynamic info");
    const dynamicModules = info.map((info) => info.value);
    setdynamicModules(dynamicModules);
    setEditFormData({ ...editFormData, dynamicModuleHidden: "dynamicModuleHidden" });
    // dynamicModuleHidden.current.value = "dynamicModuleHidden";
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    // console.log(editFormData);
    // const editedContact = {
    //   id: editedTableId,
    //   department: editFormData?.department?.name,
    //   service: editFormData?.service?.name,
    //   service_type: editFormData?.service_type?.name,
    //   section_type: editFormData?.section_type?.name,
    //   total_pages: editFormData?.total_page,
    //   bdt_price: editFormData?.bdt_price,
    //   usd_price: editFormData?.usd_price,
    //   duration: editFormData?.duration,
    // };

    // const newContacts = [...tableData];
    // const index = tableData.findIndex((data) => data.id === editedTableId);

    // newContacts[index] = editedContact;
    // setTableData(newContacts);

    console.log("editFormData", editFormData);
    setEditedTableId(null);
    const formdata = new FormData();
    formdata.append("_method", "PUT");

    formdata.append("main_service_id", editFormData.serviceName);
    formdata.append("sub_service_id", editFormData.subServiceName);
    formdata.append("child_service_id", editFormData.ServiceType);
    if (editFormData.SectionType) {
      formdata.append("section_type", editFormData.SectionType);
    }
    formdata.append("total_page", editFormData.total_pages);
    formdata.append("usd_price", editFormData.usd_price);
    formdata.append("bdt_price", editFormData.bdt_price);
    formdata.append("duration", editFormData.duration);

    // console.log("includeAppPlugin=>onChange", includeAppPlugin);
    // console.log("includeAppPlugin=>existing", editFormData?.include_plugins);
    // console.log("additionalAppRef=>existing", includeAppPluginRef);

    //! new included plugins
    if (includeAppPlugin.length > 0) {
      includeAppPlugin.forEach((item) => {
        formdata.append("include_app_plugin_id[]", item);
      });
    } else {
      if (editFormData.includeAppPluginHidden == "includeAppPluginHidden") {
        formdata.append("include_app_plugin_id[]", []);
      }
    }
    console.log("excludeAppPlugin.length", excludeAppPlugin.length);

    //! new excluded plugins
    if (excludeAppPlugin.length > 0) {
      excludeAppPlugin.forEach((item) => {
        formdata.append("exclude_app_plugin_id[]", item);
      });
    } else {
      if (editFormData.excludeAppPluginHidden == "excludeAppPluginHidden") {
        formdata.append("exclude_app_plugin_id[]", []);
      }
    }

    // ! new additional plugins
    if (additionalService.length > 0) {
      additionalService.forEach((item) => {
        formdata.append("additional_service_id[]", item);
      });
    } else {
      if (editFormData.additionalServiceHidden == "additionalServiceHidden") {
        formdata.append("additional_service_id[]", []);
      }
    }

    // ! new dynamic modules
    if (dynamicModules.length > 0) {
      dynamicModules.forEach((item) => {
        formdata.append("dynamic_module_id[]", item);
      });
    } else {
      if (editFormData.dynamicModuleHidden == "dynamicModuleHidden") {
        formdata.append("dynamic_module_id[]", []);
      }
    }

    axios
      .post(`${BACKEND_BASE_URL}/api/v2/rianaire/new-service-cost/update/${editedTableId}`, formdata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })

      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            text: "Updated successfully",
            confirmButtonColor: "#5eba86",
          });
          event.target.reset();
        } else {
        }
      });
  };

  const handleCancelClick = () => {
    setEditedTableId(null);
  };

  const handleDeleteClick = async (tableId) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(`${BACKEND_BASE_URL}/api/v2/rianaire/new-service-cost/delete/${tableId}`, {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
          },
        })
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: "Deleted Successfully",
            confirmButtonColor: "#5eba86",
          });
          fetchAllInfo();
        });
    }
  };

  const duplicateRow = (e, index) => {
    const duplicateRow = tableData[index];
    console.log(duplicateRow);

    const formdata = new FormData();
    formdata.append("main_service_id", duplicateRow.main_service.id);
    formdata.append("sub_service_id", duplicateRow.sub_service.id);
    formdata.append("child_service_id", duplicateRow.child_service.id);
    if (duplicateRow.section_type) {
      formdata.append("section_type[]", duplicateRow.section_type.id);
    }
    formdata.append("total_page[]", duplicateRow.total_page);
    formdata.append("usd_price[]", duplicateRow.usd_price);
    formdata.append("bdt_price[]", duplicateRow.bdt_price);
    formdata.append("duration[]", duplicateRow.duration);

    axios
      .post(`${BACKEND_BASE_URL}/api/v2/rianaire/new-service-cost/store`, formdata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })

      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            title: "data duplicate successful",
          });

          fetchAllInfo();
        }
      });
    e.preventDefault();
  };

  const handleReorder = (e) => {
    startTransition(() => {
      setFilteredData(e);
      updateOrder(e);
    });
  };

  const debounceRef = useRef();
  function updateOrder(state) {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      if (state.length === 0) return;
      const formdata = new FormData();
      state.forEach((item, index) => {
        formdata.append("ids[]", item.id);
        formdata.append("display_orders[]", index + 1);
      });

      setIsSorting(true);
      axios
        .post(`${BACKEND_BASE_URL}/api/v3/rianaire/reorder/new-service-cost`, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
          },
        })
        .then((res) => {})
        .catch((error) => {
          setError(true);
        })
        .finally(() => {
          setIsSorting(false);
        });
    }, 1000);
  }

  useEffect(() => {
    fetchAllInfo();
    fetchAdditionalAppService();
    fetchAdditionalServices();
    fetchDynamicModuleServices();
  }, []);

  useEffect(() => {
    const result = tableData.filter((data) => {
      return data.main_service?.name.toLowerCase().match(search.toLowerCase()) || data.sub_service?.name.toLowerCase().match(search.toLowerCase()) || data.child_service?.name.toLowerCase().match(search.toLowerCase()) || data.section_type?.name.toLowerCase().match(search.toLowerCase());
    });
    if (result.length != 0) {
      setFilteredData(result);
    }
  }, [search]);

  return (
    <div className="main__container">
      <div className="body-wrapper ">
        <AdminDashboardNavbar pageTitle="Service Cost" />

        <div className="col-md-12 p-4">
          <div className="card" style={{maxHeight:"100%", overflow:"hidden"}}>
            <div className="card-body">
              <div className="col-lg-12">
                <>
                  <div className="custom_table">
                    <Form onSubmit={handleEditFormSubmit}>
                      <Row className="flex_between mb-3">
                        <Col md={6}>
                          <div className="d-flex ms-4">
                            <div className="me-5 d-flex flex-column">
                              <b>40</b>
                              <span>Departments</span>
                            </div>
                            <div className="me-5 d-flex flex-column">
                              <b>40</b>
                              <span>Services</span>
                            </div>
                          </div>
                        </Col>
                        <Col md={4} className="mb-2">
                          <Form.Control type="text" placeholder="search here..." value={search} onChange={(e) => setSearch(e.target.value)} />
                        </Col>
                      </Row>
                      <div className="table_fixed">
                        {isSorting && <LinearProgressMui />}
                        <table className="table">
                          <thead>
                            <tr>
                              <th>
                                <Dropdown style={{ position: "unset" }}>
                                  <Dropdown.Toggle>
                                    <span
                                      style={{
                                        backgroundColor: "#eeeeee",
                                        padding: "8px 12px",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      <MdAdd color="#000" className="me-2" />
                                      <AiOutlineCaretDown color="#000" />
                                    </span>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item>
                                      {/* {JSON.parse(
                                        localStorage.getItem(
                                          "LOGGED_IN_USER_PERMISSION_INFO"
                                        )
                                      )?.map(
                                        (data, index) =>
                                          data?.permission_name ===
                                            "create-service-cost" && ( */}
                                      <Link to="/admin/service-costing/add-new" className="text-black">
                                        Add Service
                                      </Link>
                                      {/* )
                                      )} */}
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button" className="csv_export_btn">
                                      <CSVLink data={filteredData} headers={headers} filename={"service-cost.csv"} target="_blank">
                                        CSV Export
                                      </CSVLink>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </th>
                              <th>Department</th>
                              <th>Service name</th>
                              <th>Service type</th>
                              <th>Section type</th>
                              <th>Num of Page/Section Type/Store Type</th>
                              <th>Duration</th>
                              <th>USD price</th>
                              <th>BDT price</th>
                              <th>Include Additional App</th>
                              <th>Exclude Additional App</th>
                              <th>Additional Services</th>
                              <th>Dynamic Module</th>
                              <th>Updated Date</th>
                            </tr>
                          </thead>

                          <Reorder.Group layoutScroll as="tbody" axis="y" values={filteredData} onReorder={handleReorder}>
                            {isLoading && !error && (
                              <tr>
                                <td colSpan="14">
                                  <LinearProgress />
                                </td>
                              </tr>
                            )}
                            {!isLoading &&
                              filteredData?.length > 0 &&
                              filteredData
                                // .slice(
                                //   page * rowsPerPage,
                                //   page * rowsPerPage + rowsPerPage
                                // )
                                .map((data, index) => (
                                  <DragItem
                                    key={data.id}
                                    data={data}
                                    editedTableId={editedTableId}
                                    handleCancelClick={handleCancelClick}
                                    handleDeleteClick={handleDeleteClick}
                                    handleEditFormChange={handleEditFormChange}
                                    fetchSubServiceCategory={fetchSubServiceCategory}
                                    fetchDesignType={fetchDesignType}
                                    additoinalAppServices={additoinalAppServices}
                                    handleIncludeAppPlugin={handleIncludeAppPlugin}
                                    handleExcludeAppPlugin={handleExcludeAppPlugin}
                                    additoinalServices={additoinalServices}
                                    handleAdditionalService={handleAdditionalService}
                                    dynamicModule={dynamicModule}
                                    handledynamicModule={handledynamicModule}
                                    handleEditClick={handleEditClick}
                                    duplicateRow={duplicateRow}
                                    // handledynamicModule={handledynamicModule}
                                  />
                                ))}

                            {!isLoading && filteredData?.length < 1 && (
                              <>
                                <tr className="text-center text-danger">
                                  <td colSpan="14">No data Found</td>
                                </tr>
                              </>
                            )}
                          </Reorder.Group>
                        </table>
                      </div>
                    </Form>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllServiceCosting;
